const FONT = {
  IOS: {
    Bold: "HelveticaNeue-Bold",
    Regular: "HelveticaNeue"
  },
  ANDROID: {
    Bold: "Roboto-Bold",
    Normal: "Roboto"
  }
};

export default FONT;
