import React, { Component } from "react";
import { StyleSheet, View, TextInput, Modal, TouchableHighlight, Platform, Dimensions, KeyboardAvoidingView } from "react-native";
import {
  Button,
  Text,
  Container,
  Header,
  Left,
  Right,
  Icon,
  Content,
  Form,
  Item,
  Label,
  Body,
  Title,
  Input,
  Picker,
	Toast, Textarea, Spinner
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { CommonActions } from '@react-navigation/native';

import * as Linking from 'expo-linking';

import { Col, Row, Grid } from "../lib/easy-grid";

import { Routes } from '../navigation/routes';
import { connect } from 'react-redux';
import agent from '../agent'
import {
	setAuthToken,
  setAuthUser,
  setAuthUserDetails
} from '../ducks/auth';

import { TouchableOpacity } from "react-native-gesture-handler";

const mapStateToProps = state => ({ ...state.bill, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
  setAuthUser: (user) => dispatch(setAuthUser(user)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
});

class GeneralSettingsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      zip_code_5: "",
      zip_code_9: "",
      street_address: "",
      screenname: "",
      apt_number: "",
      about_me: "",
      city: "",
      state: "",
      age_group: "",
      party: "",
      religion: "",
      g_street_address: "",
      g_apt_number: "",
      g_city: "",
      g_state: "",
      deactivate: false
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this.focusListener = this.props.navigation.addListener('focus', () => {
      agent.Auth.get_personal_details()
      .then(res => {
        this.props.setAuthUserDetails(res)
        this.refreshData()
      })    
    })
  } 

  componentWillUnmount() {
    this.focusListener();
  }

  refreshData = () => {
    this.setState({first_name: this.props.user.first_name})
    this.setState({last_name: this.props.user.last_name})
    this.setZipCode()
    this.setState({city: this.props.user.city})
    this.setState({about_me: this.props.user.about_me})
    this.setState({state: this.props.user.state})
    this.setState({g_city: this.props.user.city})
    this.setState({g_state: this.props.user.state})
    this.setState({screenname: this.props.user.screen_name})
    this.setState({g_street_address: this.props.user.street_address})
    this.setState({g_apt_number: this.props.user.apt_number})
    this.setState({street_address: this.props.user.street_address})
    this.setState({apt_number: this.props.user.apt_number})
    this.setState({age_group: this.props.user.age_group})
    this.setState({party: this.props.user.party_affiliation})
    this.setState({religion: this.props.user.religion})
  }

  updatecity = (zip) => {
    if(zip.length > 5) {
      zip = zip.substring(0,5);
    }
    this.setState({zip_code_5: zip})
    agent.Home.getcity(zip)
    .then(res => {
      this.setState({city: res.city})
      this.setState({state: res.state})
    })
  }

  setNineZipCode = (zip) => {
    if(zip.includes("-")) {
      if(zip.length > 10) {
        zip = zip.substring(0,10);
      }
    } else {
      if(zip.length > 9) {
        zip = zip.substring(0,9);
      }
    }
    this.setState({zip_code_9: zip})
  }

  setZipCode = () => {
    if (this.props.user["5-digit-zip-code"] != undefined && this.props.user["5-digit-zip-code"] != "") {
      this.setState({ zip_code_5: this.props.user["5-digit-zip-code"] })
    }
    if (this.props.user["9-digit-zip-code"] != undefined && this.props.user["9-digit-zip-code"] != "") {
      this.setState({ zip_code_9: this.props.user["9-digit-zip-code"] })
      this.setState({ zip_code_5: this.props.user["9-digit-zip-code"].substring(0,5) })
      // if (this.props.user["5-digit-zip-code"] != undefined)
      //   this.setState({ zip_code_5: this.props.user["5-digit-zip-code"].substring(0,5) })
    }
  }

  checkReqdFields = () => {
    if(this.state.first_name != "" && this.state.last_name != "" && (this.state.zip_code_5 != "" || this.state.zip_code_9 != "" || this.state.g_street_address != "") && (this.state.city != "" || this.state.g_city != "") && (this.state.g_state != "" || this.state.state != ""))
      return true
    else
      return false
  }

  openUsps = () => {
    Linking.openURL("https://tools.usps.com/go/ZipLookupAction_input");
  }

  updateUser = () => {
    if(this.checkReqdFields()) {
      let u = {
        user: {
          age_group: this.state.age_group,
          city: this.state.city,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          party_affiliation: this.state.party,
          religion: this.state.religion,
          screenname: this.state.screenname,
          state: this.state.state,
          about_me: this.state.about_me,
          zip_code: (this.state.zip_code_9 != "" ? this.state.zip_code_9 : this.state.zip_code_5),
        }
      }
      if(this.state.zip_code_9 != "") {
        delete u.user.city
        delete u.user.state
      }
      if (this.state.g_street_address != "") {
        delete u.user.city
        delete u.user.state
        u = {
          ...u,
          apt_number: this.state.g_apt_number,
          street_address: this.state.g_street_address,
          city: this.state.g_city,
          state: this.state.g_state,
        }
      }
      agent.Auth.update(u)
      .then(res => {
        console.log(res)
        if (res.message == "Successfully updated") {
          agent.Auth.get_personal_details()
            .then(res => {
              this.props.setAuthUserDetails(res)
              Toast.show({
                text: 'Successfully updated profile.',
                duration: 5000,
                type: "success",
                textStyle: {textAlign: "center"}
              })
              this.refreshData()
            })
        } else {
          Toast.show({
            text: res.message,
            duration: 5000,
            type: "danger",
            textStyle: {textAlign: "center"}
          })
        }
        // this.props.navigation.navigate(Routes.HOME);
      })
    } else {
				Toast.show({
              text: 'Please fill in all required fields.',
							duration: 5000,
              type: "danger",
              textStyle: {textAlign: "center"}
            })
    }
  }

  deactivateAccount = () => {
    this.setState({action_loading: true})
    agent.Auth.deactivate()
    .then(res => {
      this.setState({action_loading: false})
      agent.setToken("")
      this.props.setAuthToken("");
      AsyncStorage.setItem('@auth_token', "")
      this.props.setAuthUser({})
      this.props.navigation.dispatch(state => {
        return CommonActions.reset({
          routes: [
            { name: Routes.SIGN_IN }
          ],
          index: 0,
        });
      });
    })
  } 

  openDeactiveAccount = () => {
    this.setState({deactivate: true})
  }

  render() {
    return (
      <Container style={styles.container}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.deactivate}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Reply</Text>
              </View>
              <View>
                <Text>Are you sure you want to delete your account?</Text>
              </View>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ deactivate: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.deactivateAccount()
                  }}
                >
                  <Text style={styles.textStyle}>Ok</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Row style={[styles.buttonMargin]}>
          <Button onPress={this.updateUser} style={{width: "100%", height: 35, backgroundColor: "#2196F3"}}>
            <Text>Save</Text>
          </Button>
        </Row>

        <Content padder style={styles.container}>
          <View style={styles.contentMargin}>
            <Form>
              <Grid>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>First Name<Text style={{color: "red"}}>*</Text></Label>
                      <Input style={styles.text} value={this.state.first_name} autoCapitalize='none' onChangeText={(text) => { this.setState({first_name: text}); }} />
                    </Item>
                  </Col>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>Last Name<Text style={{color: "red"}}>*</Text></Label>
                      <Input style={styles.text} value={this.state.last_name} autoCapitalize='none' onChangeText={(text) => { this.setState({last_name: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>5-digit Zipcode<Text style={{color: "red"}}>*</Text></Label>
                      <Input style={styles.text} value={this.state.zip_code_5} autoCapitalize='none' onChangeText={this.updatecity} />
                    </Item>
                  </Col>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>City</Label>
                      <Input style={styles.text} value={this.state.city} onChangeText={(text) => { this.setState({city: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>State</Label>
                      <Input style={styles.text} value={this.state.state} onChangeText={(text) => { this.setState({state: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row style={{marginTop: 20, marginLeft: 15, marginBottom: 10}}>
                  <Col>
                    <Textarea bordered rowSpan={5} style={styles.text} placeholder="About me (Optional) Max 30 characters" placeholderTextColor="#fff"
                            maxLength={30} onChangeText={(text) => { this.setState({about_me: text }) }} value={this.state.about_me} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>Screen Name (Optional)</Label>
                      <Input style={styles.text} autoCapitalize='none' value={this.state.screenname} onChangeText={(text) => { this.setState({screenname: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>9-digit Zipcode (Optional)</Label>
                      <Input style={styles.text} autoCapitalize='none' value={this.state.zip_code_9} onChangeText={ this.setNineZipCode } /> 
                    </Item> 
                  </Col>
                </Row>
                <Row style={{marginLeft: 10, marginTop: -16}}>
                  <TouchableOpacity onPress={() => this.openUsps()}>
                    <Text style={[{fontSize: 15, textDecorationLine: "underline"}, styles.optional]}>Tap here to get your 9-digit zipcode</Text>
                  </TouchableOpacity>
                </Row>
                <Row style={{justifyContent: "center"}}><Text style={[styles.text, styles.optional, { textAlign: "center" }]}>(or)</Text></Row>
                <Row style={{marginLeft: 10}}><Text style={[styles.text, styles.optional, { textAlign: "center" }]}>Address (Optional)</Text></Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>Street Address</Label>
                      <Input style={styles.text} value={this.state.g_street_address} autoCapitalize='none' onChangeText={(text) => { this.setState({g_street_address: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>Apartment No.</Label>
                      <Input style={styles.text} autoCapitalize='none' value={this.state.g_apt_number} onChangeText={(text) => { this.setState({g_apt_number: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>City</Label>
                      <Input style={styles.text} autoCapitalize='none' value={this.state.g_city} onChangeText={(text) => { this.setState({g_city: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>State</Label>
                      <Input style={styles.text} autoCapitalize='none' value={this.state.g_state} onChangeText={(text) => { this.setState({g_state: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row style={{marginLeft: 10}}><Text style={[styles.text, styles.optional, { textAlign: "center" }]}>Demographics (Optional)</Text></Row>
                <Row style={{marginBottom: 20}}>
                  <Col style={{marginLeft: 15, marginTop: 25}}>
                    <Item picker>
                      <Label style={styles.text}>Age Group</Label>
                      <Col style={{alignItems: DetectDeviceService.isTablet ? "flex-start" : "flex-end", marginLeft: 55}}>
                        <Picker
                          mode="dropdown"
                          style={{ width: 150 }}
                          iosIcon={<Icon style={{ color: "#fff" }} name="arrow-down" />}
                          textStyle={{ color: "#fff" }}
                          placeholder="Select"
                          placeholderStyle={{ color: "#fff" }}
                          placeholderIconColor="#fff"
                          selectedValue={this.state.age_group}
                          onValueChange={(val) => { this.setState({age_group: val}); }}
                        >
                          <Picker.Item label="18-25" value="18-25" />
                          <Picker.Item label="25-30" value="25-30" />
                          <Picker.Item label="30-35" value="30-35" />
                          <Picker.Item label="35-40" value="35-40" />
                          <Picker.Item label="40-45" value="40-45" />
                          <Picker.Item label="45-50" value="45-50" />
                          <Picker.Item label="50-55" value="50-55" />
                          <Picker.Item label="55-65" value="55-65" />
                          <Picker.Item label=">65" value=">65" />
                        </Picker>
                      </Col>
                    </Item>
                  </Col>
                </Row>
                <Row style={{marginBottom: 20}}>
                  <Col style={{marginLeft: 15}}>
                    <Item picker>
                      <Label style={styles.text}>Party Affiliation</Label>
                      <Col style={{alignItems: DetectDeviceService.isTablet ? "flex-start" : "flex-end", marginLeft: 19}}>
                        <Picker
                          mode="dropdown"
                          style={{ width: 150 }}
                          iosIcon={<Icon style={{ color: "#fff" }} name="arrow-down" />}
                          textStyle={{ color: "#fff", textAlign: "right" }}
                          placeholder="Select"
                          placeholderStyle={{ color: "#fff" }}
                          placeholderIconColor="#fff"
                          selectedValue={this.state.party}
                          onValueChange={(val) => { this.setState({party: val}); }}
                        >
                          <Picker.Item label="Democrat" value="Democrat" />
                          <Picker.Item label="Republican" value="Republican" />
                          <Picker.Item label="Unaffiliated" value="Unaffiliated" />
                          <Picker.Item label="Other" value="Other" />
                        </Picker>
                      </Col>
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col style={{marginLeft: 15}}>
                    <Item picker>
                      <Label style={styles.text}>Religion</Label>
                      <Col style={{alignItems: DetectDeviceService.isTablet ? "flex-start" : "flex-end", marginLeft: 74}}>
                        <Picker
                          mode="dropdown"
                          style={{ width: 160 }}
                          iosIcon={<Icon style={{ color: "#fff" }} name="arrow-down" />}
                          textStyle={{ color: "#fff" }}
                          placeholder="Select"
                          placeholderStyle={{ color: "#fff" }}
                          placeholderIconColor="#fff"
                          selectedValue={this.state.religion}
                          onValueChange={(val) => { this.setState({religion: val}); }}
                        >
                          <Picker.Item label="Non-religious" value="Non-religious" />
                          <Picker.Item label="Christian" value="Christian" />
                          <Picker.Item label="Jewish" value="Jewish" />
                          <Picker.Item label="Islam" value="Islam" />
                          <Picker.Item label="Hindu" value="5" />
                          <Picker.Item label="Buddhist" value="6" />
                          <Picker.Item label="Other" value="7" />
                        </Picker>
                      </Col>
                    </Item>
                  </Col>
                </Row>
              </Grid>
            </Form>
            <Button light style={styles.voiceopinionbutton} onPress={this.openDeactiveAccount}>
              <Text style={{textAlign: "center", color: "white"}}>Delete Account</Text>
            </Button>
          </View>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgb(43, 94, 170)",
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "#fff"
  },
  contentMargin: {
    // marginLeft: Platform.isPad ? 170 : 0,
    // marginRight: Platform.isPad ? 170 : 0,
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "76.5%" : "100%",
    marginRight: DetectDeviceService.isTab ? 0 : DetectDeviceService.isTablet ? 64 : 0,
    alignSelf: "center",
    backgroundColor: "#fff",
    paddingRight: DetectDeviceService.isTab ? "auto" : DetectDeviceService.isTablet ? 50 : 0,
    paddingLeft: DetectDeviceService.isTab ? "auto" : DetectDeviceService.isTablet ? 50 : 0,
    paddingTop: DetectDeviceService.isTablet ? 20 : 0,
  },
  buttonMargin: {
    marginLeft: DetectDeviceService.isTab ? 30 : DetectDeviceService.isTablet ? 149 : 20,
    // marginRight: Platform.isPad ? 170 : 0,
    width: 65,
    // marginRight: DetectDeviceService.isTablet ? 64 : 0,
    // alignSelf: "center",
    backgroundColor: "rgb(236, 236, 236)",
    // paddingRight: DetectDeviceService.isTablet ? 50 : 0,
    // paddingLeft: DetectDeviceService.isTablet ? 50 : 0,
    // paddingTop: DetectDeviceService.isTablet ? 20 : 0,
  },
  header: {
    fontSize: 15,
    width: 180
  },
  text: {
    // color: "#FFF",
  },
  optional: {
    marginTop: 20
  },
  voiceopinionbutton: {
    // backgroundColor: "red",
    width: DetectDeviceService.isTablet ? "20%" : "100%",
    height: 40,
    alignSelf: "flex-end",
    // alignItems: "flex-end",
    justifyContent: "center",
    fontSize: 12,
    marginTop: 30
  },
  forgotcancel: {
		height: 36,
		width: 90,
    marginRight: 20
  },
  forgotsubmit: {
		height: 36,
		width: 50
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsScreen);