const LOAD_SHARES = 'LOAD_SHARES'
const UPDATE_SHARE = 'UPDATE_SHARE'
const NEW_SHARE_TEXT = 'NEW_SHARE_TEXT'

export function setShares(list){
  return {
    type: LOAD_SHARES,
    list
  }
}

export function updateShare(shareId, share){
  return {
    type: UPDATE_SHARE,
    shareId,
    share
  }
}

export function newShareText(share){
  return {
    type: NEW_SHARE_TEXT,
    share,
  }
}

const initialState = {
  list: {},
	details: {},
  new_share_text: null
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SHARES:
      return Object.assign(
        {},
        state,
        {
          list: action.list,
        }
      );
    case UPDATE_SHARE:
      return Object.assign(
        {},
        state,
        {
          list: { 
            ...state.list,
            ...{[action.shareId]: action.share}
          },
        }
      );
    case NEW_SHARE_TEXT:
      return Object.assign(
        {},
        state,
        {
          new_share_text: action.share,
        }
      );
    default:
      return state;
  }
}
