import React from "react";
import { StyleSheet, View, Platform, Dimensions } from "react-native";
import {
  Container,
  Content,
  Tab,
  Tabs,
  TabHeading,
  Item,
  Input,
  Icon,
  Text,
  Button,
  Spinner,
  Segment,
  List
} from 'native-base';

import DetectDeviceService from '../lib/devicedetection';

import {
  TabView,
  TabBar,
  SceneMap,
} from 'react-native-tab-view';

import ConnectUser from "../components/ConnectUser";

import { Col, Row, Grid } from "../lib/easy-grid";

import agent from '../agent'
import { connect } from 'react-redux';

import {
  setConnectUsers,
  setInvitations,
  setSuggestions
} from '../ducks/connect';

const mapStateToProps = state => ({ ...state.connect, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setConnectUsers: (users) => dispatch(setConnectUsers(users)),
  setInvitations: (users) => dispatch(setInvitations(users)),
  setSuggestions: (users) => dispatch(setSuggestions(users)),
});

class ConnectTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      term: "",
      loading: false,
      search: false,
      activeSegment: "members",
      users_count: 0,
      loginTextError: false,
      activeTab: this.props.activesubtab,
      tabroutes: []
    }
  }

	componentDidMount() {
    if (this.props.auth_token != "") {
      this.setState({ term: "" })
      // this.props.setConnectUsers({users: []});
      agent.Connect.invitations()
        .then(res => {
          this.props.setInvitations(res);
        })
      agent.Home.userscount()
        .then(res => {
          this.setState({ users_count: res.user_count })
        })
      agent.Connect.suggestions()
        .then(res => {
          this.props.setSuggestions({ suggestions: res.suggestions });
        })
    }
    let defroutes = [
      { key: 'members', title: 'Members' },
      { key: 'invitations', title: 'Invitations' },
      { key: 'suggestions', title: 'Suggestions' },
    ]
    this.setState({tabroutes: defroutes})
  }

  setActiveSegment = (seg) => {
    this.setState({activeSegment: seg})
    if (seg == "invitations") {
      this.setState({ loading: true })
      agent.Connect.invitations()
      .then(res => {
        this.props.setConnectUsers(res);
        this.setState({ loading: false })
      })
    }
  }

  activeSegment = (seg) => {
    return this.state.activeSegment == seg
  }
  
  searchUsers() {
    if(this.props.auth_token != "") {
      this.setState({loading: true})
      agent.Connect.search_users(this.state.term)
      .then(res => {
        // res = { users: res.invitations }
        this.props.setConnectUsers(res);
        this.setState({loading: false})
        this.setState({search: true})
      })
    }
  }

  membersComponent = () => {
    return <View>
      <View style={styles.searchContainer}>
        <Item regular style={styles.search}>
          <Input placeholder="Search" onChangeText={(text) => this.setState({ term: text })} />
          <Button onPress={() => this.searchUsers()}>
            <Icon active name="search" />
          </Button>
        </Item>
      </View>
      {this.state.loading && <Spinner color='blue' />}
      {!this.state.loading && <View>
        {this.props.users.users.length > 0 && <List>
          {
            this.props.users.users.map((user) => {
              return (<ConnectUser key={user.id} user={user} page_type="members" navigation={this.props.navigation}/>);
            })
          }
        </List>}
        {this.props.users.users.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
          Find users based on Name, City or State
        </Text>}
      </View>}
    </View>
  }

  invitationsComponent = () => {
    return 
      {this.state.loading && <Spinner color='blue' />}
      {!this.state.loading && <View>
        {this.props.users.users.length > 0 && <List>
          {
            this.props.users.users.map((user) => {
              return (<ConnectUser key={user.id} user={user} page_type="invitations" />);
            })
          }
        </List>}
        {this.props.users.users.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
          Find users based on Name, City or State
        </Text>}
      </View>}
  }

  suggestionsComponent = () => {
    return 
      {this.state.loading && <Spinner color='blue' />}
      {!this.state.loading && <View>
        {this.props.users.users.length > 0 && <List>
          {
            this.props.users.users.map((user) => {
              return (<ConnectUser key={user.id} user={user} />);
            })
          }
        </List>}
        {this.props.users.users.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
          Find users based on Name, City or State
        </Text>}
      </View>}
  }

  setActiveTab = (tab) => {
    this.setState({activeTab: tab})
    if(tab == 0) {

    } else if(tab == 1) {
      if (this.props.auth_token != "") {
        this.setState({ loading: true })
        agent.Connect.invitations()
          .then(res => {
            this.props.setInvitations(res);
            this.setState({ loading: false })
          })
      }
    } else if(tab == 2) {
      if (this.props.auth_token != "") {
        this.setState({ loading: true })
        agent.Connect.suggestions()
          .then(res => {
            this.props.setSuggestions({ suggestions: res.suggestions });
            this.setState({ loading: false })
          })
      }
    }
  }

  MembersRoute = () => ( 
    <Container style={styles.contentMargin}>   
      <View style={styles.searchContainer}>
        <Item regular style={styles.search}>
          <Input placeholder="Search" onChangeText={(text) => this.setState({ term: text })} />
          <Button onPress={() => this.searchUsers()}>
            <Icon active name="search" />
          </Button>
        </Item>
      </View>
      {this.state.loading && <Spinner color='blue' /> }
      {!this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
        {this.props.users.users.length > 0 && <List>
          {
            this.props.users.users.map((user) => {
              return (<ConnectUser key={user.id} user={user} page_type="members" navigation={this.props.navigation}/>);
            })
          }
        </List>}
        {(this.props.auth_token != "" && this.props.users.users.length == 0 && !this.state.search) && <Text style={{ padding: 20, fontSize: 18 }}>
        { this.state.users_count } registered members.
        </Text>}
        {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
          Signup and Login to use this feature.
        </Text>}
        {this.props.user && this.props.user.account_setup == false && <Text style={{ padding: 20, fontSize: 18 }}>
          Account setup must be completed to use this feature.
        </Text>} 
        {(this.props.users.users.length == 0 && this.state.search) && <Text style={{ padding: 20, fontSize: 18 }}>
        No members match with the search criteria.
        </Text>}
      </Content>}
    </Container>  
  )

  InvitationsRoute = () => (
    <Container style={styles.contentMargin}>
      {this.state.loading && <Spinner color='blue' /> }
        {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
          Signup and Login to use this feature.
        </Text>} 
        {this.props.user && this.props.user.account_setup == false && <Text style={{ padding: 20, fontSize: 18 }}>
          Account setup must be completed to use this feature.
        </Text>}
        {this.props.auth_token != "" && !this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
          {this.props.invitations.invitations.length > 0 && <List>
            {
              this.props.invitations.invitations.map((user) => {
                return (<ConnectUser key={user.id} user={user} page_type="invitations" navigation={this.props.navigation}/>);
              })
            }
          </List>}
          {this.props.invitations.invitations.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
            You have no invitations
          </Text>}
        </Content>}
    </Container>
  )

  SuggestionsRoute = () => (
    <Container style={styles.contentMargin}>
      {this.state.loading && <Spinner color='blue' /> }
        {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
            Signup and Login to use this feature.
          </Text>} 
          {this.props.user && this.props.user.account_setup == false && <Text style={{ padding: 20, fontSize: 18 }}>
          Account setup must be completed to use this feature.
        </Text>}
        {this.props.auth_token != "" && (this.props.user && this.props.user.account_setup != false) && !this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
          {this.props.suggestions.suggestions.length > 0 && <List>
            {
              this.props.suggestions.suggestions.map((user) => {
                return (<ConnectUser key={user.id} user={user} page_type="members" navigation={this.props.navigation}/>);
              })
            }
          </List>}
          {this.props.suggestions.suggestions.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
          You have no user suggestions.
          </Text>}
        </Content>}
    </Container>
  )

  renderScene = SceneMap({
    members: this.MembersRoute,
    invitations: this.InvitationsRoute,
    suggestions: this.SuggestionsRoute,
  });

  renderTabBar = (props) => {
    if(!DetectDeviceService.isTablet)
      <TabBar
        style={[styles.tabs, {elevation: 0, borderColor: '#0', borderBottomWidth: 1, height:50}]}
        labelStyle={{fontSize: 13}}
        {...props}
        indicatorStyle={{backgroundColor: 'yellow', height: 2.5}}
      />
  }

  render() {
    return (
      <Container  style={{ backgroundColor: "#ececec", paddingRight: (DetectDeviceService.isTablet ? 60 : 0) }}>
        {/* <Segment style={{marginTop: -10}}>
          <Button style={{ width: "33.3%"}} first active={this.activeSegment("members")} onPress={() => this.setActiveSegment("members")}>
            <Text >Members</Text>
          </Button>
          <Button style={{ width: "33.3%"}} active={this.activeSegment("invitations")} onPress={() => this.setActiveSegment("invitations")}>
            <Text>Invitations</Text>
          </Button>
          <Button style={{ width: "33.3%"}}  last active={this.activeSegment("suggestions")} onPress={() => this.setActiveSegment("suggestions")}>
            <Text>Suggestions</Text>
          </Button>
        </Segment>
        
        <Content>
          {
            this.activeSegment("members") && this.membersComponent()
          }
          {
            this.activeSegment("invitations") && this.invitationsComponent()
          }
          {
            this.activeSegment("suggestions") && this.suggestionsComponent()
          }
        </Content> */}

        <TabView
          // lazy
          navigationState={{ index: this.state.activeTab, routes: this.state.tabroutes }}
          renderScene={this.renderScene}
          renderTabBar={this.renderTabBar}
          onIndexChange={this.setActiveTab}
        />

        {/* <Tabs initialPage={0} onChangeTab={this.setActiveTab} >
          <Tab style={styles.contentMargin} heading={<TabHeading style={styles.tabs}><Text style={styles.tabHeading}>Members</Text></TabHeading>}>
            <View style={styles.searchContainer}>
              <Item regular style={styles.search}>
                <Input placeholder="Search" onChangeText={(text) => this.setState({ term: text })} />
                <Button onPress={() => this.searchUsers()}>
                  <Icon active name="search" />
                </Button>
              </Item>
            </View>
            {this.state.loading && <Spinner color='blue' /> }
            {!this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
              {this.props.users.users.length > 0 && <List>
                {
                  this.props.users.users.map((user) => {
                    return (<ConnectUser key={user.id} user={user} page_type="members" navigation={this.props.navigation}/>);
                  })
                }
              </List>}
              {(this.props.auth_token != "" && this.props.users.users.length == 0 && !this.state.search) && <Text style={{ padding: 20, fontSize: 18 }}>
              { this.state.users_count } registered members.
              </Text>}
              {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
                Signup and Login to use this feature.
              </Text>} 
              {(this.props.users.users.length == 0 && this.state.search) && <Text style={{ padding: 20, fontSize: 18 }}>
              No members match with the search criteria.
              </Text>}
            </Content>}
          </Tab>
          <Tab style={styles.contentMargin} heading={<TabHeading style={styles.tabs}><Text style={styles.tabHeading}>Invitations</Text></TabHeading>}>
              {this.state.loading && <Spinner color='blue' /> }
              {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
                Signup and Login to use this feature.
              </Text>} 
              {this.props.auth_token != "" && !this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
                {this.props.invitations.invitations.length > 0 && <List>
                  {
                    this.props.invitations.invitations.map((user) => {
                      return (<ConnectUser key={user.id} user={user} page_type="invitations" navigation={this.props.navigation}/>);
                    })
                  }
                </List>}
                {this.props.invitations.invitations.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
                  You have no invitations
                </Text>}
              </Content>}
          </Tab>
          <Tab style={styles.contentMargin} heading={<TabHeading style={styles.tabs}><Text style={styles.tabHeading}>Suggestions</Text></TabHeading>}>
            {this.state.loading && <Spinner color='blue' /> }
            {this.props.auth_token == "" && <Text style={{ padding: 20, fontSize: 18 }}>
                Signup and Login to use this feature.
              </Text>} 
            {this.props.auth_token != "" && !this.state.loading && <Content style={{backgroundColor: "#ececec"}}>
              {this.props.suggestions.suggestions.length > 0 && <List>
                {
                  this.props.suggestions.suggestions.map((user) => {
                    return (<ConnectUser key={user.id} user={user} page_type="members" navigation={this.props.navigation}/>);
                  })
                }
              </List>}
              {this.props.suggestions.suggestions.length == 0 && <Text style={{ padding: 20, fontSize: 18 }}>
              You have no user suggestions.
              </Text>}
            </Content>}
          </Tab>
        </Tabs> */}
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  tabHeading: {
    color: "rgba(255,255,255,1)",
    textTransform: "uppercase",
    fontSize: 13
  },
  contentMargin: {
    // marginLeft: Platform.isPad ? 170 : 0,
    marginBottom: 0,
    // marginRight: Platform.isPad ? 170 : 0,
    marginTop: 0,
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  tabs: {
    backgroundColor: "rgba(43,94,170,1)",
    // flex: 1
  },
  search: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: "#FFFFFF"
  },
  searchContainer: {
    backgroundColor: "#d3d3d3",
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectTab);
