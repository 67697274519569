import React, { Component } from "react";
import { StyleSheet, View, Platform, Modal } from "react-native";
import {
  Container,
  Tab,
  Tabs,
  TabHeading,
  ScrollableTab,
  Fab,
  Icon,
  IconNB,
  Content,
  Badge,
  Button,
  Spinner,
  Text,
  Picker
} from 'native-base';

import DetectDeviceService from '../../lib/devicedetection';

import { Col, Row, Grid } from "../../lib/easy-grid";

import ConnectTab from '../ConnectTab';
import SharesScreen from '../SharesScreen';
import BillListing from '../BillListing';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';

import dayjs from 'dayjs';

import LandingScreen from '../LandingScreen';
import { WebView } from 'react-native-webview';

import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../../styles/Color'
import FONT from '../../styles/Font'
import { Routes } from '../../navigation/routes';

import { CommonActions } from '@react-navigation/native';

import agent from '../../agent'
import { connect } from 'react-redux';
import {
  setBillId,
  setListFilter,
  setListType,
} from '../../ducks/bill';
import {
  setHomeTabCount,
} from '../../ducks/home';
import {
  setAuthUserDetails,
} from '../../ducks/auth';

import { API_URL } from '../../constants/server';

import VideoAds from '../../components/VideoAds';
import HeaderMenu from '../../components/HeaderMenu';
import { Dimensions } from "react-native";
import RepresentativeListingScreen from "../RepresentativeListingScreen";
import LeftMenu from "../../components/LeftMenu";
import BreadCrumb from "../../components/BreadCrumb";

const mapStateToProps = state => ({ ...state.bill, ...state.home, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
  setListFilter: (filters) => dispatch(setListFilter(filters)),
  setListType: (list) => dispatch(setListType(list)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
});

class HomeScreenWeb extends React.Component {

  constructor(props) {
    super(props);
    let currentYear = new Date().getFullYear()
    let currentCongress = ''
    let prevCongress = ''
    if (currentYear % 2 == 0) {
      currentCongress = (currentYear - 1) + "," + currentYear
      prevCongress = (currentYear - 3) + "," + (currentYear - 2)
    } else {
      currentCongress = currentYear + "," + (currentYear + 1)
      prevCongress = (currentYear - 2) + "," + (currentYear - 1)
    }
    this.state = {
      showdata: false,
      prevCongress: prevCongress,
      currentCongress: currentCongress,
      visibility: "",
      filter: "recommended",
      sort: "newest",
    };
  }

  async componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setVals()
    })
  }

  setVals = () => {
    this.setState({ showdata: true })
    if (Object.keys(this.props.list_filters).length == 0 || this.props.list_type != "initiative") {
      this.props.setListType({ type: "initiative", url: "/api/v1/initiatives" })
      this.props.setListFilter({
        filter: 'recommended',
        sort: 'newest'
      });
    }
  }

  componentWillUnmount() {
    this.focusListener();
  }

  renderLoading = () => {
    return <Spinner color="blue" style={{ position: "absolute", height: "100%", width: "100%" }} />
  }

  setBillUrl = () => {
    let url;

    // if(this.state.webviewurl != "") {
    //   url = this.state.webviewurl
    // } else {
    //if(billType == "fed") {
    url = API_URL + this.props.list_url + "?" + this.toQueryString(this.props.list_filters) + "&sync=true"
    if (this.props.auth_token != "")
      url = url + "&auth_token=" + this.props.auth_token
    //}
    // }
    return url;
  }

  toQueryString = (params) => {
    return (
      Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
  }

  tabHeadingView = (title, tabcount) => {
    return <TabHeading style={styles.tabs}>
      <Text style={styles.tabHeading}>{title}</Text>
      {this.props.auth_token != "" && <Badge><Text>{tabcount}</Text></Badge>}
    </TabHeading>
  }

  isStateCalifornia = () => {
    if (this.props.user.state)
      return ["California", "california", "ca", "CA", "Ca"].includes(this.props.user.state)
    else
      return false
  }

  isCityMv = () => {
    if (this.props.user.city)
      return ["mountain view"].includes(this.props.user.city.toLowerCase())
    else
      return false
  }

  submitInitiative = (id) => {
    agent.Bill.submit_initiative(id)
    MailComposer.composeAsync({
      recipients: ["admin@yoopine.com"]
    })
  }

  createInitiative = () => {
    if (this.props.auth_token != "") {
      this.props.navigation.navigate(Routes.CREATE_INITIATIVE)
    } else {
      this.setState({ loginWarnPopup: true })
    }
  }

  HomeRoute = () => (
    <View style={{ height: 120, flex: 1, flexDirection: "row", paddingRight: (DetectDeviceService.isTablet ? 200 : 0), backgroundColor: "#ececec", }}>
      <LandingScreen navigation={this.props.navigation} key={this.props.auth_token} />
    </View>
  )

  ShareRoute = () => (
    <SharesScreen navigation={this.props.navigation} key={this.state.shareRefreshVal} />
  )

  FedBillsRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      {this.state.showdata && <BillListing key={this.setBillUrl()} navigation={this.props.navigation} uri={this.setBillUrl()} currentCongress={this.state.currentCongress} prevCongress={this.state.prevCongress} setBillUrl={this.setBillUrl} />}
    </Container>
    // <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
    //   <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
    //     ref={this.setFedBillRef}
    //     source={{ uri: this.setBillUrl() }} key={this.state.fedBillRefreshVal}
    //     incognito={true}
    //     startInLoadingState={true} renderLoading={this.renderLoading} />
    //   <View style={styles.filterButton}>
    //     <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
    //       prevCongress: this.state.prevCongress,
    //       currentCongress: this.state.currentCongress,
    //       refresh: this.resetwebviewurl
    //     })}>
    //       <Icon name="ios-funnel" type="Ionicons" />
    //     </Button>
    //   </View>
    //   <View style={styles.filterButtonText}>
    //     <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
    //       <Text style={{ color: "#fff" }}>Filters</Text>
    //     </Badge>
    //   </View>
    // </Container>
  )

  ConnectRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={this.state.activesubtab} />
  )
  ConnectInvRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={1} />
  )
  ConnectSuggRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={2} />
  )
  ConnectMemRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={0} />
  )

  ExecOrerRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setEoBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.execOrderRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  InitiativesRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      <WebView originWhitelist={["schemainitiativesubmit://", "schemainitiatives://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setIniBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.initiativeBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
      <View style={styles.addInitiativeButton}>
        <Button info rounded onPress={() => this.createInitiative()}>
          <Icon name="plus" type="FontAwesome" />
        </Button>
      </View>
      <View style={styles.addInitiativeButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Create</Text>
        </Badge>
      </View>
    </Container>
  )

  RepreRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      <RepresentativeListingScreen navigation={this.props.navigation} uri={(API_URL + "/api/v1/representatives.json?auth_token=" + this.props.auth_token)} setBillUrl={this.setBillUrl} />
    </Container>
  )

  MatchRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      <WebView originWhitelist={["schemasettings:", "schemarepresentative://", "schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail}
        source={{ uri: API_URL + "api/v1/users/match_my_votes_page?auth_token=" + this.props.auth_token }}
        incognito={true} key={this.state.matchMyVotesRefreshVal}
        startInLoadingState={true} renderLoading={this.renderLoading} />
    </Container>
  )

  StateRoute = () => (
    <Container style={{ paddingRight: (DetectDeviceService.isTablet ? 200 : 0) }}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail}
        ref={this.setStateBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.stateBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  renderBadge = (props) => {
    if (props.route.key == "share")
      return <Badge style={{ marginTop: -10, marginLeft: 5 }}><Text style={{ fontSize: 13 }}>{this.props.tabcount.shares}</Text></Badge>
    else if (props.route.key == "connect")
      return <Badge style={{ marginTop: -10, marginLeft: 5 }}><Text style={{ fontSize: 13 }}>{this.props.tabcount.connects}</Text></Badge>
    else if (props.route.key == "fedbills")
      return <Badge style={{ marginTop: -10, marginLeft: 5 }}><Text style={{ fontSize: 13 }}>{this.props.tabcount.fed_bills}</Text></Badge>
    else if (props.route.key == "execorders")
      return <Badge style={{ marginTop: -10, marginLeft: 5 }}><Text style={{ fontSize: 13 }}>{this.props.tabcount.executive_orders}</Text></Badge>
    // else if(props.route.key == "initiatives")
    //   return <Badge><Text>{props.route.key}</Text></Badge>
    else if (props.route.key == "statebills")
      return <Badge style={{ marginTop: -10, marginLeft: 5 }}><Text style={{ fontSize: 13 }}>{this.props.tabcount.state_bills}</Text></Badge>
  }

  billsFilterSet = (key) => value => {
    let ff = {
      filter: this.state.filter,
      sort: this.state.sort,
      visibility: this.state.visibility
      // filter: this.state.filter,
    }
    if (key == "general") {
      if (value == "national") {
        this.setState({ visibility: value })
        ff['visibility'] = value
        ff['filter'] = value
        this.setState({ filter: value })
      } else {
        this.setState({ filter: value })
        ff['filter'] = value
        ff['visibility'] = ""
        this.setState({ visibility: "" })
      }
    } else if (key == "sort") {
      this.setState({ sort: value })
      ff['sort'] = value
    }
    if (ff['filter'] == "" || ff['filter'] == "national")
      delete ff["filter"]
    if (ff['visibility'] == "")
      delete ff["visibility"]
    this.props.setListFilter(ff)
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{ flex: 1, backgroundColor: "#2B5EAA" }}>
        <VideoAds navigation={this.props.navigation} />
        <Container style={styles.tabs} key={Dimensions.width}>
          <HeaderMenu navigation={this.props.navigation} />
          <Grid>
            {/* <LeftMenu /> */}
            <Col style={{ backgroundColor: "rgb(236, 236, 236)" }}>
              <BreadCrumb navigation={this.props.navigation} links={["Home", "Initiatives"]}></BreadCrumb>
              {DetectDeviceService.isTablet && <Row style={{ width: DetectDeviceService.isTab ? "95%" : "80%", paddingRight: 60, marginLeft: "auto", marginRight: "auto", marginTop: 25 }}>
                <Col style={{ width: '20%' }}>
                  <Text style={styles.filterHeading}>General Filters</Text>
                  <Picker
                    note
                    mode="dropdown"
                    style={{ width: 120, maxHeight: 30, marginTop: 20, backgroundColor: "#fff" }}
                    textStyle={{ color: "#333", fontSize: 12 }}
                    selectedValue={this.state.filter}
                    onValueChange={this.billsFilterSet("general")}
                  >
                    <Picker.Item label="Townhall Short List" value="recommended" />
                    {this.props.auth_token != "" && (this.props.user && this.props.user.account_setup != false) && <Picker.Item label="My Initiatives" value="" />}
                    <Picker.Item label="National" value="national" />
                  </Picker>
                </Col>
                <Col>
                  <Text style={styles.filterHeading}>Sort</Text>
                  <Picker
                    note
                    mode="dropdown"
                    style={{ width: 120, maxHeight: 30, marginTop: 20, backgroundColor: "#fff" }}
                    textStyle={{ color: "#333", fontSize: 12 }}
                    selectedValue={this.state.sort}
                    onValueChange={this.billsFilterSet("sort")}
                  >
                    <Picker.Item label="Newest" value="watchlist" />
                    <Picker.Item label="Most Viewed" value="all" />
                  </Picker>
                </Col>
                <Col>
                  <View style={styles.addInitiativeButtonWeb}>
                    <Button info rounded onPress={() => this.createInitiative()}>
                      <Icon name="plus" type="FontAwesome" />
                    </Button>
                  </View>
                  <View style={styles.addInitiativeButtonTextWeb}>
                    <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                      <Text style={{ color: "#fff" }}>Create</Text>
                    </Badge>
                  </View>
                </Col>
              </Row>}
              {this.state.showdata && <BillListing key={this.setBillUrl()} navigation={this.props.navigation} uri={this.setBillUrl()} currentCongress={this.state.currentCongress} prevCongress={this.state.prevCongress} setBillUrl={this.setBillUrl} />}
              {!DetectDeviceService.isTablet && <View style={styles.addInitiativeButton}>
                <Button info rounded onPress={() => this.createInitiative()}>
                  <Icon name="plus" type="FontAwesome" />
                </Button>
              </View>}
              {!DetectDeviceService.isTablet && <View style={styles.addInitiativeButtonText}>
                <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                  <Text style={{ color: "#fff" }}>Create</Text>
                </Badge>
              </View>}
            </Col>
          </Grid>
        </Container>
      </SafeAreaView>
    );
  }

}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.PRIMARY
  },
  tabHeading: {
    color: "rgba(255,255,255,1)",
    textTransform: "uppercase"
  },
  tabs: {
    backgroundColor: "rgba(43,94,170,1)",
  },
  filterButton: {
    position: "absolute",
    bottom: 40,
    right: 40
  },
  filterButtonText: {
    position: "absolute",
    bottom: 10,
    right: 30
  },
  addInitiativeButton: {
    position: "absolute",
    bottom: 40,
    left: "40%"
  },
  addInitiativeButtonText: {
    position: "absolute",
    bottom: 10,
    left: DetectDeviceService.isTablet ? "39%" : "38%",
  },
  forgotcancel: {
    height: 36,
    width: 90,
    marginRight: 20
  },
  forgotsubmit: {
    height: 36,
    width: 90
  },
  forgotactions: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  leftmenu: {
    width: 400,
    backgroundColor: "#fff",
    paddingLeft: 200
  },
  addInitiativeButtonWeb: {
    position: "absolute",
    bottom: 40,
    right: "5%"
  },
  addInitiativeButtonTextWeb: {
    position: "absolute",
    bottom: 10,
    right: "4%",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreenWeb);
