import React from "react";
import {
  StyleSheet, ScrollView, View, Modal, Image, Keyboard,
  Dimensions, TouchableOpacity, KeyboardAvoidingView, Platform
} from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  List,
  Separator,
  Toast,
  Form, Item, Label, Input, Textarea, Spinner
} from "native-base";

import SafeAreaView from 'react-native-safe-area-view';

import HeaderMenu from '../components/HeaderMenu';

import DetectDeviceService from '../lib/devicedetection';

import { Col, Row, Grid } from "../lib/easy-grid";

import { Routes } from '../navigation/routes';

import * as Linking from 'expo-linking';

import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../styles/Color';
import agent from '../agent';
import { connect } from 'react-redux';
import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import { MaterialIcons } from '@expo/vector-icons';

import * as MailComposer from 'expo-mail-composer';

import { API_URL } from '../constants/server';

import {
  setOpinions,
  updateMyVote,
  updateOpinion
} from '../ducks/bill';

import BillOpinion from '../components/BillOpinion';
import BillComment from '../components/BillComment';
import { cos } from "react-native-reanimated";

import BreadCrumb from "../components/BreadCrumb";

import Menu, { MenuItem } from 'react-native-material-menu';

const mapStateToProps = state => ({ ...state.bill, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setOpinions: (opinions) => dispatch(setOpinions(opinions)),
  updateOpinion: (voteId, vote) => dispatch(updateOpinion(voteId, vote)),
  updateMyVote: (vote) => dispatch(updateMyVote(vote)),
});

class VoiceYourOpinionScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      replymodal: false,
      flagmodal: false,
      editmodal: false,
      modalopen: false,
      opensaveerror: false,
      opensaveerrormsg: "",
      newComment: {
        parent_comment: "",
        comment_to: "",
        index: "",
        heading: ""
      },
      newFlag: {
        comment: "",
      },
      editModal: {
        comment_text: "",
        bill_id: "",
        vote_id: ""
      },
      comment_text: "",
      flag_comment_text: "",
      opinionvote: "",
      opinioncomment: "",
      editcomment: "",
      loading: false,
      lastPage: false,
      pageLoading: false,
      action_loading: false,
      network: [],
      selectedItems: [],
      page: 1,
      modalpayload: {},
      bill: {},
      my_representatives: [],
      representative_select: "",
      opinionOrderIds: {
        "My Opinion": [],
        "My Network (Friends & Family)": [],
        "Other Opinions": []
      },
      uniqueVotes: [],
      voteTypeCount: {},
      blockusermodal: false,
      blockuserid: "",
    }
  }

  _menu = null;
  ContentScroll = null;

  setMenuRef = ref => {
    this._menu = ref;
  };

  hideMenu = () => {
    this._menu.hide();
  };

  showMenu = () => {
    this._menu.show();
  };

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.loadData("initial");
    });

    let self = this;

    agent.Connect.network()
      .then(res => {
        let rn = []
        res.friends_and_family.map(f => {
          let r = {
            ...f,
            icon: { uri: this.avatarUrl(f.avatar) }
          }
          rn.push(r)
        })
        this.setState({ network: rn })
        this.setState({ selectedItems: rn.map(function (el) { return el.id; }) });
      })
    let rep = []
    if (this.props.list_type == "state") {
      agent.Home.staterepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators" && key != "other_state_assembly_representatives" && key != "other_state_senator") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep })
        })
    } else {
      agent.Home.myrepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep })
        })
    }
  }

  componentWillUnmount() {
    this.focusListener();
  }

  titleCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
  }

  nextPage = () => {
    let page = parseInt(this.state.page) + 1;
    this.setState({
      page: page
    }, () => { this.loadData("page") })
  }

  prevPage = () => {
    let page = parseInt(this.state.page) - 1;
    this.setState({
      page: page
    }, () => { this.loadData("page") })
  }

  getFirstPage = () => {
    let page = 1;
    this.setState({
      page: page
    }, () => { this.loadData("page") })
  }

  loadData = (caller) => {
    if (caller == "initial")
      this.setState({ loading: true })
    else
      this.setState({ pageLoading: true })

    agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url, this.state.page)
      .then(res => {
        // this.props.setOpinions(res);
        // if (res["Other Opinions"].length != 0) {
        // this.setState({ lastPage: false })

        this.setOpinionsInitial(res)
        this.setState({ bill: res.object })

        let userVote = ""
        try {
          userVote = res["My Opinion"][0].user_vote
        } catch (e) {

        }
        // this.setState({ opinionvote: userVote })
        // }

        if (res["Other Opinions"].length < 25) {
          this.setState({ lastPage: true })
        } else if (res["Other Opinions"].length == 25) {
          this.setState({ lastPage: false })
        } else {
          this.setState({ lastPage: false })
        }

        if (caller == "initial")
          this.setState({ loading: false })
        else
          this.setState({ pageLoading: false })
      })

    agent.Bill.voice_your_opinion_all(this.props.details.id, this.props.list_url, "")
      .then(res => {
        this.setOpinionsCount(res)
      })

    agent.Bill.user_vote(this.props.details.id, this.props.list_url)
      .then(res => {
        if (res.vote != undefined)
          this.setState({ opinionvote: res.vote.voting_parameters })
        else
          this.setState({ opinionvote: "" })
      })
  }

  setOpinionsInitial = (res) => {
    // this.setState({shares: res.shares})
    // this.setState({loading: false})
    // if(res.shares.length == 0)
    //   this.setState({lastPage: true})

    let op1 = res["My Opinion"].reduce((map, obj) => (map[obj.vote_id] = obj, map), {});
    let op2 = res["My Network (Friends & Family)"].reduce((map, obj) => (map[obj.vote_id] = obj, map), {});
    let op3 = res["Other Opinions"].reduce((map, obj) => (map[obj.vote_id] = obj, map), {});
    let opsObj = {
      ...op1,
      ...op2,
      ...op3
    }
    this.props.setOpinions(opsObj);

    let ops = {}
    ops["My Opinion"] = res["My Opinion"].map(s => (s.vote_id))
    ops["My Network (Friends & Family)"] = res["My Network (Friends & Family)"].map(s => (s.vote_id))
    ops["Other Opinions"] = res["Other Opinions"].map(s => (s.vote_id))

    // let shareIds = this.state.shareOrderIds.concat(res.shares.map(s => ( s.id )))
    this.setState({ opinionOrderIds: ops })
  }

  setOpinionsCount = (res) => {
    var unique = [];
    var distinct = [];
    for (let i = 0; i < res["My Network (Friends & Family)"].length; i++) {
      if (!unique[res["My Network (Friends & Family)"][i].user_id]) {
        distinct.push(res["My Network (Friends & Family)"][i]);
        unique[res["My Network (Friends & Family)"][i].user_id] = 1;
      }
    }

    var unique1 = [];
    var distinct1 = [];
    for (let i = 0; i < res["Other Opinions"].length; i++) {
      if (!unique1[res["Other Opinions"][i].user_id]) {
        distinct1.push(res["Other Opinions"][i]);
        unique1[res["Other Opinions"][i].user_id] = 1;
      }
    }

    distinct = distinct.concat(distinct1)

    let strong_support = distinct.filter((op) => {
      return op != null && op.user_vote == "Strong support"
    })

    let support = distinct.filter((op) => {
      return op != null && op.user_vote == "Support"
    })

    let neutral = distinct.filter((op) => {
      return op != null && op.user_vote == "Neutral"
    })

    let oppose = distinct.filter((op) => {
      return op != null && op.user_vote == "Oppose"
    })

    let strong_oppose = distinct.filter((op) => {
      return op != null && op.user_vote == "Strong oppose"
    })

    distinct = distinct.map(s => (s.vote_id))

    this.setState({ uniqueVotes: distinct })

    this.setState({
      voteTypeCount: {
        "Strong support": strong_support.length,
        "Support": support.length,
        "Neutral": neutral.length,
        "Strong oppose": strong_oppose.length,
        "Oppose": oppose.length
      }
    })
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  getEmailFromIds = () => {
    let s = []
    let self = this;
    this.state.my_representatives.forEach(function (obj) {
      obj["children"].forEach(function (obj) {
        if (self.state.selectedItems.includes(obj["id"]))
          s.push(obj["email"])
      })
    })
    return s;
  }

  openReplyModal = (comment, heading, index, comment_to = null) => {
    this.setState({ replymodal: true })
    let nc = {
      parent_comment: comment,
      heading: heading,
      index: index
    }
    if (comment_to != null)
      nc.comment_to = comment_to
    this.setState({ newComment: nc })
  }

  openFlagModal = (comment) => {
    this.setState({ flagmodal: true })
    let nf = {
      comment: comment,
    }

    this.setState({ newFlag: nf })
  }

  closeFlagModal = () => {
    this.setState({ flagmodal: false })
  }

  closeReplyModal = () => {
    this.setState({ replymodal: false })
  }

  openEditModal = (bill_id, comment_text, vote_id) => {
    this.setState({ editmodal: true })
    let editMeta = {
      vote_id: vote_id,
      bill_id: bill_id,
      comment_text: comment_text
    }
    this.setState({
      editModal: editMeta
    })
    // this.setState({ newComment: {
    //   parent_comment: comment,
    //   heading: heading,
    //   index: index
    // }})
  }

  closeEditModal = () => {
    this.setState({ editmodal: false })
  }

  gotoVoteDetails = (comment, index, heading) => {
    this.props.navigation.navigate(Routes.VOTE_DETAILS, {
      vote: [comment],
      commentIndex: index,
      heading: heading
    });
  }

  getVoteTypeCount = (voteType) => {
    // let ops = this.state.opinionOrderIds["My Network (Friends & Family)"].concat(this.state.opinionOrderIds["Other Opinions"])
    let ops = this.state.uniqueVotes
    let my_opinion_count = 0;
    if (this.state.opinionvote == voteType) {
      my_opinion_count = 1
    }
    // let my_opinion = this.state.opinionOrderIds["My Opinion"][0];
    // if(this.state.opinionOrderIds["My Opinion"].length != 0) {
    //   my_opinion = this.state.opinionOrderIds["My Opinion"][0]
    // }
    // if(my_opinion != undefined)
    //   ops = ops.concat([my_opinion])

    let count = this.state.voteTypeCount[voteType] + my_opinion_count
    count = isNaN(count) ? 0 : count

    return count

    // return ops.filter((op) => {
    //   return this.props.opinions[op.toString()] != null && this.props.opinions[op.toString()].user_vote.toUpperCase() == voteType.toUpperCase()
    // }).length + my_opinion_count
  }

  getVoteTypePerc = (voteType) => {
    // let ops = this.state.opinionOrderIds["My Network (Friends & Family)"].concat(this.state.opinionOrderIds["Other Opinions"])
    let ops = this.state.uniqueVotes
    let my_opinion_count = 0;
    if (this.state.opinionvote != "") {
      my_opinion_count = 1
    }
    let totalVotes = ops.length + my_opinion_count
    // let my_opinion = this.state.opinionOrderIds["My Opinion"][0];
    // if(this.state.opinionOrderIds["My Opinion"].length != 0) {
    //   my_opinion = this.state.opinionOrderIds["My Opinion"][0]
    // }
    // if(my_opinion != undefined)
    //   ops = ops.concat([my_opinion])
    // let perc = ((ops.filter((op) => {
    //   return this.props.opinions[op.toString()] != null && this.props.opinions[op.toString()].user_vote == voteType
    // }).length * 100 ) / ops.lengxth).toFixed(0)

    let perc = Math.round((this.getVoteTypeCount(voteType) * 100 / totalVotes)).toFixed(0)

    perc = isNaN(perc) ? 0 : perc

    return perc
  }

  getAllVotes = () => {
    let ops = this.state.opinionOrderIds["My Network (Friends & Family)"].concat(this.state.opinionOrderIds["Other Opinions"])
    let my_opinion = this.state.opinionOrderIds["My Opinion"][0];
    if (this.state.opinionOrderIds["My Opinion"].length != 0) {
      my_opinion = this.state.opinionOrderIds["My Opinion"][0]
    }
    if (my_opinion != undefined)
      ops = ops.concat([my_opinion])
    return ops;
  }

  voteBarColor = (voteType, pos) => {
    if (this.getVoteTypePerc(voteType) <= 20 && pos <= 1) {
      return { backgroundColor: "#808080" }
    } else if (this.getVoteTypePerc(voteType) > 20 && this.getVoteTypePerc(voteType) <= 40 && pos <= 2) {
      return { backgroundColor: "#808080" }
    } else if (this.getVoteTypePerc(voteType) > 40 && this.getVoteTypePerc(voteType) <= 60 && pos <= 3) {
      return { backgroundColor: "#808080" }
    } else if (this.getVoteTypePerc(voteType) > 60 && this.getVoteTypePerc(voteType) <= 80 && pos <= 4) {
      return { backgroundColor: "#808080" }
    } else if (this.getVoteTypePerc(voteType) > 80 && this.getVoteTypePerc(voteType) <= 100 && pos <= 5) {
      return { backgroundColor: "#808080" }
    } else {
      return { backgroundColor: "#EEEEEE" }
    }
  }

  getVoteTypeBar = (voteType) => {
    return <Col>
      <Text style={styles.voteBarVal}>{this.getVoteTypeCount(voteType)}</Text>
      <View block disabled style={[styles.voteBar, this.voteBarColor(voteType, 5)]}></View>
      <View block disabled style={[styles.voteBar, this.voteBarColor(voteType, 4)]}></View>
      <View block disabled style={[styles.voteBar, this.voteBarColor(voteType, 3)]}></View>
      <View block disabled style={[styles.voteBar, this.voteBarColor(voteType, 2)]}></View>
      <View block disabled style={[styles.voteBar, this.voteBarColor(voteType, 1)]}></View>
    </Col>
  }

  editVoteComment = () => {
    this.setState({ action_loading: true })
    let vote = {
      bill_id: this.props.details.id
    }
    let btype;
    if (this.props.list_type == "initiative") {
      btype = "initiatives"
    } else if (this.props.list_type == "representative") {
      btype = "representatives"
    } else {
      btype = "bills"
    }
    agent.Bill.update_opinion(this.props.details.id, this.state.editModal.vote_id, this.state.editModal.comment_text, btype)
      .then(res => {
        this.state.editmodal = false;
        agent.Bill.vote_details(this.state.editModal.vote_id)
          .then(res => {
            let vote = {
              ...res.vote_details,
            }
            this.setState({ details: res.vote_details })
            this.props.updateOpinion(this.state.editModal.vote_id, vote)
            this.setState({ action_loading: false })
          })
      })
  }

  voteComment = () => {
    // let newComment = this.props.comment;
    this.setState({ action_loading: true })
    let newCommentReply = {
      parent_comment_id: this.state.newComment.parent_comment.comment_id,
      vote_id: this.state.newComment.parent_comment.vote_id,
      comment_text: this.state.comment_text,
      user_id: this.props.user.user_id
    }
    if (this.state.newComment.comment_to != "" && this.state.newComment.comment_to != undefined && this.state.newComment.comment_to != "")
      newCommentReply.comment_to_id = this.state.newComment.comment_to.comment_id

    agent.Bill.comment_opinion(newCommentReply)
      .then(res => {
        agent.Bill.vote_details(this.state.newComment.parent_comment.vote_id)
          .then(res => {
            let vote = {
              ...res.vote_details,
            }
            this.setState({ details: res.vote_details })
            this.props.updateOpinion(this.state.newComment.parent_comment.vote_id, vote)
            this.setState({ replymodal: false });
            this.setState({ action_loading: false })
          })
      })
    // let updateObj = {
    //   comment: this.state.comment_text,
    //   "comment_id": 4180,
    //   "comment_to_id": 4178,
    //   "reply_count": 0,
    //   "reply_created_at": "2020-04-06T17:24:49.000Z",
    //   "user_avatar": "/uploads/user/avatar/715/file-495.jpg",
    //   "user_id": 715,
    //   "user_liked": "no",
    //   "user_name": "pramod-test",
    //   "user_state": "CA",
    // }
    // newComment.reply_count = newComment.reply_count + 1
    // if (this.props.comment.user_liked == "yes")
    //   newComment.user_liked = "no"
    // else
    //   newComment.user_liked = "yes"
    //   this.props.updateOpinions(newComment, this.props.heading, this.props.commentIndex)

  }

  flagComment = () => {
    // let newComment = this.props.comment;
    this.setState({ action_loading: true })
    let newFlagComment = {
      comment_id: this.state.newFlag.comment,
      comment_text: this.state.flag_comment_text,
    }

    agent.Bill.flag_comment(newFlagComment.comment_id, newFlagComment.comment_text)
      .then(res => {

        this.setState({ flagmodal: false });
        this.setState({ action_loading: false })

        Toast.show({
          text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
          duration: 5000,
          type: "success",
          textStyle: { textAlign: "center" }
        })

      })
  }

  myVote = () => {
    let vote = ""
    if (this.state.opinionOrderIds["My Opinion"] && this.state.opinionOrderIds["My Opinion"].length > 0) {
      let myop = this.state.opinionOrderIds["My Opinion"][0]
      vote = this.props.opinions[myop.to.String()].user_vote
    }
    return vote
  }

  myVotePos = () => {
    let pos = "0"
    try {
      pos = ((((this.billOpinionScore()) / 1) * 10));
    } catch {

    }

    if (pos > 0)
      pos = (pos / 2) + 45
    else
      pos = 50 + (pos / 2)

    if (pos > 88)
      pos = 88

    return {
      marginLeft: pos + "%"
    }
  }

  snakeCase = string => {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  };

  myVoteImage = (voteType) => {
    let imageUrl = "";
    // let vote = this.myVote();
    let vote = this.state.opinionvote;
    if (vote == "Neutral" && voteType == "Neutral") {
      imageUrl = "/assets/images/vote_meter/neutral_selected.png"
    } else if (vote == "Strong support" && voteType == "Strong support") {
      imageUrl = "/assets/images/vote_meter/strong_support_selected.png"
    } else if (vote == "Support" && voteType == "Support") {
      imageUrl = "/assets/images/vote_meter/support_selected.png"
    } else if (vote == "Strong oppose" && voteType == "Strong oppose") {
      imageUrl = "/assets/images/vote_meter/strong_oppose_selected.png"
    } else if (vote == "Oppose" && voteType == "Oppose") {
      imageUrl = "/assets/images/vote_meter/oppose_selected.png"
    } else {
      imageUrl = "/assets/images/vote_meter/" + this.snakeCase(voteType) + ".png"
    }
    return API_URL + imageUrl;
  }

  myVoteLocalImage = (voteType) => {
    let imageUrl = "";
    let opimagesselected = {
      strong_oppose: require("../assets/images/strong_oppose_selected.png"),
      oppose: require("../assets/images/oppose_selected.png"),
      neutral: require("../assets/images/neutral_selected.png"),
      support: require("../assets/images/support_selected.png"),
      strong_support: require("../assets/images/strong_support_selected.png"),
    }

    let opimagesunselected = {
      strong_oppose: require("../assets/images/strong_oppose.png"),
      oppose: require("../assets/images/oppose.png"),
      neutral: require("../assets/images/neutral.png"),
      support: require("../assets/images/support.png"),
      strong_support: require("../assets/images/strong_support.png")
    }
    // let vote = this.myVote();
    let vote = this.state.opinionvote;
    if (vote == "Neutral" && voteType == "Neutral") {
      return opimagesselected[this.snakeCase(voteType)]
    } else if (vote == "Strong support" && voteType == "Strong support") {
      return opimagesselected[this.snakeCase(voteType)]
    } else if (vote == "Support" && voteType == "Support") {
      return opimagesselected[this.snakeCase(voteType)]
    } else if (vote == "Strong oppose" && voteType == "Strong oppose") {
      return opimagesselected[this.snakeCase(voteType)]
    } else if (vote == "Oppose" && voteType == "Oppose") {
      return opimagesselected[this.snakeCase(voteType)]
    } else {
      return opimagesunselected[this.snakeCase(voteType)]
    }
  }

  voteBill = (voteType) => {
    // let vote = {
    //   comment_created_at: "2019-02-25T06:16:03.000Z",
    //   comment_id: 4017,
    //   comment_text: "testing on Feb 24",
    //   others_reply: [],
    //   parent_comment_id: 4017,
    //   reply_count: 0,
    //   user_avatar: "/uploads/user/avatar/715/file-495.jpg",
    //   user_city: "Santa Clara",
    //   user_id: 715,
    //   user_like: "no",
    //   user_name: "Pramod Mitta1",
    //   user_state: "CA",
    //   user_vote: voteType,
    //   vote_id: 6840,
    // }
    // this.props.updateMyVote(vote)
    this.setState({ opinionvote: voteType })
  }

  saveOpinion = () => {
    let newVote = {
      vote: {
        voting_parameters: this.snakeCase(this.state.opinionvote)
      },
      comment_text: this.state.opinioncomment
    }
    let btype;
    if (this.props.list_type == "initiative") {
      btype = "initiatives"
    } else if (this.props.list_type == "representative") {
      btype = "representatives"
    } else {
      btype = "bills"
    }
    agent.Bill.post_opinion(this.props.details.id, newVote, btype)
      .then(res => {
        if (res.errors == undefined) {
          this.setState({ opinioncomment: "" })
          agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
            .then(res1 => {
              this.setOpinionsInitial(res1)
              let userVote = ""
              try {
                userVote = res1["My Opinion"][0].user_vote
              } catch (e) {

              }
              this.setState({ opinionvote: userVote })
              Keyboard.dismiss();
              // this.props.setOpinions(res);
            })
        } else {
          this.setState({ opensaveerror: true })
          this.setState({ opensaveerrormsg: res.errors })
        }
      })
  }

  shareOpinion = () => {
    let share = {}
    if (this.props.list_type == "initiative") {
      share["sharable_type"] = "Initiative"
    } else if (this.props.list_type == "representative") {
      share["sharable_type"] = "Representative"
    } else {
      share["sharable_type"] = "Bill"
    }
    share["sharable_id"] = this.props.details.id
    share["share_text"] = this.state.opinioncomment

    agent.Share.create(share)

    // if(this.state.network.length == this.state.selectedItems.length){
    //   agent.Share.create(share)
    // } else {
    //   agent.Share.shareWith(share, this.state.selectedItems.join(","))
    // }
    Toast.show({
      text: "Successfully shared your opinion with members in your network.",
      duration: 5000,
      type: "success",
      textStyle: { textAlign: "center" }
    })
  }

  saveAndShareOpinion = () => {
    if (this.state.selectedItems.length == 0) {
      Toast.show({
        text: "Select at least 1 member to share your post.",
        duration: 5000,
        type: "danger",
        textStyle: { textAlign: "center" }
      })
      return false
    }
    this.saveOpinion()
    this.shareOpinion()
  }

  updateOpinion = () => {
    let newVote = {
      vote: {
        voting_parameters: this.snakeCase(this.state.opinionvote)
      },
      comment_text: this.state.opinioncomment
    }
    let btype;
    if (this.props.list_type == "initiative") {
      btype = "initiatives"
    } else if (this.props.list_type == "representative") {
      btype = "representatives"
    } else {
      btype = "bills"
    }
    agent.Bill.post_opinion(this.props.details.id, newVote, btype)
      .then(res => {
        this.setState({ opinioncomment: "" })
        agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
          .then(res => {
            this.props.setOpinions(res);
          })
      })
  }

  // removeOpinion = (bill_id, vote_id) => {
  //   let btype;
  //   if(this.props.list_type == "initiative") {
  //     btype="initiatives"
  //   } else if(this.props.list_type == "representative") {
  //     btype="representatives"
  // 	} else {
  //     btype="bills"
  //   }
  //   agent.Bill.delete_opinion(bill_id, vote_id, btype)
  //   .then(res => {
  //     agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
  //     .then(res => {
  //       this.props.setOpinions(res);
  //     })
  //   })
  // }

  removeOpinion = (bill_id, vote_id) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Posting",
      modalaction: "delete_opinion",
      modaltext: "Are you sure you want to delete this Posting?",
      modalpayload: { bill_id: bill_id, vote_id: vote_id }
    })
  }

  billOpinionScore = () => {
    // let self = this;
    let totalscore = (["Strong support", "Support", "Neutral", "Oppose", "Strong oppose"].map((voteType) => {
      let voteCount = this.getVoteTypeCount(voteType);

      if (voteType == "Strong support") {
        return voteCount * 10
      } else if (voteType == "Support") {
        return voteCount * 5
      } else if (voteType == "Neutral") {
        return voteCount * 0
      } else if (voteType == "Oppose") {
        return voteCount * -5
      } else if (voteType == "Strong oppose") {
        return voteCount * -10
      } else {
        return 0
      }
    }).reduce((a, b) => a + b, 0));

    let myscore = 0
    let myscorecount = 0
    if (this.state.opinionvote == "") {
      myscore = 0
      myscorecount = 0
    } else if (this.state.opinionvote == "Strong support") {
      myscore = 10
      myscorecount = 1
    } else if (this.state.opinionvote == "Support") {
      myscore = 5
      myscorecount = 1
    } else if (this.state.opinionvote == "Neutral") {
      myscore = 0
      myscorecount = 1
    } else if (this.state.opinionvote == "Oppose") {
      myscore = -5
      myscorecount = 1
    } else if (this.state.opinionvote == "Strong oppose") {
      myscore = -10
      myscorecount = 1
    } else {
      myscore = 0
    }

    let score = (totalscore) / (this.state.uniqueVotes.length + myscorecount)

    score = isNaN(score) ? 0 : score
    return score
  }

  // billOpinionScore = () => {
  //   let totalscore = (this.state.uniqueVotes.map((op) => {
  //     console.log(op)

  //     op = this.props.opinions[op.toString()]
  //     console.log(op)

  //     if(op == null) {
  //       return 0
  //     } else if(op.user_vote == "Strong support") {
  //       return 10
  //     } else if(op.user_vote == "Support") {
  //       return 5
  //     } else if(op.user_vote == "Neutral") {
  //       return 0
  //     } else if(op.user_vote == "Oppose") {
  //       return -5
  //     } else if(op.user_vote == "Strong oppose") {
  //       return -10
  //     } else {
  //       return 0
  //     }
  //   }).reduce((a, b) => a + b, 0))

  //   let myscore = 0
  //   let myscorecount = 0
  //   if(this.state.opinionvote == "") {
  //     myscore = 0
  //     myscorecount = 0
  //   } else if(this.state.opinionvote == "Strong support") {
  //     myscore = 10
  //     myscorecount = 1
  //   } else if(this.state.opinionvote == "Support") {
  //     myscore = 5
  //     myscorecount = 1
  //   } else if(this.state.opinionvote == "Neutral") {
  //     myscore = 0
  //     myscorecount = 1
  //   } else if(this.state.opinionvote == "Oppose") {
  //     myscore = -5
  //     myscorecount = 1
  //   } else if(this.state.opinionvote == "Strong oppose") {
  //     myscore = -10
  //     myscorecount = 1
  //   } else {
  //     myscore = 0
  //   }

  //   let score = (totalscore + myscore) / (this.state.uniqueVotes.length + myscorecount)

  //   console.log(12312312)
  //   console.log(totalscore)
  //   console.log(myscore)
  //   console.log(this.state.uniqueVotes.length)


  //   score = isNaN(score) ? 0 : score
  //   return score
  // }

  openDeleteShare = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Posting",
      modalaction: "delete_opinion",
      modaltext: "Are you sure you want to delete this Posting?",
      modalpayload: payload
    })
  }

  callFunction = () => {
    this.setState({ action_loading: true })
    if (this.state.modalaction == "delete_opinion") {
      let btype;
      if (this.props.list_type == "initiative") {
        btype = "initiatives"
      } else if (this.props.list_type == "representative") {
        btype = "representatives"
      } else {
        btype = "bills"
      }
      agent.Bill.delete_opinion(this.state.modalpayload.bill_id, this.state.modalpayload.vote_id, btype)
        .then(res => {
          // agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
          // .then(res => {
          let opIds = this.state.opinionOrderIds;
          opIds["My Opinion"] = opIds["My Opinion"].filter(arrayItem => arrayItem !== this.state.modalpayload.vote_id);
          this.setState({ opinionOrderIds: opIds })

          agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
            .then(res1 => {
              this.setOpinionsInitial(res1)
              let userVote = ""
              try {
                userVote = res1["My Opinion"][0].user_vote
              } catch (e) {

              }
              this.setState({ opinionvote: userVote })
              Keyboard.dismiss();
              // this.props.setOpinions(res);
            })

          // this.props.setOpinions(res);
          this.setState({ modalopen: false })
          this.setState({ action_loading: false })
          // })
        })
    }
  }

  onSelectedItemsChange = (selectedItems) => {
    //let selectedIssuesObj = selectedItems.map((id) => { return this.props.issues[id] })
    //this.props.issues.map((obj) => selectedItems.includes(obj["id"]))
    //let selectedIds = selectedIssuesObj.map((obj) => { return obj.id })
    this.setState({ selectedItems: selectedItems });
    //this.props.setBillsIssues({ selectedItems: selectedIssuesObj });
  };

  SelectOrRemoveAll = () =>
    this.SectionedMultiSelect && (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          height: 44,
          borderWidth: 0,
          paddingHorizontal: 10,
          backgroundColor: 'darkgrey',
          alignItems: 'center',
        }}
        onPress={
          this.state.selectedItems.length == this.state.network.length
            ? this.SectionedMultiSelect._removeAllItems
            : this.SectionedMultiSelect._selectAllItems
        }
      >
        <Text style={{ color: 'white', fontWeight: 'bold' }}>
          {this.state.selectedItems.length == this.state.network.length ? 'Remove' : 'Select'} all
        </Text>
      </TouchableOpacity>
    );

  emailElectedOfficials = () => {
    this.hideMenu()
    this.setState({ representative_select: "email_elected" })
    let self = this;
    setTimeout(function () { self.SectionedMultiSelect1._toggleSelector() }, 500);
  }

  callRepFunction = () => {
    // console.log(this.state.representative_select)
    if (this.state.representative_select == "email_elected") {
      let emails = this.getEmailFromIds()
      let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
        this.billTypeText() + " Title: " + "\n" +
        this.state.bill.title + '\n\n' +
        this.billTypeText() + " Summary: " + "\n" +
        this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
        "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
        "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
        "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
        "Sincerely,\n" + this.props.user.first_name
      MailComposer.composeAsync({
        recipients: emails,
        body: body
      })
    } else if (this.state.representative_select == "initiative") {
      // let emails = this.getEmailFromIds()
      // MailComposer.composeAsync({
      //   recipients: emails
      // })
      let ini = {}
      ini.representative_ids = this.state.selectedItems
      agent.Bill.update_initiative(ini, this.props.details.id)
    }
  }

  emailFriend = () => {
    let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
      this.billTypeText() + " Title: " + "\n" +
      this.state.bill.title + '\n\n' +
      this.billTypeText() + " Summary: " + "\n" +
      this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
      "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
      "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
      "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
      "Sincerely,\n" + this.props.user.first_name

    console.log(body)
    MailComposer.composeAsync({
      subject: this.state.bill.title,
      body: body
    })
  }

  shareDropDown = () => {
    return <Menu ref={this.setMenuRef}
      style={{ marginTop: 40, width: 220 }}
      button={<Icon
        name="share-alternative"
        style={[styles.icon2, { marginLeft: 9 }]}
        onPress={this.showMenu}
        type="Entypo"></Icon>}>
      <MenuItem style={styles.menuOption} onPress={() => this.postOnFacebook()}>
        <Text style={styles.headerMenuText}>Post on Facebook</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.postOnTwitter()}>
        <Text style={styles.headerMenuText}>Post on X</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.emailElectedOfficials()}>
        <Text style={styles.headerMenuText}>Email Elected Officials</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.emailFriend()}>
        <Text style={styles.headerMenuText}>Email a friend</Text>
      </MenuItem>
    </Menu>
  }

  billTypeText = () => {
    if (this.props.list_type == "fed") {
      return "Federal Bills"
    } else if (this.props.list_type == "state") {
      return "State Bills"
    } else if (this.props.list_type == "eo") {
      return "Executive Orders"
    } else if (this.props.list_type == "initiative") {
      return "Initiatives"
    } else if (this.props.list_type == "representative") {
      return "Representatives"
    } else {
      return "Federal Bills"
    }
  }

  scrollTextArea = () => {
    if (this.ContentScroll != undefined) {
      this.ContentScroll.scrollTo({ y: 100, animated: true })
    }
  }

  postOnFacebook = () => {
    let facebookParameters = [];
    let shareurl;
    if (this.props.list_type == "initiative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "eo") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "representative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    }

    facebookParameters.push('u=' + encodeURI(shareurl));
    facebookParameters.push('quote=' + encodeURI(''));
    const url =
      'https://www.facebook.com/sharer/sharer.php?'
      + facebookParameters.join('&');

    Linking.openURL(url)
      .then((data) => {
        // alert('Facebook Opened');
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  openBlockUser = (share) => {
    this.setState({ blockusermodal: true })
    this.setState({ blockuserid: share.blockuserid })
  }


  blockUser = () => {
    let user_id = this.state.blockuserid

    this.setState({ action_loading: false })
    this.setState({ blockusermodal: false })


    Toast.show({
      text: "This user has been blocked.",
      duration: 5000,
      type: "success",
      textStyle: { textAlign: "center" }
    })

    this.deleteVotesWith(this.state.blockuserid)

    agent.Connect.updatehold("hold_postings", user_id)
      .then(res => {

        //this.props.editNetwork(updatedUser, this.state.index);
        //user.status = res.status
      })
  }

  deleteVotesWith = (userId) => {
    let friendsvoteIds, othervoteids;
    friendsvoteIds = this.state.opinionOrderIds["My Network (Friends & Family)"].filter((id) => {
      return this.props.opinions[id].user_id != userId
    })
    othervoteids = this.state.opinionOrderIds["Other Opinions"].filter((id) => {
      return this.props.opinions[id].user_id != userId
    })
    let ops = {}
    ops["My Opinion"] = this.state.opinionOrderIds["My Opinion"]
    ops["My Network (Friends & Family)"] = friendsvoteIds
    ops["Other Opinions"] = othervoteids
    this.setState({ opinionOrderIds: ops })
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{ flex: 1, backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)" }}>
        <Container key={this.props.details.id} >
          {!DetectDeviceService.isTablet && <Header style={styles.header}>
            <Left style={{ flex: 1 }}>
              <Button transparent onPress={() => this.props.navigation.goBack()}>
                <Icon name="arrow-back" style={styles.icon} />
              </Button>
            </Left>
            <Body style={{ flex: 3 }}>
              <Title style={[styles.textColor, { textAlign: "center" }]}>Voice Your Opinion</Title>
            </Body>
            <Right style={{ flex: 1 }}>
              {this.props.auth_token != "" && <Menu ref={this.setMenuRef}
                style={{ marginTop: 40, width: 220 }}
                button={<Icon
                  name="share-alternative"
                  style={styles.icon}
                  onPress={this.showMenu}
                  type="Entypo"></Icon>}>
                <MenuItem style={styles.menuOption} onPress={() => this.postOnFacebook()}>
                  <Text style={styles.headerMenuText}>Post on Facebook</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => console.log(1)}>
                  <Text style={styles.headerMenuText}>Post on X</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => this.emailElectedOfficials()}>
                  <Text style={styles.headerMenuText}>Email Elected Officials</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => this.emailFriend()}>
                  <Text style={styles.headerMenuText}>Email a friend</Text>
                </MenuItem>
              </Menu>}
            </Right>
          </Header>}
          {DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation} />}
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.replymodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS == "ios" ? "padding" : "height"}
              enabled
              style={{ flex: 1 }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={{ height: 45, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: 18, color: "black" }}>Reply</Text>
                  </View>
                  <Form>
                    {/* <Item> */}
                    {/* <Label style={styles.modal}>Email Address</Label> */}
                    <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Type your comment here"
                      onChangeText={(text) => { this.setState({ comment_text: text }) }} />
                    {/* </Item> */}
                  </Form>
                  <View style={styles.forgotactions}>
                    {this.state.action_loading && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                      onPress={() => {
                        this.setState({ replymodal: false });
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                      onPress={() => {
                        this.voteComment()
                      }}
                    >
                      <Text style={styles.textStyle}>Submit</Text>
                    </Button>}
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.flagmodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS == "ios" ? "padding" : "height"}
              enabled
              style={{ flex: 1 }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={{ height: 45, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: 18, color: "black" }}>Flag</Text>
                  </View>
                  <Form>
                    {/* <Item> */}
                    {/* <Label style={styles.modal}>Email Address</Label> */}
                    <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Flag objectionable content that violate the Terms of Use.  For example: violent threats, and obscene comments."
                      placeholderTextColor="#808080"
                      onChangeText={(text) => { this.setState({ flag_comment_text: text }) }} />
                    {/* </Item> */}
                  </Form>
                  <View style={styles.forgotactions}>
                    {this.state.action_loading && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                      onPress={() => {
                        this.setState({ flagmodal: false });
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                      onPress={() => {
                        this.flagComment()
                      }}
                    >
                      <Text style={styles.textStyle}>Submit</Text>
                    </Button>}
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.editmodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS == "ios" ? "padding" : "height"}
              enabled
              style={{ flex: 1 }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={{ height: 35, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: 18, color: "black" }}>Edit Comment</Text>
                  </View>
                  <Form>
                    {/* <Item> */}
                    {/* <Label style={styles.modal}>Email Address</Label> */}
                    <Textarea bordered value={this.state.editModal.comment_text} rowSpan={3} style={styles.modal} placeholder="Type your comment here"
                      onChangeText={(text) => { this.setState({ editModal: { ...this.state.editModal, comment_text: text } }) }} />
                    {/* </Item> */}
                  </Form>
                  <View style={styles.forgotactions}>
                    {this.state.action_loading && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                      onPress={() => {
                        this.setState({ editmodal: false });
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                      onPress={() => {
                        this.editVoteComment()
                      }}
                    >
                      <Text style={styles.textStyle}>Submit</Text>
                    </Button>}
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.opensaveerror}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                  <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                  <Text>{this.state.opensaveerrormsg}</Text>
                </View>
                <View style={styles.forgotactions}>
                  <Button
                    style={[styles.forgotcancel, { backgroundColor: "#333", width: 60 }]}
                    onPress={() => {
                      this.setState({ opensaveerror: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Ok</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalopen}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS == "ios" ? "padding" : "height"}
              enabled
              style={{ flex: 1 }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={{ height: 35, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: 18, color: "black" }}>{this.state.modalheading}</Text>
                  </View>
                  <View>
                    <Text>{this.state.modaltext}</Text>
                  </View>
                  <View style={styles.forgotactions}>
                    {this.state.action_loading && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                      onPress={() => {
                        this.setState({ modalopen: false });
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                      onPress={() => {
                        this.callFunction()
                        // this.voteComment()
                      }}
                    >
                      <Text style={styles.textStyle}>Continue</Text>
                    </Button>}
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.blockusermodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
              behavior={Platform.OS == "ios" ? "padding" : "height"}
              enabled
              style={{ flex: 1 }}
            >
              <View style={styles.centeredView}>
                <View style={styles.modalView}>
                  <View style={{ height: 45, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontSize: 18, color: "black" }}>Block</Text>
                  </View>
                  <View style={{ marginBottom: 10 }}>
                    <Text>Block comments from this member?</Text>
                  </View>
                  <View style={styles.forgotactions}>
                    {this.state.action_loading && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                      onPress={() => {
                        this.setState({ blockusermodal: false });
                      }}
                    >
                      <Text style={styles.textStyle}>Cancel</Text>
                    </Button>}
                    {!this.state.action_loading && <Button
                      style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                      onPress={this.blockUser}
                    >
                      <Text style={styles.textStyle}>Submit</Text>
                    </Button>}
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>

          <Col style={[{ backgroundColor: "#ececec", maxHeight: 60 }]} >
            <BreadCrumb navigation={this.props.navigation} links={["Home", this.billTypeText(), "Details", "VYO"]} shareDropDown={this.shareDropDown}></BreadCrumb>
          </Col>
          {!this.state.loading && <Content innerRef={ref => { this.ContentScroll = ref }} style={[{ backgroundColor: "#ececec" }, styles.contentMargin]} extraScrollHeight={75} scrollEnabled={!(this.state.replymodal || this.state.modalopen || this.state.editmodal)} enableResetScrollToCoords={false} keyboardShouldPersistTaps={'handled'}>

            <Grid style={{ backgroundColor: "#fff", padding: 15, marginLeft: DetectDeviceService.isTablet ? 0 : 10, marginRight: 10 }}>
              <Row>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>{this.state.bill.title}</Text>
              </Row>
              <Row style={[{ marginTop: 20, alignItems: "center", justifyContent: "center" }, styles.infographic]}>
                {this.getVoteTypeBar("Strong oppose")}
                {this.getVoteTypeBar("Oppose")}
                {this.getVoteTypeBar("Neutral")}
                {this.getVoteTypeBar("Support")}
                {this.getVoteTypeBar("Strong support")}
              </Row>
              <Row style={[{ width: (DetectDeviceService.isTablet ? 400 : "100%"), alignSelf: "center", marginTop: (DetectDeviceService.isTablet ? 50 : 30) }]}>
                <Icon style={[{ position: "relative", bottom: (DetectDeviceService.isTablet ? 15 : 5) }, this.myVotePos()]} name='caret-down-outline' type="Ionicons" />
                <Text style={{ fontWeight: "bold", position: "relative", left: -28, bottom: (DetectDeviceService.isTablet ? 25 : 15), textAlign: "center" }}>{this.billOpinionScore().toFixed(1)}</Text>
              </Row>
              <Row style={[{ marginBottom: 20 }, styles.infographic]}>
                <Col>
                  <Row style={{ alignSelf: "center" }}>
                    <Button style={{ width: 81 }} transparent onPress={() => this.voteBill("Strong oppose")}>
                      <Image style={styles.voteButton} source={this.myVoteLocalImage("Strong oppose")} />
                    </Button>
                  </Row>
                  <Row style={{ marginTop: (DetectDeviceService.isTablet ? 40 : 20), justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>Strong Oppose</Text>
                  </Row>
                  <Row style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>{this.getVoteTypePerc("Strong oppose")}%</Text>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ alignSelf: "center" }}>
                    <Button style={{ width: 81 }} transparent onPress={() => this.voteBill("Oppose")}>
                      <Image style={styles.voteButton} source={this.myVoteLocalImage("Oppose")} />
                    </Button>
                  </Row>
                  <Row style={{ marginTop: (DetectDeviceService.isTablet ? 40 : 20), justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>Oppose</Text>
                  </Row>
                  <Row style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>{this.getVoteTypePerc("Oppose")}%</Text>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ alignSelf: "center" }}>
                    <Button style={{ width: 81 }} transparent onPress={() => this.voteBill("Neutral")}>
                      <Image style={styles.voteButton} source={this.myVoteLocalImage("Neutral")} />
                    </Button>
                  </Row>
                  <Row style={{ marginTop: (DetectDeviceService.isTablet ? 40 : 20), justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>Not sure</Text>
                  </Row>
                  <Row style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>{this.getVoteTypePerc("Neutral")}%</Text>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ alignSelf: "center" }}>
                    <Button style={{ width: 81 }} transparent onPress={() => this.voteBill("Support")}>
                      <Image style={styles.voteButton} source={this.myVoteLocalImage("Support")} />
                    </Button>
                  </Row>
                  <Row style={{ marginTop: (DetectDeviceService.isTablet ? 40 : 20), justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>Support</Text>
                  </Row>
                  <Row style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>{this.getVoteTypePerc("Support")}%</Text>
                  </Row>
                </Col>
                <Col>
                  <Row style={{ alignSelf: "center" }}>
                    <Button style={{ width: 81 }} transparent onPress={() => this.voteBill("Strong support")}>
                      <Image style={styles.voteButton} source={this.myVoteLocalImage("Strong support")} />
                    </Button>
                  </Row>
                  <Row style={{ marginTop: (DetectDeviceService.isTablet ? 40 : 20), justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>Strong Support</Text>
                  </Row>
                  <Row style={{ justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ textAlign: "center", color: "#808080", fontSize: 14 }}>{this.getVoteTypePerc("Strong support")}%</Text>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 0, marginBottom: 15 }}>
                <Textarea rowSpan={5} bordered placeholder="Type your comment here" style={{ width: "100%" }} onChangeText={(text) => this.setState({ opinioncomment: text })} value={this.state.opinioncomment} />
              </Row>
              <Row>
                <Col style={{ marginRight: 15, alignItems: "flex-start" }}>
                  <Row><Button block style={{ width: 100, height: 30, backgroundColor: "#4589c6" }} onPress={() => this.saveOpinion()}>
                    <Text style={{ fontSize: 15, textAlign: "center" }}>Save</Text>
                  </Button></Row>
                </Col>
                <Col style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
                  <Row><Button block onPress={() => this.saveAndShareOpinion()}
                    style={{ width: 100, height: 30, backgroundColor: "#4589c6" }}>
                    <Text style={{ fontSize: 15, textAlign: "center" }}>Share</Text>
                  </Button></Row>
                  <SectionedMultiSelect
                    ref={SectionedMultiSelect1 => (this.SectionedMultiSelect1 = SectionedMultiSelect1)}
                    IconRenderer={MaterialIcons}
                    items={this.state.my_representatives}
                    noItemsComponent={() => <Text>There are no members in your network.  Go to CONNECT page to find members to connect with.</Text>}
                    hideSearch={true}
                    hideSelect={true}
                    showChips={false}
                    uniqueKey="id"
                    displayKey="name"
                    subKey="children"
                    selectText="Select users"
                    alwaysShowSelectText={true}
                    showDropDowns={false}
                    // readOnlyHeadings={true}
                    showCancelButton={true}
                    modalWithSafeAreaView={true}
                    onSelectedItemsChange={this.onSelectedItemsChange}
                    onConfirm={() => this.callRepFunction()}
                    selectedItems={this.state.selectedItems}
                    renderSelectText={this.renderSelectText}
                    iconKey="icon"
                    styles={{
                      chipText: {
                        maxWidth: Dimensions.get('screen').width - 90,
                      },
                      // itemText: {
                      //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                      // },
                      // selectedItemText: {
                      //   color: 'blue',
                      // },
                      // subItemText: {
                      //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                      // },
                      item: {
                        paddingHorizontal: 10,
                      },
                      subItem: {
                        paddingHorizontal: 10,
                      },
                      selectedItem: {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                      selectedSubItem: {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                      // selectedSubItemText: {
                      //   color: 'blue',
                      // },
                      scrollView: { paddingHorizontal: 0 },
                      container: { width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center" }
                    }}

                  />
                  <SectionedMultiSelect
                    ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
                    IconRenderer={MaterialIcons}
                    items={this.state.network}
                    noItemsComponent={() => <Text>There are no members in your network.  Go to CONNECT page to find members to connect with.</Text>}
                    hideSearch={true}
                    hideSelect={true}
                    showChips={false}
                    uniqueKey="id"
                    displayKey="name"
                    // subKey="children"
                    headerComponent={this.SelectOrRemoveAll}
                    selectText="Select users"
                    alwaysShowSelectText={true}
                    showDropDowns={false}
                    // readOnlyHeadings={true}
                    showCancelButton={true}
                    modalWithSafeAreaView={true}
                    onSelectedItemsChange={this.onSelectedItemsChange}
                    onConfirm={() => this.saveAndShareOpinion()}
                    selectedItems={this.state.selectedItems}
                    renderSelectText={this.renderSelectText}
                    iconKey="icon"
                    styles={{
                      chipText: {
                        maxWidth: Dimensions.get('screen').width - 90,
                      },
                      // itemText: {
                      //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                      // },
                      // selectedItemText: {
                      //   color: 'blue',
                      // },
                      // subItemText: {
                      //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                      // },
                      item: {
                        paddingHorizontal: 10,
                      },
                      subItem: {
                        paddingHorizontal: 10,
                      },
                      selectedItem: {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                      selectedSubItem: {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                      // selectedSubItemText: {
                      //   color: 'blue',
                      // },
                      scrollView: { paddingHorizontal: 0 },
                      container: { width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center" }
                    }}

                  />
                </Col>
              </Row>
            </Grid>
            {/* <View style={{height: 530}}>
         <WebView originWhitelist={["schemabills://", "https://", "http://"]} source={{uri: "http://staging.yoopine.com/api/v1/bills/"+this.props.details.id+"/opinions?auth_token="}} />
        </View> */}
            <List style={{ backgroundColor: "#fff", marginLeft: 10, marginRight: 10 }}>
              <Separator bordered>
                <Text style={{ height: 22, fontSize: 18, color: "#000" }}>My Opinion</Text>
              </Separator>
              {
                this.state.opinionOrderIds["My Opinion"].map((comment, index) => {
                  let comment1 = this.props.opinions[comment.toString()]
                  return (
                    comment1 != null && <BillOpinion key={comment1.id}
                      pageObject={this.props.details}
                      commentIndex={index}
                      comment={comment1}
                      heading="My Opinion"
                      gotoVoteDetails={this.gotoVoteDetails}
                      removeOpinion={this.removeOpinion}
                      openEditModal={this.openEditModal}
                      openFlagModal={this.openFlagModal}
                      openBlockUser={this.openBlockUser}
                      openReplyModal={this.openReplyModal} />
                  );
                })
              }
            </List>
            <List style={{ backgroundColor: "#fff", marginLeft: 10, marginRight: 10 }}>
              <Separator bordered>
                <Text style={{ height: 22, fontSize: 18, color: "#000" }}>My Network (Connections & Following)</Text>
              </Separator>
              {
                this.state.opinionOrderIds["My Network (Friends & Family)"].map((comment, index) => {
                  let comment1 = this.props.opinions[comment.toString()]
                  return (
                    comment1 != null && <BillOpinion key={comment1.id}
                      pageObject={this.props.details}
                      commentIndex={index}
                      comment={comment1}
                      heading="My Network (Friends & Family)"
                      gotoVoteDetails={this.gotoVoteDetails}
                      openFlagModal={this.openFlagModal}
                      openBlockUser={this.openBlockUser}
                      openReplyModal={this.openReplyModal} />
                  );
                })
              }
            </List>
            <List style={{ backgroundColor: "#fff", marginLeft: 10, marginRight: 10 }}>
              <Separator bordered>
                <Text style={{ height: 22, fontSize: 18, color: "#000" }}>Other Opinions</Text>
              </Separator>
              {
                !this.state.pageLoading && this.state.opinionOrderIds["Other Opinions"].map((comment, index) => {
                  let comment1 = this.props.opinions[comment.toString()]
                  return (
                    comment1 != null && <BillOpinion key={comment1.id}
                      pageObject={this.props.details}
                      commentIndex={index}
                      comment={comment1}
                      heading="Other Opinions"
                      gotoVoteDetails={this.gotoVoteDetails}
                      openFlagModal={this.openFlagModal}
                      openBlockUser={this.openBlockUser}
                      openReplyModal={this.openReplyModal} />
                  );
                })
              }
            </List>
            {this.state.pageLoading && <Spinner color='blue'></Spinner>}
            {!this.state.pageLoading && <Row style={{ alignItems: "center", justifyContent: "center", marginTop: 10, marginBottom: 10 }}>
              {this.state.page == 1 && <Button disabled style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 15 }}>First</Text>
              </Button>}
              {this.state.page != 1 && <Button bordered onPress={() => this.getFirstPage()} style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 15 }}>First</Text>
              </Button>}
              {this.state.page != 1 && <Button bordered onPress={() => this.prevPage()} style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 15 }}>Prev</Text>
              </Button>}
              {this.state.page == 1 && <Button disabled style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 15 }}>Prev</Text>
              </Button>}
              {!this.state.lastPage && <Button bordered onPress={() => this.nextPage()}>
                <Text style={{ fontSize: 15 }}>Next</Text>
              </Button>}
              {this.state.lastPage && <Button disabled style={{ marginRight: 10 }}>
                <Text style={{ fontSize: 15 }}>Next</Text>
              </Button>}
            </Row>}
          </Content>}
          {this.state.loading && <Spinner color='blue'></Spinner>}
        </Container>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
    flex: 1
  },
  contentMargin: {
    // paddingLeft: Platform.isPad ? 170 : 0,
    // paddingRight: Platform.isPad ? 170 : 0
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "76%" : "100%",
    alignSelf: "center",
    marginRight: DetectDeviceService.isTab ? 0 : DetectDeviceService.isTablet ? 56 : 0
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
  modal: {
    color: "#333",
  },
  voteBar: {
    height: 6,
    width: 35,
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 18,
    marginRight: 18,
  },
  infographic: {
    width: DetectDeviceService.isTablet ? 400 : "100%",
    // marginLeft: Platform.isPad ? 200 : 0
    alignSelf: "center"
  },
  voteBarLight: {
    backgroundColor: "#eee"
  },
  voteBarDark: {
    backgroundColor: "#808080"
  },
  voteBarVal: {
    fontSize: 13,
    textAlign: "center",
    marginLeft: 0
  },
  forgotcancel: {
    height: 36,
    width: 90,
    marginRight: 20
  },
  forgotsubmit: {
    height: 36,
    width: 90
  },
  forgotactions: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  voteButton: {
    height: (DetectDeviceService.isTablet ? 81 : 74),
    width: "100%"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
  },
  textColor: {
    color: "rgba(255,255,255,1)",
    width: 180
  },
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 20
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceYourOpinionScreen);
