import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';

import DetectDeviceService from '../lib/devicedetection';

//import { HomeNavigator } from './home.navigator';
import SplashScreen from '../screens/SplashScreen';
import AccountSetupScreen from '../screens/AccountSetupScreen';
import SignupScreen from '../screens/SignupScreen';
import LoginScreen from '../screens/LoginScreen';
import HomeScreenWeb from '../screens/web/HomeScreenWeb';
import ShareScreenWeb from '../screens/web/ShareScreenWeb';

import BillDetailsScreen from '../screens/BillDetailsScreen';
import RepresentativeDetailsScreen from '../screens/RepresentativeDetailsScreen';
import RepresentativesDetailsScreenWeb from '../screens/web/RepresentativesDetailsScreenWeb';

import RepresentativesScreenWeb from '../screens/web/RepresentativesScreenWeb';
import VoiceYourOpinionScreen from '../screens/VoiceYourOpinionScreen';
import FilterScreen from '../screens/FilterScreen';
import AboutScreen from '../screens/AboutScreen';
import TermsScreen from '../screens/TermsScreen';
import PrivacyScreen from '../screens/PrivacyScreen';
import DisclosuresScreen from '../screens/DisclosuresScreen';

import { Routes } from './routes';
import NotificationSettingsScreen from '../screens/NotificationSettingsScreen';
import GeneralSettingsScreen from '../screens/GeneralSettingsScreen';
import PrivacySettingsScreen from '../screens/PrivacySettingsScreen';
import ManageNetworkScreen from '../screens/ManageNetworkScreen';
import VoteDetailsScreen from "../screens/VoteDetailsScreen";
import ShareDetailsScreen from "../screens/ShareDetailsScreen";
import CreateInitiativeScreen from '../screens/CreateInitiativeScreen';
import FirstAboutUsScreen from '../screens/FirstAboutUsScreen';
import CommonFriendsScreen from '../screens/CommonFriendsScreen';
import FedBillsScreenWeb from '../screens/web/FedBillsScreenWeb';
import StateBillsScreenWeb from '../screens/web/StateBillsScreenWeb';
import ExecutiveOrdersScreenWeb from '../screens/web/ExecutiveOrdersScreenWeb';
import InitiativesScreenWeb from '../screens/web/InitiativesScreenWeb';
import MembersScreenWeb from '../screens/web/MembersScreenWeb';
import InvitationsScreenWeb from '../screens/web/InvitationsScreenWeb';
import SuggestionsScreenWeb from '../screens/web/SuggestionsScreenWeb';
import MatchMyVotesScreenWeb from '../screens/web/MatchMyVotesScreenWeb';
import GeneralSettingsScreenWeb from '../screens/web/GeneralSettingsScreenWeb';
import PrivacySettingsScreenWeb from '../screens/web/PrivacySettingsScreenWeb';
import NotificationSettingsScreenWeb from '../screens/web/NotificationSettingsScreenWeb';
import ManageNetworkScreenWeb from '../screens/web/ManageNetworkScreenWeb';
import ManageNetworkBlockedWeb from '../screens/web/ManageNetworkBlockedWeb';
import ManageNetworkFollowingWeb from '../screens/web/ManageNetworkFollowingWeb';
import ManageAdsWeb from '../screens/web/ManageAdsWeb';
import CreateAdsWeb from '../screens/web/CreateAdsWeb';

import { connect } from 'react-redux';

import LeftMenu from '../components/LeftMenu';
import SplashScreenWeb from '../screens/web/SplashScreenWeb';

const Stack = createDrawerNavigator();

const mapStateToProps = state => ({ ...state.home });

const mapDispatchToProps = dispatch => ({
});


// export const WebNavigator = (props): React.ReactElement => {
class WebNavigator extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activesubtab: 1,
      ad_enabled: false
    }
    console.log(this.props)
  }

  componentDidMount() {
    console.log(12112211)
    console.log(this.props.ad_playing)
  }

  render() {
    return (<Stack.Navigator {...this.props} drawerType={DetectDeviceService.isTablet ? (this.props.ad_playing == true ? "back" : "permanent") : "front"}
      drawerContent={(props) => <LeftMenu {...props} />} drawerStyle={{ width: (DetectDeviceService.isTablet ? 240 : 240) }}
      headerMode='none'>
      <Stack.Screen name={Routes.SPLASH} component={SplashScreen} />
      <Stack.Screen name={Routes.HOME} component={HomeScreenWeb} />
      <Stack.Screen name={Routes.SHARES} component={ShareScreenWeb} />
      <Stack.Screen name={Routes.SIGN_IN} component={LoginScreen} />
      <Stack.Screen name={Routes.SIGN_UP} component={SignupScreen} />
      <Stack.Screen name={Routes.ACCOUNT_SETUP} component={AccountSetupScreen} />
      <Stack.Screen name={Routes.AUTH} component={LoginScreen} />

      <Stack.Screen name={Routes.FEDERAL_BILLS} component={FedBillsScreenWeb} />
      <Stack.Screen name={Routes.STATE_BILLS} component={StateBillsScreenWeb} />
      <Stack.Screen name={Routes.EXECUTIVE_ORDERS} component={ExecutiveOrdersScreenWeb} />
      <Stack.Screen name={Routes.INITIATIVES} component={InitiativesScreenWeb} />

      <Stack.Screen name={Routes.MEMBERS} component={MembersScreenWeb} />
      <Stack.Screen name={Routes.INVITATIONS} component={InvitationsScreenWeb} />
      <Stack.Screen name={Routes.SUGGESTIONS} component={SuggestionsScreenWeb} />

      <Stack.Screen name={Routes.REPRESENTATIVES} component={RepresentativesScreenWeb} />

      <Stack.Screen name={Routes.BILL_DETAILS} component={BillDetailsScreen} />
      <Stack.Screen name={Routes.INITIATIVE_DETAILS} component={BillDetailsScreen} />

      <Stack.Screen name={Routes.REPRESENTATIVE_DETAILS} component={BillDetailsScreen} />
      <Stack.Screen name={Routes.VOICE_YOUR_OPINION} component={VoiceYourOpinionScreen} />
      <Stack.Screen name={Routes.FILTERS} component={FilterScreen} />
      <Stack.Screen name={Routes.FIRSTABOUTUS} component={FirstAboutUsScreen} />
      <Stack.Screen name={Routes.ABOUT} component={AboutScreen} />
      <Stack.Screen name={Routes.TERMS} component={TermsScreen} />
      <Stack.Screen name={Routes.PRIVACY} component={PrivacyScreen} />
      <Stack.Screen name={Routes.DISCLOSURES} component={DisclosuresScreen} />

      <Stack.Screen name={Routes.PRIVACY_SETTINGS} component={PrivacySettingsScreenWeb} />
      <Stack.Screen name={Routes.NOTIFICATION_SETTINGS} component={NotificationSettingsScreenWeb} />
      <Stack.Screen name={Routes.GENERAL_SETTINGS} component={GeneralSettingsScreenWeb} />
      <Stack.Screen name={Routes.MANAGE_NETWORK} component={ManageNetworkScreenWeb} />
      <Stack.Screen name={Routes.MANAGE_NETWORK_BLOCKED} component={ManageNetworkBlockedWeb} />
      <Stack.Screen name={Routes.MANAGE_NETWORK_FOLLOWING} component={ManageNetworkFollowingWeb} />


      <Stack.Screen name={Routes.VOTE_DETAILS} component={VoteDetailsScreen} />
      <Stack.Screen name={Routes.SHARE_DETAILS} component={ShareDetailsScreen} />
      <Stack.Screen name={Routes.CREATE_INITIATIVE} component={CreateInitiativeScreen} />
      <Stack.Screen name={Routes.COMMON_FRIENDS} component={CommonFriendsScreen} />
      <Stack.Screen name={Routes.MATCH_MY_VOTES} component={MatchMyVotesScreenWeb} />

      <Stack.Screen name={Routes.ADS} component={ManageAdsWeb} />
      <Stack.Screen name={Routes.CREATE_ADS} component={CreateAdsWeb} />
    </Stack.Navigator>)
  }

};

export default connect(mapStateToProps, mapDispatchToProps)(WebNavigator);