import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Footer,
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import BillsFilter from '../components/BillsFilter';
import StateBillsFilter from '../components/StateBillsFilter';
import LocalBillsFilter from '../components/LocalBillsFilter';
import InitiativesFilter from '../components/InitiativesFilter';
import ExecutiveOrdersFilter from '../components/ExecutiveOrdersFilter';
import COLOR from '../styles/Color'
import { connect } from 'react-redux';

import agent from '../agent'

import {
  setListFilter,
  setListType
} from '../ducks/bill';

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
  setListFilter: (filters) => dispatch(setListFilter(filters)),
  setListType: (list) => dispatch(setListType(list)),
});

class FilterScreen extends React.Component {

  constructor(props) {
    super(props);
    let selfF = {}
    if(this.props.list_filters.years != undefined){
      selfF['years'] = this.props.list_filters.years
    }
    if(this.props.list_filters.filter2 != undefined){
      selfF['filter2'] = this.props.list_filters.filter2
    }
    if(this.props.list_filters.filter != undefined){
      selfF['filter'] = this.props.list_filters.filter
    }
    if(this.props.list_filters.sort != undefined){
      selfF['sort'] = this.props.list_filters.sort
    }
    if(this.props.list_filters.category != undefined){
      selfF['category'] = this.props.list_filters.category
    }
    selfF['issue_list'] = ''
    let currentYear = new Date().getFullYear()
    let currentCongress = ''
    let prevCongress = ''
    if(currentYear % 2 == 0) {
      currentCongress = (currentYear - 1) + "," + currentYear
      prevCongress = (currentYear - 3) + "," + (currentYear - 2)
    } else {
      currentCongress = currentYear + "," + (currentYear + 1)
      prevCongress = (currentYear - 2) + "," + (currentYear - 1)
    }
    this.state = {
      selectedFilter: selfF,
      selectedItems: [],
      filterYears: [this.props.route.params.prevCongress, this.props.route.params.currentCongress],
      issues:[],
      // issues: [
        // {
          // name: "Categories",
          // id: 0,
          // children: [
            // {id: 0, name: "Animal Rights"},
            // {id: 1, name: "Armed Services"},
            // {id: 2, name: "Banking and Finance"},
            // {id: 3, name: "Budget and Economy"},
            // {id: 4, name: "Civil Rights"},
            // {id: 5, name: "Commerce and Consumer Affairs"},
            // {id: 6, name: "Controlled Substances"},
            // {id: 7, name: "Crime"},
            // {id: 8, name: "Education"},
            // {id: 9, name: "Elections"},
            // {id: 10, name: "Energy"},
            // {id: 11, name: "Environment"},
            // {id: 12, name: "Ethics and Accountability"},
            // {id: 13, name: "Firearms"},
            // {id: 14, name: "Food and Argiculture"},
            // {id: 15, name: "Foreign Policy"},
            // {id: 16, name: "Foreign Trade"},
            // {id: 17, name: "Government Affairs"},
            // {id: 18, name: "Health Care, Medicare and Medicaid"},
            // {id: 19, name: "Homeland Security"},
            // {id: 20, name: "Housing and Development"},
            // {id: 21, name: "Human Rights"},
            // {id: 22, name: "Human Trafficking"},
            // {id: 23, name: "Immigration"},
            // {id: 24, name: "Insurance"},
            // {id: 25, name: "Labor and Employment"},
            // {id: 26, name: "Law Enforcement and Judiciary"},
            // {id: 27, name: "Natural Resources / Monuments"},
            // {id: 28, name: "Oversight and Reform"},
            // {id: 29, name: "Privacy / Information Security"},
            // {id: 30, name: "Public Health and Safety"},
            // {id: 31, name: "Public Works and Utilities"},
            // {id: 32, name: "Reproductive Health/ Abortion"},
            // {id: 33, name: "Science and Technology"},
            // {id: 34, name: "Small Businesses"},
            // {id: 35, name: "Social Security / Retirement"},
            // {id: 36, name: "Social Services"},
            // {id: 37, name: "Taxes"},
            // {id: 38, name: "Transportation and Infrastructure"},
            // {id: 39, name: "Veterans Affairs"},
            // {id: 40, name: "Z-Not Categorized"}
          // ]
        // }
      // ]
    }
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      let selfF = {}
      if(this.props.list_filters.years != undefined){
        selfF['years'] = this.props.list_filters.years
      }
      if(this.props.list_filters.filter2 != undefined){
        selfF['filter2'] = this.props.list_filters.filter2
      }
      if(this.props.list_filters.filter != undefined){
        selfF['filter'] = this.props.list_filters.filter
      }
      if(this.props.list_filters.sort != undefined){
        selfF['sort'] = this.props.list_filters.sort
      }
      if(this.props.list_filters.category != undefined){
        selfF['category'] = this.props.list_filters.category
      }
      this.setState({selectedFilter: selfF})
    })
    agent.Bill.issue_titles()
    .then((res) => {
      this.setState({issues: res.issue_titles})
    })
  }

  setBillsFilter = (obj) => {
    this.setState({ selectedFilter: obj });
  }

  setBillsIssues = (selectedItems) => {
    this.setState({ selectedItems:  selectedItems.selectedItems});
  }

  applyFilters = () => {
    // let selectedIssues = this.state.selectedItems.map((obj) => { return obj.name }).join(":")
    let selectedIssues = this.state.selectedItems.map((obj) => { return obj.id })
    let ff = this.state.selectedFilter
    if(selectedIssues && selectedIssues != undefined)
      ff["issue_title_ids"] = selectedIssues
    if(ff.filter == "watchlist") {
      // delete ff['filter']
      this.props.setListType({type: this.props.list_type, url: "/api/v1/bills/watchlist"})
    } else {
      let url;
      if(this.props.list_type == "initiative") {
        url = "/api/v1/initiatives"
      } else if(this.props.list_type == "eo") {
        url = "/api/v1/bills"
      } else if(this.props.list_type == "representative") {
        url = "/api/v1/representatives"
      } else {
        url = "/api/v1/bills"
      }
      this.props.setListType({type: this.props.list_type, url: url})
    }
    this.props.setListFilter(ff);
    if(this.props.route.params != undefined && this.props.route.params.refresh != undefined)
      this.props.route.params.refresh()
    this.props.navigation.goBack()
  }

  filterTitle = () => {
    if(this.props.list_type == "fed") {
			return <Title style={styles.textColor}>Filters for Federal Bills</Title>
    } else if(this.props.list_type == "state") {
      return <Title style={styles.textColor}>Filters for State Bills</Title>
    } else if(this.props.list_type == "local") {
      return <Title style={styles.textColor}>Filters for Local Bills</Title>
    } else if(this.props.list_type == "eo") {
      return <Title style={styles.textColor}>Filters for Executive Orders</Title>
    } else if(this.props.list_type == "initiative") {
      return <Title style={styles.textColor}>Filters for Initiatives</Title>
    }
  }

  showFilterOptions = () => {
    if(this.props.list_type == "fed") {
			return this.state.issues.length > 0 && <BillsFilter setBillsFilter={this.setBillsFilter}
                          setBillsIssues={this.setBillsIssues}
                          selectedFilter={this.state.selectedFilter}
                          selectedItems={this.state.selectedItems}
                          filterYears={this.state.filterYears}
                          issues={this.state.issues} />
    } else if(this.props.list_type == "state") {
			return <StateBillsFilter setBillsFilter={this.setBillsFilter}
                          setBillsIssues={this.setBillsIssues}
                          selectedFilter={this.state.selectedFilter}
                          selectedItems={this.state.selectedItems}
                          filterYears={this.state.filterYears}
                          issues={this.state.issues} />
    } else if(this.props.list_type == "local") {
			return <LocalBillsFilter setBillsFilter={this.setBillsFilter}
                          setBillsIssues={this.setBillsIssues}
                          selectedFilter={this.state.selectedFilter}
                          selectedItems={this.state.selectedItems}
                          filterYears={this.state.filterYears}
                          issues={this.state.issues} />
    } else if(this.props.list_type == "eo") {
			return <ExecutiveOrdersFilter setBillsFilter={this.setBillsFilter}
                          setBillsIssues={this.setBillsIssues}
                          selectedFilter={this.state.selectedFilter}
                          selectedItems={this.state.selectedItems}
                          filterYears={this.state.filterYears}
                          issues={this.state.issues} />
    } else if(this.props.list_type == "initiative") {
			return <InitiativesFilter setBillsFilter={this.setBillsFilter}
                          setBillsIssues={this.setBillsIssues}
                          selectedFilter={this.state.selectedFilter}
                          selectedItems={this.state.selectedItems}
                          filterYears={this.state.filterYears}
                          issues={this.state.issues} />
    }
  }

  render() {
    return (
      <Container>
        <Header style={styles.header}>
          <Left style={{flex: 1}}>
            <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon}/>
            </Button>
          </Left>
          <Body style={{flex: 3}}>
            {this.filterTitle()}
          </Body>
          <Right style={{flex: 1}}>
          </Right>
        </Header>

       <Content style={styles.contentMargin}>
        {this.showFilterOptions()}
         
       </Content>
        <View style={styles.filterButton}>
          <Button dark rounded onPress={() => this.applyFilters()}>
            <Icon name="search" type="FontAwesome" />
          </Button>
        </View>
       {/* <Footer style={styles.footer}>
         <Button transparent style={styles.voiceopinionbutton} onPress={this.applyFilters}>
           <Text style={styles.textColor}>Search</Text>
         </Button>
       </Footer> */}
     </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
	  flex: 1
  },
  contentMargin: {
    // paddingLeft: Platform.isPad ? 170 : 0,
    // paddingRight: Platform.isPad ? 170 : 0,
    backgroundColor: "#ececec",
    width: DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	textColor: {
    color: "rgba(255,255,255,1)",
    alignSelf: "center",
    width: 200
	},
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
		paddingLeft: 70,
		paddingRight: 70,
    height: 40
  },
  footer: {
    padding: 20,
  },
  filterButton: {
		position: "absolute",
    bottom: 40,
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center"
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterScreen);
