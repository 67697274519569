import React, { Component } from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Footer,
  Spinner,
  FooterTab
} from "native-base";

import HeaderMenu from '../components/HeaderMenu';

import { Col, Row, Grid } from "../lib/easy-grid";

import { API_URL } from "../constants/server";

import DetectDeviceService from '../lib/devicedetection';

import { WebView } from 'react-native-webview';
import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import { Routes } from '../navigation/routes';
import COLOR from '../styles/Color'
import FONT from '../styles/Font'
import { connect } from 'react-redux';
import {
	setBillId,
} from '../ducks/bill';

import BreadCrumb from "../components/BreadCrumb";

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
});

class BillDetailsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      spinner: false
    }
  }

  componentDidMount() {
    this.setState({spinner: true})
  }

  hideSpinner = () => {
    this.setState({spinner: false})
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{flex: 1, backgroundColor: "#2B5EAA"}}>
      <Container style={{backgroundColor: "rgb(236, 236, 236)"}}>

        <HeaderMenu navigation={this.props.navigation}/>

        { this.state.spinner && <Spinner color="blue" /> }
        <Col style={[{backgroundColor: "#ececec", maxHeight: 60}]} >
          <BreadCrumb navigation={this.props.navigation} links={["Home", "Terms of use"]}></BreadCrumb>
        </Col>
        <View style={styles.webviewContainer} >
        <WebView androidHardwareAccelerationDisabled={true} onLoad={this.hideSpinner} source={{ uri: (API_URL + "api/v1/terms_of_service") }} />
        </View>
      </Container>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
	  flex: 1
  },
  webviewContainer: {
    flex: 1,
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center",
    paddingRight: (DetectDeviceService.isTablet ? 60 : 0) 
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	textColor: {
    color: "rgba(255,255,255,1)",
    width: 200
	},
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 20
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillDetailsScreen);
