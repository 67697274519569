import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, Platform } from "react-native";
import FONT from '../styles/Font'
import {
  Icon,
  Thumbnail,
  Text
} from "native-base";

const mime = require('mime');

import {
  DrawerToggleButton,
} from '@react-navigation/drawer';

import Menu, { MenuItem } from 'react-native-material-menu';

import { API_URL } from "../constants/server";

import DetectDeviceService from '../lib/devicedetection';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { Col, Row, Grid } from "../lib/easy-grid";

import * as ImagePicker from 'expo-image-picker';
import uid from 'uuid/v4';

import { connect } from 'react-redux';
import agent from '../agent'

import { Routes } from '../navigation/routes';
import { CommonActions } from '@react-navigation/native';

import {
	setAuthToken,
  setAuthUser,
  setAuthUserDetails
} from '../ducks/auth';

import {
	setHomeTabCount,
  setAdEnabled,
  setFirstAd
} from '../ducks/home';

import * as MailComposer from 'expo-mail-composer';
import devicedetection from "../lib/devicedetection";

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
  setAuthUser: (user) => dispatch(setAuthUser(user)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
  setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
  setAdEnabled: (data) => dispatch(setAdEnabled(data)),
  setFirstAd: (data) => dispatch(setFirstAd(data)),
});

class HeaderMenu extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(123123123123)
    console.log(this.props.navigation)
  }

  _menu = null;

  setMenuRef = ref => {
    this._menu = ref;
  };

  hideMenu = () => {
    this._menu.hide();
  };

  showMenu = () => {
    this._menu.show();
  };

  gotosignup = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.SIGN_UP);
  }

  gotologin = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.SIGN_IN);
  }

  gotoAboutUs = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.ABOUT);
  }

  gotoDisclosure = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.DISCLOSURES);
  }

  gotoGeneralSettings = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.GENERAL_SETTINGS);
  }

  gotoManageNetworkScreen = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.MANAGE_NETWORK);
  }

  gotoPrivacySettings = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.PRIVACY_SETTINGS);
  }

  gotoPrivacyPolicy = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.PRIVACY);
  }

  gotoTermsofUse = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.TERMS);
  }

  gotoNotificationSettings = () => {
    this.hideMenu();
    this.props.navigation.navigate(Routes.NOTIFICATION_SETTINGS);
  }

  logout = () => {
    this.hideMenu();
    agent.setToken("")
    this.props.setAuthToken("");
    AsyncStorage.setItem('@auth_token', "")
    this.props.setAuthUser({})
    this.props.setHomeTabCount({});
    this.props.setAdEnabled(false)
    this.props.setFirstAd(false)
    this.props.navigation.dispatch(state => {
      return CommonActions.reset({
        routes: [
          { name: Routes.SIGN_IN }
        ],
        index: 0,
      });
    });
    // this.props.navigation.navigate(Routes.SIGN_IN);
  }

  contactUs = () => {
    MailComposer.composeAsync({
      recipients: ["admin@yoopine.com"]
    })
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/user_image_placeholder.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  selectProfileImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      // alert('Sorry, we need camera roll permissions to make this work!');
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      if (!result.cancelled) {
        this.uploadImageAsync(result.uri).then((response) => response.json())
        //If response is in json then in success
        .then((responseJson) => {
          let pic = responseJson.pic_url;
          this.props.setAuthUserDetails({
            avatar: pic
          })
        })
        // setImage(result.uri);
      }
    }
  }

  uploadImageAsync(uri) {    
    const method = 'PUT';
    let formData = new FormData();
    const endpoint = API_URL + 'api/v1/users/update_avatar?auth_token='+agent.getToken(); // Define Endpoint Here


    if(Platform.OS == "web") {
      const fileName = uri.split('/').pop();
      const fileType = mime.getType(uri);
      const byteString = atob(uri.split(',')[1]);

      const ab = new ArrayBuffer(byteString.length);
      const arr = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++)
        arr[i] = byteString.charCodeAt(i);

      const blob = new Blob([arr], {
        type: fileType || 'image/png',
      });

      const file = new File([blob], `${fileName}`);

      formData.append('user[avatar]', file);

    } else {
      const uriParts = uri.split('.');
      const fileType = uriParts[uriParts.length - 1];
      const { headers } = this.props;
      const payloadKey = 'user[avatar]';
  
      // formData.append(payloadKey, { uri: localUri, name: filename, type });
  
      formData.append(payloadKey, {
        uri,
        name: uid(),
        type: `image/${fileType}`,
      });
    }
    

    const options = {
      method,
      body: formData,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    };
    return fetch(endpoint, options);
}


  render() {
    return (
     // <Header style={styles.header}>
     //   <Left>
     //     <Button transparent>
     //       <Icon name='arrow-back' />
     //     </Button>
     //   </Left>
     //   <Body>
     //     <Title>Header</Title>
     //   </Body>
     //   <Right>
     //     <Button transparent>
     //       <Icon name='menu' />
     //     </Button>
     //   </Right>
     // </Header>
      <View style={styles.header}>
        {/* <View style={styles.iconRowFiller}></View> */}
        {!DetectDeviceService.isTablet && <View><Icon name="menu"
                           style={styles.icon2}
                           onPress={this.props.navigation.toggleDrawer}
	  		                 	type="Entypo"></Icon></View>}
        {!DetectDeviceService.isTablet && <View style={styles.group}>
        <Thumbnail small style={{marginTop: 4, position: "absolute"}} source={{ uri: "https://www.townhallusa.com/assets/logo.png" }} />
          <Text style={styles.yoopine}>Townhall USA</Text>
          <Text style={styles.yourOpinionMatters}>Your Opinion Matters</Text>
        </View>}
        <View style={styles.iconRowFiller}></View>
        {this.props.auth_token != "" && <TouchableOpacity onPress={() => this.selectProfileImage()}><Thumbnail small style={{width: 35, height: 35, marginTop: 10}} 
                   source={{ uri: this.avatarUrl(this.props.user.avatar) }} /></TouchableOpacity>}
        <Menu ref={this.setMenuRef}
        style={{marginTop: 40, width: 220}}
              button={<Icon
                           name="menu"
                           style={styles.icon2}
                           onPress={this.showMenu}
	  		                 	type="Entypo"></Icon>}>
          { this.props.auth_token == "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotologin}>
                <Text style={styles.headerMenuText}>  Login</Text>
              </MenuItem> 
          }
          { this.props.auth_token == "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotosignup}>
                <Text style={styles.headerMenuText}>  Sign up</Text>
              </MenuItem> }
          { this.props.auth_token != "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotoGeneralSettings}>
                <Icon
                      name="gear"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
                <Text style={styles.headerMenuText}>  General Settings</Text>
              </MenuItem> 
          }
          { this.props.auth_token != "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotoPrivacySettings}>
                <Icon
                      name="lock"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
                <Text style={styles.headerMenuText}>  Privacy Settings</Text>
              </MenuItem> 
          }
          { this.props.auth_token != "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotoNotificationSettings}>
                <Icon
                      name="bell"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
                <Text style={styles.headerMenuText}>  Notification Settings</Text>
              </MenuItem> }
          { this.props.auth_token != "" && 
              <MenuItem style={styles.menuOption} onPress={this.gotoManageNetworkScreen}>
                <Icon name="people"
                      style={[styles.headerMenuIcon, {fontSize: 28, paddingTop: 15}]}
                />
                <Text style={[styles.headerMenuText, {paddingTop: -10}]}>  Manage Network</Text>
              </MenuItem> }
          <MenuItem style={styles.menuOption} onPress={this.gotoDisclosure}>
            <Icon
                      name="info-circle"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
            <Text style={styles.headerMenuText}>  Disclosures</Text>
          </MenuItem>
          <MenuItem style={styles.menuOption} onPress={this.gotoAboutUs}>
            <Icon
                      name="info-circle"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
            <Text style={styles.headerMenuText}>  About Townhall USA</Text>
          </MenuItem>
          <MenuItem style={styles.menuOption} onPress={this.contactUs}>
            <Icon
                      name="user-circle"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
            <Text style={styles.headerMenuText}>  Contact us</Text>
          </MenuItem>
          <MenuItem style={styles.menuOption} onPress={this.gotoPrivacyPolicy}>
            <Icon
                      name="file-lock"
                      style={styles.headerMenuIcon}
                      type="MaterialCommunityIcons"></Icon>
            <Text style={styles.headerMenuText}>  Privacy Policy</Text>
          </MenuItem>
          <MenuItem style={styles.menuOption} onPress={this.gotoTermsofUse}>
            <Icon
                      name="text-document"
                      style={styles.headerMenuIcon}
                      type="Entypo"></Icon>
            <Text style={styles.headerMenuText}>  Terms of use</Text>
          </MenuItem>
          { this.props.auth_token != "" && 
              <MenuItem style={styles.menuOption} onPress={this.logout}>
                <Icon
                      name="power-off"
                      style={styles.headerMenuIcon}
                      type="FontAwesome"></Icon>
                <Text style={styles.headerMenuText}>  Logout</Text>
              </MenuItem> }
        </Menu>
        
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    height: 50,
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)",
    flexDirection: "row",
    // paddingLeft: 200,
    paddingRight: DetectDeviceService.isTab ? 0 : DetectDeviceService.isTablet ? 200 : 0
    // justifyContent: "center",
    // alignItems: "center",
  },
  menuOption: {
    borderBottomColor: "#333",
    borderBottomWidth: 1
  },
  headerMenuIcon: {
    fontSize: 22,
    marginTop: 5,
    paddingTop: 5,
  },
  headerMenuText: {
    fontSize: 16
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 40,
  },
  group: {
    width: 190,
    height: 33,
    marginTop: 4,
    marginLeft: 40,
  },
  yoopine: {
    color: "rgba(255,255,255,1)",
    fontSize: 17,
    // fontFamily: FONT.IOS.Bold,
    fontWeight: "bold",
    // alignSelf: "center"
    marginLeft: 45
  },
  yourOpinionMatters: {
    color: "rgba(255,255,255,1)",
    fontSize: 13,
    // fontFamily: FONT.IOS.Bold,
    fontWeight: "bold",
    // alignSelf: "center"
    marginLeft: 45
  },
  iconRow: {
    height: 40,
    flexDirection: "row",
    marginLeft: 16,
    marginTop: 5
  },
  iconRowFiller: {
    flex: 1,
    flexDirection: "row",
  },
  icon2: {
    color: DetectDeviceService.isTablet ? "#333333" : "rgba(255,255,255,1)",
    fontSize: 35,
    marginRight: 5,
    marginLeft: 10,
    marginTop: 13
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
