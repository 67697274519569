import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
} from "react-native";

import Svg, { Ellipse } from "react-native-svg";

import DetectDeviceService from '../lib/devicedetection';

import {
  Container,
  ListItem,
  Header,
  Button,
  Text
} from "native-base";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton
} from "react-share";

import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import { Col, Row, Grid } from "../lib/easy-grid";

import { Routes } from '../navigation/routes';

import { connect } from 'react-redux';

const mapStateToProps = state => ({ ...state.auth, ...state.home, ...state.bill });

const mapDispatchToProps = dispatch => ({

});

class BreadCrumb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      links: []
    }
  }

  componentDidMount() {
    this.setState({links: this.props.links})
  }

  redirect = (route, params={}) => {
    this.props.navigation.navigate(route, params);
  }

  linking = (link) => {
    if (link == "Home")
      this.redirect(Routes.HOME)
    else if (link == "Share")
      this.redirect(Routes.SHARES)
    else if (link == "Bill Listing")
      this.redirect(Routes.HOME)
    else if (link == "Details") {
      this.redirect(Routes.BILL_DETAILS)
    }
    else if (link == "Voice your Opinion") {
      this.redirect(Routes.VOICE_YOUR_OPINION)
    }
    else if (link == "Executive Orders")
      this.redirect(Routes.EXECUTIVE_ORDERS)
    else if (link == "General Settings")
      this.redirect(Routes.GENERAL_SETTINGS)
    else if (link == "Initiatives")
      this.redirect(Routes.INITIATIVES)
    else if (link == "Connect")
      this.redirect(Routes.MEMBERS)
    else if (link == "Manage Network")
      this.redirect(Routes.MANAGE_NETWORK)
    else if (link == "Privacy Settings")
      this.redirect(Routes.PRIVACY_SETTINGS)
    else if (link == "Match my Votes")
      this.redirect(Routes.MATCH_MY_VOTES)
    else if (link == "Members")
      this.redirect(Routes.MEMBERS)
    else if (link == "Notification Settings")
      this.redirect(Routes.NOTIFICATION_SETTINGS)
    else if (link == "Representatives")
      this.redirect(Routes.REPRESENTATIVES)
    else if (link == "Federal Bills")
      this.redirect(Routes.FEDERAL_BILLS)
    else if (link == "State Bills")
      this.redirect(Routes.STATE_BILLS)
    else if (link == "Suggestions")
      this.redirect(Routes.SUGGESTIONS)
  }

  linkUrl = (link, index) => {
    if(index == (this.state.links.length - 1)) {
      return <Button style={{fontSize: 15, height: 35}} onPress={() => this.linking(link)}>
      <Text style={{fontWeight: "bold", paddingLeft: 10, paddingRight: 10}}>{link}</Text>
    </Button>
    } else {
      return <Button style={{fontSize: 15, height: 35}} transparent onPress={() => this.linking(link)}>
      <Text style={{paddingLeft: 0, paddingRight: 10}}>{link}</Text>
    </Button>
    }
  }

  render() {
    return (
      <Grid key={this.state.links} style={{marginTop: 5, marginBottom: 5, maxHeight: 35, backgroundColor: "rgb(236, 236, 236)", width: (DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ?  "80%": "96%"), alignSelf: "center"}}>
        <Row>
            {
                this.state.links.map((link, index) => {
                    return (
                        <View key={index} style={{flexDirection: "row", justifyContent: "flex-start", marginRight: 10}}>
                            {/* <Text style={{marginRight: 10, fontSize: 15}}>{link}</Text> */}
                            {this.linkUrl(link, index)}
                            {((index + 1) != this.state.links.length) && <Text style={{marginTop: 5}}>{"/"}</Text>}
                        </View>
                    )
                })
            }
            { DetectDeviceService.isTablet && (this.state.links[this.state.links.length - 1] == "Details" || this.state.links[this.state.links.length - 1] == "VYO") && this.props.shareDropDown()}
        </Row>
        {/* <Row>
            <View style={{flexDirection: "row", justifyContent: "flex-start", marginRight: 10}}>
                <Text style={{marginTop: 5, marginRight: 10, fontWeight: "bold"}}>
                  {this.state.links[this.state.links.length - 1]}
                </Text>
                {/* <View style={styles.socialIcon}>
                { this.state.links[this.state.links.length - 1] == "Details" && <FacebookShareButton url={window.location.href}><FacebookIcon size={24} round={true}></FacebookIcon></FacebookShareButton> }
                </View>
                <View style={styles.socialIcon}>
                { this.state.links[this.state.links.length - 1] == "Details" && <TwitterShareButton url={window.location.href}><TwitterIcon size={24} round={true}></TwitterIcon></TwitterShareButton> }
                </View>
                <View style={styles.socialIcon}>
                { this.state.links[this.state.links.length - 1] == "Details" && <EmailShareButton url={window.location.href}><EmailIcon size={24} round={true}></EmailIcon></EmailShareButton> }
                </View> */}
                {/* { DetectDeviceService.isTablet &&  this.state.links[this.state.links.length - 1] == "Details" && this.props.shareDropDown()} */}
            {/* </View> */}
        {/* </Row> */}
    </Grid>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: '#808080',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  socialIcon: {
    marginTop: 3,
    marginRight: 10
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumb);
