import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
} from "react-native";

import Svg, { Ellipse } from "react-native-svg";

import {
  Container,
  ListItem,
  Header,
  List,
  Text,
  Button,
  Icon,
  Left, Thumbnail, Right, Body
} from "native-base";

import { connect } from 'react-redux';
import { API_URL } from "../constants/server";

import agent from '../agent'

import dayjs from 'dayjs';

import {
  updateOpinions,
  updateOpinion
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";
import { TouchableOpacity } from "react-native-gesture-handler";

import FONT from '../styles/Font'

const mapStateToProps = state => ({ ...state.bill, ...state.auth });

const mapDispatchToProps = dispatch => ({
  updateOpinions: (comment, heading, index) => dispatch(updateOpinions(comment, heading, index)),
  updateOpinion: (voteId, vote) => dispatch(updateOpinion(voteId, vote)),
});

class NestedBillOpinion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      padding: 20,
      load: true
    }
  }

  componentDidMount() {
    if(this.props.comment.comment_to_id != this.props.parent_comment.comment_id) {
      this.setState({padding: 40}, () => { 
        this.setState({load: false})
      })
    }
  }

  avatarUrl = (avatar) => {
    if(avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  voteLike = () => {
    let newComment = this.props.comment;
    
    if (this.props.comment.user_liked == "yes") {
      newComment.user_liked = "no"
      newComment.reply_count = newComment.reply_count - 1
    } else {
      newComment.user_liked = "yes"
      newComment.reply_count = newComment.reply_count + 1
    }
    let newParentComment = this.props.parent_comment
    
    let nestedReplies = newParentComment.others_reply
    let newNestedReplies = [
      ...nestedReplies.slice(0, this.props.nestedCommentIndex),
      ...[newComment],
      ...nestedReplies.slice(this.props.nestedCommentIndex + 1),
    ]

    newParentComment.others_reply = newNestedReplies
    this.props.updateOpinion(newParentComment.vote_id, newParentComment)
    // this.props.updateOpinions(newParentComment, this.props.heading, this.props.commentIndex)
  }

  voteCommentPopup = () => {
    this.props.openReplyModal(this.props.parent_comment, this.props.heading, this.props.commentIndex, this.props.comment)
  }

  voteCommentPopup = () => {
    this.props.openReplyModal(this.props.parent_comment, this.props.heading, this.props.commentIndex, this.props.comment)
  }

  flagCommentPopup = () => {
    this.props.openFlagModal(this.props.comment.comment_id, this.props.heading, this.props.commentIndex)
  }

  paddingVal = () => {
    if(this.props.comment.comment_to_id != this.props.parent_comment.comment_id) {
      return 40
    } else {
      return 20
    }
  }

  commentsPadding = () => {
    if(this.props.comment.comment_to_id != this.props.parent_comment.comment_id) {
      return 23
    } else {
      return 15
    }
  }

  marginThirdLevelCommentFix = () => {
    if(this.props.comment.comment_to_id != this.props.parent_comment.comment_id) {
      return -10
    }
  }

  render() {
    return (
      !this.load && <View key={this.props.comment.comment_id} style={{paddingLeft: this.paddingVal(), paddingTop: 10, paddingBottom: 10}}>
        <Row>
          <Col style={{width: 60}}>
            <Thumbnail small source={{ uri: this.avatarUrl(this.props.comment.user_avatar) }} />
          </Col>
          <Col size={3} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 0 }}>
            <Text style={{ marginBottom: 5, color: "#4589c6" }}>{this.props.comment.user_name}, {this.props.comment.user_state}</Text>
            <Text style={styles.vote}>
              <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(this.props.comment.reply_created_at).format('MM/DD/YYYY hh:mm A')}</Text>
            </Text>
          </Col>
          <Col style={{width: 120, alignItems: "flex-start", paddingLeft: this.commentsPadding()}}>
            <Row>
              <Col style={{marginTop: 10, marginRight: 10}}>
                <TouchableOpacity style={[{ justifyContent: "flex-start", alignItems: "flex-start" }, {marginLeft: this.marginThirdLevelCommentFix()}]} onPress={() => this.voteCommentPopup()}>
                  <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 25}} />
                  {/* <Text style={styles.unselectedText}>({this.firstLevelComments().length})</Text> */}
                </TouchableOpacity>
              </Col>
              {this.props.user.user_id != this.props.comment.user_id && !this.props.comment.flag_comment && <Col style={{marginTop: 10}}>
                <TouchableOpacity style={{ justifyContent: "flex-start", alignItems: "flex-start" }} onPress={() => this.flagCommentPopup()}>
                  <Icon name='flag-o' type="FontAwesome" style={{color: "red", fontSize: 22}} />
                </TouchableOpacity>
              </Col>}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginLeft: 60 }}>
          {this.props.comment.flag_comment != true && <Text style={styles.textColor}>{this.props.comment.comment}</Text>}
          {this.props.comment.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}        
        </Row>
        {this.paddingVal() != 40 && <Row style={{marginLeft: 60}}>
          <Col size={4}>
            <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={this.voteLike} style={this.props.comment.user_liked == "yes" ? styles.selectedButton : styles.unselectedButton}>
                {/* <Button transparent onPress={this.voteLike}> */}
                <Text style={{ color: "#4589c6", fontSize: 15 }}>Like ({this.props.comment.reply_count})</Text>
                {/* </Button> */}
              </TouchableOpacity>
            </View>
          </Col>
          {/* <Col size={3}>
            <View style={{marginTop: 10}}>
                <TouchableOpacity onPress={this.voteCommentPopup}>
              <Text style={{ color: "#4589c6", fontSize: 15, marginLeft: 10}}>Reply</Text>
            </TouchableOpacity>
              </View>
          </Col> */}
        </Row>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // borderBottomColor: '#808080',
    // borderBottomWidth: 1,
    // paddingBottom: 10,
    // marginLeft: 0
  },
  image: {
    width: 39,
    height: 37,
    borderRadius: 100,
    borderColor: "#000000",
    borderWidth: 0,
    overflow: "hidden"
  },
  name: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
  },
  ellipse: {
    width: 12,
    height: 12
  },
  vote: {
    width: 115,
    height: 15,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
  },
  ellipseRow: {
    height: 15,
    flexDirection: "row",
    marginTop: 1
  },
  nameColumn: {
    width: 136,
    marginLeft: 19,
    marginTop: 4,
    marginBottom: 3
  },
  imageRow: {
    flexDirection: "row",
    marginTop: 17
  },
  imageRowFiller: {
    flex: 1,
    flexDirection: "row"
  },
  timestamp: {
    width: 125,
    height: 20,
    color: "#121212",
    fontSize: 9,
    //fontFamily: FONT.IOS.Regular,
  },
  imageRowRow: {
    height: 54,
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 18
  },
  comment: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
    marginTop: 13,
    marginLeft: 18,
    marginRight: -18
  },
  commentCount: {
    width: 79,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
  },
  likeCount: {
    width: 69,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 14
  },
  like: {
    width: 62,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 38
  },
  reply: {
    width: 41,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 17
  },
  commentCountRow: {
    height: 13,
    flexDirection: "row",
    marginTop: 37,
    marginLeft: 18,
    marginRight: 22
  },
  selectedButton: {
    // backgroundColor: "blue",
    padding: 5, 
    borderRadius: 10,
    borderColor: "#4589c6",
    borderWidth: 1,
    // marginLeft: 10,
    marginRight: 10,
    color: "#fff",
    width: 70,
  },
  unselectedButton: {
    padding: 5, 
    borderRadius: 10,
    // marginLeft: 10,
    marginRight: 10,
  },
  selectedText: {
    color: "#fff",
    fontSize: 13
  },
  unselectedText: {
    color: "#4589c6",
    fontSize: 13
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedBillOpinion);