const SET_HOME_DATA = 'SET_HOME_DATA'
const SET_TAB_COUNT = 'SET_TAB_COUNT'
const SET_FIRST_AD = 'SET_FIRST_AD'
const SET_AD_PLAYING = 'SET_AD_PLAYING'
const SET_AD_ENABLED = 'SET_AD_ENABLED'


export function setHome(data) {
  return {
    type: SET_HOME_DATA,
    data
  }
}

export function setHomeTabCount(data) {
  return {
    type: SET_TAB_COUNT,
    data
  }
}

export function setFirstAd(data) {
  return {
    type: SET_FIRST_AD,
    data
  }
}

export function setAdPlaying(data) {
  return {
    type: SET_AD_PLAYING,
    data
  }
}

export function setAdEnabled(data) {
  return {
    type: SET_AD_ENABLED,
    data
  }
}

const initialState = {
  cards: {
    graphs: [],
    Bills: [],
    Initiatives: [],
  },
  tabcount: {},
  first_ad: false,
  ad_playing: false,
  ad_enabled: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HOME_DATA:
      return Object.assign(
        {},
        state,
        {
          cards: action.data
        }
      );
    case SET_TAB_COUNT:
      return Object.assign(
        {},
        state,
        {
          tabcount: action.data
        }
      );
    case SET_FIRST_AD:
      return Object.assign(
        {},
        state,
        {
          first_ad: true
        }
      );
    case SET_AD_PLAYING:
      return Object.assign(
        {},
        state,
        {
          ad_playing: action.data
        }
      );
    case SET_AD_ENABLED:
      return Object.assign(
        {},
        state,
        {
          ad_enabled: action.data
        }
      );
    default:
      return state;
  }
}
