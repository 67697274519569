import React from "react";
import { StyleSheet, View, TouchableOpacity, Modal, Dimensions, KeyboardAvoidingView, FlatList, Platform, Image } from "react-native";
import {
  Container,
  Content,
  Form,
  Textarea,
  Left, Right,
  Button,
  Thumbnail,
  Text,
  List, Icon,
  Input,
  Toast,
  IconButton,
  Spinner,Item
} from 'native-base';

import { default as ScalableImage } from 'react-native-scalable-image';

import mime from 'mime';

import DetectDeviceService from '../lib/devicedetection';

import * as MailComposer from 'expo-mail-composer';

import Menu, { MenuItem } from 'react-native-material-menu';

import * as ImagePicker from 'expo-image-picker';
import uid from 'uuid/v4';

import dayjs from 'dayjs';

import * as Linking from 'expo-linking';
import * as Clipboard from 'expo-clipboard';

import { API_URL } from "../constants/server";

// import LinkPreview from "expo-link-preview";
import { LinkPreview as WebLinkPreview} from '@dhaiwat10/react-link-preview';
import { LinkPreview } from '@flyerhq/react-native-link-preview'


import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import VideoPlayer from '../components/VideoPlayer';

import ConnectUser from "../components/ConnectUser";

import { Col, Row, Grid } from "../lib/easy-grid";
import { Routes } from '../navigation/routes';
import agent from '../agent'
import { connect } from 'react-redux';

import {
  setShares,
  updateShare,
  newShareText
} from '../ducks/share';

import {
	setBillId,
	setListType,
} from '../ducks/bill';
import { Link } from "@react-navigation/native";

const mapStateToProps = state => ({ ...state.share, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setShares: (shares) => dispatch(setShares(shares)),
  updateShare: (shareId, share) => dispatch(updateShare(shareId, share)),
  setListType: (list) => dispatch(setListType(list)),
  setBillId: (id) => dispatch(setBillId(id)),
  newShareText: (share) => dispatch(newShareText(share)),
});

class SharesScreen extends React.Component {

  constructor(props) {
    console.log("*&*&*&*&")
    super(props);
    this.state = {
      shares: [],
      shareOrderIds: [],
      new_share_text: "",
      new_share_url: "",
      new_share_attachment: "",
      reshare_new_share_text: "",
      reshare_new_share_url: "",
      new_share_receiver_ids: "",
      new_share_parent_share_id: null,
      page: 1,
      share_comment_text: "",
      flag_share_comment: "",
      blockusermodal: false,
      blockuserid: "",
      flagshareid: "",
      flagsharetype: "",
      comment_share_id: "", 
      comment_share_parent_id: "",
      sharecommentmodal: false,
      flagsharemodal: false,
      network: [],
      selectedItems: [],
      modalopen: false,
      reshareApi: false,
      lastPage: false,
      loading: false,
      resharemodal: false,
      modalpayload: {},
      action_loading: false,
      loginWarnPopup: false,
      accountnotsetuppopup: false,
      commentVals: {},
      replyVisible: {},
      share_loading: false,
      video_enabled: false
      // myTextInput: React.createRef()
    }
  }

  componentDidMount() {
    if(this.props.auth_token != "") {
      this.focusListener = this.props.navigation.addListener('focus', () => {
        this.fetchShares()
      })
      this.focusListener = this.props.navigation.addListener('blur', () => {
        this.props.newShareText(null)
      }) 
    }
  }

  // componentWillUnmount() {
  //   this.focusListener();
  // }

  fetchShares = () => {
    // if(this.state.page == 1)
    this.props.setShares({})
    this.setState({ shareOrderIds: []})
    this.setState({loading: true})
    agent.Share.list(this.state.page)
    .then(res => {
      if(res.shares.length != 0) {
        let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {});
        // let sharesObj = {
        //   ...result,
        //   ...this.props.list 
        // }
        // let sharesObj = result
        this.props.setShares(result)
        // let shareIds = this.state.shareOrderIds.concat(res.shares.map(s => ( s.id )))
        let shareIds = res.shares.map(s => ( s.id ))
        this.setState({ shareOrderIds: shareIds})
        //this.setState({shares: res.shares})
        if(res.shares.length < 20) {
          this.setState({lastPage: true})
        } else {
          this.setState({lastPage: false})
        }
      } else {
        this.setState({lastPage: true})
      }      
      this.setState({loading: false})    
      
      if(this.props.new_share_text != null) {
        this.setState({ new_share_url: this.props.new_share_text })
      } else {
        this.setState({ new_share_url: null })
      }
    })
    agent.Share.image_video_enabled()
    .then(res => {
      this.setState({ video_enabled: res.video_enabled })
    })
    agent.Connect.network()
      .then(res => {
        let rn = []
        res.friends_and_family.map(f => {
          let r = {
            ...f,
            icon: { uri: this.avatarUrl(f.avatar) }
          }
          rn.push(r)
        })
        this.setState({ network: rn })
        this.setState({selectedItems: rn.map(function (el) { return el.id; })});
      })
  }

  setShareUrl = () => {
    this.setState({ new_share_url: this.props.new_share_text })
  }

  getNextPage = () => {
    let page = parseInt(this.state.page) + 1;
    this.setState({
      page: page
    },() => { this.fetchShares() })    
  }

  getPrevPage = () => {
    let page = parseInt(this.state.page) - 1;
    this.setState({lastPage: false})
    this.setState({
      page: page
    },() => { this.fetchShares() })    
  }

  getFirstPage = () => {
    let page = 1;
    this.setState({lastPage: false})
    this.setState({
      page: page
    },() => { this.fetchShares() })    
  }

  getClipboardContent = async () => {
    let content = await Clipboard.getStringAsync();
    if(content != null && content != "") {
      this.setState({ new_share_url: content })
      this.props.newShareText(content)
    } 
  }

  selectProfileImage = async () => {
    if (this.state.video_enabled == true) {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        // alert('Sorry, we need camera roll permissions to make this work!');
      } else {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });

        if (!result.cancelled) {
          this.setState({ new_share_attachment: result.uri })

        }
      }
    } else {
      this.openSharePermission({})
    }
  }

  uploadImageAsync(uri, data) {    
    const method = 'POST';
    let formData = new FormData();
    const endpoint = API_URL + 'api/v1/shares?auth_token='+agent.getToken(); // Define Endpoint Here


    if(Platform.OS == "web") {
      if(uri != "" && uri != null) {
        const uripath = uri.split('/');
        let fileName = uripath[uripath.length - 1]
        const fileType = mime.getType(uri);
        const fileTypeMine = uri.split(',')[0].split(":")[1].split(";")[0]
        const byteString = atob(uri.split(',')[1]);

        const ab = new ArrayBuffer(byteString.length);
        const arr = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++)
          arr[i] = byteString.charCodeAt(i);

        const blob = new Blob([arr], {
          type: fileTypeMine || 'image/png',
        });

        fileName = fileName.substring(0, 20)

        data.file_type = fileTypeMine;

        const file = new File([blob], `${fileName}`, {type: (fileTypeMine || 'image/png')});

        formData.append('share[attachment]', file);

      }

      Object.keys(data).forEach(key => {
        formData.append("share["+key+"]", data[key])
      })

    } else {
      if(uri != "" && uri != null) {
        const uriParts = uri.split('.');
        const fileType = uriParts[uriParts.length - 1];
        const fileTypeMine = mime.getType(uri);
        const { headers } = this.props;
        const uripath = uri.split('/');
        const fileName = uripath[uripath.length - 1]
        const payloadKey = 'share[attachment]';
    
        // formData.append(payloadKey, { uri: localUri, name: filename, type });
    
        formData.append(payloadKey, {
          uri,
          name: fileName,
          type: fileTypeMine,
        });

      }

      Object.keys(data).forEach(key => {
        formData.append("share["+key+"]", data[key])
      })
    }
    
    const options = {
      method,
      body: formData,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    };
    return fetch(endpoint, options);
}

  postShare = () => {
    if(this.state.reshareApi){
      if(this.state.selectedItems.length == 0) {
        Toast.show({
          text: "Select at least 1 member to share your post.",
          duration: 5000,
          type: "danger",
          textStyle: {textAlign: "center"}
        })
        return false
      }
      this.setState({action_loading: true})
      this.setState({share_loading: true})
      this.reshare()
    } else {
      if(this.state.selectedItems.length == 0) {
        Toast.show({
          text: "Select at least 1 member to share your post.",
          duration: 5000,
          type: "danger",
          textStyle: {textAlign: "center"}
        })
        return false
      }
      this.setState({action_loading: true})
      this.setState({share_loading: true})
      
      let new_share = {}
      new_share["share_text"] = this.state.new_share_text
      if(this.props.new_share_text == "null")
        new_share["url"] = null
      else
        new_share["url"] = this.props.new_share_text
      // console.log(this.state.network.length)
      // console.log(this.state.selectedItems.length)
      if(this.state.network.length == this.state.selectedItems.length){
        // new_share["receiver_ids"] = this.state.selectedItems
        // agent.Share.create(new_share)
        this.uploadImageAsync(this.state.new_share_attachment, new_share)
        .then(res => {
          this.setState({ new_share_text: ""})
          this.setState({ new_share_text: ""})
          this.setState({new_share_attachment: ""})
          // this.setState({ selectedItems: []})
          this.setState({ new_share_url: ""})
          this.props.newShareText("")

          this.setState({action_loading: false})
          this.setState({share_loading: false})

          // this.state.myTextInput.clear()
          agent.Share.list(this.state.page)
          .then(res => {
            let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
            this.props.setShares(result)
            this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
            //this.setState({shares: res.shares})
          })
        })
      } else {
        new_share["receiver_ids"] = this.state.selectedItems.join(",")
        // agent.Share.shareWith(new_share, this.state.selectedItems.join(","))
        this.uploadImageAsync(this.state.new_share_attachment, new_share)
        .then(res1 => {
          this.setState({ new_share_text: ""})
          this.setState({ selectedItems: []})
          this.setState({ new_share_url: ""})
          this.props.newShareText("")

          this.setState({action_loading: false})
          this.setState({share_loading: false})

          // this.state.myTextInput.clear()
          agent.Share.list(this.state.page)
          .then(res => {
            let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
            this.props.setShares(result)
            this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
          })
        })
      }
    }
  }

  deleteShare = (shareId) => {
    agent.Share.delete(shareId)
    .then(res => {
      this.setState({action_loading: false})
      let shareIds = this.state.shareOrderIds;
      shareIds = shareIds.filter(arrayItem => arrayItem !== shareId);
      this.setState({shareOrderIds: shareIds})
    })
  }

  shareLink = (url) => {
    if(Platform.OS == 'web'){
      window.open(url, '_blank');
   } else {
    Linking.openURL(url);
   }
    // this.props.onPress && this.props.onPress();
  };

  newCommentShare = (share_id) => {
    // if (comment_id != null)
    //   this.setState({ comment_share_parent_id: comment_id })
    // else
    //   this.setState({ comment_share_parent_id: null })
    this.setState({ comment_share_id: share_id })
  }
  
  shareCommentStore = () => {
    this.setState({action_loading: true})
    let new_comment = {};
    if(this.state.comment_share_parent_id != null)
      new_comment.parent_comment_id = this.state.comment_share_parent_id 
    else
      new_comment.parent_comment_id = null

    new_comment.share_id = this.state.comment_share_id
    new_comment.comment_text = this.state.share_comment_text
    agent.Share.comment(new_comment)
    .then(res => {
      agent.Share.details(this.state.comment_share_id, false)
      .then(res => {
        // this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(this.state.comment_share_id, share)
        this.setState({ share_comment_text: "", comment_share_id: "" })
        this.setState({sharecommentmodal: false})
        this.setState({action_loading: false})
      })
    })
  }

  shareCommentStoreNew = (comment_share_id, comment_share_parent_id) => {

    let new_comment = {};
    if(comment_share_parent_id != null && comment_share_parent_id != 0) {
      new_comment.parent_comment_id = comment_share_parent_id 
    } else {
      new_comment.parent_comment_id = null
      comment_share_parent_id = 0
    }

    new_comment.share_id = comment_share_id
    let key = comment_share_id + ":" + comment_share_parent_id

    console.log(key)
    
    new_comment.comment_text = this.state.commentVals[key]

    agent.Share.comment(new_comment)
    .then(res => {
      agent.Share.details(comment_share_id, false)
      .then(res => {
        // this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.setState({commentVals: {...this.state.commentVals, [key]: ""}});

        this.props.updateShare(comment_share_id, share)
        this.setState({ share_comment_text: "", comment_share_id: "" })
        this.setState({sharecommentmodal: false})
        this.setState({action_loading: false})
        console.log(this.state.commentVals)
      })
    })
  }

  shareLike = (shareId, refreshShareId=null) => {
    if(refreshShareId == null)
      refreshShareId = shareId
    agent.Share.like(shareId)
    .then(res => {
      agent.Share.details(refreshShareId, false)
      .then(res => {
        // this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          // ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(refreshShareId, share)
        // this.setState({ share_comment_text: "", comment_share_id: "" })
      })
    })
  }

  shareTruth = (shareId, val, refreshShareId=null) => {
    if(refreshShareId == null)
      refreshShareId = shareId
    agent.Share.truth(shareId, val)
    .then(res => {
      agent.Share.details(refreshShareId, false)
      .then(res => {
        // this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          // ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(refreshShareId, share)
        // this.setState({ share_comment_text: "", comment_share_id: "" })
      })
    })
  }

  openReshare = (share) => {
    // this.setState({ reshare_new_share_text: share.share_text })
    // this.setState({ reshare_new_share_url: share.share_url })
    if(share.parent_share_id != null)
      this.setState({ new_share_parent_share_id: share.parent_share_id })
    else
      this.setState({ new_share_parent_share_id: share.id })
    this.setState({ reshareApi: true })
    this.setState({ resharemodal: true })
    // this.SectionedMultiSelect._toggleSelector()
  }

  closeReshare = () => {
    this.setState({ resharemodal: false })
    // this.SectionedMultiSelect._toggleSelector()
    this.reshare()
  }

  openShare = () => {
    if (this.props.user && this.props.user.account_setup == false)
      this.setState({ accountnotsetuppopup: true })
    else if (this.props.auth_token == "") {
      this.setState({ loginWarnPopup: true })
    } else {
      this.setState({ reshareApi: false })
      this.SectionedMultiSelect._toggleSelector()    }
  }

  reshare = (share) => {
    let new_share = {}
    new_share["share_text"] = this.state.reshare_new_share_text
    new_share["url"] = this.state.reshare_new_share_url
    if(this.state.new_share_parent_share_id != null)
      new_share["parent_share_id"] = this.state.new_share_parent_share_id
    if(this.state.network.length == this.state.selectedItems.length){
      // new_share["receiver_ids"] = this.state.selectedItems
      agent.Share.create(new_share)
      .then(res => {
        this.setState({ new_share_receiver_ids: "", new_share_url: ""})
        this.setState({action_loading: false})
        agent.Share.list(this.state.page)
        .then(res => {
          let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
          this.props.setShares(result)
          this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
          //this.setState({shares: res.shares})
        })
      })
    } else {
      // new_share["receiver_ids"] = this.state.selectedItems
      agent.Share.shareWith(new_share, this.state.selectedItems.join(","))
      .then(res => {
        this.setState({ new_share_text: "", selectedItems: [], new_share_url: ""})
        this.setState({action_loading: false})
        agent.Share.list(this.state.page)
        .then(res => {
          let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
          this.props.setShares(result)
          this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
          //this.setState({shares: res.shares})
        })
      })
    }
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  customFetcher = async (url) => {
    const response = await fetch(`https://www.townhallusa.com/v2?url=${url}`);
    const json = await response.json();
    let r = json.metadata;
    if(json.description == null)
      r.description = ""
    return r;
  };
  
  openCommentModal = (share_id, comment_id=null) => {
    this.setState({action_loading: false})
    if(comment_id != null)
      this.setState({ comment_share_parent_id: comment_id })
    else
      this.setState({ comment_share_parent_id: null })
    this.setState({ comment_share_id: share_id })
    this.setState({sharecommentmodal: true})
  }

  openCommentBox = (share_id, comment_id=null) => {
    let replies = this.state.replyVisible

    if (comment_id == null) {
      if (replies[share_id] == undefined || (replies[share_id]["parent_reply"] == undefined || replies[share_id]["parent_reply"] == false)) {
        replies[share_id] = replies[share_id] == undefined ? {} : replies[share_id]
        replies[share_id]["parent_reply"] = true
      } else {
        replies[share_id] = replies[share_id] == undefined ? {} : replies[share_id]
        replies[share_id]["parent_reply"] = false
      }
     } else {
      if (replies[comment_id] == undefined || (replies[comment_id]["sub_reply"] == undefined || replies[comment_id]["sub_reply"] == false)) {
        replies[comment_id] = replies[comment_id] == undefined ? {} : replies[comment_id]
        replies[comment_id]["sub_reply"] = true
      } else {
        replies[comment_id] = replies[comment_id] == undefined ? {} : replies[comment_id]
        replies[comment_id]["sub_reply"] = false
      }       
     }
    this.setState({replyVisible: replies})
  }

  gotoShareDetails = (share) => {
    this.props.navigation.navigate(Routes.SHARE_DETAILS, {
      shareId: share.id, 
      share_id: share.id,
    });
  }

  gotoVYPdetails = (share) => {
    if(share.sharable_type == "Bill")
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
    else if(share.sharable_type == "Initiative")
      this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
    this.props.setBillId(share.sharable_id)
    // setTimeout(this.props.navigation.navigate(Routes.VOICE_YOUR_OPINION), 5000);
    this.props.navigation.navigate(Routes.VOICE_YOUR_OPINION);
    
  }
     
  onSelectedItemsChange = (selectedItems) => {
    //let selectedIssuesObj = selectedItems.map((id) => { return this.props.issues[id] })
    //this.props.issues.map((obj) => selectedItems.includes(obj["id"]))
    //let selectedIds = selectedIssuesObj.map((obj) => { return obj.id })
    console.log("*******")
    console.log(selectedItems)
    this.setState({ selectedItems:  selectedItems});
    //this.props.setBillsIssues({ selectedItems: selectedIssuesObj });
  };

  openDeleteShare = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Posting",
      modalaction: "delete_share",
      modaltext: "Are you sure you want to delete this posting?",
      modalpayload: payload
    })
  }

  openSharePermission = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Share Media",
      modalaction: "share_media_permission",
      modaltext: "This feature is reserved for influencers and elected officials.  You must request permission to access this feature.",
      modalpayload: payload
    })    
  }

  openBlockUser = (share) => {
    this.setState({ blockusermodal: true })
    this.setState({ blockuserid: share.blockuserid })
  }

  openFlagShare = (share) => {
    this.setState({ flagsharemodal: true })
    this.setState({ flagshareid: share.shareId })
    this.setState({ flagsharetype: "share" })
  }

  openFlagShareComment = (share) => {
    this.setState({ flagsharemodal: true })
    this.setState({ flagshareid: share.shareId })
    this.setState({ flagsharetype: "share_comment" })
  }

  blockUser = () => {
    let user_id = this.state.blockuserid

    this.setState({ action_loading: false })
    this.setState({ blockusermodal: false })

    Toast.show({
      text: "This user has been blocked.",
      duration: 5000,
      type: "success",
      textStyle: { textAlign: "center" }
    })

    this.deleteSharesWith(this.state.blockuserid)
    
    agent.Connect.updatehold("hold_postings", user_id)
    .then(res => {


      //this.props.editNetwork(updatedUser, this.state.index);
      //user.status = res.status
    })
  }

  flagShare = () => {
    let share_id = this.state.flagshareid
    let comment = this.state.flag_share_comment

    if(this.state.flagsharetype == "share") {
      agent.Share.flag_share(share_id, comment)
        .then(res => {

          this.setState({ flagsharemodal: false });
          this.setState({ action_loading: false })

          Toast.show({
            text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })

        })
    } else {
      agent.Share.flag_share_comment(share_id, comment)
        .then(res => {

          this.setState({ flagsharemodal: false });
          this.setState({ action_loading: false })

          Toast.show({
            text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })

        })
    }
  }

  openDeleteShareComment = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Comment",
      modalaction: "delete_comment",
      modaltext: "Are you sure you want to delete this comment?",
      modalpayload: payload
    })
  }

  gotoBillDetail = (share) => {  
    if(share.sharable_type == "Bill") {
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
      this.props.setBillId(share.sharable_id);
  		this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: share.sharable_id
      });
    } else if(share.sharable_type == "Initiative") {
      this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
      this.props.setBillId(share.sharable_id);
  		this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: share.sharable_id
      });
    } 
  }

  callFunction = () => {
    this.setState({action_loading: true})
    if(this.state.modalaction == "delete_share") {
      agent.Share.delete(this.state.modalpayload.shareId)
      .then(res => {
        let shareIds = this.state.shareOrderIds;
        shareIds = shareIds.filter(arrayItem => arrayItem !== this.state.modalpayload.shareId);
        this.setState({shareOrderIds: shareIds})
        this.setState({modalopen: false})
        this.setState({action_loading: false})
      })
    } else if(this.state.modalaction == "delete_comment") {
      agent.Share.delete_comment(this.state.modalpayload.commentId)
        .then(res => {
          agent.Share.details(this.state.modalpayload.shareId, false)
            .then(res => {
              // this.setState({ shareDetails: res.share})
              let share = {
                ...res.share,
                // ["share_comments"]: res.share.share_comments.slice(0, 2)
              }
              this.props.updateShare(this.state.modalpayload.shareId, share)
              this.setState({ modalopen: false })
              this.setState({action_loading: false})
              // this.setState({ share_comment_text: "", comment_share_id: "" })
            })
      })
    } else if(this.state.modalaction == "share_media_permission") {
      // this.setState({modalopen: false})
      this.setState({action_loading: false})
      MailComposer.composeAsync({
        recipients: ["contact@townhallusa.com"]
      })
      this.setState({ modalopen: false })
    } 
  }

  setCommentVal = (comment, share_id, parent_comment_id) => {
    if(parent_comment_id == null || parent_comment_id == 0)
      parent_comment_id = 0
    let key = share_id + ":" + parent_comment_id
    this.setState({commentVals: {...this.state.commentVals, [key]: comment}});
  }

  shareImageUrl = (uri) => {
    let image;
    if(DetectDeviceService.isTablet)
      image = (API_URL+ uri).replace("thumb", "medium").replace(/([^:]\/)\/+/g, "$1")
    else
      image = (API_URL+ uri).replace(/([^:]\/)\/+/g, "$1")
    return image
  }

  SelectOrRemoveAll = () =>
  this.SectionedMultiSelect && (
    <TouchableOpacity
      style={{
        justifyContent: 'center',
        height: 44,
        borderWidth: 0,
        paddingHorizontal: 10,
        backgroundColor: 'darkgrey',
        alignItems: 'center',
      }}
      onPress={
        this.state.selectedItems.length == this.state.network.length
          ? this.SectionedMultiSelect._removeAllItems
          : this.SectionedMultiSelect._selectAllItems
      }
    >
      <Text style={{ color: 'white', fontWeight: 'bold' }}>
        {this.state.selectedItems.length == this.state.network.length ? 'Remove' : 'Select'} all
      </Text>
    </TouchableOpacity>
  );

  shareCard = (share) => {
    return (share && <Grid key={share.id} style={[{ paddingLeft: (DetectDeviceService.isTablet ? 80 : 10), paddingRight: 5, paddingTop: 20, paddingBottom: 10 }, styles.container]}>
    <Row style={{width: DetectDeviceService.isTablet ? "83%" : "100%"}}>
      <Col style={{width: 80}}>
        <Thumbnail source={{ uri: this.avatarUrl(share.user_avatar) }} />
      </Col>
      <Col size={1} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
        <Row>
        <Col>
          <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {this.props.user.user_id == share.share_user_id ? "I shared" : share.share_user_name}
          </Text>
          <Text style={styles.vote}>
            <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(share.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
          </Text>
        </Col>
        </Row>
      </Col>
      <Col style={{alignItems: "flex-end"}}>
        {/* <Button iconLeft dark> */}
        <Row>
          <Col style={{marginTop: 10}}>
            {/* {(share.sharable_id == null) && <TouchableOpacity style={{ justifyContent: "flex-end", alignItems: "flex-end" }} onPress={() => this.openCommentBox(share.id)}>
              <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 25}} />
              <Text style={styles.unselectedText}>({share.comments_count})</Text>
            </TouchableOpacity>} */}
          </Col>
          <Col>
            {(this.props.user.user_id == share.share_user_id) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openDeleteShare({shareId: share.id}) }}>
                <Icon name='trash' style={{marginBottom: 3, marginRight: 5, fontSize: 22, color: '#d9534f'}} />
              </Button>}
              {(this.props.user.user_id != share.share_user_id && !share.flag_comment) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShare({shareId: share.id}) }}>
                <Icon name='flag-o' type="FontAwesome" style={{marginBottom: 3, marginRight: 5, fontSize: 22, color: 'red'}} />
              </Button>}
          </Col>
          <Col style={{marginLeft: (DetectDeviceService.isTablet ? 15 : 5)}}>
          {(this.props.user.user_id != share.share_user_id) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end"  }} transparent onPress={() => { this.openBlockUser({blockuserid: share.share_user_id}) }}>
                <Icon name='block' type="Entypo" style={{marginLeft: 0, marginBottom: 5, fontSize: 20, color: 'red'}} />
              </Button>}
          </Col>
        </Row>
        {/* </Button> */}
      </Col>
    </Row>
    {share.sharable_id != null && <Row style={{ marginTop: 10, marginLeft: (DetectDeviceService.isTablet ? 80 : 0), width: DetectDeviceService.isTablet ? "66%" : "90%", marginRight: (DetectDeviceService.isTablet ? 165 : 0)}}>
      <Text>{share.my_vote_on_share == "Neutral" ? "Not sure" : share.my_vote_on_share},
        <Text style={[styles.unselectedText, {marginLeft: 5, fontSize: 16}]} onPress={() => this.gotoBillDetail(share)}>
          {share.title_or_name}
        </Text>
      </Text> 
    </Row>}
    {share.attachment_type != null && <Row style={{alignSelf: (DetectDeviceService.isTablet ? "flex-start" : "flex-start"), width: (DetectDeviceService.isTablet ? "80%" : "100%"), marginTop: 20, marginLeft: (DetectDeviceService.isTablet ? 98 : 0)}}>
      {share.attachment_type == "image" && <ScalableImage style={{marginLeft: (DetectDeviceService.isTablet ? 28 : 0)}} width={DetectDeviceService.isTab ? 295 : DetectDeviceService.isTablet ? 450 : 315} resizeMode="cover" source={{uri: this.shareImageUrl(share.share_attachment)}} />}
      {share.attachment_type == "video" && <VideoPlayer uri={(API_URL+ share.share_attachment).replace(/([^:]\/)\/+/g, "$1")}/>}
    </Row>}
    <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0), marginRight: (DetectDeviceService.isTablet ? 165 : 0) }}>
      {share.flag_comment != true && <Text style={styles.textColor}>{share.share_text}</Text>}
      {share.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
    </Row>
    {/* {(share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) }}>
      <Text style={{color: "#808080"}} onPress={() => this.shareLink(share.share_url)}>{share.share_url}</Text>
    </Row>} */}
    {(Platform.OS == "web" && share.parent_share_id == null && share.share_url != "" && share.share_url != null) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 125 : 0) } } >
      <WebLinkPreview fetcher={this.customFetcher} url={share.share_url} width="80%" /> 
    </Row>}
    {(Platform.OS != "web" && (share.share_url != null && share.share_url != "")) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "75%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } >
      <LinkPreview containerStyle={{width: "100%", borderColor: '#d3d3d3', borderWidth: 0.4, borderRadius: 20, overflow: 'hidden',}} text={share.share_url} width="100%" /> 
    </Row>}
    {/* {(Platform.OS != "web" && share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } ><LinkPreview link={share.share_url} width="100%" /> </Row>} */}

    {share.parent_share_id != null && this.nestedShareCard(share.parent_share, share)}

    {/* 
    {share.parent_share_id != null && !share.parent_share_deleted && this.nestedShareCard(share.parent_share, share)}
    {share.parent_share_id != null && share.parent_share_deleted && this.nestedDeletedShareCard(share)} */}
    { share.parent_share_id == null && <View
      style={{
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: StyleSheet.hairlineWidth,
        marginLeft: (DetectDeviceService.isTablet ? 80 : 0),
        marginTop: 10,
        width: DetectDeviceService.isTablet ? "77%" : "90%"
      }}
    />
    }
    {share.parent_share_id == null && <Row style={{marginTop: 5, width: DetectDeviceService.isTablet ? "77%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) }}>
      <Col size={3} style={{alignItems: "flex-start"}}>
        <View>
        {/* <Button transparent> */}
        <TouchableOpacity onPress={() => this.openReshare(share)} style={share.reshared ? styles.selectedButton : styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>SHARE </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.reshare_count})</Text>}
            {(share.sharable_id != null) && <Text style={styles.unselectedText}>SHARE ({share.reshare_count})</Text>}
        </TouchableOpacity>
        {/* </Button> */}
        </View>
      </Col>
      <Col size={3} style={{alignItems: "center"}}>
        <View>
        {/* <Button transparent> */}
        <TouchableOpacity onPress={() => this.openCommentBox(share.id)} style={styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>COMMENT </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.comments_count})</Text>}
        </TouchableOpacity>
        {/* </Button> */}
        </View>
      </Col>
      {(share.sharable_id != null) && <Col size={3} style={{alignItems: "flex-end", marginRight: (DetectDeviceService.isTablet ? 0 : 0)}}>
        <View>
          <TouchableOpacity onPress={() => this.gotoVYPdetails(share)} style={styles.selectedButton }>
          {/* <Button transparent onPress={this.voteCommentPopup}> */}
            <Text style={styles.unselectedText}>Voice Your Opinion</Text>
          {/* </Button> */}
          </TouchableOpacity>
          </View>
      </Col>}
      {(share.sharable_id == null) && <Col size={3} style={{alignItems: "flex-end"}}>
        <View>
          <TouchableOpacity onPress={() => this.shareLike(share.id)} style={[(share.liked ? styles.selectedButton : styles.unselectedButton), {width: 50}]}>
          {/* <Button transparent onPress={this.voteCommentPopup}> */}
            <Text style={styles.unselectedText}>LIKE</Text>
            <Text style={styles.unselectedText}>({share.like_count})</Text>
          {/* </Button> */}
          </TouchableOpacity>
          </View>
      </Col>}

    </Row>}
    { share.parent_share_id == null && <View
      style={{
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: StyleSheet.hairlineWidth,
        marginLeft: (DetectDeviceService.isTablet ? 80 : 0),
        marginTop: 5,
        width: DetectDeviceService.isTablet ? "77%" : "90%"
      }}
    />
    }
    {(Object.keys(this.state.replyVisible).length != 0 && this.state.replyVisible[share.id] != undefined && this.state.replyVisible[share.id]["parent_reply"]) && this.newComment(share)}

    <FlatList style={{marginTop: 10}}
          listKey={(item, index) => 'C' + index.toString()}
          keyboardShouldPersistTaps='always'
          data={share.share_comments}
          initialNumToRender ={2}
          maxToRenderPerBatch = {2}
          renderItem={this.renderComment}
          keyExtractor={({item, index}) => (item && item.id)}
          >
    </FlatList>
    
    {share.comments_count > 2 && <Row style={{ alignItems: "center", justifyContent: "center" }}>
      {/* <Col> */}
      <Button transparent onPress={() => this.gotoShareDetails(share)}>
        <Text style={{ fontSize: 13 }}>Show more comments</Text>
      </Button>
      {/* </Col> */}
    </Row>}
  </Grid>)
  }

  nestedDeletedShareCard = (share) => {
    return (<Grid key={share.id} style={[{ width: (DetectDeviceService.isTablet ? "80%" : "100%"), marginTop: 10, paddingLeft: 10, paddingRight: 5, paddingTop: 10, paddingBottom: 10, borderColor: "#d3d3d3", borderWidth: 1, borderRadius: 15 }, styles.container]}>
        <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>This posting no longer exists because the account has been deactivated.</Text>
    </Grid>)
  }

  nestedShareCard = (share, sharedShare) => {
    return (share && <Grid key={share.id} style={[{ width: (DetectDeviceService.isTablet ? "78%" : "100%"), marginLeft: DetectDeviceService.isTablet ? 80 : 0, marginTop: 10, paddingLeft: 10, paddingRight: 5, paddingTop: 10, paddingBottom: 10, borderColor: "#d3d3d3", borderWidth: 1, borderRadius: 15 }, styles.container]}>
      <Row>
        <Col style={{width: 80}}>
          <Thumbnail source={{ uri: this.avatarUrl(share.user_avatar) }} />
        </Col>
        <Col size={7} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
          <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {this.props.user.user_id == share.share_user_id ? "I shared" : share.share_user_name}
          </Text>
          <Text style={styles.vote}>
          <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(share.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
          </Text>
        </Col>
      </Row>
      {share.sharable_id != null && <Row style={{ marginTop: 10 }}>
        <Text>{share.my_vote_on_share == "Neutral" ? "Not sure" : share.my_vote_on_share}, 
        <Text style={[styles.unselectedText, {fontSize: 16, marginLeft: 5}]} onPress={() => this.gotoBillDetail(share)}>
          {share.title_or_name}
        </Text>
        </Text> 
      </Row>}
      {share.attachment_type != null && <Row style={{alignSelf: "center", alignItems:"center", width: "80%", marginTop: 20}}>
      {share.attachment_type == "image" && <ScalableImage width={DetectDeviceService.isTab ? 295 : DetectDeviceService.isTablet ? 450 : 295} resizeMode="cover" source={{uri: (API_URL+ share.share_attachment).replace(/\/\//g, "/")}} />}
      {share.attachment_type == "video" && <VideoPlayer uri={(API_URL+ share.share_attachment).replace(/([^:]\/)\/+/g, "$1")}/>}
    </Row>}
      <Row style={{ marginTop: 10 }}>
        {share.flag_comment != true && <Text style={styles.textColor}>{share.share_text}</Text>}
        {share.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
      </Row>
      {/* {(share.share_url != null && share.share_url != "") && <Row style={{ marginTop: 10 }}>
        <Text style={{color: "#808080"}} onPress={() => this.shareLink(share.share_url)}>{share.share_url}</Text>
      </Row>} */}
      {(Platform.OS == "web" && share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } >
        <WebLinkPreview fetcher={this.customFetcher} url={share.share_url} width="100%" /> 
      </Row>}
      {(Platform.OS != "web" && (share.share_url != null && share.share_url != "")) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "95%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 7 : 0) } } >
        <LinkPreview containerStyle={{width: "100%", borderColor: '#d3d3d3', borderWidth: 0.4, borderRadius: 20, overflow: 'hidden',}} text={share.share_url} width="90%" /> 
      </Row>}
      <Row style={{marginTop: 10 }}>
        <Col size={3} style={{alignItems: "flex-start"}}>
          <View style={{marginTop: 10}}>
          {/* <Button transparent> */}
          <TouchableOpacity onPress={() => this.openReshare(share)} style={share.reshared ? styles.selectedButton : styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>SHARE </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.reshare_count})</Text>}
            {(share.sharable_id != null) && <Text style={styles.unselectedText}>SHARE ({share.reshare_count})</Text>}
          </TouchableOpacity>
          {/* </Button> */}
          </View>
        </Col>
        {(share.sharable_id == null) && <Col size={2} style={{alignItems: "flex-end"}}>
          <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.shareLike(share.id, sharedShare.id)} style={[(share.liked ? styles.selectedButton : styles.unselectedButton), {width: 50}]}>
            {/* <Button transparent onPress={this.voteCommentPopup}> */}
              <Text style={styles.unselectedText}>LIKE</Text>
              <Text style={styles.unselectedText}>({share.like_count})</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
        {(share.sharable_id != null) && <Col size={3} style={{alignItems: "flex-end"}}>
          <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.gotoVYPdetails(share)} style={styles.selectedButton }>
            {/* <Button transparent onPress={this.voteCommentPopup}> */}
              <Text style={styles.unselectedText}>Voice Your Opinion</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
      </Row>
    </Grid>)
  }

  shareComment = (comment) => {
    return <Grid key={comment.id} style={[{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }]}>
    {/* <Col style={{borderLeftWidth: 0.5, width: 2}}></Col> */}
    <Col>
    <Row style={{width: (DetectDeviceService.isTablet ? "84%" : "100%")}}>      
      <Col style={{width: 60, marginTop: 10}}>
        <Thumbnail small source={{ uri: this.avatarUrl(comment.user.avatar_url) }} />
      </Col>
      <Col size={(DetectDeviceService.isTablet ? 2 : 3)} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
        <Text style={{ marginBottom: 5, color: "#4589c6" }}>
          {comment.user.id == this.props.user.user_id ? "I commented" : (comment.user.first_name + " " + comment.user.last_name)}
        </Text>
        <Text style={styles.vote}>
          <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(comment.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
        </Text>
      </Col>
      <Col style={{marginLeft: (DetectDeviceService.isTablet ? 0 : -20), alignItems: (DetectDeviceService.isTablet ? "flex-end" : "flex-start")}}>
        {/* <Button iconLeft dark> */}
        <Row>
          {/* <Col> */}
            <TouchableOpacity onPress={() => this.openCommentBox(comment.share_id, comment.id)}>
              <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 22, marginTop: 10, marginRight: 15}} />
              <Text style={styles.unselectedText}>({comment.comments.length})</Text>
            </TouchableOpacity> 
            
          {/* </Col> */}
          {/* <Col> */}
            {(this.props.user.user_id == comment.user.id) && 
              <Button transparent onPress={() => { this.openDeleteShareComment({commentId: comment.id, shareId: comment.share_id}) }}>
                <Icon name='trash' style={{marginBottom: 3, fontSize: 22, color: '#d9534f'}} />
              </Button>}
              {(this.props.user.user_id != comment.user.id && !comment.flag_comment) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShareComment({shareId: comment.id}) }}>
                <Icon name='flag-o' type="FontAwesome" style={{fontSize: 25, color: 'red'}} />
              </Button>}
          {/* </Col> */}
        </Row>
        {/* </Button> */}
      </Col>
    </Row>
    <Row style={{ marginTop: 10, marginLeft: 57, width: DetectDeviceService.isTablet ? "80%" : "75%" }}>
      {comment.flag_comment != true && <Text style={styles.textColor}>{comment.comment_text}</Text>}
      {comment.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
    </Row>

    {(Object.keys(this.state.replyVisible).length != 0 && this.state.replyVisible[comment.id] != undefined && this.state.replyVisible[comment.id]["sub_reply"]) && this.newNestedComment(comment)}

    <FlatList style={{marginTop: 10}}
          listKey={(item, index) => 'NC' + item.id.toString()}
          data={comment.comments.slice(0,2)}
          keyboardShouldPersistTaps='always'
          initialNumToRender ={2}
          maxToRenderPerBatch = {2}
          renderItem={this.renderNestedComment}
          keyExtractor={({item, index}) => (item && item.id)}
          >
    </FlatList>
    
    </Col>
  </Grid>
  }

  nestedShareComment = (comment, index) => {
    return <Grid style={[{ paddingLeft: 30, paddingRight: 20, paddingTop: 0, paddingBottom: 0 }, styles.white]}>
      <Col style={{ borderLeftWidth: 0.5, width: 2, position: "relative", top: -34, left: -13 }}></Col>
      <Col>
        <Row style={{width: (DetectDeviceService.isTablet ? "86%" : "100%")}}>
          <Col style={{width: 10, borderBottomWidth: 0.5, position: "relative", top: -27, left: -13}}></Col>
          <Col style={{ width: 60, marginTop: 10 }}>
            <Thumbnail small source={{ uri: this.avatarUrl(comment.user.avatar_url) }} />
          </Col>
          <Col size={7} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
            <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {comment.user.id == this.props.user.user_id ? "I commented" : (comment.user.first_name + " " + comment.user.last_name)}
            </Text>
            <Text style={styles.vote}>
              <Text note style={{ color: "#797979", fontSize: 10 }}>{dayjs(comment.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
            </Text>
          </Col>
          <Col size={3} style={{ alignItems: (DetectDeviceService.isTablet ? "flex-end" : "flex-end"), marginLeft: (DetectDeviceService.isTablet ? 0 : 0), }}>
            {/* <Button iconLeft dark> */}
            <Row>
              {(this.props.user.user_id == comment.user.id) &&
                <Button transparent onPress={() => { this.openDeleteShareComment({ commentId: comment.id, shareId: comment.share_id }) }}>
                  <Icon name='trash' style={{ marginBottom: 3, fontSize: 22, color: '#d9534f' }} />
                </Button>}
              {(this.props.user.user_id != comment.user.id && !comment.flag_comment) &&
                <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShareComment({ shareId: comment.id }) }}>
                  <Icon name='flag-o' type="FontAwesome" style={{ fontSize: 25, color: 'red' }} />
                </Button>}
            </Row>
            {/* </Button> */}
          </Col>
        </Row>
        <Row style={{ marginTop: 0, marginLeft: 70, width: DetectDeviceService.isTablet ? "67%" : "75%" }}>
          {comment.flag_comment != true && <Text style={styles.textColor}>{comment.comment_text}</Text>}
          {comment.flag_comment == true && <Text style={[styles.textColor, { color: "#808080", fontStyle: 'italic' }]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
        </Row>
      </Col>
  </Grid>
  }

  newComment = (share) => {
    return <Grid style={[{ paddingLeft: 25 }]}>
      <Row>
        <Col style={{width: 40, height: 40}}><Thumbnail small style={{width: 35, height: 35, marginTop: 10}} source={{ uri: this.avatarUrl(this.props.user.avatar) }} /></Col>
        <Col style={{width: "68%"}}>
          <Item rounded style={{height: 30, marginTop: 12}}>
            <Input placeholder='Write a comment...' style={{outlineStyle: "none"}} value={this.state.commentVals[share.id + ":" + "0"]} onChangeText={(text) => { this.setCommentVal(text, share.id, 0) }}/>
          </Item>
        </Col>
        <Col style={{marginLeft: 6, width: 40, height: 40, paddingTop: 5}}>
          <Button transparent
            style={styles.commentpost}
            onPress={() => this.shareCommentStoreNew(share.id, 0)}
          >
            <Icon name="arrow-up" style={{fontSize: 32, marginLeft: 0, marginTop: 3, color: "rgb(69, 137, 198)", fontStyle: 600}} type="EvilIcons" />
          </Button>
          {/* <Icon name="comments-o" type="FontAwesome" onPress={() => this.shareCommentStoreNew(share.id, 0)} /> */}
        </Col>
      </Row>
    </Grid>
  }

  newNestedComment = (comment) => {
    return <Grid>
      <Row>
        <Col style={{marginLeft: 30, width: 40, height: 40}}><Thumbnail small style={{width: 35, height: 35, marginTop: 10}} source={{ uri: this.avatarUrl(this.props.user.avatar) }} /></Col>
        <Col style={{width: "66%"}}>
          <Item rounded style={{height: 30, marginTop: 12}}>
            <Input placeholder='Write a comment...' style={{outlineStyle: "none"}} value={this.state.commentVals[comment.share_id + ":" + comment.id]} onChangeText={(text) => { this.setCommentVal(text, comment.share_id, comment.id) }}/>
          </Item>
        </Col>
        <Col style={{marginLeft: 6, width: 40, height: 40, paddingTop: 10}}>
          <Button transparent
            style={styles.commentpost}
            onPress={() => this.shareCommentStoreNew(comment.share_id, comment.id)}
          >
            <Icon name="arrow-up" style={{fontSize: 32, marginLeft: 0, marginTop: 3, color: "rgb(69, 137, 198)"}} type="EvilIcons" />
          </Button>
          {/* <Icon name="comments-o" type="FontAwesome" onPress={() => this.shareCommentStoreNew(comment.share_id, comment.id)} /> */}
        </Col>
      </Row>
    </Grid>
  }

  deleteSharesWith = (userId) => {
    let shareIds;
    shareIds = this.state.shareOrderIds.filter((id) => {
      return this.props.list[id].share_user_id != userId
    })
    this.setState({ shareOrderIds: shareIds})
  }

  uploadFileName = () => {
    if(this.state.new_share_attachment == "" || this.state.new_share_attachment == null) {
      return ""
    } else {
      let filepath = this.state.new_share_attachment.split("/")
      return filepath[filepath.length - 1].substring(0, 20);
    }
  }

  renderItem = ({item, index}) => (this.shareCard(this.props.list[item.toString()]))
  renderComment = (({item, index}) => (this.shareComment(item)))
  renderNestedComment = (({item, index}) => (this.nestedShareComment(item, index)))

  render() {
    return (
      <Container key={this.props.new_share_text}>
                  <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.resharemodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
        >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ height: 45, alignItems: "center", justifyContent: "center" }}>
                  <Text style={{ fontSize: 18, color: "black" }}>Reshare Comment</Text>
                </View>
                <Form>
                  {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={3} style={styles.modal} placeholder="Type your comment here"
                    onChangeText={(text) => { this.setState({ reshare_new_share_text: text }) }} />
                  {/* </Item> */}
                </Form>
                <View style={styles.forgotactions}>
                  {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                  {!this.state.action_loading && <Button
                    style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                    onPress={() => {
                      this.setState({ resharemodal: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>}
                  {!this.state.action_loading && <Button
                    style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={() => this.closeReshare()}
                  >
                    <Text style={styles.textStyle}>Submit</Text>
                  </Button>}
                </View>
              </View>
            </View>
            </KeyboardAvoidingView>
          </Modal>
          <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.loginWarnPopup}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
              </View>
              <View style={{marginBottom: 10}}>
                <Text>Signup and Login to use this feature.</Text>
              </View>
              <View style={styles.forgotactions}>
              <Button
                  style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ loginWarnPopup: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.setState({ loginWarnPopup: false });this.props.navigation.navigate(Routes.SIGN_IN);
                  }}
                >
                  <Text style={styles.textStyle}>Login</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.accountnotsetuppopup}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
              </View>
              <View style={{marginBottom: 10}}>
                <Text>Account setup must be completed to use this feature.</Text>
              </View>
              <View style={styles.forgotactions}>
              <Button
                  style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ accountnotsetuppopup: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.setState({ accountnotsetuppopup: false });this.props.navigation.navigate(Routes.GENERAL_SETTINGS);
                  }}
                >
                  <Text style={styles.textStyle}>Setup</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.sharecommentmodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Comment</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Type your comment here"
                            onChangeText={(text) => { this.setState({share_comment_text: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ sharecommentmodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.shareCommentStore}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.flagsharemodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Flag Comment</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Flag objectionable content that violate the Terms of Use.  For example: violent threats, and obscene comments."
                            placeholderTextColor="#808080"
                            onChangeText={(text) => { this.setState({flag_share_comment: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotcancel, {backgroundColor: "#333"}]}
                  onPress={() => {
                    this.setState({ flagsharemodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.flagShare}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.blockusermodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Block</Text>
              </View>
              <View style={{marginBottom: 10}}>
                <Text>Block comments from this member?</Text>
              </View>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotcancel, {backgroundColor: "#333"}]}
                  onPress={() => {
                    this.setState({ blockusermodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.blockUser}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalopen}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
           <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center" }}>
                <Text style={{ fontSize: 18, color: "black" }}>{this.state.modalheading}</Text>
              </View>
              <View style={{ alignItems: (DetectDeviceService.isTablet ? "center" : "flex-start"), justifyContent: (DetectDeviceService.isTablet ? "center" : "flex-start") }}>
                <Text>{this.state.modaltext}</Text>
              </View>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, {backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ modalopen: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[styles.forgotsubmit, {backgroundColor: "#2196F3" }]}
                  onPress={this.callFunction}
                >
                  <Text style={styles.textStyle}>Continue</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
      <Content style={{ backgroundColor: "#ececec" }} scrollEnabled={!(this.state.sharecommentmodal || this.state.resharemodal)} enableResetScrollToCoords={false} keyboardShouldPersistTaps={'handled'}>
        <Grid style={styles.contentMargin}>
          <Row style={{backgroundColor: "#fff", paddingTop: 20, paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), paddingRight: (DetectDeviceService.isTablet ? 60 : 5), height: 120}}>
            <Col>
              <Form>
                <Textarea style={{height: 97}} rowSpan={5} placeholderTextColor="#808080" placeholder="Share a thought or a news article..." onChangeText={(text) => { this.setState({ new_share_text: text }); }} value={this.state.new_share_text} />
              </Form>
            </Col>
          </Row>
          <Row style={{backgroundColor: "#fff", paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), paddingTop: 10, paddingBottom: 10, borderTopWidth: 0.5, borderColor: "#D3D3D3", height: 60}}>
            <Button iconLeft transparent onPress={this.getClipboardContent}>
              <Icon active name='ios-globe' type="Ionicons" style={{color: "#808080", fontSize: 25}}/>
              {Platform.OS == "ios" && (this.props.new_share_text == "" || this.props.new_share_text == null) && <Text style={{color: "#d3d3d3", width: "75%"}}>Paste website link here</Text>}
              {Platform.OS == "ios" && this.props.new_share_text != "" && this.props.new_share_text != null && <Text style={{width: "75%"}}>{this.props.new_share_text}</Text>}
              {/* <Input disabled={true} onPress={this.getClipboardContent} placeholderTextColor="#808080" placeholder='Paste website link here' style={{marginTop: 5, height: 18, fontSize: 15}} onChangeText={(text) => { this.setState({ new_share_url: text }); }} value={this.state.new_share_url} /> */}
            </Button>
            {Platform.OS != "ios" && <Row style={{width: "75%"}}><Input style={{height: 42, fontSize: 16}} placeholderTextColor="#d3d3d3" placeholder='            Paste website link here' value={this.state.new_share_url} onChangeText={(text) => { this.setState({ new_share_url: text }); this.props.newShareText(text)}} ></Input></Row>}
            <Icon active name='circle-with-cross' type="Entypo" style={{color: "#808080", fontSize: 25, marginTop: 10}} onPress={()=> {this.props.newShareText(""); this.setState({new_share_url: ""})}} />
          </Row>
          <Row style={{backgroundColor: "#fff", paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), paddingTop: 10, paddingBottom: 10, borderTopWidth: 0.5, borderColor: "#D3D3D3", height: 60}}>
            <Button iconLeft transparent onPress={this.selectProfileImage}>
              <Icon active name='photo-video' type="FontAwesome5" style={{color: "#808080", fontSize: 25}}/>    
              {Platform.OS != "web" && (this.state.new_share_attachment == "" || this.state.new_share_attachment == null) && <Text style={{color: "#d3d3d3", width: "75%"}}>Image / Video upload</Text>}
              {Platform.OS != "web" && this.state.new_share_attachment != "" && <Text style={{width: "75%"}}>{this.uploadFileName()}</Text>}
            </Button>
            {Platform.OS == "web" && (this.state.new_share_attachment == "" || this.state.new_share_attachment == null) && <Text style={{marginTop: "10px", marginLeft: "48px", marginRight: "-54px", color: "#d3d3d3", width: "75%"}}>Image / Video upload</Text>}
            {Platform.OS == "web" && this.state.new_share_attachment != "" && <Text style={{marginTop: "10px", marginLeft: "20px", width: "72.8%"}}>{this.uploadFileName()}</Text>}
            <Icon active name='circle-with-cross' type="Entypo" style={{color: "#808080", fontSize: 25, marginTop: 10}} onPress={()=> {this.setState({new_share_attachment: ""})}} />
          </Row>
          {Platform.OS == "web" && this.state.new_share_url != "" && this.state.new_share_url != null && <Row style={{paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), width: "100%", backgroundColor: "#fff"}}>
            <WebLinkPreview fetcher={this.customFetcher} url={this.state.new_share_url} width="83%" />
          </Row>}
          {(Platform.OS != "web" && this.props.new_share_text != "" && this.props.new_share_text != null) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } >
            <LinkPreview containerStyle={{width: "100%", borderColor: '#d3d3d3', borderWidth: 0.4, borderRadius: 20, overflow: 'hidden',}} text={this.props.new_share_text} width="100%" /> 
          </Row>}
          {/* {Platform.OS != "web" && this.state.new_share_url != "" && this.state.new_share_url != null && <Row style={{paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), width: "100%", backgroundColor: "#fff"}}><LinkPreview link={this.state.new_share_url} width="100%" /></Row>} */}
          <Row style={{backgroundColor: "#fff", borderTopWidth: 1, paddingLeft: (DetectDeviceService.isTablet ? 60 : 5), height: 40}}>
            <Col style={{alignItems: "flex-start"}}>
            {!this.state.share_loading && <Button transparent 
                      onPress={() => this.postShare()}>
                      {/* onPress={() => this.postShare()}> */}
                <Text style={styles.shareButton}>SHARE</Text>
              </Button>}
              {this.state.share_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
              <SectionedMultiSelect
              ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
              IconRenderer={MaterialIcons} 
              items={this.state.network}
              noItemsComponent={() => <Text>There are no members in your network.  Go to CONNECT page to find members to connect with.</Text>}
              hideSearch={true}
              hideSelect={true}
              showChips={false}
              uniqueKey="id"
              displayKey="name"
              // subKey="children"
              headerComponent={this.SelectOrRemoveAll}
              selectText="Select users"
              alwaysShowSelectText={true}
              // showDropDowns={true}
              // readOnlyHeadings={true}
              showCancelButton={true}
              modalWithSafeAreaView={true}
              onSelectedItemsChange={this.onSelectedItemsChange}
              onConfirm={() => this.postShare()}
              selectedItems={this.state.selectedItems}
              renderSelectText={this.renderSelectText}
              iconKey="icon"
              styles={{
               chipText: {
                 maxWidth: Dimensions.get('screen').width - 90,
               },
              // itemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              // selectedItemText: {
              //   color: 'blue',
              // },
              // subItemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              item: {
                paddingHorizontal: 10,
              },
              subItem: {
                paddingHorizontal: 10,
              },
              selectedItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              selectedSubItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              // selectedSubItemText: {
              //   color: 'blue',
              // },
              scrollView: { paddingHorizontal: 0 },
              container: {width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center"}
            }}

            />
            </Col>
          </Row>
          {/* <View style={{marginTop: 10}}>
          {
            this.state.shareOrderIds.map(id => {
              return this.shareCard(this.props.list[id.toString()]);
            })
          }
          </View> */}

          <FlatList style={{marginTop: 10}}
            listKey={(item, index) => ('S' + index.toString())}
            keyboardShouldPersistTaps='always'
            data={this.state.shareOrderIds}
            initialNumToRender ={3}
            maxToRenderPerBatch = {3}
            renderItem={this.renderItem}
            keyExtractor={({item, index}) => (item)}
          >
          </FlatList>
          {/* {!this.state.loading && !this.state.lastPage && <Row style={{ alignItems: "center", justifyContent: "center" }}>
            <Button transparent onPress={() => this.getNextPage()}>
              <Text style={{ fontSize: 13 }}>Show more</Text>
            </Button>
          </Row>} */}
          {this.props.auth_token != "" && !this.state.loading && <Row style={{ alignItems: "center", justifyContent: "center" }}>
            {this.state.page == 1 && <Button disabled style={{marginRight: 10}}>
              <Text style={{ fontSize: 15 }}>First</Text>
            </Button>}
            {this.state.page != 1 && <Button bordered onPress={() => this.getFirstPage()} style={{marginRight: 10}}>
              <Text style={{ fontSize: 15 }}>First</Text>
            </Button>}
            {this.state.page != 1 && <Button bordered onPress={() => this.getPrevPage()} style={{marginRight: 10}}>
              <Text style={{ fontSize: 15 }}>Prev</Text>
            </Button>}
            {this.state.page == 1 && <Button disabled style={{marginRight: 10}}>
              <Text style={{ fontSize: 15 }}>Prev</Text>
            </Button>}
            {!this.state.lastPage && <Button bordered onPress={() => this.getNextPage()}>
              <Text style={{ fontSize: 15 }}>Next</Text>
            </Button>}
            {this.state.lastPage && <Button disabled style={{marginRight: 10}}>
              <Text style={{ fontSize: 15 }}>Next</Text>
            </Button>}
          </Row>}
        </Grid>
        {this.state.loading && <Spinner color='blue'></Spinner>}
      </Content>
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  tabHeading: {
    color: "rgba(255,255,255,1)",
    textTransform: "uppercase",
    fontSize: 14
  },
  contentMargin: {
    // marginLeft: Platform.isPad ? 170 : 10,
    marginBottom: 10,
    // marginRight: Platform.isPad ? 170 : 10,
    marginTop: 10,
    marginRight: 0,
    paddingRight: (DetectDeviceService.isTablet ? 60 : 0),
    width: (DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%"),
    alignSelf: "center"
  },
  tabs: {
    backgroundColor: "rgba(43,94,170,1)",
    flex: 1
  },
  search: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 8,
    backgroundColor: "#FFFFFF"
  },
  searchContainer: {
    backgroundColor: "#d3d3d3",
  },
  shareButton: {
    fontSize: 12,
    color: "#3774b9",
  },
  container: {
    backgroundColor: "#fff",
    marginBottom: 10
  },
  white: {
    backgroundColor: "#fff",
  },
  forgotcancel: {
		height: 36,
		width: 90,
    marginRight: 20
  },
  forgotsubmit: {
		height: 36,
		width: 90
  },
  commentpost: {
		height: 40,
		width: 63
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: { 
    width: DetectDeviceService.isTablet ? "30%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  selectedButton: {
    // backgroundColor: "blue",
    padding: 5, 
    borderRadius: 10,
    borderColor: "#4589c6",
    borderWidth: 1,
    // marginLeft: 10,
    marginRight: 10,
    color: "#fff",
  },
  unselectedButton: {
    padding: 5, 
    borderRadius: 10,
    // marginLeft: 10,
    marginRight: 10,
  },
  selectedText: {
    color: "#fff",
    fontSize: 13
  },
  unselectedText: {
    color: "#4589c6",
    fontSize: 13
  },
  icon2: {
    color: DetectDeviceService.isTablet ? "#333333" : "rgba(255,255,255,1)",
    fontSize: 35,
    marginRight: 5,
    marginLeft: 10,
    marginTop: 13
  },
  menuOption: {
    borderBottomColor: "#333",
    borderBottomWidth: 1
  },
  headerMenuIcon: {
    fontSize: 22,
    marginTop: 5,
    paddingTop: 5,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SharesScreen);
