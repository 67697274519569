import React, { Component } from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Container,
  Header,
  Left,
  Button,
  Body,
  Icon,
  Right,
  Title,
  Text,
  CheckBox,
  Label,
  Picker,
  Item,
  Toast,
  Content
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import { Col, Row, Grid } from "../lib/easy-grid";

import { connect } from 'react-redux';
import agent from '../agent'
import {
	setUserPrivacySetting
} from '../ducks/auth';

const mapStateToProps = state => ({ ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setUserPrivacySetting: (privacy_setting) => dispatch(setUserPrivacySetting(privacy_setting)),
});

class PrivacySettingsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: {
        secure: 1
      },
      states: [],
      congressional_districts: Array.from(Array(54), (_, i) => i + 1),
      state_senate_districts: [],
      state_assembly_districts: []
    }
  }

  componentDidMount() {
    console.log(this.props)
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({settings: {secure: 1}})
      if (this.props.user.privacy_setting != null) {
        this.setState({ settings: this.props.user.privacy_setting })
      }    
    })

    agent.Home.getstates()
      .then(res => {
        this.setState({ states: res.states })
        if (this.state.settings.selected != undefined)
          this.getDistricts(this.state.settings.selected.state, false)
      })
  }

  componentWillUnmount() {
    this.focusListener();
  }

  getStateById = (stateId) => {
    let state;
    this.state.states.map(data => {
      if (parseInt(data.id) == parseInt(stateId)) {
        state = data;
      }
    });
    return state;
  }

  getDistricts = (value, setCurrent) => {
    if(setCurrent) {
      this.setState({settings: { "selected": {
        state: value,
      }}})
    }
    let stateName = this.getStateById(value).name;
    agent.Home.getdistricts(stateName)
    .then(res => {
      this.setState({state_senate_districts: res.senate[stateName]})
      this.setState({state_assembly_districts: res.assembly[stateName]})
		})
  }

  checkSettings = (val) => {
    if(this.state.settings == null) {
      return false
    }
    if(this.state.settings[val] == "1")
      return true
    else {
      if(val == "selected") {
        if(this.state.settings["selected"] != undefined)
          return true
        else
          return false
      } else {
          return false
      }
    }
  }

  setSettings = (val) => (event) => {
    if(val == "secure") {
      this.setState({settings: {"secure": "1"}})
    } else if(val == "selected") {
      this.setState({settings: { "selected": {
        state: "",
        congressional_district: "",
        state_assembly_district: "",
        state_senate_district: ""
      }}})
    } else if(val == "open") {
      this.setState({settings: {"open": "1"}})
    }
  }

  selectedPrivValue = (val) => {
    if(this.checkSettings("selected")) {
      return this.state.settings.selected[val]
    } else {
      return ""
    }
  }

  setStateSettings = (val, setting) => {
    let set = {
      ...this.state.settings,
    }
    console.log(val)
    console.log(1111)
    console.log(setting)
    set.selected[val] = parseInt(setting)
    console.log(set)
    this.setState({settings: set})
  }

  saveSettings = () => {
    if (this.state.settings.selected != undefined) {
      if(this.state.settings.selected["state"] == "" || this.state.settings.selected["state"] == undefined) {
        Toast.show({
          text: 'Please select state',
          duration: 5000,
          type: "danger",
          textStyle: {textAlign: "center"}
        })
      } else {
        let u = { user: { privacy_setting: this.state.settings } }
        this.props.setUserPrivacySetting(this.state.settings);
        agent.Auth.update(u)
          .then(res => {
            Toast.show({
              text: 'Settings have been saved',
              duration: 5000,
              type: "success",
              textStyle: {textAlign: "center"}
            })
          })
      }
    } else {
      let u = { user: { privacy_setting: this.state.settings } }
      this.props.setUserPrivacySetting(this.state.settings);
      agent.Auth.update(u)
        .then(res => {
          Toast.show({
            text: 'Settings have been saved',
            duration: 5000,
            type: "success",
            textStyle: {textAlign: "center"}
          })
        })
    }
  }

  checkValidCustomSelected = () => {
    if(this.state.settings.selected != undefined){
      return (this.state.settings.selected["state"] != "" && this.state.settings.selected["state"] != undefined)
    } else {
      return false 
    }
  }

  showErrorToast = () => {
    Toast.show({
      text: 'Please select state first',
      duration: 5000,
      type: "danger",
      textStyle: {textAlign: "center"}
    })
  }

  render() {
    return (
      <Container style={styles.container} key={this.state.settings}>

        <Row style={[styles.buttonMargin]}>
          <Button  onPress={this.saveSettings} style={{height: 35, backgroundColor: "#2196F3"}}>
            <Text>Save</Text>
          </Button>
        </Row>

        <Content style={[styles.contentMargin, styles.contentcolor]}>
          <Row style={[styles.listitem, styles.border]}>
            <CheckBox checked={this.checkSettings("secure")} onPress={this.setSettings("secure")} style={styles.selection}/>
            <Text>I must accept invitations before other members can join my network. Note: Your posting will be shared with members that you approved to be in your network.</Text>
          </Row>
          <Row style={[styles.listitem]}>
            <CheckBox checked={this.checkSettings("selected")} onPress={this.setSettings("selected")} style={styles.selection}/>
            <Text>All of my constituents can add me to their network without my approval. Recommended for elected officials. Note: Your postings will be shared with your constituents and you will receive postings from your constituents</Text>
          </Row>
          <Row style={[{ margin: 10 }]}>
            <Col>
              <Text style={{ textAlign: "right", marginTop: 5, fontSize: 15 }}>State (Required)</Text>
            </Col>
            <Col style={{width: 150}}>
                {/* <Right> */}
                  <Picker
                    //enabled={this.checkSettings("selected")}
                    style={{backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff"}}
                    textStyle={{color: "#fff"}}
                    mode="dropdown"
                    placeholder="Select"
                    selectedValue={this.selectedPrivValue("state")}
                    onValueChange={(val) => { this.checkSettings("selected") && this.getDistricts(val, true) }}
                  >
                      {
                        this.state.states.map((state) => {
                          return (<Picker.Item key={state.id} label={state.name} value={state.id} />);
                        })
                      }
                  </Picker>
                {/* </Right> */}
            </Col>
          </Row>
          <Row style={[{ margin: 10 }]}>
            <Col>
              <Text style={{ textAlign: "right", marginTop: 5, fontSize: 15 }}>Congressional District (Optional)</Text>
            </Col>
            <Col style={{width: 150}}>
              {/* <Right> */}
              {this.checkValidCustomSelected() ? <Picker
                style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}
                textStyle={{ color: "#fff" }}
                mode="dropdown"
                placeholder="Select"
                selectedValue={this.selectedPrivValue("congressional_district")}
                onValueChange={(val) => { this.setStateSettings("congressional_district", val) }}
              >
                {
                  this.state.congressional_districts.map((cd) => {
                    return (<Picker.Item key={cd.toString()} label={cd.toString()} value={parseInt(cd)} />);
                  })
                }
              </Picker> : <Button onPress={this.showErrorToast} style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}><Text>Select</Text></Button>}
              {/* </Right> */}
            </Col>
          </Row>
          <Row style={[{ margin: 10 }]}>
            <Col>
              <Text style={{ textAlign: "right", marginTop: 5, fontSize: 15 }}>State Assembly District (Optional)</Text>
            </Col>
            <Col style={{ width: 150 }}>
              {/* <Right> */}
                {this.checkValidCustomSelected() ? <Picker
                  style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}
                  textStyle={{ color: "#fff" }}
                  mode="dropdown"
                  placeholder="Select"
                  selectedValue={this.selectedPrivValue("state_assembly_district")}
                  onValueChange={(val) => { this.setStateSettings("state_assembly_district", val) }}
                >
                  {
                    this.state.state_assembly_districts.map((cd) => {
                      return (<Picker.Item key={cd} label={cd.toString()} value={parseInt(cd)} />);
                    })
                  }
                </Picker> : <Button onPress={this.showErrorToast} style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}><Text>Select</Text></Button>}
              {/* </Right> */}
            </Col>
          </Row>
          <Row style={[{ margin: 10 }]}>
            <Col>
              <Text style={{ textAlign: "right", marginTop: 5, fontSize: 15 }}>State Senate District (Optional)</Text>
            </Col>
            <Col style={{ width: 150 }}>
              {/* <Right> */}
                {this.checkValidCustomSelected() ? <Picker
                  style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}
                  textStyle={{ color: "#fff" }}
                  mode="dropdown"
                  placeholder="Select"
                  selectedValue={this.selectedPrivValue("state_senate_district")}
                  onValueChange={(val) => { this.setStateSettings("state_senate_district", val) }}
                >
                  {
                    this.state.state_senate_districts.map((cd) => {
                      return (<Picker.Item key={cd} label={cd} value={parseInt(cd)} />);
                    })
                  }
                </Picker> : <Button onPress={this.showErrorToast} style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}><Text>Select</Text></Button>}
              {/* </Right> */}
            </Col>
          </Row>
          <Row style={[styles.listitem, styles.border]}>
            <CheckBox checked={this.checkSettings("open")} onPress={this.setSettings("open")} style={styles.selection}/>
            <Text>Any member can add me to their network without my approval. Note: Your postings will be shared with all members and you will recieve postings from all members that joined your network.</Text>
          </Row>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center"
  },
  container: {
    // backgroundColor: "rgb(43, 94, 170)",
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "#fff"
  },
  contentcolor: {
    // backgroundColor: "rgb(43, 94, 170)",
    backgroundColor: "#fff"
  },
  contentMargin: {
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  text: {
    color: "#fff"
  },
  title: {
    width: 200,
  },
  listitem: {
    margin: 10,
    paddingRight: 30,
    paddingBottom: 10,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: "#d3d3d3"
  },
  selection: {
    marginRight: 20
  },
  buttonMargin: {
    marginLeft: DetectDeviceService.isTab ? 25 : DetectDeviceService.isTablet ? 149 : 20,
    // marginRight: Platform.isPad ? 170 : 0,
    width: 65,
    // marginRight: DetectDeviceService.isTablet ? 64 : 0,
    // alignSelf: "center",
    backgroundColor: "rgb(236, 236, 236)",
    marginBottom: 15
    // paddingRight: DetectDeviceService.isTablet ? 50 : 0,
    // paddingLeft: DetectDeviceService.isTablet ? 50 : 0,
    // paddingTop: DetectDeviceService.isTablet ? 20 : 0,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettingsScreen);
