import React, { Component } from "react";
import { StyleSheet } from "react-native";
import {
  Text,
  Button,
} from "native-base";

const FacebookButton = props => {

  return (
    <Button style={styles.button}>
      <Text style={styles.textWhite}>{props.text}</Text>
    </Button>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    width: 131,
    height: 33,
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    fontSize: 17,
  },
  textWhite: {
    color: "#fff",
    fontSize: 17,
  },
});

export default FacebookButton;
