const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
const SET_AUTH_USER = 'SET_AUTH_USER'
const SET_USER_NOTIFICATION = 'SET_USER_NOTIFICATION'
const SET_USER_PRIVACY = 'SET_USER_PRIVACY'
const GET_AUTH_USER = 'GET_AUTH_USER'
const SET_AUTH_USER_DETAILS = 'SET_AUTH_USER_DETAILS'

export function setAuthToken(auth_token) {
  return {
    type: SET_AUTH_TOKEN,
    auth_token
  }
}

export function setAuthUser(user) {
  return {
    type: SET_AUTH_USER,
    user
  }
}

export function setAuthUserDetails(user) {
  return {
    type: SET_AUTH_USER_DETAILS,
    user
  }
}

export function setUserNotificationSetting(notification_setting) {
  return {
    type: SET_USER_NOTIFICATION,
    notification_setting
  }
}

export function setUserPrivacySetting(privacy_setting) {
  return {
    type: SET_USER_PRIVACY,
    privacy_setting
  }
}

const initialState = {
  auth_token: '',
  user: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return Object.assign(
        {},
        state,
        {
          auth_token: action.auth_token
        }
      );
    case SET_AUTH_USER:
      return Object.assign(
        {},
        state,
        {
          user: action.user,
        }
      );
      case SET_AUTH_USER_DETAILS:
        return Object.assign(
          {},
          state,
          {
            user: {
              ...state.user,
              ...action.user,
            }
          }
        );  
    case SET_USER_NOTIFICATION:
      return Object.assign(
        {},
        state,
        {
          user: {
            ...state.user,
            notification_setting: action.notification_setting,
          }
        }
      );
      case SET_USER_PRIVACY:
        return Object.assign(
          {},
          state,
          {
            user: {
              ...state.user,
              privacy_setting: action.privacy_setting,
            }
          }
        );  
    case GET_AUTH_USER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
