import React from "react";
import { StyleSheet, View, Platform, Dimensions } from "react-native";
import {
  Container,
  Content,
  Card,
  Tabs,
  TabHeading,
  Item,
  Input,
  Icon,
  Text,
  Button,
  Spinner,
  Segment,
  List,
  Badge,
  CardItem,
  Thumbnail
} from 'native-base';

import { Routes } from '../navigation/routes';

const superagent = require('superagent');

import DetectDeviceService from '../lib/devicedetection';

import {
  TabView,
  TabBar,
  SceneMap,
} from 'react-native-tab-view';


import { Col, Row, Grid } from "../lib/easy-grid";

import agent from '../agent'
import { connect } from 'react-redux';

import {
	setBillId
} from '../ducks/bill';
import { API_URL } from "../constants/server";

const mapStateToProps = state => ({  });

const mapDispatchToProps = dispatch => ({
	setBillId: (id) => dispatch(setBillId(id)),
});

class RepresentativeListingScreen extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      uri: this.props.uri,
      reps: {
        president: {},
        vice_president: {},
        governor: {},
        lieutenant_governor: {},
        representatives: [],
        mayor: [],
        vice_mayor: [],
        reps: [],
        my_reps: [],
        my_senators: [],
        my_state_reps: [],
        my_state_senator: []
      },
    }
  }

  componentDidMount() {
    this.getRepresentatives()
  }

  removeURLParameter = (url, parameter) => {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');   
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {    
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

getRepresentatives = () => {
    superagent
    .get(this.state.uri)
    .set('accept', 'application/json')
    .then(res => {
      this.setState({reps: res.body.representatives})
    })
  }

  getFirstPage = () => {
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=1"
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: 1})
  }

  getLastPage = () => {
    
  }

  getPrevPage = () => {
    let prevPage = this.state.page - 1
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=" + prevPage
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: prevPage})
  }

  getNextPage = () => {
    let nextPage = this.state.page + 1
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=" + nextPage
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: nextPage})
  }

  billDetail = (bill) => {
    this.props.setBillId(bill.id);
    this.props.navigation.navigate(Routes.REPRESENTATIVE_DETAILS, {
      // refreshCurrentTab: this.refreshCurrentTab
    });
  }

  BillItem = (bill) => {
    return <Card key={bill.id}>
      <CardItem button onPress={() => this.billDetail(bill)}>
        <Grid>
          <Col style={{width: 75}}>
            {bill.image != undefined && <Thumbnail square source={{uri: (API_URL+bill.image.url)}} />}
          </Col>
          <Col>
          <Row>
            <Text style={{ fontWeight: "bold", fontSize: 12 }}>
              {bill.name}
            </Text>
          </Row>
          <Row>
            <Text style={{ fontSize: 12 }}>
              {bill.position}
            </Text>
          </Row>
          <Row>
            <Text style={{ fontSize: 12 }}>
              {bill.party_description}
            </Text>
          </Row>
          </Col>
        </Grid>
      </CardItem>
    </Card>
  }

  refreshData = () => {
  let uri = this.props.setBillUrl()
  this.setState({uri: uri}, () => { this.getBills()})
  }

  render() {
    return (
      <Container>
        <Content style={styles.container}>
          <Text>Federal</Text>
          {this.BillItem(this.state.reps.president)}
          {this.BillItem(this.state.reps.vice_president)}
          {
            this.state.reps.my_senators.map((senator) => {
              return (this.BillItem(senator))
            })
          }
                    {
            this.state.reps.my_reps.map((senator) => {
              return (this.BillItem(senator))
            })
          }
          <Text>State</Text>
          {this.BillItem(this.state.reps.governor)}
          {this.BillItem(this.state.reps.lieutenant_governor)}
          {
            this.state.reps.my_state_reps.map((senator) => {
              return (this.BillItem(senator))
            })
          }
                    {
            this.state.reps.my_state_senator.map((senator) => {
              return (this.BillItem(senator))
            })
          }
        </Content>
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // marginRight: 200,
    paddingLeft: (DetectDeviceService.isTablet ? 80 : 20),
    paddingRight: (DetectDeviceService.isTablet ? 80 : 20),  
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#ececec",
  },
  support: {
    backgroundColor: "#54945d"
  },
  oppose: {
    backgroundColor: "#cd5f5f"
  },
  neutral: {
    backgroundColor: "#b2b2b2"
  },
  filterButton: {
		position: "absolute",
    bottom: 40,
    right: 40
  },
  filterButtonText: {
    position: "absolute",
    bottom: 10,
    right: 30
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeListingScreen);