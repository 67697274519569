import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Button,
  Text,
  Container,
  Header,
  Left,
  Right,
  Icon,
  Content,
  Form,
  Item,
  Label,
  Body,
  Title,
  Input,
  Picker,
  Toast,
  Spinner,
  Textarea
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import HeaderMenu from '../components/HeaderMenu';

import { Col, Row, Grid } from "../lib/easy-grid";
import { CommonActions } from '@react-navigation/native';

import BreadCrumb from "../components/BreadCrumb";

import { Routes } from '../navigation/routes';
import { connect } from 'react-redux';
import agent from '../agent'
import {
	setAuthToken,
	setAuthUser,
} from '../ducks/auth';

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
	setAuthUser: (user) => dispatch(setAuthUser(user)),
});

class AccountSetupScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      zip_code_5: "",
      zip_code_9: "",
      city: "",
      state: "",
      about_me: "",
      loading: false
    }
    // GLOBAL.data = this;
  }

  updatecity = (zip) => {
    this.setState({zip_code_5: zip})
    agent.Home.getcity(zip)
    .then(res => {
      this.setState({city: res.city})
      this.setState({state: res.state})
    })
  }

  checkReqdFields = () => {
    if(this.state.first_name != "" && this.state.last_name != "" && (this.state.zip_code_5 != "" || this.state.zip_code_9 != "") && this.state.city != "" && this.state.state != "")
      return true
    else
      return false
  }

  updateUser = () => {
    this.setState({loading: true})
    if(this.checkReqdFields()) {
      let u = {
        user: {
          city: this.state.city,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          state: this.state.state,
          about_me: this.state.about_me,
          zip_code: (this.state.zip_code_9 != "" ? this.state.zip_code_9 : this.state.zip_code_5),
        }
      }
      agent.Auth.update(u)
      .then(res => {
        if (res.message == "Successfully updated") {
          this.props.setAuthUser(res);
          this.props.setAuthToken(res.auth_token);
          this.setState({loading: false})
          // this.props.navigation.navigate(Routes.HOME);
          this.props.navigation.dispatch(state => {
            return CommonActions.reset({
              routes: [
                { name: Routes.HOME, params: {first_time: "true"} }
              ],
              index: 0,
            });
          });
      } else {
        Toast.show({
          text: res.message,
          duration: 5000,
          type: "danger",
          textStyle: {textAlign: "center"}
        })
        this.setState({loading: false})
      }
      })
    } else {
				Toast.show({
              text: 'Please fill in all required fields to complete account setup.',
							duration: 5000,
              type: "danger",
              textStyle: {textAlign: "center"}
            })
        this.setState({loading: false})
    }
  }

  render() {
    return (
      <Container >
        { !DetectDeviceService.isTablet && <Header style={styles.container}>
          <Left style={{flex: 1}}>
            
          </Left>
          <Body style={{flex: 1}}>
            <Title style={[styles.text, styles.header, {width: 200}]}>Complete Signup</Title>
          </Body>
          <Right style={{flex: 1}}>
            {this.state.loading && <Spinner color="blue"></Spinner>}
            {!this.state.loading && <Button transparent light onPress={this.updateUser}>
              <Text>Save</Text>
            </Button>}
          </Right>
        </Header>}
        { DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation} />}

        {/* <Col style={[{backgroundColor: "#ececec", maxHeight: 60}]} >
        { DetectDeviceService.isTablet && <BreadCrumb navigation={this.props.navigation} links={["Home", "Account Setup"]}></BreadCrumb> }
        </Col> */}
        {DetectDeviceService.isTablet && <Row style={[styles.contentMargin]}>
          <Button  onPress={this.updateUser} style={{height: 25, backgroundColor: "#2196F3"}}>
            <Text>Save</Text>
          </Button>
        </Row>}
        <Content padder style={[styles.container, styles.contentMargin]}>
          <View style={{marginBottom: 20}}>
            <Text style={[styles.text, { fontSize: 15 }]}>Welcome! Your user account has been successfully created.{'\n'}</Text>
            <Text style={[styles.text, { fontSize: 15 }]}>Please proceed with your account setup below.{'\n'}</Text>
            <Text style={[styles.text, { fontSize: 15 }]}>Zipcode is needed to find your elected representatives.</Text>
          </View>
          <View>
            <Form>
              <Grid>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>First Name</Label>
                      <Input style={styles.text} autoCapitalize='none' onChangeText={(text) => { this.setState({first_name: text}); }} />
                    </Item>
                  </Col>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>Last Name</Label>
                      <Input style={styles.text} autoCapitalize='none' onChangeText={(text) => { this.setState({last_name: text}); }} />
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Item stackedLabel>
                      <Label style={styles.text}>5-digit Zipcode</Label>
                      <Input style={styles.text} autoCapitalize='none' onChangeText={this.updatecity} />
                    </Item>
                  </Col>
                </Row>
                <Row style={{marginTop: 20, marginLeft: 15}}>
                  <Col>
                    <Textarea bordered rowSpan={5} style={styles.text} placeholder="About me (optional), max 30 characters." placeholderTextColor={DetectDeviceService.isTablet ? "#808080" : "#808080"}
                              maxLength={30}
                              onChangeText={(text) => { this.setState({about_me: text }) }} />
                  </Col>
                </Row>
              </Grid>
            </Form>
          </View>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "rgba(43,94,170,1)"
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "#fff"
  },
  contentMargin: {
    width: DetectDeviceService.isTablet ? "76.5%" : "100%",
    marginRight: DetectDeviceService.isTablet ? 64 : 0,
    alignSelf: "center",
    backgroundColor: "#fff",
    paddingRight: DetectDeviceService.isTablet ? 50 : 0,
    paddingLeft: DetectDeviceService.isTablet ? 50 : 0,
    paddingTop: DetectDeviceService.isTablet ? 20 : 0,
  },
  buttonMargin: {
    marginLeft: DetectDeviceService.isTablet ? 149 : 0,
    // marginRight: Platform.isPad ? 170 : 0,
    width: 60,
    // marginRight: DetectDeviceService.isTablet ? 64 : 0,
    // alignSelf: "center",
    backgroundColor: "rgb(236, 236, 236)",
    // paddingRight: DetectDeviceService.isTablet ? 50 : 0,
    // paddingLeft: DetectDeviceService.isTablet ? 50 : 0,
    // paddingTop: DetectDeviceService.isTablet ? 20 : 0,
  },
  header: {
    fontSize: 15,
    alignSelf: "center"
  },
  text: {
    // color: "#FFF",
  },
  optional: {
    marginTop: 20
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetupScreen);
