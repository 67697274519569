import React from "react";
import { StyleSheet } from "react-native";
import {
  Button,
  Switch,
  Icon,
  Card,
  CardItem,
  Text,
  Thumbnail,
  Left,
  Body,
  Right
} from "native-base";

import { connect } from 'react-redux';

import agent from '../agent'
import { API_URL } from "../constants/server";

import {
	editNetwork,
} from '../ducks/connect';

const mapStateToProps = state => ({ ...state.bill });

const mapDispatchToProps = dispatch => ({
	editNetwork: (user, index) => dispatch(editNetwork(user, index)),
});

class NetworkUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      index: props.index,
      fromPage: props.fromPage,
      unfollow: true
    }
  }

  holdPostingsColor = (posting) => {
    let color = (posting != "Hold postings" ? "#f0ad4e" : "#5cb85c")
    return {color: color}
  }

  avatarUrl = (user) => {
    if(user.avatar == null || user.avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + user.avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  updateHoldStatus = (user) => {
    let updatedStatus, statusText;

    if(user.status == "Active") {
      updatedStatus = "hold_postings"
      statusText = "Hold postings"
    } else {
      updatedStatus = "active"
      statusText = "Active"
    }
    let updatedUser = this.state.user
    updatedUser.status = statusText
    this.setState({user: updatedUser})
    agent.Connect.updatehold(updatedStatus, user.id)
    .then(res => {
      //this.props.editNetwork(updatedUser, this.state.index);
      //user.status = res.status
    })
  }

  unfollowUser = (user) => {
    let userId = user.id;
    agent.Connect.unfollow(userId)
    .then(res => {
      this.setState({ unfollow: false })
    })
  }

  removeFromNetwork = (user, index) => {
    this.props.openConfirmDelete(user, index)
  }

  render() {
    return (
      this.state.user.id == undefined ? null : 
      <Card key={this.state.user.id} style={styles.mb}>
        <CardItem>
          <Left>
            <Thumbnail square large source={{ uri: this.avatarUrl(this.state.user) }} style={{ marginRight: 10 }} />
            <Body>
              <Text style={{ width: 300 }}>{this.state.user.name}</Text>
              <Text style={{ width: 300, marginTop: 3 }} note>{this.state.user.city}, {this.state.user.state}</Text>
              {/* <Button style={{ height: 30, marginLeft: -20 }} transparent onPress={() => this.updateHoldStatus(this.state.user)}>
                <Text style={[{ width: 300 }, this.holdPostingsColor(this.state.user.status)]}>{this.state.user.status == "Hold postings" ? "Turn off hold postings" : "Turn on hold postings"}</Text>
              </Button> */}
              {this.state.fromPage != "followers" && <Text style={[{ width: 300, fontSize: 13, marginTop: 10 }]}>
                { this.state.fromPage == "following" ? "Following" : "Block postings" }
              </Text> }
            </Body>
          </Left>
          {this.state.fromPage != "followers" && <Right>
            <Button style={{marginTop: -20, marginBottom: 5}} transparent onPress={() => this.removeFromNetwork(this.state.user, this.state.index)}>
              <Icon active name="trash" style={{ color: "#FF0000", fontSize: 25 }} />
            </Button>
            { this.state.fromPage != "following" && <Switch style={{height: 20, marginTop: 5}} value={(this.state.user.status == "Hold postings")} onValueChange={() => this.updateHoldStatus(this.state.user)} /> }
            { this.state.fromPage == "following" && <Switch style={{height: 20, marginTop: 5}} value={this.state.unfollow} onValueChange={() => this.unfollowUser(this.state.user)} /> }
          </Right>}
          {this.state.fromPage == "blocked" && <Switch style={{height: 13, marginTop: 15, paddingLeft: 20}} onValueChange={() => this.updateHoldStatus(this.state.user)} value={(this.state.user.status == "Hold postings")}  /> }
        </CardItem>

      </Card>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(43,94,170,1)"
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkUser);