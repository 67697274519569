import React, { Component } from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  List,
  Spinner,
} from "native-base";

import HeaderMenu from '../components/HeaderMenu';

import DetectDeviceService from '../lib/devicedetection';

import { Routes } from '../navigation/routes';
import COLOR from '../styles/Color'
import { connect } from 'react-redux';

import ConnectUser from "../components/ConnectUser";

import BreadCrumb from "../components/BreadCrumb";

import agent from '../agent'

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
});

class CommonFriendsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      user_id: this.props.route.params.user_id,
      users: []
    }
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({ spinner: true })
      agent.Connect.common_friends(this.props.route.params.user_id)
        .then(res => {
          this.setState({ spinner: false })
          this.setState({ users: res.users })
        })
    })
  }

  componentWillUnmount() {
    this.focusListener();
  }

  render() {
    return (
      <Container key={this.props.route.params.user_id}>
         { !DetectDeviceService.isTablet && <Header style={styles.header}>
          <Left style={{flex: 1}}>
            <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon} />
            </Button>
          </Left>
          <Body style={{flex: 1}}>
            <Title style={styles.textColor}>Common Friends</Title>
          </Body>
          <Right style={{flex: 1}}>
          </Right>
        </Header> }
        { DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation}/> }

        <View style={{backgroundColor: "rgb(236, 236, 236)", paddingBottom: 15}}>
          <BreadCrumb navigation={this.props.navigation} links={["Home", "Connect", "Suggestions", "Common Friends"]}></BreadCrumb>
        </View>
        <Content style={[{ backgroundColor: "#ececec" }, styles.contentMargin]} enableResetScrollToCoords={false} keyboardShouldPersistTaps={'handled'}>
          { this.state.spinner && <Spinner color="blue" /> }
          {!this.state.spinner && <View>
            {this.state.users.length > 0 && <List>
              {
                this.state.users.map((user) => {
                  return (<ConnectUser key={user.id} user={user} page_type="mutual" />);
                })
              }
            </List>}
          </View>}
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
	  flex: 1
  },
  contentMargin: {
    marginLeft: DetectDeviceService.isTablet ? 150 : 0,
    marginBottom: 0,
    marginRight: DetectDeviceService.isTablet ? 150 : 0,
    marginTop: 0,
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center"
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	textColor: {
    color: "rgba(255,255,255,1)",
    width: 200
	},
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 20
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonFriendsScreen);
