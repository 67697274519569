import React from "react";
import { StyleSheet, View, Modal, Platform } from "react-native";
import {
  Container,
  Content,
  Header,
  Left,
  Item,
  Input,
  Icon,
  Button,
  List,
  Body,
  Title,
  Text,
  Toast,
  Spinner,
  Right
} from 'native-base';

import DetectDeviceService from '../lib/devicedetection';

import { Col, Row, Grid } from "../lib/easy-grid";

import { Routes } from '../navigation/routes';

import NetworkUser from "../components/NetworkUser";
import agent from '../agent'
import { connect } from 'react-redux';

import {
  setNetwork,
  deleteNetwork
} from '../ducks/connect';

const mapStateToProps = state => ({ ...state.connect, ...state.device });

const mapDispatchToProps = dispatch => ({
  setNetwork: (users) => dispatch(setNetwork(users)),
  deleteNetwork: (user, index) => dispatch(deleteNetwork(user, index)),
});

class ManageNetworkScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
      deleteUserId: "",
      deleteUserIndex: "",
      loading: false
    }
  }

	componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({ loading: true })
      agent.Connect.network()
        .then(res => {
          let users = res.friends_and_family
          this.props.setNetwork(users);
          this.setState({ loading: false })
        })
    });
  }

  componentWillUnmount() {
    this.focusListener();
  }

  openConfirmDelete = (user, index) => {
    this.setState({ confirmDelete: true })
    this.setState({ deleteUserId: user.id })
    this.setState({ deleteUserIndex: index })
  }

  closeConfirmDelete = () => {
    this.setState({ confirmDelete: false })
  }

  removeFromNetwork = () => {
    // console.log(this.state)
    agent.Connect.removenetwork(this.state.deleteUserId)
    .then(res => {
      this.props.deleteNetwork(this.state.deleteUserIndex)
      Toast.show({
        text: 'The user has been deleted from your network.',
        duration: 5000,
        type: "danger",
        textStyle: {textAlign: "center"}
      })
      this.setState({confirmDelete: false})
    })
  }
  
  searchNetwork = (term) => {
    this.props.setNetwork([]);

    if (term != "") {
      agent.Connect.search(term)
        .then(res => {
          let users = res.friends_and_family
          this.props.setNetwork(users);
        })
    } else {
      agent.Connect.network()
        .then(res => {
          let users = res.friends_and_family
          this.props.setNetwork(users);
        })
    }
  }

  gotoBlockedUsers = () => {
    this.props.navigation.navigate(Routes.MANAGE_NETWORK_BLOCKED);
  }

  gotoFollowing = () => {
    this.props.navigation.navigate(Routes.MANAGE_NETWORK_FOLLOWING);
  }

  render() {
    return (
      <Container >
        <Content  style={{ backgroundColor: "#ececec" }} >
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.confirmDelete}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text>Confirm your intent to delete this user from your network</Text>
                <View style={styles.forgotactions}>
                  <Button light
                    style={[ styles.forgotcancel, { backgroundColor: "#808080" }]}
                    onPress={() => {
                      this.setState({ confirmDelete: false });
                    }}
                  >
                    <Text style={[styles.textStyle, { color: "#fff"}]}>Cancel</Text>
                  </Button>
                  <Button
                    style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.removeFromNetwork()
                    }}
                  >
                    <Text style={styles.textStyle} onPress={this.forgotpassword}>Confirm</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Row style={[styles.tabcontainer, styles.contentMargin]}>
            <Col style={styles.tabselected}><Text style={{color: "#fff"}}>Connections</Text></Col>
            <Col style={styles.tab}><Text onPress={this.gotoFollowing}>Following</Text></Col>
            <Col style={styles.tab}><Text onPress={this.gotoBlockedUsers}>Followers</Text></Col>
          </Row>
          <View style={[styles.searchContainer, styles.contentMargin]}>
            <Item regular style={styles.search}>
              <Icon active name="search" />
              <Input placeholder="Name, city, state" onChangeText={this.searchNetwork} />
              <Icon active name="people" />
            </Item>
          </View>
          {(this.props.network.length != 0 && !this.state.loading) && <List style={styles.contentMargin}>
            {
              this.props.network.map((user, index) => {
                return (<NetworkUser key={user.id} user={user} index={index} openConfirmDelete={this.openConfirmDelete} closeConfirmDelete={this.closeConfirmDelete} />);
              })
            }
          </List>
          }
          {(this.props.network.length == 0 && !this.state.loading) &&
            <Row style={{ marginTop: 20, justifyContent: "center" }}>
              <Text style={{ textAlign: "center" }}>There are no users in your network to display. Invite users to join your network in the Connect page.</Text>
            </Row>
          }
          {
            (this.state.loading) && <Spinner color='blue' />
          }
        </Content>
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  tabcontainer: {
    justifyContent: 'center', //Centered horizontally
    alignItems: 'center', //Centered vertically
    flex: 1,
 },
  tab: {
    alignItems: "center",
    height: 35,
    paddingTop: 5,
    borderColor: "rgba(43,94,170,1)",
    borderWidth: 1
  },
  tabselected: {
    alignItems: "center",
    height: 35,
    paddingTop: 5,
    borderColor: "rgba(43,94,170,1)",
    backgroundColor: "rgba(43,94,170,1)",
    borderWidth: 1,
    color: "#fff"
  },
  search: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#FFFFFF"
  },
  contentMargin: {
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  searchContainer: {
    backgroundColor: "#d3d3d3"
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center"
  },
  text: {
    color: "#fff"
  },
  title: {
    width: 200,
  },
  modal: {
    color: "#333",
  },
  forgotcancel: {
		height: 35,
		width: 85,
    marginRight: 20
  },
  forgotsubmit: {
		height: 35,
		width: 85
  },
  textStyle: {
    fontSize: 14,
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 15,
    paddingTop: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageNetworkScreen);