import React, { Component } from "react";
import { StyleSheet, View, Text } from "react-native";
import FONT from '../styles/Font'

function RoundRectButton(props) {
  return (
    <View style={[styles.container, props.style]}>
      <Text style={styles.login}>{props.text1 || "Lorem Ipsum"}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 5,
    justifyContent: "center"
  },
  login: {
    color: "rgba(33,72,155,1)",
    fontSize: 17,
    fontFamily: FONT.IOS.Regular,
    alignSelf: "center"
  }
});

export default RoundRectButton;
