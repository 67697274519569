const SET_BILL_ID = 'SET_BILL_ID'
const LOAD_VOICE_OPINION = 'LOAD_VOICE_OPINION'
const UPDATE_OPINIONS = 'UPDATE_OPINIONS'
const UPDATE_OPINION = 'UPDATE_OPINION'
const UPDATE_MY_VOTE = 'UPDATE_MY_VOTE'
const GET_BILL = 'GET_BILL'
const SET_LIST_TYPE = 'SET_LIST_TYPE'
const SET_LIST_FILTER = 'SET_LIST_FILTER'

export function setBillId(id){
  return {
    type: SET_BILL_ID,
    id
  }
}

export function setOpinions(opinions){
  return {
    type: LOAD_VOICE_OPINION,
    opinions
  }
}

export function setListType(list){
  return {
    type: SET_LIST_TYPE,
    list
  }
}

export function setListFilter(filters){
  return {
    type: SET_LIST_FILTER,
    filters
  }
}

export function updateOpinions(comment, heading, index){
  return {
    type: UPDATE_OPINIONS,
    comment,
    heading,
    index
  }
}

export function updateOpinion(voteId, vote){
  return {
    type: UPDATE_OPINION,
    voteId,
    vote
  }
}

export function updateMyVote(vote){
  return {
    type: UPDATE_MY_VOTE,
    vote
  }
}

const initialState = {
	details: {},
  page_type: '',
  opinions: {},
	// opinions: {
	// 	"Other Opinions": [],
  //   "My Opinion": [],
  //   "My Network (Friends & Family)": [],
  //   "object": {title: ""}
	// },
  list_url: '/api/v1/bills',
  list_type: '',
  list_filters: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_BILL_ID:
      return Object.assign(
        {},
        state,
        {
          details: {id: action.id},
          page_type: 'bill'
        }
      );
    case LOAD_VOICE_OPINION:
      return Object.assign(
        {},
        state,
        {
          opinions: action.opinions,
        }
      );
    case UPDATE_OPINIONS:
      return Object.assign(
        {},
        state,
        {
          opinions: {
            ...state.opinions,
            [action.heading]:
            [
            ...state.opinions[action.heading].slice(0, action.index),
            ...[action.comment],
            ...state.opinions[action.heading].slice(action.index + 1),
            ]
          }
        }
      );
    case UPDATE_OPINION:
      return Object.assign(
        {},
        state,
        {
          opinions: { 
            ...state.opinions,
            ...{[action.voteId]: action.vote}
          },
        }
      );
      case UPDATE_MY_VOTE:
        return Object.assign(
          {},
          state,
          {
            opinions: {
              ...state.opinions,
              ["My Opinion"]:
              [
                action.vote
              ]
            }
          }
        );
    case GET_BILL:
      return {
        ...state,
      };
    case SET_LIST_TYPE:
      return Object.assign(
        {},
        state,
        {
          list_type: action.list.type,
          list_url: action.list.url
        }
      );
    case SET_LIST_FILTER:
      return Object.assign(
        {},
        state,
        {
          list_filters: action.filters
        }
      );
    default:
      return state;
  }
}
