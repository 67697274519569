import React, { Component } from "react";
import { StyleSheet, Platform } from "react-native";
import {
  Container,
  Header,
  Left,
  Button,
  Body,
  Icon,
  Right,
  Title,
  List,
  ListItem,
  Text,
  Switch,
  Content
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import { connect } from 'react-redux';
import agent from '../agent'
import {
	setUserNotificationSetting,
} from '../ducks/auth';

const mapStateToProps = state => ({ ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setUserNotificationSetting: (notification_setting) => dispatch(setUserNotificationSetting(notification_setting)),
});

class NotificationSettingsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notification_setting: {
        new_bill: true,
        bill_status_change: true,
        sponsor_new_bill: true,
        invite_network: true,
        share_comment: true
      }
    }
  }

  componentDidMount() {
    this.setSetting()
  }

  setSetting() {
    let setting = {}
    if (this.props.user.notification_setting.new_bill == undefined)
      setting.new_bill = this.state.notification_setting.new_bill
    else
      setting.new_bill = this.props.user.notification_setting.new_bill
    
    if (this.props.user.notification_setting.bill_status_change == undefined)
      setting.bill_status_change = this.state.notification_setting.bill_status_change
    else
      setting.bill_status_change = this.props.user.notification_setting.bill_status_change
    
    if (this.props.user.notification_setting.sponsor_new_bill == undefined)
      setting.sponsor_new_bill = this.state.notification_setting.sponsor_new_bill
    else
      setting.sponsor_new_bill = this.props.user.notification_setting.sponsor_new_bill

    if (this.props.user.notification_setting.invite_network == undefined)
      setting.invite_network = this.state.notification_setting.invite_network
    else
      setting.invite_network = this.props.user.notification_setting.invite_network

    if (this.props.user.notification_setting.share_comment == undefined)
      setting.share_comment = this.state.notification_setting.share_comment
    else
      setting.share_comment = this.props.user.notification_setting.share_comment

    this.setState({ notification_setting: setting })
  }

  checkSetting(setting) {
    return ((this.state.notification_setting[setting] == "1") || (this.state.notification_setting[setting] == true))
  }

  updateSetting = setting => (value) => {
    let u = {notification_setting: this.props.user.notification_setting}
    let s = this.state.notification_setting
    console.log(s)
    if(this.state.notification_setting[setting] == "1" || this.state.notification_setting[setting] == true) {
      u.notification_setting[setting] = false
      s[setting] = false
    } else {
      u.notification_setting[setting] = true
      s[setting] = true
    }
    this.setState({notification_setting: s})
    console.log(s)
    this.props.setUserNotificationSetting(u.notification_setting);
    agent.Auth.update(u)
      .then(res => {
       
      })
  }

  render() {
    return (
      <Container style={styles.container}>
        <Content style={[styles.contentcolor, styles.contentMargin]}>
          <List>
            <ListItem><Text>Notify me when:</Text></ListItem>
            <ListItem>
              <Left>
                <Text>There are new bills to opine</Text>
              </Left>
              <Right>
                <Switch value={this.checkSetting("new_bill")} onValueChange={this.updateSetting("new_bill")} />
              </Right>
            </ListItem>
            <ListItem>
              <Left>
                <Text>Bill status has changed</Text>
              </Left>
              <Right>
                <Switch value={this.checkSetting("bill_status_change")} onValueChange={this.updateSetting("bill_status_change")} />
              </Right>
            </ListItem>
            <ListItem>
              <Left>
                <Text>My representatives sponsor a new Bill</Text>
              </Left>
              <Right>
                <Switch value={this.checkSetting("sponsor_new_bill")} onValueChange={this.updateSetting("sponsor_new_bill")} />
              </Right>
            </ListItem>
            <ListItem>
              <Left>
                <Text>When I am invited to join a friend network</Text>
              </Left>
              <Right>
                <Switch value={this.checkSetting("invite_network")} onValueChange={this.updateSetting("invite_network")} />
              </Right>
            </ListItem>
            <ListItem>
              <Left>
                <Text>When a friend shares a comment</Text>
              </Left>
              <Right>
                <Switch value={this.checkSetting("share_comment")} onValueChange={this.updateSetting("share_comment")} />
              </Right>
            </ListItem>
          </List>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center"
  },
  container: {
    // backgroundColor: "rgb(43, 94, 170)",
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "#fff"
  },
  contentcolor: {
    // backgroundColor: "rgb(43, 94, 170)",
    backgroundColor: "#fff"
  },
  contentMargin: {
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  text: {
    color: "#fff"
  },
  title: {
    width: 200,
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsScreen);
