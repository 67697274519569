import React, { Component } from "react";
import { StyleSheet, View, Modal, TextInput } from "react-native";
import {
  Button,
  Icon,
  Card,
  CardItem,
  Text,
  Thumbnail,
  Left,
  Body,
  Form,
  Textarea,
  Toast,
  Right
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import { Routes } from '../navigation/routes';

import { API_URL } from "../constants/server";

import { Col, Row, Grid } from "../lib/easy-grid";

import { connect } from 'react-redux';
import agent from '../agent'

import {
  setInvitations,
  setSuggestions
} from '../ducks/connect';
import { Platform } from "react-native";

const mapStateToProps = state => ({ ...state.device });

const mapDispatchToProps = dispatch => ({
  setInvitations: (users) => dispatch(setInvitations(users)),
  setSuggestions: (users) => dispatch(setSuggestions(users)),
});

class ConnectUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalopen: false,
      modaltext: "",
      modalheading: "",
      modalaction: "",
      modalokfunction: "",
      modalpayload: {},
      user: {}
    }
  }

  componentDidMount() {
    this.setState({user: this.props.user})
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }
  
  userName = (user) => {
    return user.name == undefined ? user.member_name : user.name
  }

  openFollow = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Follow",
      modalaction: "follow",
      modaltext: "Are you sure you want to follow this member?",
      modalpayload: payload
    })
  }

  openUnfollow = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Unfollow",
      modalaction: "unfollow",
      modaltext: "Are you sure you want to unfollow this member?",
      modalpayload: payload
    })
  }

  openSendInvitation = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Send Invitation",
      modalaction: "send_invitation",
      modaltext: "Are you sure you want to send an invitation to this member?",
      modalpayload: payload
    })
  }

  openInvitationAccept = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Accept Invitation",
      modalaction: "accept_invitation",
      modaltext: "Are you sure you want to accept this invitation?",
      modalpayload: payload
    })
  }

  openInvitationCancel = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Invitation",
      modalaction: "cancel_invitation",
      modaltext: "Are you sure you want to delete this invitation?",
      modalpayload: payload
    })
  }

  openInvitationDelete = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Cancel Invitation",
      modalaction: "delete_invitation",
      modaltext: "Are you sure you want to cancel this invitation?",
      modalpayload: payload
    })
  }

  callFunction = () => {
    if(this.state.modalaction == "send_invitation") {
      agent.Connect.send_invitation(this.state.modalpayload.member_ids)
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.invite_already_sent = true
        uu.invitation_id = res.invitation_id
        this.setState({user: uu})
        agent.Connect.suggestions()
        .then(res => {
          this.props.setSuggestions({suggestions: res.suggestions});
        })
        Toast.show({
          text: 'Invitation successfully sent.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
      })
    } else if(this.state.modalaction == "accept_invitation") {
      agent.Connect.accept_invitation(this.state.modalpayload.member_ids, 'accepted')
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.in_network = true
        this.setState({user: uu})
        Toast.show({
          text: 'Invitation accepted.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
        agent.Connect.invitations()
        .then(res => {
          this.props.setInvitations(res);
        })
      })
    } else if(this.state.modalaction == "cancel_invitation") {
      agent.Connect.accept_invitation(this.state.modalpayload.member_ids, 'declined')
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.in_network = false
        this.setState({user: uu})
        Toast.show({
          text: 'Invitation deleted.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
        agent.Connect.invitations()
        .then(res => {
          this.props.setInvitations(res);
        })
      })
    }  else if(this.state.modalaction == "delete_invitation") {
      agent.Connect.delete_invitation(this.state.modalpayload.invitation_id)
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.invite_already_sent = false
        this.setState({user: uu})
        Toast.show({
          text: 'Invitation cancelled.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
        // agent.Connect.invitations()
        // .then(res => {
        //   this.props.setInvitations(res);
        // })
      })
    } else if(this.state.modalaction == "follow") {
      agent.Connect.follow(this.state.modalpayload.member_ids)
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.is_follow = true
        this.setState({user: uu})
        Toast.show({
          text: 'You are now following the selected member.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
        // agent.Connect.invitations()
        // .then(res => {
        //   this.props.setInvitations(res);
        // })
      })
    } else if(this.state.modalaction == "unfollow") {
      agent.Connect.unfollow(this.state.modalpayload.member_ids)
      .then(res => {
        this.setState({modalopen: false})
        let uu = {...this.state.user}
        uu.is_follow = false
        this.setState({user: uu})
        Toast.show({
          text: 'You have successfully unfollowed the selected member.',
          duration: 5000,
          type: "success",
          textStyle: {textAlign: "center"}
        })
        // agent.Connect.invitations()
        // .then(res => {
        //   this.props.setInvitations(res);
        // })
      })
    } 
  }

  gotoMutualFriends = (user) => {
    let gotoId = user.id == undefined ? user.member_id : user.id
    this.props.navigation.navigate(Routes.COMMON_FRIENDS, {
      user_id: gotoId
    });
  }

  render() {
    return (
      <View key={this.state.user.id}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalopen}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center" }}>
                <Text style={{ fontSize: 18, color: "black" }}>{this.state.modalheading}</Text>
              </View>
              <View>
                <Text>{this.state.modaltext}</Text>
              </View>
              <View style={styles.forgotactions}>
                <Button
                  style={[styles.forgotcancel, {backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ modalopen: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[ styles.forgotsubmit, {backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.callFunction()
                    // this.voteComment()
                  }}
                >
                  <Text style={styles.textStyle}>Confirm</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        <Card>
          <CardItem style={styles.paddingContent}>
            <Col style={{width: 80, marginLeft: 20}}>
              <Thumbnail source={{ uri: this.avatarUrl(this.state.user.avatar) }} />
            </Col>
            <Col size={6}>
              <Text>{this.userName(this.state.user)}</Text>
              {this.state.user.about_me && <Text note style={{marginTop: 0}}>{this.state.user.about_me}</Text>}
              <Text note>{this.state.user.city}, {this.state.user.state}</Text>
              {this.props.page_type != "mutual" && (this.state.user.common_friends != undefined && this.state.user.common_friends != 0) && <Text style={{color: "#4589c6"}} note onPress={() => this.gotoMutualFriends(this.state.user)}>{this.state.user.common_friends} common friends</Text>}
              {this.props.page_type != "mutual" && (this.state.user.common_friends == undefined || this.state.user.common_friends == 0) && <Text note >{this.state.user.common_friends} common friends</Text>}
              {this.state.user.invite_already_sent && !this.state.user.in_network && <Text note style={{ color: "#f0ad4e" }}>Invitation Sent</Text>}
              {this.state.user.in_network && <Text note style={{ color: "#5cb85c" }}>In Network</Text>}
            </Col>
            <Col size={3}>
              {this.props.page_type == "members" && (!this.state.user.invite_already_sent && !this.state.user.in_network) && <Button small bordered style={[styles.rightAction, {borderColor: "#2b5eaa", borderRadius: 10, padding: 0, borderWidth: 1}]} onPress={() => this.openSendInvitation({member_ids: this.state.user.id})}>
                <Text style={styles.buttonText}>Connect</Text>
              </Button>}
              {this.props.page_type == "members" && (this.state.user.invite_already_sent && !this.state.user.in_network) && <Button small bordered style={[styles.rightAction, {borderColor: "#2b5eaa", borderRadius: 10, padding: 0, borderWidth: 1}]} onPress={() => this.openInvitationDelete({invitation_id: this.state.user.invitation_id} )}>
                <Text style={styles.buttonText}>Withdraw</Text>
              </Button>}
              {this.props.page_type == "members" && (!this.state.user.is_follow && !this.state.user.in_network) && <Button small bordered style={[styles.rightAction, {marginTop: 5, borderColor: "#2b5eaa", borderRadius: 10, padding: 0, borderWidth: 1}]} onPress={() => this.openFollow({member_ids: this.state.user.id})}>
                <Text style={styles.buttonText}>Follow</Text>
              </Button>}
              {this.props.page_type == "members" && (this.state.user.is_follow) && <Button small bordered style={[styles.rightAction, {marginTop: 5, borderColor: "#2b5eaa", borderRadius: 10, padding: 0, borderWidth: 1}]} onPress={() => this.openUnfollow({member_ids: this.state.user.id})}>
                <Text style={styles.buttonText}>Unfollow</Text>
              </Button>}
              {this.props.page_type == "invitations" && <Button style={styles.rightAction} transparent onPress={() => this.openInvitationCancel({member_ids: this.state.user.member_id})}>
                <Icon name="ios-close-circle" type="Ionicons"  style={{fontSize: 30}}/>
              </Button>}
              {this.props.page_type == "invitations" && <Button style={styles.rightAction} transparent onPress={() => this.openInvitationAccept({member_ids: this.state.user.member_id})}>
                <Icon name="ios-checkmark-circle" type="Ionicons"  style={{fontSize: 30}}/>
              </Button>}
            </Col>
          </CardItem>
        </Card>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgba(43,94,170,1)"
  },
  modal: {
    color: "#333",
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  paddingContent: {
    paddingLeft: DetectDeviceService.isTablet ? 125 : 0,
    paddingRight: DetectDeviceService.isTablet ? 125 : 0,
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  forgotcancel: {
		height: 30,
		width: 85,
    marginRight: 20
  },
  forgotsubmit: {
		height: 30,
		width: 90
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  rightAction: {
    alignSelf: "flex-end"
  },
  buttonText: {
    color: "#000000",
    width: 75,
    textAlign: "center",
    paddingLeft: 5,
    paddingRight: 5
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectUser);