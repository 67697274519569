import React from "react";
import {
  StyleSheet, Dimensions, View,
  TouchableOpacity, Platform, Modal
} from "react-native";
import {
  Container,
  Header,
  Title,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Footer,
  FooterTab,
  Spinner,
  Toast
} from "native-base";

import SafeAreaView from 'react-native-safe-area-view';

import HeaderMenu from '../components/HeaderMenu';
import LeftMenu from '../components/LeftMenu';

import DetectDeviceService from '../lib/devicedetection';

import { Col, Row, Grid } from "../lib/easy-grid";

import Menu, { MenuItem } from 'react-native-material-menu';

import agent from '../agent'

import { API_URL } from "../constants/server";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import { MaterialIcons } from '@expo/vector-icons';

import * as MailComposer from 'expo-mail-composer';

import * as Linking from 'expo-linking';

import { WebView } from 'react-native-webview';
import EntypoIcon from "react-native-vector-icons/Entypo";
import { Routes } from '../navigation/routes';
import COLOR from '../styles/Color'
import { connect } from 'react-redux';

import BreadCrumb from "../components/BreadCrumb";

import {
  setBillId,
  setListType,
  setListFilter
} from '../ducks/bill';

const mapStateToProps = state => ({ ...state.bill, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setListType: (list) => dispatch(setListType(list)),
  setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class BillDetailsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opinionvote: "",
      my_representatives: [],
      selectedItems: [],
      bill: {},
      representative_select: "",
      loginWarnPopup: false,
      accountnotsetuppopup: false,
      webviewcounter: 1,
      loading: true,
      showwebview: 0,
      currentCongress: "",
      billtypetext: "",
      blinks: []
    }
  }

  _menu = null;
  _iniwebview = null;

  setIniBillRef = ref => {
    this._iniwebview = ref;
  };

  setMenuRef = ref => {
    this._menu = ref;
  };

  hideMenu = () => {
    this._menu.hide();
  };

  showMenu = () => {
    this._menu.show();
  };

  onSelectedItemsChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  getEmailFromIds = () => {
    let s = []
    let self = this;
    this.state.my_representatives.forEach(function (obj) {
      obj["children"].forEach(function (obj) {
        if (self.state.selectedItems.includes(obj["id"]))
          s.push(obj["email"])
      })
    })
    return s;
  }

  componentDidMount() {
    let currentYear = new Date().getFullYear()
    let currentCongress = ''
    let prevCongress = ''
    if (currentYear % 2 == 0) {
      currentCongress = (currentYear - 1) + "," + currentYear
      prevCongress = (currentYear - 3) + "," + (currentYear - 2)
    } else {
      currentCongress = currentYear + "," + (currentYear + 1)
      prevCongress = (currentYear - 2) + "," + (currentYear - 1)
    }
    this.setState({ currentCongress: currentCongress })
    let self = this;
    this.props.setBillId(this.props.route.params.bill_id);

    this.focusListener = this.props.navigation.addListener('focus', () => {
      agent.Bill.voice_your_opinion(this.props.route.params.bill_id, this.props.list_url)
        .then(res => {
          // this.props.setOpinions(res);
          // this.setState({loading: false})
          console.log(12121212121212)
          console.log(res.object)
          this.setState({ bill: res.object }, () => {
            this.setState({ blinks: ["Home", this.billTypeText(), "Details"] }, () => {
              console.log(this.state.blinks)
            })
          })

          // let userVote = ""
          // try {
          //   userVote = res["My Opinion"][0].user_vote
          // } catch (e) {

          // }
          // this.setState({opinionvote: userVote})
        })

      agent.Bill.user_vote(this.props.route.params.bill_id, this.props.list_url)
        .then(res => {
          let vote = res.vote != undefined ? res.vote.voting_parameters : "Not Voted"
          this.setState({ opinionvote: vote })
        })
    });

    let rep = []
    if (this.props.list_type == "state") {
      agent.Home.staterepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators" && key != "other_state_assembly_representatives" && key != "other_state_senator") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep }, () => console.log(this.state.my_representatives))
        })
    } else {
      agent.Home.myrepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators" && key != "other_state_assembly_representatives" && key != "other_state_senator") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep }, () => console.log(this.state.my_representatives))
        })
    }
  }

  componentWillUnmount() {
    this.focusListener();
  }

  titleCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
  }

  billTypeUri = (req) => {
    let current_route = this.props.route.name;
    if (current_route == "Initiative Details") {
      return (API_URL + "api/v1/initiatives/" + this.props.route.params.bill_id + "?auth_token=" + this.props.auth_token)
    } else if (current_route == "Representative Details") {
      return (API_URL + "api/v1/representatives/" + this.props.route.params.bill_id + "?auth_token=" + this.props.auth_token)
    } else {
      return (API_URL + "api/v1/bills/" + this.props.route.params.bill_id + "?auth_token=" + this.props.auth_token)
    }
  };

  billTypeTitle = (req) => {
    if (this.props.list_type == "initiative") {
      return "Initiative details"
    } else if (this.props.list_type == "eo") {
      return "Executive Order details"
    } else if (this.props.list_type == "representative") {
      return "Representative details"
    } else {
      return "Bill details"
    }
  };

  // billTypeText = () => {
  //   if(this.props.list_type == "initiative") {
  //     return "Initiative"
  //   } else if(this.props.list_type == "eo") {
  //     return "Executive Order"
  // 	} else if(this.props.list_type == "representative") {
  //     return "Representative"
  // 	} else {
  //     return "Bill"
  //   }
  // }

  redirectToVyp = () => {
    if (this.props.user && this.props.user.account_setup == false)
      this.setState({ accountnotsetuppopup: true })
    else if (this.props.auth_token == "") {
      this.setState({ loginWarnPopup: true })
    } else {
      this.props.navigation.navigate(Routes.VOICE_YOUR_OPINION);
    }
  }

  gotoDetail = (req) => {
    if (req.url.startsWith("schemabillswatchlist")) {
      let id = req.url.split("schemabillswatchlist://104.154.88.101/api/v1/bills/")[1].split("/")[0]
      agent.Bill.add_watchlist(this.props.details.id)
        .then(res => {
          Toast.show({
            text: 'Added to Watchlist',
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })
        })
      return false
    } else if (req.url.startsWith("schemainitiativesubmit")) {
      let id = req.url.split("schemainitiativesubmit://104.154.88.101/api/v1/initiatives/")[1].split("/")[0]
      this.submitInitiative(id)
      return false;
    } else if (req.url.startsWith("schemainitiativepetition")) {
      let id = this.props.details.id
      this.setState({ representative_select: "initiative" })
      this.SectionedMultiSelect._toggleSelector()
      return false;
    } else if (req.url.startsWith("schemabills")) {
      this.setState({ webviewcounter: (this.state.webviewcounter + 1) })
      let id = req.url.split("schemabills://104.154.88.101/api/v1/bills/")[1].split("/")[0]
      this.props.setBillId(id);
      this.props.setListType({ type: "fed", url: "/api/v1/bills" });
      this.props.setListFilter({
        filter: 'recommended',
        sort: 'issues',
        years: this.state.currentCongress,
        issue_list: ''
      });
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: id,
        refreshCurrentTab: (() => {
          this.props.setBillId(id);
          this.setState({ billListType: "representative" })
          this.props.setListType({ type: "representative", url: "/api/v1/representatives" })
          this.props.setListFilter({});
        })
      });
      return false
    } else {
      return true
    }
  }

  SelectOrRemoveAll = () =>
    this.SectionedMultiSelect && (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          height: 44,
          borderWidth: 0,
          paddingHorizontal: 10,
          backgroundColor: 'darkgrey',
          alignItems: 'center',
        }}
        onPress={
          this.state.selectedItems.length == this.state.my_representatives.length
            ? this.SectionedMultiSelect._removeAllItems
            : this.SectionedMultiSelect._selectAllItems
        }
      >
        <Text style={{ color: 'white', fontWeight: 'bold' }}>
          {this.state.selectedItems.length == this.state.my_representatives.length ? 'Remove' : 'Select'} all
        </Text>
      </TouchableOpacity>
    );

  callRepFunction = () => {
    // console.log(this.state.representative_select)
    if (this.state.representative_select == "email_elected") {
      let emails = this.getEmailFromIds()
      let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
        this.billTypeText() + " Title: " + "\n" +
        this.state.bill.title + '\n\n' +
        this.billTypeText() + " Summary: " + "\n" +
        this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
        "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
        "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
        "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.com\n\n" +
        "Sincerely,\n" + this.props.user.first_name
      MailComposer.composeAsync({
        recipients: emails,
        body: body
      })
    } else if (this.state.representative_select == "initiative") {
      // let emails = this.getEmailFromIds()
      // MailComposer.composeAsync({
      //   recipients: emails
      // })
      let ini = {}
      ini.representative_ids = this.state.selectedItems
      agent.Bill.update_initiative(ini, this.props.details.id)
        .then(res => {
          this.reloadPage()
        })
    }
  }

  submitInitiative = (id) => {
    agent.Bill.submit_initiative(id)
    agent.Bill.email_initiatives(id)
      .then(res => {
        let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
          this.billTypeText() + " Title: " + "\n" +
          this.state.bill.title + '\n\n' +
          this.billTypeText() + " Summary: " + "\n" +
          this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
          "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
          "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
          "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.com\n\n" +
          "Sincerely,\n" + this.props.user.first_name
        MailComposer.composeAsync({
          recipients: res.map(r => r.email),
          body: body,
          subject: ''
        })
      })
  }

  renderLoading = () => {
    return <Spinner color="blue" style={{ position: "absolute", height: "100%", width: "100%" }} />
  }

  emailElectedOfficials = () => {
    this.hideMenu()
    this.setState({ representative_select: "email_elected" })
    let self = this;
    setTimeout(function () { self.SectionedMultiSelect._toggleSelector() }, 500);
  }

  emailFriend = () => {
    let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
      this.billTypeText() + " Title: " + "\n" +
      this.state.bill.title + '\n\n' +
      this.billTypeText() + " Summary: " + "\n" +
      this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
      "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
      "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
      "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
      "Sincerely,\n" + this.props.user.first_name
    console.log(body)
    MailComposer.composeAsync({
      subject: this.state.bill.title,
      body: body
    })
  }

  backButton = () => {
    if (this.props.route.params != undefined && this.props.route.params.notification == "true") {
      this.props.navigation.navigate(Routes.HOME);
    } else {
      if (this.props.route.params != undefined && this.props.route.params.refreshCurrentTab != undefined)
        this.props.route.params.refreshCurrentTab()
      this.props.navigation.goBack()
    }
  }

  reloadPage = () => {
    this._iniwebview.reload()
  }

  postOnFacebook = () => {
    let facebookParameters = [];
    let shareurl;
    if (this.props.list_type == "initiative") {
      shareurl = API_URL + "initiatives/" + this.state.bill.id;
    } else if (this.props.list_type == "eo") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "representative") {
      shareurl = API_URL + "representatives/" + this.state.bill.id;
    } else {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    }

    facebookParameters.push('u=' + encodeURI(shareurl));
    facebookParameters.push('quote=' + encodeURI(''));
    const url =
      'https://www.facebook.com/sharer/sharer.php?'
      + facebookParameters.join('&');

    Linking.openURL(url)
      .then((data) => {
        // alert('Facebook Opened');
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  postOnTwitter = () => {
    let facebookParameters = [];
    let shareurl;
    if (this.props.list_type == "initiative") {
      shareurl = API_URL + "initiatives/" + this.state.bill.id;
    } else if (this.props.list_type == "eo") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "representative") {
      shareurl = API_URL + "representatives/" + this.state.bill.id;
    } else {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    }

    facebookParameters.push('url=' + encodeURI(shareurl));
    // facebookParameters.push('text=' + encodeURI(''));
    const url =
      'https://twitter.com/intent/tweet?'
      + facebookParameters.join('&');

    Linking.openURL(url)
      .then((data) => {
        // alert('Facebook Opened');
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  billTypeText = () => {
    let current_route = this.props.route.name;
    console.log(this.props.route)
    if (current_route == "Initiative Details") {
      return "Initiatives"
    } else if (current_route == "Representative Details") {
      return "Representatives"
    } else {
      if (this.state.bill.category == "fed") {
        return "Federal Bills"
      } else if (this.state.bill.category == "state") {
        return "State Bills"
      } else if (this.state.bill.category == "eo") {
        return "Executive Orders"
      } else if (this.state.bill.category == "initiative") {
        return "Initiatives"
      } else if (this.props.list_type == "representative") {
        return "Representatives"
      } else {
        return "Federal Bills"
      }
    }


  }

  shareDropDown = () => {
    return <Menu ref={this.setMenuRef}
      style={{ marginTop: 40, width: 220 }}
      button={<Icon
        name="share-alternative"
        style={[styles.icon2, { marginLeft: 9 }]}
        onPress={this.showMenu}
        type="Entypo"></Icon>}>
      <MenuItem style={styles.menuOption} onPress={() => this.postOnFacebook()}>
        <Text style={styles.headerMenuText}>Post on Facebook</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.postOnTwitter()}>
        <Text style={styles.headerMenuText}>Post on X</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.emailElectedOfficials()}>
        <Text style={styles.headerMenuText}>Email Elected Officials</Text>
      </MenuItem>
      <MenuItem style={styles.menuOption} onPress={() => this.emailFriend()}>
        <Text style={styles.headerMenuText}>Email a friend</Text>
      </MenuItem>
    </Menu>
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{ flex: 1, backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)" }}>
        <Container>
          {!DetectDeviceService.isTablet && <Header style={styles.header}>
            <Left style={{ flex: 1 }}>
              <Button transparent onPress={() => this.backButton()}>
                <Icon name="arrow-back" style={styles.icon} />
              </Button>
            </Left>
            <Body style={{ flex: 1 }}>
              <Title style={[styles.textColor, { width: 200, alignSelf: "center" }]}>{this.billTypeTitle()}</Title>
            </Body>
            <Right style={{ flex: 1 }}>
              {this.props.auth_token != "" && <Menu ref={this.setMenuRef}
                style={{ marginTop: 40, width: 220 }}
                button={<Icon
                  name="share-alternative"
                  style={styles.icon2}
                  onPress={this.showMenu}
                  type="Entypo"></Icon>}>
                <MenuItem style={styles.menuOption} onPress={() => this.postOnFacebook()}>
                  <Text style={styles.headerMenuText}>Post on Facebook</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => this.postOnTwitter()}>
                  <Text style={styles.headerMenuText}>Post on X</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => this.emailElectedOfficials()}>
                  <Text style={styles.headerMenuText}>Email Elected Officials</Text>
                </MenuItem>
                <MenuItem style={styles.menuOption} onPress={() => this.emailFriend()}>
                  <Text style={styles.headerMenuText}>Email a friend</Text>
                </MenuItem>
              </Menu>}
            </Right>
          </Header>}
          {DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation} />}
          {/* { DetectDeviceService.isTablet && <LeftMenu navigation={this.props.navigation} /> } */}
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.loginWarnPopup}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                  <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                  <Text>Signup and Login to use this feature.</Text>
                </View>
                <View style={styles.forgotactions}>
                  <Button
                    style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                    onPress={() => {
                      this.setState({ loginWarnPopup: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>
                  <Button
                    style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({ loginWarnPopup: false }); this.props.navigation.navigate(Routes.SIGN_IN);
                    }}
                  >
                    <Text style={styles.textStyle}>Login</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.accountnotsetuppopup}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                  <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
                </View>
                <View style={{ marginBottom: 10 }}>
                  <Text>Account setup must be completed to use this feature.</Text>
                </View>
                <View style={styles.forgotactions}>
                  <Button
                    style={[styles.forgotcancel, { backgroundColor: "#333" }]}
                    onPress={() => {
                      this.setState({ accountnotsetuppopup: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>
                  <Button
                    style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({ accountnotsetuppopup: false }); this.props.navigation.navigate(Routes.GENERAL_SETTINGS);
                    }}
                  >
                    <Text style={styles.textStyle}>Setup</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Grid>
            <Col style={{ backgroundColor: "rgb(236, 236, 236)" }}>
              <BreadCrumb key={this.state.bill.id} navigation={this.props.navigation} links={["Home", this.billTypeText(), "Details"]} shareDropDown={this.shareDropDown}></BreadCrumb>
              <View style={{ width: (DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%"), height: "92%", marginRight: DetectDeviceService.isTab ? 200 : 10, marginTop: 10, alignSelf: "center" }}>
                <WebView incognito={true}
                  ref={this.setIniBillRef}
                  key={this.state.webviewcounter}
                  originWhitelist={["schemainitiativepetition://", "schemainitiativesubmit://", "schemabillswatchlist://", "https://", "http://", "schemabills://"]}
                  startInLoadingState={true} renderLoading={this.renderLoading}
                  //  onLoad={() => this.setState({loading: false})}
                  onShouldStartLoadWithRequest={this.gotoDetail} source={{ uri: this.billTypeUri() }} />
              </View>
              <SectionedMultiSelect
                ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
                IconRenderer={MaterialIcons}
                items={this.state.my_representatives}
                hideSearch={true}
                hideSelect={true}
                showChips={false}
                uniqueKey="id"
                displayKey="name"
                subKey="children"
                headerComponent={this.SelectOrRemoveAll}
                selectText="Select users"
                alwaysShowSelectText={true}
                showDropDowns={false}
                readOnlyHeadings={true}
                showCancelButton={true}
                modalWithSafeAreaView={true}
                onSelectedItemsChange={this.onSelectedItemsChange}
                onConfirm={() => this.callRepFunction()}
                selectedItems={this.state.selectedItems}
                iconKey="icon"
                styles={{
                  chipText: {
                    maxWidth: Dimensions.get('screen').width - 90,
                  },
                  // itemText: {
                  //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                  // },
                  // selectedItemText: {
                  //   color: 'blue',
                  // },
                  // subItemText: {
                  //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                  // },
                  item: {
                    paddingHorizontal: 10,
                  },
                  subItem: {
                    paddingHorizontal: 10,
                  },
                  selectedItem: {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  },
                  selectedSubItem: {
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  },
                  // selectedSubItemText: {
                  //   color: 'blue',
                  // },
                  scrollView: { paddingHorizontal: 0 },
                  container: { width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center" }
                }}

              />
            </Col>
          </Grid>


          <Footer style={styles.footer}>
            {/* <FooterTab> */}
            <Button transparent style={styles.voiceopinionbutton} onPress={this.redirectToVyp}>
              {this.state.opinionvote == "" && <Text style={({ textAlign: "center" }, [styles.textColor])}>Voice Your Opinion</Text>}
              {this.state.opinionvote != "" && <Text style={({ textAlign: "center" }, [styles.textColor])}>My Opinion: {this.state.opinionvote == "Neutral" ? "Not Sure" : this.state.opinionvote}</Text>}
            </Button>
            {/* </FooterTab> */}
          </Footer>
        </Container>
      </SafeAreaView>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
    flex: 1
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
  },
  textColor: {
    color: "rgba(255,255,255,1)"
  },
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: DetectDeviceService.isTablet ? "33%" : "100%",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12
  },
  footer: {
    padding: 5,
    backgroundColor: "rgb(236, 236, 236)"
  },
  icon2: {
    color: DetectDeviceService.isTablet ? "#333" : "rgba(255,255,255,1)",
  },
  forgotcancel: {
    height: 36,
    width: 90,
    marginRight: 20
  },
  forgotsubmit: {
    height: 36,
    width: 90
  },
  forgotactions: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
    margin: 20,
    backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillDetailsScreen);
