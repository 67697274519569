import React, { Component } from "react";
import { Keyboard, AppState } from 'react-native';

import { useIsDrawerOpen } from '@react-navigation/drawer'

import dayjs from 'dayjs';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FullScreenVideo from '../components/FullScreenVideo';

import { API_URL } from "../constants/server";
import agent from '../agent'
import { setFirstAd, setAdPlaying } from "../ducks/home";


const mapStateToProps = state => ({  ...state.home });

const mapDispatchToProps = dispatch => ({
	setFirstAd: (data) => dispatch(setFirstAd(data)),
  setAdPlaying: (data) => dispatch(setAdPlaying(data)),
});

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useIsDrawerOpen();
    return <Component {...props} myHookValue={myHookValue} />;
  }
}

class VideoAds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showad: false,
      ads: [],
      next_ad: null,
      config: {},
      background: false,
    }
    this.keyboard_open = false
    this.background = false
  }

  componentDidMount() {
    if(this.props.auth_token != ""){
      this.getNextAd(false)
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow.bind(this));
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide.bind(this));
      this.changeListener = AppState.addEventListener('change', this.handleStateChange.bind(this));
      this.focusListener = this.props.navigation.addListener('focus', () => {
        this.setState({background: false})

      })
      this.blurListener = this.props.navigation.addListener('blur', () => {
        this.setState({background: true})
        this.closeAd(false)
      })
    }
  }

  getNextAd(show) {
    agent.Home.ads()
    .then(res => { 
      this.setState({ ads: res.ads, next_ad: res.ads[0], config: res.config })
      this.setState({ showad: show })

      // if(show)
        // if(res.ads.length > 0)
          // agent.Home.ad_view(res.ads[0].id)
    })
  }

  componentWillUnmount () {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
    // this.refresh.clearInterval()
    this.focusListener()
    this.blurListener()
    // AppState.removeEventListener('change', this.handleStateChange);
    if(this.changeListener != undefined)
      this.changeListener.remove();
  }

  handleStateChange (state) {
    // console.log("******* APP STATE CHANGE *********")
    // console.log(state)
    if(state.match(/inactive|background/)) {
      // this.background = true
    // } else if (state == "active") {
      // this.background = false
      this.closeAd()
    } else {
      // this.background = true
      this.closeAd()
    }
  }

  _keyboardDidShow () {
    this.keyboard_open = true
  }
    
  _keyboardDidHide () {
    this.keyboard_open = false
    this.setState({showad: false})
  }

  closeAd = (view=true) => {
    this.setState({showad: false})
    this.props.setFirstAd(true)
    this.props.setAdPlaying(false)
    var now = dayjs().unix().toString()
    AsyncStorage.setItem('@ads_open_time', now)
    AsyncStorage.setItem('@ads_seen_time', now)
    if(view && this.state.ads.length != 0) {
      // agent.Home.ad_view(this.state.next_ad.id)
    }
  }

  checkAd = () => {
    this.refresh = setTimeout(async () => {
      // console.log(1)
      // console.log(this.keyboard_open)
      // console.log(this.props.myHookValue)
      if (!this.state.showad && !this.keyboard_open && !this.state.background) {
        // console.log(2)
        var app_open_time = await AsyncStorage.getItem('@ads_open_time')
        var app_last_ad_seen_time = await AsyncStorage.getItem('@ads_seen_time')

        // console.log(3)
        var now = dayjs().unix()
        // console.log(app_last_ad_seen_time)
        // console.log(now)
        // console.log(app_open_time)
        // console.log(this.state.config)
        // 1st time ad: check if it time to show the ad first time after app open
        if(this.props.myHookValue) {
          // console.log(7)
          this.setState({ showad: false })
        }
        else if(parseInt(app_last_ad_seen_time) == 0 && (now - parseInt(app_open_time) >= this.state.config.initial_wait)) {
          // console.log(4)
          this.getNextAd(true)
          // this.setState({ showad: true })
        }
        // After 1st time ad: Check if it is time to show the ad
        else if(parseInt(app_last_ad_seen_time) != 0 && (now - parseInt(app_last_ad_seen_time) >= this.state.config.wait_between_ads)) {
          // console.log(5)
          this.getNextAd(true)
          // this.setState({ showad: true })
        }
        else {
          // console.log(6)
          this.setState({ showad: false })
        }

        // Some of these values need to some from server
        // Check the ads timestamp
        // If "x" time as passed, 
        // If ad is already open, then don't show again!
        // Logic to show only on specific pages can be here?
        // Logic to check for keyboard open / sidepanel open should be here
        // NOTE: You somehow need the sidepanel state here!
        // update timestamp
        // else, do nothing
      }
    }, 10000)
  }


  render() {
    this.checkAd()
    if(this.state.config.show_ad && this.state.showad && this.state.next_ad != null) {
      this.props.setAdPlaying(true)
      return (
        <FullScreenVideo config={this.state.config} first_ad={this.props.first_ad} ad={this.state.next_ad} uri={(API_URL+ this.state.next_ad.url).replace(/([^:]\/)\/+/g, "$1")} closeAd={() => this.closeAd()}></FullScreenVideo>
      )
    }
    else
      return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withMyHook(VideoAds));