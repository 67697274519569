import React, { Component } from "react";
import { StyleSheet, View, Platform, Modal } from "react-native";
import {
  Container,
  Tab,
  Tabs,
  TabHeading,
  ScrollableTab,
  Fab,
  Icon,
  IconNB,
  Content,
  Badge,
  Button,
  Spinner,
  Text
} from 'native-base';

// import "./table.css";

import DetectDeviceService from '../../lib/devicedetection';

import { Col, Row, Grid } from "../../lib/easy-grid";

import ConnectTab from '../ConnectTab';
import SharesScreen from '../SharesScreen';
import ManageNetworkBlockedScreen from '../ManageNetworkBlockedScreen';

import BillListing from '../BillListing';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';

import dayjs from 'dayjs';

import LandingScreen from '../LandingScreen';
import { WebView } from 'react-native-webview';

import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../../styles/Color'
import FONT from '../../styles/Font'
import { Routes } from '../../navigation/routes';

import { CommonActions } from '@react-navigation/native';

import agent from '../../agent'
import { connect } from 'react-redux';
import {
  setBillId,
  setListFilter,
  setListType,
} from '../../ducks/bill';
import {
  setHomeTabCount,
} from '../../ducks/home';
import {
  setAuthUserDetails,
} from '../../ducks/auth';

import { API_URL } from '../../constants/server';

import HeaderMenu from '../../components/HeaderMenu';
import { Dimensions } from "react-native";
import RepresentativeListingScreen from "../RepresentativeListingScreen";
import ManageNetworkFollowingScreen from "../ManageNetworkFollowingScreen";

import LeftMenu from "../../components/LeftMenu";
import BreadCrumb from "../../components/BreadCrumb";


const mapStateToProps = state => ({ ...state.bill, ...state.home, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
  setListFilter: (filters) => dispatch(setListFilter(filters)),
  setListType: (list) => dispatch(setListType(list)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
});

class ManageAdsWeb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ads: []
    };
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      agent.Home.ad_list()
        .then(res => {
          this.setState({ ads: res.ads })
        })
    })
  }

  componentWillUnmount() {
    this.focusListener();
  }

  updateAdStatus(id, status) {
    agent.Home.update_ad_status(id, status)
      .then(res => {
        agent.Home.ad_list()
          .then(res => {
            this.setState({ ads: res.ads })
          })
      })
  }

  adStopButton(id, status) {
    if (status == null) {
      return <Text style={{ color: "#808080" }}>Stop</Text>
    } else if (status == "running") {
      return <a href="#" onClick={() => this.updateAdStatus(id, "paused")}>Stop</a>
    } else if (status == "completed") {
      return <Text style={{ color: "#808080" }}>Stop</Text>
    } else if (status == "paused") {
      return <Text style={{ color: "#808080" }}>Stop</Text>
    }
  }

  adResumeButton(id, status) {
    if (status == null) {
      return <Text style={{ color: "#808080" }}>Resume</Text>
    } else if (status == "running") {
      return <Text style={{ color: "#808080" }}>Resume</Text>
    } else if (status == "completed") {
      return <Text style={{ color: "#808080" }}>Resume</Text>
    } else if (status == "paused") {
      return <a href="#" onClick={() => this.updateAdStatus(id, "running")}>Resume</a>
    }
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{ flex: 1, backgroundColor: "#2B5EAA" }}>
        <Container style={styles.tabs} key={Dimensions.width}>
          <HeaderMenu navigation={this.props.navigation} />
          <Grid>
            {/* <LeftMenu /> */}
            <Col style={{ backgroundColor: "rgb(236, 236, 236)" }}>
              <BreadCrumb navigation={this.props.navigation} links={["Home", "Ads"]}></BreadCrumb>
              <Container >
                <Content style={{ backgroundColor: "#ececec" }} >
                  <Row style={[styles.contentMargin, { marginTop: 20 }]}>
                    <Button style={{ fontSize: 15, height: 35 }} onPress={() => { this.props.navigation.navigate(Routes.CREATE_ADS) }}>
                      <Text style={{ paddingLeft: 10, paddingRight: 10 }}>Create</Text>
                    </Button>
                  </Row>
                  <Row style={[styles.contentMargin, { overflowX: "scroll" }]}>
                    <table className="styled-table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Organization Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Status</th>
                          <th>Video Status</th>
                          <th>Views</th>
                          <th>States</th>
                          <th>Zipcodes</th>
                          <th>Congressional Districts</th>
                          <th>Ad Content</th>
                          <th>Actions</th>
                          <th>Billing amount</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ads.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.title}</td>
                              <td>{val.organization_name}</td>
                              <td>{val.start_date}</td>
                              <td>{val.end_date}</td>
                              <td>{val.status}</td>
                              <td>{val.video_status}</td>
                              <td>{val.views}</td>
                              <td>{val.state}</td>
                              <td>{Array.isArray(val.zipcodes) ? val.zipcodes.join(", ") : val.zipcodes}</td>
                              <td>{Array.isArray(val.districts) ? val.districts.join(", ") : val.districts}</td>
                              <td>{val.url != "" && val.url != null && <a href={val.url}>Link</a>}</td>
                              <td>{this.adResumeButton(val.id, val.status)} | {this.adStopButton(val.id, val.status)}</td>
                              <td>Free</td>
                              <td>{val.created_at}</td>
                            </tr>
                          )
                        })}
                        {this.state.ads.length == 0 &&
                          <tr style={{ height: 35 }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        }
                        {this.state.ads.length == 0 &&
                          <tr style={{ height: 35 }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Row>
                </Content>
              </Container>
            </Col>
          </Grid>
        </Container>
      </SafeAreaView>
    );
  }

}


const styles = StyleSheet.create({
  tabcontainer: {
    justifyContent: 'center', //Centered horizontally
    alignItems: 'center', //Centered vertically
    flex: 1,
  },
  contentMargin: {
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdsWeb);
