import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import FONT from '../styles/Font'
import {
  Icon,
  Thumbnail,
  Badge,
  Text,
  Container,
  Button
} from "native-base";

import SafeAreaView from 'react-native-safe-area-view';

import {
  DrawerItem,
  DrawerContentScrollView,
} from '@react-navigation/drawer';

import AsyncStorage from '@react-native-async-storage/async-storage';

import {
    TabViewVertical,
    TabBarVertical,
    TabView,
    TabBar,
    SceneMap,
  } from 'react-native-vertical-tab-view';

import DetectDeviceService from '../lib/devicedetection';

import { WebView } from 'react-native-webview';


import LandingScreen from '../screens/LandingScreen';
import ConnectTab from '../screens/ConnectTab';
import SharesScreen from '../screens/SharesScreen';
import BillListing from '../screens/BillListing';
import RepresentativeListingScreen from "../screens/RepresentativeListingScreen";


import { Col, Row, Grid } from "../lib/easy-grid";

import { API_URL } from '../constants/server';


import { connect } from 'react-redux';
import agent from '../agent'

import { Routes } from '../navigation/routes';
import { CommonActions } from '@react-navigation/native';

import {
	setAuthToken,
  setAuthUser,
  setAuthUserDetails
} from '../ducks/auth';

import {
	setHomeTabCount,
  setAdEnabled
} from '../ducks/home';

import * as MailComposer from 'expo-mail-composer';

const mapStateToProps = state => ({ ...state.auth, ...state.home, ...state.bill });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
  setAuthUser: (user) => dispatch(setAuthUser(user)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
  setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
  setAdEnabled: (data) => dispatch(setAdEnabled(data)),
});

class LeftMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activesubtab: 1,
      ad_enabled: false
    }
  }

  async componentDidMount() {
    let shares_from_time = await AsyncStorage.getItem('@shares_from_time')
    if(shares_from_time == null)
      shares_from_time = ''
      agent.Home.tabcount(shares_from_time)
        .then(res => {  
          this.props.setHomeTabCount(res);
        })
      agent.Home.ad_enabled()
      .then(res => {
        this.props.setAdEnabled(res.ad_enabled)
        // this.setState({ ad_enabled: res.ad_enabled })
      })
  }

  setBillUrl = () => {
    let url;

    // if(this.state.webviewurl != "") {
    //   url = this.state.webviewurl
    // } else {
    //if(billType == "fed") {
    url = API_URL + this.props.list_url + "?" + this.toQueryString(this.props.list_filters)+"&sync=true"
    if(this.props.auth_token != "")
      url = url + "&auth_token="+this.props.auth_token
    //}
    // }
    return url;
  }

  toQueryString = (params) =>{
    return (
      Object.entries(params)
      .map(
        ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")
    );
  }

  HomeRoute = () => (
    <View style={{ height: 120, flex: 1, flexDirection: "row", paddingRight: (DetectDeviceService.isTablet ? 200 : 0), backgroundColor: "#ececec", }}>
      <LandingScreen navigation={this.props.navigation} key={this.props.auth_token} />
    </View>
  )

  ShareRoute = () => (
    <SharesScreen navigation={this.props.navigation} key={this.state.shareRefreshVal}/>
  )

  FedBillsRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <BillListing key={this.setBillUrl()} navigation={this.props.navigation} uri={this.setBillUrl()} currentCongress={this.state.currentCongress} prevCongress={this.state.prevCongress} setBillUrl={this.setBillUrl}/>
    </Container>
    // <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
    //   <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
    //     ref={this.setFedBillRef}
    //     source={{ uri: this.setBillUrl() }} key={this.state.fedBillRefreshVal}
    //     incognito={true}
    //     startInLoadingState={true} renderLoading={this.renderLoading} />
    //   <View style={styles.filterButton}>
    //     <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
    //       prevCongress: this.state.prevCongress,
    //       currentCongress: this.state.currentCongress,
    //       refresh: this.resetwebviewurl
    //     })}>
    //       <Icon name="ios-funnel" type="Ionicons" />
    //     </Button>
    //   </View>
    //   <View style={styles.filterButtonText}>
    //     <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
    //       <Text style={{ color: "#fff" }}>Filters</Text>
    //     </Badge>
    //   </View>
    // </Container>
  )

  ConnectRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={this.state.activesubtab}/>
  )
  ConnectInvRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={1}/>
  )
  ConnectSuggRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={2}/>
  )
  ConnectMemRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={0}/>
  )

  ExecOrerRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setEoBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.execOrderRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  InitiativesRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemainitiativesubmit://", "schemainitiatives://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setIniBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.initiativeBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
      <View style={styles.addInitiativeButton}>
        <Button info rounded onPress={() => this.createInitiative()}>
          <Icon name="plus" type="FontAwesome" />
        </Button>
      </View>
      <View style={styles.addInitiativeButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Create</Text>
        </Badge>
      </View>
    </Container>
  )

  RepreRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <RepresentativeListingScreen navigation={this.props.navigation} uri={(API_URL+"/api/v1/representatives.json?auth_token="+this.props.auth_token)} setBillUrl={this.setBillUrl}/>
    </Container>
  )

  MatchRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
    <WebView originWhitelist={["schemasettings:", "schemarepresentative://", "schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         source={{uri: API_URL + "api/v1/users/match_my_votes_page?auth_token="+this.props.auth_token}} 
                         incognito={true} key={this.state.matchMyVotesRefreshVal}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
    </Container>
  )

  StateRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail}
        ref={this.setStateBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.stateBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  renderScene = SceneMap({
    home: this.HomeRoute,
    share: this.ShareRoute,
    connect: this.ConnectRoute,
    connectmem: this.ConnectMemRoute,
    connectinv: this.ConnectInvRoute,
    connectsugg: this.ConnectSuggRoute,
    fedbills: this.FedBillsRoute,
    execorders: this.ExecOrerRoute,
    initiatives: this.InitiativesRoute,
    representatives: this.RepreRoute,
    match: this.MatchRoute,
    statebills: this.StateRoute,
  });

  renderBadge = (props) => {
    if(props.route.key == "share")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.shares}</Text></Badge>
    else if(props.route.key == "connect")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.connects}</Text></Badge>
    else if(props.route.key == "fedbills")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.fed_bills}</Text></Badge>
    else if(props.route.key == "execorders")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.executive_orders}</Text></Badge>
    // else if(props.route.key == "initiatives")
    //   return <Badge><Text>{props.route.key}</Text></Badge>
    else if(props.route.key == "statebills")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.state_bills}</Text></Badge>
  }

  renderTabBar = (props) => {
    if (!DetectDeviceService.isTablet) {
      return <TabBar
        {...props}
        scrollEnabled
        // indicatorStyle={{ backgroundColor: 'white' }}
        // style={styles.tabs}
        style={[styles.tabs, {elevation: 0, borderColor: 'rgba(43,94,170,1)', borderBottomWidth: 1, height:50}]}
        indicatorStyle={{backgroundColor: 'yellow', height: 2.5}}
        {...(DetectDeviceService.isTablet? {tabStyle: {width: 240}} : {})}
        renderLabel={({ route, focused, color }) => {
          return <View style={{ flexDirection: "row", width: '100%', alignItems: "center", justifyContent: "center"}}>
             <Text style={{ color: "#fff", textTransform: "uppercase", fontSize: 15}}>
              {route.title}
            </Text>
            {this.props.auth_token != "" && this.renderBadge({ route })}
          </View>
        }}
      // renderBadge={this.renderBadge}
      // labelStyle={{fontSize: 15, paddingRight: 25}}
      />
    } else {
      return <TabBarVertical
      {...props}
      scrollEnabled
      // indicatorStyle={{ backgroundColor: 'white' }}
      // style={styles.tabs}
      style={[styles.leftmenu, { elevation: 0, borderColor: 'rgba(43,94,170,1)', borderBottomWidth: 1 }]}
      indicatorStyle={{ backgroundColor: '#6da8c0', width: 200, marginLeft: 200, height: "100%" }}
      {...(DetectDeviceService.isTablet ? { tabStyle: { height: 45 } } : { height: 80 })}
      renderLabel={({ route, focused, color }) => {
        return <View style={{ flexDirection: "row", width: (DetectDeviceService.isTablet ? '100%' : '120%') }}>
          <Text style={{ color: (focused ? "#fff" : "#000"), textTransform: "uppercase", fontSize: (route.type == "sub" ? 13 : 15), paddingLeft: (route.type == "sub" ? 25 : 0) }}>
            {route.title}
          </Text>
          {this.props.auth_token != "" && this.renderBadge({ route })}
        </View>
      }}
    // renderBadge={this.renderBadge}
    // labelStyle={{fontSize: 15, paddingRight: 25}}
    />
    }
  }

  isStateCalifornia = () => {
    if(this.props.user.state)
      return ["California", "california", "ca", "CA", "Ca"].includes(this.props.user.state)
    else
      return false
  }

  isCityMv = () => {
    if(this.props.user.city)
      return ["mountain view"].includes(this.props.user.city.toLowerCase())
    else
      return false
  }

  redirect = (route, params={}) => {
    this.props.navigation.navigate(route, params);
  }

  getCurrentRoute = () => {
    let index = this.props.navigation.dangerouslyGetState();
    return index.index
  }

  getBillsRoute = (type) => {
    let index;
    if(type == "fed")
      index = 7
    else if(type == "state")
      index = 8
    else if(type == "eo")
      index = 9
    else if(type == "initiative")
      index = 10
    else if(type == "representative")
      index = 14

    if(this.getCurrentRoute() == index) {
      return styles.activeColor
    } else if(this.getCurrentRoute() == 15 || this.getCurrentRoute() == 17) {
        if(this.props.list_type == type)
          return styles.activeColor
        else
          return styles.inActiveColor
    }
  }

  activeRoute = (route) => {
    console.log(this.getCurrentRoute())
    if (route == "home" && this.getCurrentRoute() == 1)
      return styles.activeColor
    else if (route == "share" && (this.getCurrentRoute() == 2 || this.getCurrentRoute() == 26))
      return styles.activeColor
    else if (route == "connect" && this.getCurrentRoute() == 111)
      return styles.activeColor
    else if (route == "members" && this.getCurrentRoute() == 11)
      return styles.activeColor
    else if (route == "invitations" && this.getCurrentRoute() == 12)
      return styles.activeColor
    else if (route == "suggestions" && this.getCurrentRoute() == 13)
      return styles.activeColor
    else if (route == "match_my_votes" && this.getCurrentRoute() == 34)
      return styles.activeColor
    else if (route == "ads" && this.getCurrentRoute() == 35)
      return styles.activeColor
    else
      return styles.inActiveColor
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{flex: 1, backgroundColor: "#2B5EAA"}}>
      <Grid style={{ backgroundColor: "rgb(43, 94, 170)"}}>
        {DetectDeviceService.isTablet && <View style={{ backgroundColor: "rgb(43, 94, 170)", height: 50 }}>
          <Thumbnail small style={{marginLeft: DetectDeviceService.isTablet ? 37 : 37, marginTop: 11, position: "absolute"}} source={{ uri: "https://www.townhallusa.com/assets/logo.png" }} />
          <View style={styles.group}>
            <Text style={styles.yoopine}>Yoopine</Text>
            <Text style={styles.yourOpinionMatters}>Your Opinion Matters</Text>
          </View>
        </View>}
        <View
          style={{
            borderBottomColor: 'rgb(236, 236, 236)',
            borderBottomWidth: 1,
            marginTop: DetectDeviceService.isTablet ? 20 : 50,
            width: "70%",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        />
        <Row style={{ marginTop: 20 }}>
          <DrawerContentScrollView>
            {/* <Col style={styles.leftmenu}> */}
            <Row style={styles.drawerItem} ><Text onPress={() => { this.redirect(Routes.HOME) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.activeRoute("home")]}>HOME</Text></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.SHARES) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.activeRoute("share")]}>SHARE</Text><Badge style={{marginTop: 0, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.shares}</Text></Badge></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.MEMBERS) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.activeRoute("connect")]}>CONNECT</Text></Row>
            <Row style={styles.drawerItemNested}><Text onPress={() => { this.redirect(Routes.MEMBERS) }} style={[styles.nestedWidth, styles.label, styles.tabHeading, this.activeRoute("members")]}>MEMBERS</Text></Row>
            <Row style={styles.drawerItemNested}><Text onPress={() => { this.redirect(Routes.INVITATIONS) }} style={[styles.nestedWidth, styles.label, styles.tabHeading, this.activeRoute("invitations")]}>INVITATIONS</Text><Badge style={{marginTop: 0, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.connects}</Text></Badge></Row>
            <Row style={styles.drawerItemNested}><Text onPress={() => { this.redirect(Routes.SUGGESTIONS) }} style={[styles.nestedWidth, , styles.label, styles.tabHeading, this.activeRoute("suggestions")]}>SUGGESTIONS</Text></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.FEDERAL_BILLS) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.getBillsRoute("fed")]}>FEDERAL BILLS</Text><Badge style={{marginTop: 0, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.fed_bills}</Text></Badge></Row>

            {
              this.props.auth_token != "" && this.isStateCalifornia() && 
              <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.STATE_BILLS) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.getBillsRoute("state")]}>STATE BILLS</Text><Badge style={{marginTop: 0, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.state_bills}</Text></Badge></Row>
            }

            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.EXECUTIVE_ORDERS) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.getBillsRoute("eo")]}>EXECUTIVE ORDERS</Text><Badge style={{marginTop: 0, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.executive_orders}</Text></Badge></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.INITIATIVES) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.getBillsRoute("initiative")]}>INITIATIVES</Text></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.REPRESENTATIVES) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.getBillsRoute("representative")]}>REPRESENTATIVES</Text></Row>
            <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.MATCH_MY_VOTES) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.activeRoute("match_my_votes")]}>MATCH MY VOTES</Text></Row>

            {this.props.ad_enabled && DetectDeviceService.isTablet && <Row style={styles.drawerItem}><Text onPress={() => { this.redirect(Routes.ADS) }} style={[styles.fullWidth, styles.label, styles.tabHeading, this.activeRoute("ads")]}>ADS</Text></Row>}

            {/* <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 1} activeBackgroundColor="#fff" inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="HOME" onPress={() => { this.redirect(Routes.HOME) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 2 || this.getCurrentRoute() == 26} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="SHARE" onPress={() => { this.redirect(Routes.SHARES) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 111} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="CONNECT" onPress={() => { this.redirect(Routes.MEMBERS) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 11} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItemNested : styles.drawerItemNestedMobile} label="MEMBERS" onPress={() => { this.redirect(Routes.MEMBERS) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 12} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItemNested : styles.drawerItemNestedMobile} label="INVITATIONS" onPress={() => { this.redirect(Routes.INVITATIONS) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 13} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItemNested : styles.drawerItemNestedMobile} label="SUGGESTIONS" onPress={() => { this.redirect(Routes.SUGGESTIONS) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getBillsRoute("fed")} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="FEDERAL BILLS" onPress={() => { this.redirect(Routes.FEDERAL_BILLS) }} />
            {
              this.props.auth_token != "" && this.isStateCalifornia() && 
              <DrawerItem labelStyle={styles.label} focused={this.getBillsRoute("state")} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="STATE BILLS" onPress={() => { this.redirect(Routes.STATE_BILLS) }} />
            }
            <DrawerItem labelStyle={styles.label} focused={this.getBillsRoute("eo")} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="EXECUTIVE ORDERS" onPress={() => { this.redirect(Routes.EXECUTIVE_ORDERS) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getBillsRoute("initiative")} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="INITIATIVES" onPress={() => { this.redirect(Routes.INITIATIVES) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getBillsRoute("representative")} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="REPRESENTATIVES" onPress={() => { this.redirect(Routes.REPRESENTATIVES ) }} />
            <DrawerItem labelStyle={styles.label} focused={this.getCurrentRoute() == 29} activeBackgroundColor="#fff"  inactiveTintColor={DetectDeviceService.isTablet ? "rgb(255, 255, 255)" : "rgba(28, 28, 30, 0.68)"} style={DetectDeviceService.isTablet ? styles.drawerItem : styles.drawerItemMobile} label="MATCH MY VOTES" onPress={() => { this.redirect(Routes.MATCH_MY_VOTES) }} /> */}
            {/* </Col> */}
          </DrawerContentScrollView>
        </Row>
      </Grid>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
    tabHeading: {
        color: "rgba(255,255,255,1)",
        textTransform: "uppercase"
      },
      tabs: {
        backgroundColor: "rgba(43,94,170,1)",
      },
      leftmenu: {
        width: 400,
        backgroundColor: "#fff",
        paddingLeft: 70
      },
      menurow: {
        marginTop: 20,
        marginBottom: 20,
      },
      menutext: {
        height: 38
      },
      group: {
        // width: 131,
        height: 33,
        marginTop: 8,
        marginLeft: DetectDeviceService.isTablet ? 80 : 80,
      },
      yoopine: {
        color: "rgba(255,255,255,1)",
        fontSize: 17,
        // fontFamily: FONT.IOS.Bold,
        fontWeight: "bold",
        // alignSelf: "center"
      },
      yourOpinionMatters: {
        color: "rgba(255,255,255,1)",
        fontSize: 13,
        // fontFamily: FONT.IOS.Bold,
        fontWeight: "bold",
        // alignSelf: "center"
      },
      label: {
        fontSize: 13,
        // width: "100%",
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 4,
        paddingLeft: 5
      },
      nestedWidth: {
        width: "54%"
      },
      fullWidth: {
        width: "80%"
      },
      activeColor: {
        backgroundColor: "#fff",
        color: "rgb(0, 122, 255)"
      },
      inActiveColor: {
        backgroundColor: "rgba(43,94,170,1)",
        color: "#fff"
      },
      drawerItem: {
        marginLeft: DetectDeviceService.isTablet ? 40 : 30,
        // maxHeight: 30,
        paddingTop: 6,
        width: "70%"
      },
      drawerItemNested: {
        marginLeft: DetectDeviceService.isTablet ? 60 : 60,
        maxHeight: 30,
      },
      drawerItemMobile: {
        marginLeft: 50
      },
      drawerItemNestedMobile: {
        marginLeft: 80
      }
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
