import React, { Component } from "react";
import { StyleSheet, View, Platform, Modal, Image } from "react-native";
import {
  Container,
  Tab,
  Tabs,
  TabHeading,
  ScrollableTab,
  Fab,
  Icon,
  IconNB,
  Content,
  Badge,
  Button,
  Spinner,
  Text,
  Label,
  Input,
  Item,
  Picker,
  Toast, Radio
} from 'native-base';

import Select from 'react-select'

import DatePicker from "react-datepicker";
// import './react-datepicker-cssmodules.css';

import mime from 'mime';

import DetectDeviceService from '../../lib/devicedetection';

import * as ImagePicker from 'expo-image-picker';

import { Col, Row, Grid } from "../../lib/easy-grid";

import ConnectTab from '../ConnectTab';
import SharesScreen from '../SharesScreen';
import ManageNetworkBlockedScreen from '../ManageNetworkBlockedScreen';

import BillListing from '../BillListing';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';

import dayjs from 'dayjs';

import LandingScreen from '../LandingScreen';
import { WebView } from 'react-native-webview';

import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../../styles/Color'
import FONT from '../../styles/Font'
import { Routes } from '../../navigation/routes';

import { CommonActions } from '@react-navigation/native';

import agent from '../../agent'
import { connect } from 'react-redux';
import {
  setBillId,
  setListFilter,
  setListType,
} from '../../ducks/bill';
import {
  setHomeTabCount,
} from '../../ducks/home';
import {
  setAuthUserDetails,
} from '../../ducks/auth';

import { API_URL } from '../../constants/server';

import HeaderMenu from '../../components/HeaderMenu';
import { Dimensions } from "react-native";
import RepresentativeListingScreen from "../RepresentativeListingScreen";
import ManageNetworkFollowingScreen from "../ManageNetworkFollowingScreen";

import LeftMenu from "../../components/LeftMenu";
import BreadCrumb from "../../components/BreadCrumb";


const mapStateToProps = state => ({ ...state.bill, ...state.home, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
  setListFilter: (filters) => dispatch(setListFilter(filters)),
  setListType: (list) => dispatch(setListType(list)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
});

class CreateAdsWeb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      start_date: "",
      end_date: "",
      organization_entity_name: "",
      states: [],
      selectedState: "",
      zipcodes: [],
      zipcodes_text: "",
      districts: [],
      all_districts: [],
      new_attachment: "",
      creating_ad: false,
      attaching_ad: null,
      district_select: true,
      state_select: false,
      selectedImage: null,
    };
  }

  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({
        title: "",
        start_date: "",
        end_date: "",
        organization_entity_name: "",
        selectedImage: null,
        states: [],
        selectedState: "",
        zipcodes: [],
        zipcodes_text: "",
        districts: [],
        new_attachment: "",
        creating_ad: false,
        district_select: true,
        state_select: false
      })
      agent.Home.ad_list()
        .then(res => {
          this.setState({ ads: res.ads })
        })
      agent.Home.getstateslist()
        .then(res => {
          let states = res.states
          states.unshift("All States")
          this.setState({ states: states })
        })
      let districts = Array.from(Array(54), (_, i) => i + 1)
      districts.unshift("All Districts")
      this.setState({ all_districts: districts.map((val) => ({ value: (val == "All Districts" ? null : val), label: val })) })
      this.setState({ districts: [] }, () => { console.log(this.state.districts) })
    })
  }

  componentWillUnmount() {
    this.focusListener();
  }

  getDistricts = (value, setCurrent) => {
    if (value != null) {
      let stateName = value;
      agent.Home.getdistrictslist(stateName)
        .then(res => {
          let districts = res.districts
          districts.unshift("All Districts")

          let alld = districts.map((val) => ({ value: val, label: val }))
          this.setState({ all_districts: alld })
          // this.setState({ districts: res.districts })
        })
      this.setState({ selectedState: stateName })
    }
  }

  getStateById = (stateId) => {
    let state;
    this.state.states.map(data => {
      if (parseInt(data.id) == parseInt(stateId)) {
        state = data;
      }
    });
    return state;
  }

  selectProfileImage = async () => {
    this.setState({ attaching_ad: true })
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      // alert('Sorry, we need camera roll permissions to make this work!');
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });

      if (!result.cancelled) {
        // console.log(result.uri)
        this.setState({ selectedImage: result.uri })
        this.setState({ new_attachment: result.uri })
        this.setState({ attaching_ad: false })
      } else {
        this.setState({ attaching_ad: false })
      }
    }
  }

  uploadFileName = () => {
    if (this.state.new_attachment == "" || this.state.new_attachment == null) {
      return ""
    } else {
      let filepath = this.state.new_attachment.split("/")
      return filepath[filepath.length - 1].substring(0, 20);
    }
  }

  createAd = () => {
    let new_ad = {
      title: this.state.title,
      organization_name: this.state.organization_entity_name,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      zipcodes: this.state.zipcode_select ? this.state.zipcodes_text.split(",").map(item => item.trim()) : [],
      state: this.state.selectedState,
      districts: this.state.district_select ? this.state.districts.map((d) => (d.value)) : []
    }
    this.uploadImageAsync(this.state.new_attachment, new_ad)
  }

  locationSelect = (context) => {
    if (context == "district") {
      this.setState({ district_select: true })
      this.setState({ zipcode_select: false })
    } else {
      this.setState({ district_select: false })
      this.setState({ zipcode_select: true })
    }
  }

  uploadImageAsync(uri, data) {
    const method = 'POST';
    let formData = new FormData();
    const endpoint = API_URL + 'api/v1/users/ad?auth_token=' + agent.getToken(); // Define Endpoint Here

    if (uri == "" || uri == null) {
      return false
    }
    const uripath = uri.split('/');
    let fileName = uripath[uripath.length - 1]
    const fileType = mime.getType(uri);
    const fileTypeMine = uri.split(',')[0].split(":")[1].split(";")[0]
    const byteString = atob(uri.split(',')[1]);

    const ab = new ArrayBuffer(byteString.length);
    const arr = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++)
      arr[i] = byteString.charCodeAt(i);

    const blob = new Blob([arr], {
      type: fileTypeMine || 'image/png',
    });

    fileName = fileName.substring(0, 20)

    data.file_type = fileTypeMine;

    const file = new File([blob], `${fileName}`, { type: (fileTypeMine || 'image/png') });

    formData.append('ad[ad_video]', file);

    Object.keys(data).forEach(key => {
      if (!Array.isArray(data[key]))
        formData.append("ad[" + key + "]", data[key])
      else
        // formData.append("ad["+key+"][]", JSON.stringify(data[key].flat(1)))
        data[key].forEach((item) => formData.append("ad[" + key + "][]", item))
    })

    const options = {
      method,
      body: formData,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    };
    this.setState({ creating_ad: true })

    return fetch(endpoint, options).then(res => {
      this.setState({ creating_ad: false })
      this.props.navigation.navigate(Routes.ADS);
      Toast.show({
        text: 'Ad successfully created.',
        duration: 5000,
        type: "success",
        textStyle: { textAlign: "center" }
      })
    });
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={{ flex: 1, backgroundColor: "#2B5EAA" }}>
        <Container style={styles.tabs} key={Dimensions.width}>
          <HeaderMenu navigation={this.props.navigation} />
          <Grid>
            {/* <LeftMenu /> */}
            <Col style={{ backgroundColor: "rgb(236, 236, 236)" }}>
              <BreadCrumb navigation={this.props.navigation} links={["Home", "Ads", "Create"]}></BreadCrumb>
              <Container >
                <Content style={[styles.contentMargin, { backgroundColor: "#ececec" }]} >
                  <Row style={{ marginTop: 10 }}>
                    <Text style={{ color: "#333333" }}>
                      * All fields are required
                    </Text>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 1: Create a name for this Ad Campaign</Text>
                  </Row>
                  <Row style={[{ marginTop: 20, marginLeft: 15 }]}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>Ad campaign title: </Text>
                    </Col>
                    <Col style={{ width: 250 }}>
                      <Item regular style={{ height: 30 }}>
                        <Input style={{ border: 1, height: 30, backgroundColor: "#ffffff" }} value={this.state.title} placeholder='Title' onChangeText={(text) => { this.setState({ title: text }) }} />
                      </Item>
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 2: The organization name sponsoring the ad</Text>
                  </Row>
                  <Row style={[{ marginTop: 20, marginLeft: 15 }]}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>Sponsoring Entity Name: </Text>
                    </Col>
                    <Col style={{ width: 250 }}>
                      <Item regular style={{ height: 30 }}>
                        <Input style={{ border: 1, height: 30, backgroundColor: "#ffffff" }} value={this.state.organization_entity_name} placeholder='Entity name' onChangeText={(text) => { this.setState({ organization_entity_name: text }) }} />
                      </Item>
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 3: Select start and end dates for the Ad Campaign</Text>
                  </Row>
                  <Row style={[{ marginTop: 20, marginLeft: 15, zIndex: 4 }]}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>Start Date</Text>
                    </Col>
                    <Col style={{ width: 250 }}>
                      <DatePicker selected={this.state.start_date} onChange={(date) => this.setState({ start_date: date })} />
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20, marginLeft: 15, zIndex: 2 }]}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>End Date</Text>
                    </Col>
                    <Col style={{ width: 250 }}>
                      <DatePicker selected={this.state.end_date} onChange={(date) => this.setState({ end_date: date })} />
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 4: Select state where you want this ad to appear</Text>
                  </Row>
                  <Row style={{ marginTop: 20, marginLeft: 15 }}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>State</Text>
                    </Col>
                    <Col style={{ width: 150 }}>
                      {/* <Right> */}
                      <Picker
                        //enabled={this.checkSettings("selected")}
                        style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}
                        textStyle={{ color: "#fff" }}
                        mode="dropdown"
                        placeholder="Select"
                        // selectedValue={this.selectedPrivValue("state")}
                        onValueChange={(val) => { this.getDistricts(val, true) }}
                      >
                        {
                          this.state.states.map((state) => {
                            return (<Picker.Item key={state} label={state} value={state} />);
                          })
                        }
                      </Picker>
                      {/* </Right> */}
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 5: Select Congressional District or type in comma separated 5-digit Zip Codes (e.g. 94040, 95050, 94538)</Text>
                  </Row>
                  <Row style={{ marginTop: 20, marginLeft: 15, zIndex: 1 }}>
                    <Col style={{ width: 30, marginTop: 5 }}><Radio selected={this.state.district_select} onPress={() => this.locationSelect("district")} ></Radio></Col>
                    <Col style={{ width: 220 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>District</Text>
                    </Col>
                    <Col style={{ width: 250 }}>
                      {/* <Right> */}
                      <Select isMulti options={this.state.all_districts} value={this.state.districts}
                        onChange={(val) => { this.setState({ districts: val }) }}
                      />
                      {/* <Picker
                        style={{ backgroundColor: "#808080", height: 30, width: "60%", alignSelf: "center", color: "#fff" }}
                        textStyle={{ color: "#fff" }}
                        mode="dropdown"
                        placeholder="Select"
                        disabled={!this.state.district_select}
                      // selectedValue={this.selectedPrivValue("congressional_district")}
                      onValueChange={(val) => { this.setState({districts: [val]}) }}
                      >
                        {
                          this.state.all_districts.map((cd) => {
                            return (<Picker.Item key={cd.toString()} label={cd.toString()} value={cd} />);
                          })
                        }
                      </Picker> */}
                      {/* </Right> */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20, marginLeft: 15 }}>
                    <Col style={{ width: 30, marginTop: 5 }}><Radio selected={this.state.zipcode_select} onPress={() => this.locationSelect("zipcode")} ></Radio></Col>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>Zipcodes</Text>
                    </Col>
                    <Col style={{ width: 150 }}>
                      {/* <Right> */}
                      <Input style={{ border: 1, height: 30, backgroundColor: "#ffffff" }} disabled={!this.state.zipcode_select} placeholder='Zipcodes' onChangeText={(text) => { this.setState({ zipcodes_text: text }) }} />
                      {/* </Right> */}
                    </Col>
                  </Row>
                  <Row style={[{ marginTop: 20 }]}>
                    <Text style={{ marginTop: 5, fontSize: 15 }}>Step 6: Select Ad video file to upload (video must be less than 200MB. Preferred video duration is less than 15 seconds. After 15 seconds, users may skip the ad. Max video duration is 60 seconds.)</Text>
                  </Row>
                  <Row style={[{ marginTop: 20, marginLeft: 15 }]}>
                    <Col style={{ width: 250 }}>
                      <Text style={{ marginTop: 5, fontSize: 15 }}>Ad Content</Text>
                    </Col>
                    <Col style={{ width: 50 }}>
                      {this.state.selectedImage != null && <Image
                        source={{ uri: this.state.selectedImage }}
                        style={{ width: 35, height: 35 }}
                      />}
                    </Col>
                    <Col style={{ width: 250 }}>

                      <Button transparent onPress={() => { this.selectProfileImage() }}>
                        <Text>Select Video File</Text>
                      </Button>
                      {/* <Text style={{ marginTop: 5, fontSize: 15 }}>{this.uploadFileName()}</Text> */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    {!this.state.creating_ad && !this.state.attaching_ad && <Button style={{ height: 35, backgroundColor: "#2196F3" }} onPress={() => { this.createAd() }}>
                      <Text>Upload and Save</Text>
                    </Button>}
                    {this.state.creating_ad && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {this.state.creating_ad && <Text style={{ marginTop: 5, marginLeft: 10 }}>Your Video is being uploaded, please wait. Upload could take up to 5 minutes or sometimes longer, depending on the file size and internet connection speed. After the video upload is completed you will be taken to the Ads page.</Text>}
                    {this.state.attaching_ad && <Spinner color='blue' style={{ height: 40 }}></Spinner>}
                    {this.state.attaching_ad == false && <Text style={{ marginTop: 5, marginLeft: 10 }}>Your Video has been attached. Click on Upload and Save to proceed.</Text>}
                  </Row>
                </Content>
              </Container>
            </Col>
          </Grid>
        </Container>
      </SafeAreaView>
    );
  }

}


const styles = StyleSheet.create({
  tabcontainer: {
    justifyContent: 'center', //Centered horizontally
    alignItems: 'center', //Centered vertically
    flex: 1,
  },
  contentMargin: {
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdsWeb);
