import * as React from 'react';
import { useEffect, useState } from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from "expo-auth-session/providers/google";
import { ResponseType } from 'expo-auth-session';
import { StyleSheet } from "react-native";

import { Routes } from '../navigation/routes';
import { CommonActions } from '@react-navigation/native';

import AsyncStorage from '@react-native-async-storage/async-storage';


import {
	Button,
	Text,
	Toast
} from "native-base";
import DetectDeviceService from '../lib/devicedetection';
import agent from '../agent'

WebBrowser.maybeCompleteAuthSession();

const GoogleWebLogin = (props) => {
	const [userInfo, setUserInfo] = useState(null);
	const request_type = props.requestType

	//client IDs from .env
	const config = {
		webClientId: "254075655620-lrvr2hl0g22jlfervlu6671o7aik7qkq.apps.googleusercontent.com",
	};

	const redirect = {
		scheme: 'http',
		preferLocalhost: true,
		// useProxy: true,
		path: 'callback.html'
	};

	const [request, response, promptAsync] = Google.useAuthRequest(config, redirect);

	const getUserInfo = async (token) => {
		//absent token
		if (!token) return;
		//present token
		try {
			const response = await fetch(
				"https://www.googleapis.com/userinfo/v2/me",
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);
			const user = await response.json();
			//store user information  in Asyncstorage
			if (request_type == "login") {
				agent.Auth.googleloginnew(token, user.id, user.email, user)
					.then(res => {
						if (res.errors) {
							// if (res.code == 'open_email_popup') {
							//   this.setState({twfunc: "login"})
							//   this.setState({emptyemailmodal: true})
							//   this.setState({twtoken: credential.authorizationCode})
							//   this.setState({twtwitterid: credential.user})
							//   this.setState({emptyemailsocial: "apple"})
							// } else {
							Toast.show({
								text: res.errors,
								duration: 3000,
								type: "danger",
								textStyle: { textAlign: "center" }
							})
							// }
						} else {
							props.props.setAuthToken(res.auth_token);
							props.props.setAuthUser(res);
							agent.setToken(res.auth_token)
							AsyncStorage.setItem('@auth_token', res.auth_token)

							// props.props.navigation.navigate(Routes.HOME);
							props.props.navigation.dispatch(state => {
								return CommonActions.reset({
									routes: [
										{ name: Routes.HOME, params: { first_time: "true" } }
									],
									index: 0,
								});
							});
							// agent.setToken(res.auth_token)
						}
					})
			} else if (request_type = "signup") {
				agent.Auth.googlesignupnew(user.email, token, user.id, user)
					.then(res => {
						if (res.errors) {
							// if (res.code == 'open_email_popup') {
							//   this.setState({twfunc: "login"})
							//   this.setState({emptyemailmodal: true})
							//   this.setState({twtoken: credential.authorizationCode})
							//   this.setState({twtwitterid: credential.user})
							//   this.setState({emptyemailsocial: "apple"})
							// } else {
							Toast.show({
								text: res.errors,
								duration: 3000,
								type: "danger",
								textStyle: { textAlign: "center" }
							})
							// }
						} else {
							props.props.setAuthToken(res.auth_token);
							props.props.navigation.navigate(Routes.ACCOUNT_SETUP);
							agent.setToken(res.auth_token)
							AsyncStorage.setItem('@auth_token', res.auth_token)
							// agent.setToken(res.auth_token)
						}
					})
			}
		} catch (error) {
			console.error(
				"Failed to fetch user data:",
				response.status,
				response.statusText
			);
		}
	};

	const signInWithGoogle = async () => {
		if (response && response.type != "dismiss")
			getUserInfo(response.authentication.accessToken);
	};

	//add it to a useEffect with response as a dependency 
	useEffect(() => {
		signInWithGoogle();
	}, [response]);

	return (
		// <Button disabled={!request} style={styles.sqrbuttonSmall} onPress={() => { promptAsync() }}><Text style={styles.buttonText}>Sign in with Facebook</Text></Button>
		<Button style={styles.sqrbuttonSmall} onPress={() => { promptAsync() }}><Text style={styles.text}>{request_type == "signup" ? "Continue with Google" : "Sign in with Google"}</Text></Button>
	);
}

const styles = StyleSheet.create({
	sqrbuttonSmall: {
		alignSelf: "center",
		justifyContent: "center",
		backgroundColor: "rgba(255,255,255,1)",
		width: (DetectDeviceService.isTablet ? 375 : 225),
		height: 33,
	},
	buttonText: {
		// color: "rgba(33,72,155,1)",
		color: "#000",
		fontSize: 13,
		fontWeight: "500"
	},
	text: {
		color: DetectDeviceService.isTablet ? "rgb(87, 87, 87)" : "#FFF",
	},
})

export default GoogleWebLogin;
