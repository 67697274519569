import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
} from "react-native";
import Svg, { Ellipse } from "react-native-svg";
import {
  Container,
  ListItem,
  Header,
  List,
  Text
} from "native-base";
import FONT from '../styles/Font'
import NestedBillComment from '../components/NestedBillComment';

const BillComment = props => {
  const comment = props.comment;

  return (
        <View key={comment.id} style={styles.container}>
          <View style={styles.imageRowRow}>
            <View style={styles.imageRow}>
              <View style={styles.nameColumn}>
                <Text style={styles.name}>{comment.user_name}</Text>
                <View style={styles.ellipseRow}>
                  <Svg viewBox="0 0 12.00 12.14" style={styles.ellipse}>
                    <Ellipse
                      strokeWidth={1}
                      fill="rgba(126,211,33,1)"
                      stroke="rgba(230, 230, 230,1)"
                      cx={6}
                      cy={6}
                      rx={6}
                      ry={6}
                    ></Ellipse>
                  </Svg>
                  <Text style={styles.vote}>{comment.user_vote}</Text>
                </View>
              </View>
            </View>
            <View style={styles.imageRowFiller}></View>
            <Text style={styles.timestamp}>05/12/2020 10:00:00 AM IST</Text>
          </View>
          {comment.flag_comment != true && <Text style={styles.comment}>{comment.comment_text}</Text>}
          {comment.flag_comment == true && <Text style={[styles.comment, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
          <View style={styles.commentCountRow}>
            <Text style={styles.commentCount}>{comment.others_reply.length} Comments</Text>
            <Text style={styles.likeCount}>{comment.reply_count} Likes</Text>
            <Text style={styles.like}>Like</Text>
            <Text style={styles.reply}>Reply</Text>
          </View>
          <List>
            {
              comment.others_reply.map(comment => {
                return (
                  <NestedBillComment comment={comment} />
                );
              })
            }
          </List>
        </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: '#808080',
    borderBottomWidth: 1,
    paddingBottom: 10
  },
  image: {
    width: 39,
    height: 37,
    borderRadius: 100,
    borderColor: "#000000",
    borderWidth: 0,
    overflow: "hidden"
  },
  name: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
  },
  ellipse: {
    width: 12,
    height: 12
  },
  vote: {
    width: 115,
    height: 15,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 9
  },
  ellipseRow: {
    height: 15,
    flexDirection: "row",
    marginTop: 1
  },
  nameColumn: {
    width: 136,
    marginLeft: 19,
    marginTop: 4,
    marginBottom: 3
  },
  imageRow: {
    flexDirection: "row",
    marginTop: 17
  },
  imageRowFiller: {
    flex: 1,
    flexDirection: "row"
  },
  timestamp: {
    width: 125,
    height: 20,
    color: "#121212",
    fontSize: 9,
    //fontFamily: FONT.IOS.Regular,
  },
  imageRowRow: {
    height: 54,
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 18
  },
  comment: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
    marginTop: 13,
    marginLeft: 18,
    marginRight: -18
  },
  commentCount: {
    width: 79,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
  },
  likeCount: {
    width: 69,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 14
  },
  like: {
    width: 62,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 38
  },
  reply: {
    width: 41,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 17
  },
  commentCountRow: {
    height: 13,
    flexDirection: "row",
    marginTop: 17,
    marginLeft: 18,
    marginRight: 22
  }
});

export default BillComment;
