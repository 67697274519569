import * as React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import {
  Button,
  Icon
} from 'native-base';
import { Video, ResizeMode } from 'expo-av';

import DetectDeviceService from '../lib/devicedetection';

export default function VideoPlayer(props) {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const uri = props.uri;

  let _onPlaybackStatusUpdate = playbackStatus => {
    setStatus(() => playbackStatus)
    // if (playbackStatus.durationMillis === playbackStatus.positionMillis) {
      if (playbackStatus.didJustFinish) {
        video.current.setPositionAsync(0)
        video.current.pauseAsync()
      }
  };

  return (
    <View style={styles.container}>
      <Video
        ref={video}
        style={styles.video}
        source={{
          uri: uri,
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        isLooping={false}
        onPlaybackStatusUpdate={status => _onPlaybackStatusUpdate(status)}
      />
      {/* { Platform.OS != "android" && <View style={styles.buttons}>
        <Button
          title={status.isPlaying ? 'Pause' : 'Play'}
          onPress={() =>
            status.isPlaying ? video.current.pauseAsync() : video.current.playAsync()
          }
        />
      </View> } */}
      { Platform.OS == "android" && !status.isPlaying && <View style={styles.buttons}>
        <Button style={{height: "100%", width: "100%"}} transparent onPress={() => {(status.isPlaying ? video.current.pauseAsync() : video.current.playAsync())}}>
          <Icon name='playcircleo' type="AntDesign" style={{fontSize: 55, color: '#ffffff'}} />
        </Button>
      </View> }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: (DetectDeviceService.isTablet ? 'flex-start' : 'flex-start'),
    // backgroundColor: '#ecf0f1',
  },
  video: {
    alignSelf: (DetectDeviceService.isTablet ? 'flex-start' : 'flex-start'),
    width: "90%",
    height:  DetectDeviceService.isTablet ? 360 : 200,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: "absolute",
    top:  (DetectDeviceService.isTablet ? "40%" : "30%"),
    left: (DetectDeviceService.isTablet ? "40%" : "33%"),
    backgroundColor: "#000000",
    height: 85,
    width: 85,
    opacity: 0.5,
    borderRadius: 10
  },
});
