import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
  Platform
} from "react-native";
import Svg, { Ellipse } from "react-native-svg";
import {
  Container,
  ListItem,
  Header,
  List,
  Text,
  Button,
  Left, Thumbnail, Right, Body, Badge, Icon
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import { API_URL } from "../constants/server";
import { connect } from 'react-redux';

import dayjs from 'dayjs';

import agent from '../agent'

import {
  updateOpinions,
  updateOpinion
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

import FONT from '../styles/Font'
import NestedBillComment from './NestedBillComment';
import NestedBillOpinion from './NestedBillOpinion';
import { TouchableOpacity, TouchableWithoutFeedback } from "react-native-gesture-handler";

const mapStateToProps = state => ({ ...state.bill, ...state.device, ...state.auth });

const mapDispatchToProps = dispatch => ({
  updateOpinions: (comment, heading, index) => dispatch(updateOpinions(comment, heading, index)),
  updateOpinion: (voteId, vote) => dispatch(updateOpinion(voteId, vote)),
});

class BillOpinion extends React.Component {

  constructor(props) {
    super(props);
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  top2firstLevelComments = () => {
    return this.props.comment.others_reply.filter((c) => {
      return this.props.comment.comment_id == c.comment_to_id
    }).slice(0, 2)
  }

  firstLevelComments = () => {
    return this.props.comment.others_reply.filter((c) => {
      return this.props.comment.comment_id == c.comment_to_id
    })
  }

  groupedTop2Comments = () => {
    let comms = []
    let top = this.props.comment.others_reply.filter((c) => {
      return this.props.comment.comment_id == c.comment_to_id
    })
    top.slice(0, 2).forEach((topc) => {
      comms.push(topc)
      let cs = this.props.comment.others_reply.filter((c) => {
        return c.comment_to_id == topc.comment_id
      })
      comms.push(cs.slice(0, 2))
    })
    comms = [].concat.apply([], comms);
    return comms
  }

  groupedComments = () => {
    let comms = []
    let top = this.props.comment.others_reply.filter((c) => {
      return this.props.comment.comment_id == c.comment_to_id
    })
    top.forEach((topc) => {
      comms.push(topc)
      let cs = this.props.comment.others_reply.filter((c) => {
        return c.comment_to_id == topc.comment_id
      })
      comms.push(cs)
    })
    comms = [].concat.apply([], comms);
    return comms
  }

  voteLike = () => {
    let newComment = this.props.comment;
    
    if (this.props.comment.user_liked == "yes") {
      newComment.user_liked = "no"
      newComment.reply_count = newComment.reply_count - 1
    } else {
      newComment.user_liked = "yes"
      newComment.reply_count = newComment.reply_count + 1
    }

    let self = this;
    agent.Bill.like_comment(this.props.comment.comment_id)
    .then(res => {
      self.props.updateOpinion(this.props.comment.vote_id, newComment)
    })
    // this.props.updateOpinions(newComment, this.props.heading, this.props.commentIndex)
  }

  followUser = () => {
    let newComment = this.props.comment;
    newComment.user_followed = true
    let userId = this.props.comment.user_id;
    let self = this;
    agent.Connect.follow(userId)
    .then(res => {
      self.props.updateOpinion(this.props.comment.vote_id, newComment)
      this.props.comment.user_followed = true
    })
  }

  unfollowUser = () => {
    let newComment = this.props.comment;
    newComment.user_followed = true
    let userId = this.props.comment.user_id;
    let self = this;
    agent.Connect.unfollow(userId)
    .then(res => {
      self.props.updateOpinion(this.props.comment.vote_id, newComment)
      this.props.comment.user_followed = false
    })
  }

  voteCommentPopup = () => {
    this.props.openReplyModal(this.props.comment, this.props.heading, this.props.commentIndex)
  }

  flagCommentPopup = () => {
    this.props.openFlagModal(this.props.comment.comment_id, this.props.heading, this.props.commentIndex)
  }

  voteColor = () => {
    if(this.props.comment.user_vote == "Strong support") {
      return "#54945D"
    } else if(this.props.comment.user_vote == "Support") {
      return "#54945D"
    } else if(this.props.comment.user_vote == "Neutral") {
      return "#D29824"      
    } else if(this.props.comment.user_vote == "Strong oppose") {
      return "#cd5f5f"      
    } else if(this.props.comment.user_vote == "Oppose") {
      return "#cd5f5f"      
    }
  }

  render() {
    return (
      <Grid key={this.props.comment.id} style={[{ paddingLeft: (DetectDeviceService.isTablet ? 60 : 20), paddingRight: 10, paddingTop: 10 }, styles.container]}>
        <Grid>
          {/* <Row>
            <Text note style={{ color: "#797979", width: "100%", fontSize: 10, textAlign: "right" }}>{dayjs(this.props.comment.comment_created_at).format('MM/DD/YYYY hh:mm A')}</Text>
          </Row> */}
          <Row style={{marginTop: 10}}>
            <Col style={{width: (DetectDeviceService.isTablet ? 80 : 67)}}>
              <Thumbnail source={{ uri: this.avatarUrl(this.props.comment.user_avatar) }} />
            </Col>
            <Col size={3} style={{ marginTop: 0 }}>
              <Row>
                <Text style={{ marginBottom: 2, color: "#4589c6" }}>{this.props.comment.user_name}, {this.props.comment.user_state}</Text>
                <Right>
                  {this.props.comment.user_followed == false && <Text style={{borderColor: "#2b5eaa", borderRadius: 10, padding: 5, borderWidth: 1}} onPress={() => {this.followUser()}}>Follow</Text>}
                  {this.props.comment.user_followed == true && <Text style={{color: "#808080"}}>Following</Text>}
                </Right>
              </Row>
              <Row style={styles.vote}>
                {/* <Svg viewBox="0 0 12.00 12.14" style={styles.ellipse}>
                  <Ellipse
                    strokeWidth={1}
                    fill={this.voteColor()}
                    cx={6}
                    cy={6}
                    rx={6}
                    ry={6}
                  ></Ellipse>
                </Svg> */}
                <Badge style={{marginTop: 3, width: 12, height: 12, backgroundColor: this.voteColor()}}></Badge>
                <Text style={{ marginLeft: 5, color: "#797979", fontSize: 15 }}>{this.props.comment.user_vote == "Neutral" ? "Not sure" : this.props.comment.user_vote}</Text>
              </Row>
              <Text note style={{ color: "#797979", width: "100%", fontSize: 10, marginTop: 5 }}>{dayjs(this.props.comment.comment_created_at).format('MM/DD/YYYY hh:mm A')}</Text>
            </Col>
          </Row>
        </Grid>
        {this.props.comment.comment_text != "" && <Row style={{ marginTop: 10, marginLeft: 0 }}>
          {this.props.comment.flag_comment != true && <Text style={styles.textColor}>{this.props.comment.comment_text}</Text>}
          {this.props.comment.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
        </Row>}
        <Row style={{marginBottom: 10, marginLeft: (DetectDeviceService.isTablet ? 80 : 62)}}>
          {this.props.heading != "My Opinion" && <Col size={3}>
            <View style={{marginTop: 10}}>
              <TouchableOpacity onPress={this.voteLike} style={this.props.comment.user_liked == "yes" ? styles.selectedButton : styles.unselectedButton}>
                {/* <Button transparent onPress={this.voteLike}> */}
                  <Text style={{ color: "#4589c6", fontSize: 15 }}>Like ({this.props.comment.reply_count})</Text>
                {/* </Button> */}
              </TouchableOpacity>
            </View>
          </Col>}
          {this.props.heading != "My Opinion" && <Col style={{alignItems: "flex-start", width: 90, paddingLeft: (DetectDeviceService.isTablet ? 0 : 0), marginRight: (DetectDeviceService.isTablet ? 13 : 0)}}>
              <Row>
                <Col style={{marginTop: 7, marginRight: 10}}>
                  <TouchableOpacity style={{ justifyContent: "flex-start", alignItems: "flex-start" }} onPress={() => this.voteCommentPopup()}>
                    <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 25}} />
                    <Text style={styles.unselectedText}>({this.firstLevelComments().length})</Text>
                  </TouchableOpacity>
                </Col>
                {this.props.user.user_id != this.props.comment.user_id && !this.props.comment.flag_comment && <Col style={{marginTop: 10, marginRight: 10}}>
                  <TouchableOpacity style={{ justifyContent: "flex-start", alignItems: "flex-start" }} onPress={() => this.flagCommentPopup()}>
                    <Icon name='flag-o' type="FontAwesome" style={{color: "red", fontSize: 22}} />
                  </TouchableOpacity>
                </Col>}
                {this.props.user.user_id != this.props.comment.user_id && <Col style={{marginTop: 10}}>
                  <TouchableOpacity style={{ justifyContent: "flex-start", alignItems: "flex-start" }} onPress={() => this.props.openBlockUser({blockuserid: this.props.comment.user_id})}>
                    <Icon name='block' type="Entypo" style={{color: "red", fontSize: 22}} />
                  </TouchableOpacity>
                </Col>}
              </Row>
            </Col>}
          {this.props.heading == "My Opinion" && <Col size={3}>
            <View style={{marginTop: 10}}>
              {/* <TouchableWithoutFeedback> */}
                {/* <Button transparent onPress={this.voteLike}> */}
                  <Text style={{ color: "#808080", fontSize: 15 }}>Like ({this.props.comment.reply_count})</Text>
                {/* </Button> */}
              {/* </TouchableWithoutFeedback> */}
            </View>
          </Col>}
          {/* <Col size={1}></Col> */}
          {(this.props.heading == "My Opinion" && this.props.allComments != true) && <Col size={3}>
            <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.props.openEditModal(this.props.pageObject.id, this.props.comment.comment_text, this.props.comment.vote_id)}>
            {/* <Button transparent onPress={() => this.props.openEditModal(this.props.pageObject.id, this.props.comment.comment_text, this.props.comment.vote_id)}> */}
              <Text style={{ color: "#4589c6", fontSize: 15 }}>Edit</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
          </Col>}
          {(this.props.heading == "My Opinion" && this.props.allComments != true) && <Col size={3}>
            <View style={{marginTop: 10}} onPress={() => this.props.removeOpinion(this.props.pageObject.id, this.props.comment.vote_id)}>
            <TouchableOpacity onPress={() => this.props.removeOpinion(this.props.pageObject.id, this.props.comment.vote_id)}>
            {/* <Button transparent onPress={() => this.props.removeOpinion(this.props.pageObject.id, this.props.comment.vote_id)}> */}
              <Text style={{ color: "#4589c6", fontSize: 15 }}>Delete</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
          </Col>}
        </Row>
        <View>
          {
            this.props.allComments != true && this.groupedTop2Comments().map((comm, index) => {
              return (
                <NestedBillOpinion comment={comm} 
                                  commentIndex={this.props.commentIndex} 
                                  nestedCommentIndex={index} 
                                  parent_comment={this.props.comment} 
                                  heading={this.props.heading}
                                  gotoVoteDetails={this.props.gotoVoteDetails} 
                                  openReplyModal={this.props.openReplyModal} 
                                  openFlagModal={this.props.openFlagModal} 
                />
              );
            })
          }
          {
            this.props.allComments == true && this.groupedComments().map((comm, index) => {
              return (
                <NestedBillOpinion comment={comm} 
                                  commentIndex={this.props.commentIndex} 
                                  nestedCommentIndex={index} 
                                  parent_comment={this.props.comment} 
                                  heading={this.props.heading}
                                  gotoVoteDetails={this.props.gotoVoteDetails} 
                                  openReplyModal={this.props.openReplyModal} 
                                  openFlagModal={this.props.openFlagModal} 
                />
              );
            })
          }
        </View>
        {this.props.allComments != true && this.firstLevelComments().length > 2 && <Row style={{ alignItems: "center", justifyContent: "center" }}>
          {/* <Col> */}
          <Button transparent onPress={() => this.props.gotoVoteDetails(this.props.comment, this.props.commentIndex, this.props.heading)}>
            <Text style={{ fontSize: 13 }}>Show more comments</Text>
          </Button>
          {/* </Col> */}
        </Row>}
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: '#808080',
    borderBottomWidth: 1
  },
  textColor: {
    color: "rgb(33, 33, 33)"
  },
  image: {
    width: 39,
    height: 37,
    borderRadius: 100,
    borderColor: "#000000",
    borderWidth: 0,
    overflow: "hidden"
  },
  name: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
  },
  ellipse: {
    width: 12,
    height: 12,
    marginRight: 5
  },
  vote: {
    width: 140,
    height: 20,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
  },
  ellipseRow: {
    height: 15,
    flexDirection: "row",
    marginTop: 1
  },
  nameColumn: {
    width: 136,
    marginLeft: 19,
    marginTop: 4,
    marginBottom: 3
  },
  imageRow: {
    flexDirection: "row",
    marginTop: 17
  },
  imageRowFiller: {
    flex: 1,
    flexDirection: "row"
  },
  timestamp: {
    width: 125,
    height: 20,
    color: "#121212",
    fontSize: 9,
    //fontFamily: FONT.IOS.Regular,
  },
  imageRowRow: {
    height: 54,
    flexDirection: "row",
    marginTop: 10,
    marginLeft: 18
  },
  comment: {
    color: "#121212",
    //fontFamily: FONT.IOS.Regular,
    marginTop: 13,
    marginLeft: 18,
    marginRight: -18
  },
  commentCount: {
    width: 79,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
  },
  likeCount: {
    width: 69,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 14
  },
  like: {
    width: 62,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 38
  },
  reply: {
    width: 41,
    height: 13,
    color: "#121212",
    fontSize: 12,
    //fontFamily: FONT.IOS.Regular,
    marginLeft: 17
  },
  commentCountRow: {
    height: 13,
    flexDirection: "row",
    marginTop: 17,
    marginLeft: 18,
    marginRight: 22
  },
  selectedButton: {
    // backgroundColor: "blue",
    padding: 5, 
    borderRadius: 10,
    borderColor: "#4589c6",
    borderWidth: 1,
    // marginLeft: 10,
    marginRight: 10,
    color: "#fff",
    width: 70
  },
  unselectedButton: {
    padding: 5, 
    borderRadius: 10,
    // marginLeft: 10,
    marginRight: 10,
  },
  selectedText: {
    color: "#fff",
    fontSize: 13
  },
  unselectedText: {
    color: "#4589c6",
    fontSize: 13
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillOpinion);