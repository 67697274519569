import React, { Component } from "react";
import {
  StyleSheet, ScrollView, View,
  Dimensions, Platform,
  Image, TouchableOpacity, ImageBackground
} from "react-native";
import { default as ScalableImage } from 'react-native-scalable-image';
import {
  Container,
  Badge,
  Text
} from 'native-base';

import ConnectUser from "../components/ConnectUser";
import agent from '../agent'
import { connect } from 'react-redux';

import { API_URL } from "../constants/server";

import * as ScreenOrientation from 'expo-screen-orientation';
import * as Notifications from 'expo-notifications';

import DetectDeviceService from '../lib/devicedetection';

import {
  setHome,
} from '../ducks/home';
import {
  setBillId,
  setListType
} from '../ducks/bill';

import { Routes } from '../navigation/routes';
import Carousel from 'react-native-snap-carousel';

import { Carousel as WebCarousel } from '@trendyol-js/react-carousel';

import { Col, Row, Grid } from "../lib/easy-grid";

var _ = require('lodash');

const mapStateToProps = state => ({ ...state.home });

const mapDispatchToProps = dispatch => ({
  setHome: (data) => dispatch(setHome(data)),
  setBillId: (id) => dispatch(setBillId(id)),
  setListType: (list) => dispatch(setListType(list)),
});

const IS_IOS = Platform.OS === 'ios';
let viewportWidth, viewportHeight;
console.log("*(*(*(*(*")
console.log(Dimensions.get('window'))
if (Dimensions.get('window').width < Dimensions.get('window').height) {
  if (DetectDeviceService.isTablet) {
    viewportWidth = Dimensions.get('window').height;
    viewportHeight = Dimensions.get('window').width;
  } else {
    viewportWidth = Dimensions.get('window').width;
    viewportHeight = Dimensions.get('window').height;
  }
} else {
  if (DetectDeviceService.isTablet) {
    viewportWidth = Dimensions.get('window').width;
    viewportHeight = Dimensions.get('window').height;
  } else {
    viewportWidth = Dimensions.get('window').width;
    viewportHeight = Dimensions.get('window').height;
  }
}
const itemHorizontalMargin = wp(2);

const slideWidth = (DetectDeviceService.isTab ? wp(26) : DetectDeviceService.isTablet ? wp(20) : wp(70));
const slideHeight = viewportHeight * (DetectDeviceService.isTablet ? 0.35 : 0.30);

const sliderWidth = viewportWidth;
const itemWidth = slideWidth + itemHorizontalMargin * 2;

function wp(percentage) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}

class LandingScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orientation: ""
    }
  }

  componentDidMount() {
    // Notifications.addNotificationResponseReceivedListener(this._handleNotification);

    agent.Home.data()
      .then(res => {
        this.props.setHome(res)
      })
  }

  truncateText = (text, n) => {
    if (text.length > n) {
      return text.slice(0, n) + "..."
    } else {
      return text
    }
  }

  gotoDetail = (id, cardtype, category) => {
    if (cardtype == "Bill") {
      this.props.setBillId(id);
      this.props.setListType({ type: category, url: "/api/v1/bills" })
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: id
      });
      return false
    } else if (cardtype == "Initiative") {
      this.props.setBillId(id);
      this.props.setListType({ type: "initiative", url: "/api/v1/initiatives" })
      this.props.navigation.navigate(Routes.INITIATIVE_DETAILS, {
        bill_id: id
      });
      return false
    }
  };

  cardType = (item) => {
    let ctype;
    if (item.cardtype == "Bill") {
      if (item.category == "fed") {
        ctype = "Federal Bill"
      } else if (item.category == "state") {
        ctype = "State Bill"
      } else if (item.category == "local") {
        ctype = "Local Bill"
      } else if (item.category == "eo") {
        ctype = "Exec Order"
      }
    } else {
      ctype = "Initiative"
    }
    return ctype
  }

  _renderItem = ({ item, index }) => {
    return (
      <View key={item.id} style={styles.slideInnerContainer}>
        <View style={styles.shadow} />
        <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }} style={styles.imageContainer}>
          <ScalableImage width={slideWidth} style={styles.image} source={{ uri: (API_URL + item.url).replace("//", "/") }} />
        </ScrollView>
      </View>
    );
  }

  _renderItemBill = ({ item, index }) => {
    return (
      <TouchableOpacity
        key={item.id}
        activeOpacity={1}
        style={[styles.slideInnerContainer, { height: slideHeight * (DetectDeviceService.isTablet ? 0.9 : 1.1) }]}
        onPress={() => { this.gotoDetail(item.id, item.cardtype, item.category) }}
      >
        <View style={styles.shadow} />
        {item.image && <View style={[styles.imageContainer, { flex: 6, overflow: "hidden" }]}>
          <Image width={slideWidth} style={[styles.imageAb, { position: "absolute", top: -10 }]} source={{ uri: (API_URL + item.image).replace(/([^:]\/)\/+/g, "$1") }} />
          {item.cardtype == "Bill" ?
            <Badge info style={[styles.cardtype, { position: "absolute", right: 10, top: -10 }]}>
              <Text
                style={[styles.subtitle]}
              >
                {this.cardType(item)}
              </Text>
            </Badge> :
            <Badge warning style={[styles.cardtype, { position: "absolute", right: 10, top: -10 }]}>
              <Text
                style={[styles.subtitle]}
              >
                {this.cardType(item)}
              </Text>
            </Badge>
          }
          {/* <View style={[styles.radiusMask]} /> */}
        </View>}
        <View style={[styles.textContainer]}>
          {item.image == null || item.image == undefined ? (item.cardtype == "Bill" ?
            <Badge info style={[styles.cardtype, { position: "absolute", right: 10, top: -10 }]}>
              <Text
                style={[styles.subtitle]}
              >
                {this.cardType(item)}
              </Text>
            </Badge> :
            <Badge warning style={[styles.cardtype, { position: "absolute", right: 10, top: -10 }]}>
              <Text
                style={[styles.subtitle]}
              >
                {this.cardType(item)}
              </Text>
            </Badge>
          ) : null}
          <Text
            style={[styles.title]}
            numberOfLines={3}
          >
            {item.title}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }


  homeBottomCards = () => {
    var billsType = _.map(this.props.cards.Bills, function (element) {
      return _.extend({}, element, { cardtype: "Bill" });
    });
    var initiativesType = _.map(this.props.cards.Initiatives, function (element) {
      return _.extend({}, element, { cardtype: "Initiative" });
    });
    var cards = _.concat(billsType, initiativesType)
    return _.orderBy(cards, ['introduced_date'], ['desc']);
  }

  render() {
    return (
      <Container style={styles.container}>
        <ImageBackground source={require('../assets/monument_portrait_new.png')} style={styles.backImage}>
          {Platform.OS != "web" && <Carousel
            layout={'default'}
            ref={(c) => { this._carousel = c; }}
            data={this.props.cards.graphs}
            renderItem={this._renderItem}
            sliderWidth={sliderWidth}
            itemWidth={itemWidth}
            inactiveSlideScale={0.94}
            inactiveSlideOpacity={0.7}
            firstItem={2}
            containerCustomStyle={styles.slider}
            contentContainerCustomStyle={styles.sliderContentContainer}
            loop={true}
            autoplay={true}
            autoplayDelay={500}
            autoplayInterval={3000}
          />}
          {Platform.OS != "web" && <Carousel
            layout={'default'}
            ref={(c) => { this._carousel = c; }}
            data={this.homeBottomCards()}
            renderItem={this._renderItemBill}
            sliderWidth={sliderWidth}
            itemWidth={itemWidth}
            inactiveSlideScale={0.94}
            inactiveSlideOpacity={0.7}
            // firstItem={1}
            containerCustomStyle={styles.slider2}
            contentContainerCustomStyle={styles.sliderContentContainer}
            loop={true}
          // autoplay={true}
          />}

          <View style={{ marginTop: DetectDeviceService.isTablet ? 100 : (viewportHeight * 0.1) }}>
            {Platform.OS == "web" && <WebCarousel show={(viewportWidth / (2 * slideWidth).toFixed(2))} slide={1} key={this.props.cards.graphs} swiping={true}
              leftArrow={<Image style={{ height: 36, width: 20, marginLeft: (DetectDeviceService.isTablet ? 30 : 5), marginRight: (DetectDeviceService.isTablet ? 30 : 5), marginTop: 80 }} source="https://www.townhallusa.com/assets/chevron-left.png"></Image>}
              rightArrow={<Image style={{ height: 36, width: 20, marginRight: (DetectDeviceService.isTablet ? 30 : 5), marginLeft: (DetectDeviceService.isTablet ? 30 : 5), marginTop: 80 }} source="https://www.townhallusa.com/assets/chevron-right.png"></Image>}
            >
              {this.props.cards.graphs.map((item) => {
                return <div style={{ backgroundColor: "#fff", margin: "0 25px", height: DetectDeviceService.isTablet ? 210 : 170, width: slideWidth, overflow: "scroll", borderRadius: 15, whiteSpace: "nowrap", position: "relative" }}>
                  <span style={{ display: "inline-block", height: "100%", verticalAlign: "middle" }}></span>
                  <img style={{ verticalAlign: "middle" }} width={slideWidth} src={(API_URL + item.url)}></img>
                </div>
              })}
            </WebCarousel>}
          </View>
          <View style={{ marginTop: DetectDeviceService.isTablet ? 100 : (viewportHeight * 0.1) }}>
            {Platform.OS == "web" && <WebCarousel show={(viewportWidth / (2 * slideWidth).toFixed(2))} slide={1} key={this.props.cards.graphs} swiping={true}
              leftArrow={<Image style={{ height: 36, width: 20, marginLeft: (DetectDeviceService.isTablet ? 30 : 5), marginRight: (DetectDeviceService.isTablet ? 30 : 5), marginTop: 80 }} source="https://www.townhallusa.com/assets/chevron-left.png"></Image>}
              rightArrow={<Image style={{ height: 36, width: 20, marginRight: (DetectDeviceService.isTablet ? 30 : 5), marginLeft: (DetectDeviceService.isTablet ? 30 : 5), marginTop: 80 }} source="https://www.townhallusa.com/assets/chevron-right.png"></Image>}
            >
              {this.homeBottomCards().map((item) => {
                return <div style={{ backgroundColor: "#000", opacity: "0.8", margin: "0 25px", height: DetectDeviceService.isTablet ? 275 : 225, width: slideWidth, backgroundColor: "#fff", overflow: "scroll", borderRadius: 15, position: "relative" }} onClick={() => { this.gotoDetail(item.id, item.cardtype, item.category) }}>
                  <div style={{ backgroundColor: (item.cardtype == "Bill" ? "rgb(98, 177, 246)" : "rgb(240, 173, 78)"), padding: "5px 12px", fontSize: 14, fontWeight: "bold", color: "#fff", borderRadius: 10, position: "absolute", right: 10, top: 10 }}>{this.cardType(item)}</div>
                  {item.image && <div style={{ height: (DetectDeviceService.isTablet ? 207 : 155), width: slideWidth, overflow: "hidden" }}>
                    <img width={slideWidth} src={(API_URL + item.image).replace(/([^:]\/)\/+/g, "$1")}></img>
                  </div>}
                  {item.image && <div style={{ width: "95%", padding: "5px 10px", margin: "0 10", backgroundColor: "#fff" }}>
                    <span style={{ padding: 0 }}>{this.truncateText(item.title, 100)}</span>
                  </div>}
                  {!item.image && <div style={{ width: "87%", padding: 10, margin: "35% 5%", bottom: 0, position: "absolute", backgroundColor: "#fff" }}>
                    <span style={{ padding: 0 }}>{item.title}</span>
                  </div>}
                </div>
              })}
            </WebCarousel>}
          </View>
        </ImageBackground>
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
  slidec: {
    flex: 1,
  },
  slideContainer: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slide1: {
    backgroundColor: '#fff',
    height: 200,
    // width: 300
  },
  slide2: {
    backgroundColor: 'rgba(20,200,20,0.3)',
  },
  slide3: {
    backgroundColor: 'rgba(200,20,20,0.3)',
  },
  sliderContentContainer: {
    marginTop: DetectDeviceService.isTablet ? 0 : 40
  },
  backImage: {
    flex: 1,
    resizeMode: "cover",
    // justifyContent: "center"
  },
  tabHeading: {
    color: "rgba(255,255,255,1)",
    textTransform: "uppercase",
  },
  imageAb: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
    borderRadius: IS_IOS ? 8 : 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    // margin: 20
  },
  image: {
    //...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
    borderRadius: IS_IOS ? 8 : 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    // margin: 20
  },
  imageContainer: {
    //flex: 1,
    marginBottom: IS_IOS ? 0 : -1, // Prevent a random Android rendering issue
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  darkBackground: {
    backgroundColor: '#000',
  },
  slideInnerContainer: {
    opacity: 0.8,
    width: itemWidth,
    height: slideHeight * (DetectDeviceService.isTablet ? 0.8 : 0.9),
    paddingHorizontal: itemHorizontalMargin,
    paddingBottom: 18 // needed for shadow
  },
  slider: {
    overflow: "visible",
    marginTop: 40,
    marginBottom: 60,
    flexGrow: 0
  },
  slider2: {
    overflow: "visible",
    // flexGrow: 0
    // marginBottom: 90,
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: itemHorizontalMargin,
    right: itemHorizontalMargin,
    bottom: 18,
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 10 },
    shadowRadius: 10,
    borderRadius: 8
  },
  radiusMask: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 8,
    backgroundColor: 'white'
  },
  textContainer: {
    flex: 3,
    justifyContent: 'center',
    // paddingTop: 12,
    // paddingBottom: 20,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  },
  subtitle: {
    marginTop: 0,
    marginRight: 5,
    marginLeft: 5,
    color: "#ffffff",
    fontSize: 13,
    fontWeight: "bold"
  },
  cardtype: {
    marginTop: 15,
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LandingScreen);
