import React from "react";
import {
   StyleSheet, View, Modal, TouchableOpacity, 
   Platform, Dimensions, KeyboardAvoidingView, FlatList } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Toast,
  List,
  Separator,
  Thumbnail, Spinner,
  Form, Item, Label, Input, Textarea
} from "native-base";

import { default as ScalableImage } from 'react-native-scalable-image';

import DetectDeviceService from '../lib/devicedetection';

import HeaderMenu from '../components/HeaderMenu';

import { Col, Row, Grid } from "../lib/easy-grid";

import dayjs from 'dayjs';

import * as Linking from 'expo-linking';

import { API_URL } from "../constants/server";

import { LinkPreview as WebLinkPreview} from '@dhaiwat10/react-link-preview';
import { LinkPreview } from '@flyerhq/react-native-link-preview'

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import VideoPlayer from '../components/VideoPlayer';

import BreadCrumb from "../components/BreadCrumb";

import { WebView } from 'react-native-webview';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../styles/Color';
import agent from '../agent';
import { connect } from 'react-redux';
import { Routes } from '../navigation/routes';

import {
	updateShare,
} from '../ducks/share';

import {
	setBillId,
	setListType,
} from '../ducks/bill';

const mapStateToProps = state => ({ ...state.share, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  updateShare: (shareId, share) => dispatch(updateShare(shareId, share)),
  setListType: (list) => dispatch(setListType(list)),
  setBillId: (id) => dispatch(setBillId(id)),
});

class ShareDetailsScreen extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        replymodal: false,
        modalopen: false,
        comment_text: "",
        newComment: {
          parent_comment: "",
          index: "",
          heading: ""
        },
        flag_share_comment: "",
        flagshareid: "",
        flagsharetype: "",
        shareDetails: {},
        sharecommentmodal: false,
        flagsharemodal: false,
        share_comment_text: "",
        comment_share_id: "",
        comment_share_parent_id: "",
        network: [],
        selectedItems: [],
        page: 1,
        action_loading: false,
        loading: false,
        replyVisible: {}
      }
  }

	componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.setState({loading: true})
      agent.Share.details(this.props.route.params.shareId)
      .then(res => {
        this.setState({ shareDetails: res.share})
        this.setState({loading: false})
      })
      agent.Connect.network()
      .then(res => {
        let rn = []
        res.friends_and_family.map(f => {
          let r = {
            ...f,
            icon: { uri: this.avatarUrl(f.avatar) }
          }
          rn.push(r)
        })
        this.setState({ network: rn })
        this.setState({selectedItems: rn.map(function (el) { return el.id; })});
      })
      //this.props.list[this.props.route.params.shareId]
    });
  }

  avatarUrl = (avatar) => {
    if (avatar == null || avatar == "") {
      return "https://www.townhallusa.com/assets/image_placeholder-212b00e4872c0176395b0cb6ec3c4024.png"
    } else {
      return (API_URL + avatar).replace(/([^:]\/)\/+/g, "$1")
    }
  }

  gotoVYPdetails = (share) => {
    if(share.sharable_type == "Bill")
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
    else if(share.sharable_type == "Initiative")
      this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
    this.props.setBillId(share.sharable_id);
    this.props.navigation.navigate(Routes.VOICE_YOUR_OPINION);
  }

  openDeleteShare = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Posting",
      modalaction: "delete_share",
      modaltext: "Are you sure you want to delete this posting?",
      modalpayload: payload
    })
  }

  openDeleteShareComment = (payload) => {
    this.setState({
      modalopen: true,
      modalheading: "Delete Comment",
      modalaction: "delete_comment",
      modaltext: "Are you sure you want to delete this comment?",
      modalpayload: payload
    })
  }

  gotoBillDetail = (share) => {  
    if(share.sharable_type == "Bill") {
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
      this.props.setBillId(share.sharable_id);
  		this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: share.sharable_id
      });
    } else if(share.sharable_type == "Initiative") {
      this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
      this.props.setBillId(share.sharable_id);
  		this.props.navigation.navigate(Routes.BILL_DETAILS, {
        bill_id: share.sharable_id
      });
    } 
  }

  reshare = () => {
    let new_share = {}
    new_share["share_text"] = this.state.new_share_text
    new_share["url"] = this.state.new_share_url
    if(this.state.new_share_parent_share_id != null)
      new_share["parent_share_id"] = this.state.new_share_parent_share_id
    if(this.state.network.length == this.state.selectedItems.length){
      // new_share["receiver_ids"] = this.state.selectedItems
      agent.Share.create(new_share)
      .then(res => {
        this.setState({ new_share_receiver_ids: "", new_share_url: ""})
        agent.Share.list(this.state.page)
        .then(res => {
          let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
          this.props.setShares(result)
          this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
          //this.setState({shares: res.shares})
        })
      })
    } else {
      // new_share["receiver_ids"] = this.state.selectedItems
      agent.Share.shareWith(new_share, this.state.selectedItems.join(","))
      .then(res => {
        this.setState({ new_share_text: "", selectedItems: [], new_share_url: ""})
        agent.Share.list(this.state.page)
        .then(res => {
          let result = res.shares.reduce((map, obj) => (map[obj.id] = obj, map), {}); 
          this.props.setShares(result)
          this.setState({ shareOrderIds: res.shares.map(s => ( s.id ))})
          //this.setState({shares: res.shares})
        })
      })
    }
  }

  postShare = () => {
    let new_share = {}
    new_share["share_text"] = this.state.new_share_text
    new_share["url"] = this.state.new_share_url
    if(this.state.new_share_receiver_ids != "")
      new_share["receiver_ids"] = this.state.new_share_receiver_ids
    agent.Share.create(new_share)
    .then(res => {
      this.setState({ new_share_text: "", new_share_receiver_ids: "", new_share_url: ""})
    })
  }
  
  shareCommentStore = () => {
    this.setState({action_loading: true})
    let new_comment = {};
    if(this.state.comment_share_parent_id != null)
      new_comment.parent_comment_id = this.state.comment_share_parent_id  
    new_comment.share_id = this.state.comment_share_id
    new_comment.comment_text = this.state.share_comment_text
    agent.Share.comment(new_comment)
    .then(res => {
      agent.Share.details(this.props.route.params.shareId)
      .then(res => {
        this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(this.state.shareDetails.id, share)
      })
      this.setState({ share_comment_text: "", comment_share_id: "" })
      this.setState({sharecommentmodal: false})
      this.setState({action_loading: false})
    })
  }

  shareLike = (shareId) => {
    agent.Share.like(shareId)
    .then(res => {
      agent.Share.details(this.props.route.params.shareId)
      .then(res => {
        this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(this.state.shareDetails.id, share)
        agent.Share.details(this.props.route.params.shareId)
        .then(res => {
          this.setState({ shareDetails: res.share})
        })
      })
    })
  }

  shareTruth = (shareId, val) => event => {
    agent.Share.truth(shareId, val)
    .then(res => {
      agent.Share.details(shareId)
      .then(res => {
        // this.setState({ shareDetails: res.share})
        let share = {
          ...res.share,
          // ["share_comments"]: res.share.share_comments.slice(0, 2)
        }
        this.props.updateShare(shareId, share)
        // this.setState({ share_comment_text: "", comment_share_id: "" })
        this.setState({ shareDetails: share})
      })
    })
  }

  openFlagShare = (share) => {
    this.setState({ flagsharemodal: true })
    this.setState({ flagshareid: share.shareId })
    this.setState({ flagsharetype: "share" })
  }

  openFlagShareComment = (share) => {
    this.setState({ flagsharemodal: true })
    this.setState({ flagshareid: share.shareId })
    this.setState({ flagsharetype: "share_comment" })
  }

  flagShare = () => {
    let share_id = this.state.flagshareid
    let comment = this.state.flag_share_comment

    if(this.state.flagsharetype == "share") {
      agent.Share.flag_share(share_id, comment)
        .then(res => {

          this.setState({ flagsharemodal: false });
          this.setState({ action_loading: false })

          Toast.show({
            text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })

        })
    } else {
      agent.Share.flag_share_comment(share_id, comment)
        .then(res => {

          this.setState({ flagsharemodal: false });
          this.setState({ action_loading: false })

          Toast.show({
            text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })

        })
    }
  }


  openReshare = (share) => {
    this.setState({ new_share_text: share.share_text })
    this.setState({ new_share_url: share.share_url })
    this.setState({ new_share_parent_share_id: share.id })
    this.setState({ reshareApi: true })
    this.SectionedMultiSelect._toggleSelector()
  }


  customFetcher = async (url) => {
    const response = await fetch(`https://www.townhallusa.com/v2?url=${url}`);
    const json = await response.json();
    let r = json.metadata;
    if(json.description == null)
      r.description = ""
    return r;
  };
  
  openCommentModal = (share_id, comment_id=null)  => event => {
    console.log("***********")
    this.setState({action_loading: false})
    if(comment_id != null)
      this.setState({ comment_share_parent_id: comment_id })
    this.setState({ comment_share_id: share_id })
    this.setState({sharecommentmodal: true})
  }


  openCommentBox = (share_id, comment_id=null) => {
    let replies = this.state.replyVisible

    if (comment_id == null) {
      if (replies[share_id] == undefined || (replies[share_id]["parent_reply"] == undefined || replies[share_id]["parent_reply"] == false)) {
        replies[share_id] = replies[share_id] == undefined ? {} : replies[share_id]
        replies[share_id]["parent_reply"] = true
      } else {
        replies[share_id] = replies[share_id] == undefined ? {} : replies[share_id]
        replies[share_id]["parent_reply"] = false
      }
     } else {
      if (replies[comment_id] == undefined || (replies[comment_id]["sub_reply"] == undefined || replies[comment_id]["sub_reply"] == false)) {
        replies[comment_id] = replies[comment_id] == undefined ? {} : replies[comment_id]
        replies[comment_id]["sub_reply"] = true
      } else {
        replies[comment_id] = replies[comment_id] == undefined ? {} : replies[comment_id]
        replies[comment_id]["sub_reply"] = false
      }       
     }
    this.setState({replyVisible: replies})
  }


  closeReplyModal = () => {
    this.setState({ replymodal: false})
  }

  voteComment = () => {
    // let newComment = this.props.comment;
    let newCommentReply = {
      parent_comment_id: this.state.newComment.parent_comment.comment_id,
      vote_id: this.state.newComment.parent_comment.vote_id,
      comment_text: this.state.comment_text,
      user_id: this.props.user.user_id
    }
    this.setState({ replymodal: false });
    agent.Bill.comment_opinion(newCommentReply)
    .then(res => {
      agent.Bill.voice_your_opinion(this.props.details.id)
        .then(res => {
          this.props.setOpinions(res);
          // this.props.gotoVoteDetails(this.props.comment, this.props.commentIndex, this.props.heading)
        })
    })
  }

  shareImageUrl = (uri) => {
    let image;
    if(DetectDeviceService.isTablet)
      image = (API_URL+ uri).replace("thumb", "medium").replace(/([^:]\/)\/+/g, "$1")
    else
      image = (API_URL+ uri).replace(/([^:]\/)\/+/g, "$1")
    return image
  }

  onSelectedItemsChange = (selectedItems) => {
    //let selectedIssuesObj = selectedItems.map((id) => { return this.props.issues[id] })
    //this.props.issues.map((obj) => selectedItems.includes(obj["id"]))
    //let selectedIds = selectedIssuesObj.map((obj) => { return obj.id })
    this.setState({ selectedItems:  selectedItems});
    //this.props.setBillsIssues({ selectedItems: selectedIssuesObj });
  };

  callFunction = () => {
    this.setState({action_loading: true})
    if(this.state.modalaction == "delete_share") {
      agent.Share.delete(this.state.modalpayload.shareId)
      .then(res => {
        let shareIds = this.state.shareOrderIds;
        shareIds = shareIds.filter(arrayItem => arrayItem !== this.state.modalpayload.shareId);
        this.setState({shareOrderIds: shareIds})
        this.setState({modalopen: false})
        this.setState({action_loading: false})
      })
    } else if(this.state.modalaction == "delete_comment") {
      agent.Share.delete_comment(this.state.modalpayload.commentId)
        .then(res => {
          agent.Share.details(this.state.modalpayload.shareId)
          .then(res => {
            // this.setState({ shareDetails: res.share})
            let share = {
              ...res.share,
              // ["share_comments"]: res.share.share_comments.slice(0, 2)
            }
            this.setState({shareDetails: share})
            this.props.updateShare(this.state.modalpayload.shareId, share)
            this.setState({modalopen: false})
            this.setState({action_loading: false})
            // this.setState({ share_comment_text: "", comment_share_id: "" })
          })
      })
    }
  }

  shareLink = (url) => {
    Linking.openURL(url);
    // this.props.onPress && this.props.onPress();
  };

  SelectOrRemoveAll = () =>
  this.SectionedMultiSelect && (
    <TouchableOpacity
      style={{
        justifyContent: 'center',
        height: 44,
        borderWidth: 0,
        paddingHorizontal: 10,
        backgroundColor: 'darkgrey',
        alignItems: 'center',
      }}
      onPress={
        this.state.selectedItems.length
          ? this.SectionedMultiSelect._removeAllItems
          : this.SectionedMultiSelect._selectAllItems
      }
    >
      <Text style={{ color: 'white', fontWeight: 'bold' }}>
        {this.state.selectedItems.length ? 'Remove' : 'Select'} all
      </Text>
    </TouchableOpacity>
  );

  shareCard = (share) => {
    return (share && <Grid key={share.id} style={[{ paddingLeft: (DetectDeviceService.isTablet ? 80 : 10), paddingRight: 5, paddingTop: 20, paddingBottom: 10 }, styles.container]}>
    <Row style={{width: DetectDeviceService.isTablet ? "83%" : "100%"}}>
      <Col style={{width: 80}}>
        <Thumbnail source={{ uri: this.avatarUrl(share.user_avatar) }} />
      </Col>
      <Col size={1} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
        <Row>
        <Col>
          <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {this.props.user.user_id == share.share_user_id ? "I shared" : share.share_user_name}
          </Text>
          <Text style={styles.vote}>
            <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(share.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
          </Text>
        </Col>
        </Row>
      </Col>
      <Col style={{alignItems: "flex-end"}}>
        {/* <Button iconLeft dark> */}
        <Row>
          <Col style={{marginTop: 10}}>
            {/* {(share.sharable_id == null) && <TouchableOpacity style={{ justifyContent: "flex-end", alignItems: "flex-end" }} onPress={() => this.openCommentBox(share.id)}>
              <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 25}} />
              <Text style={styles.unselectedText}>({share.comments_count})</Text>
            </TouchableOpacity>} */}
          </Col>
          <Col>
            {(this.props.user.user_id == share.share_user_id) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openDeleteShare({shareId: share.id}) }}>
                <Icon name='trash' style={{marginBottom: 3, marginRight: 5, fontSize: 22, color: '#d9534f'}} />
              </Button>}
              {(this.props.user.user_id != share.share_user_id && !share.flag_comment) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShare({shareId: share.id}) }}>
                <Icon name='flag-o' type="FontAwesome" style={{marginBottom: 3, marginRight: 5, fontSize: 22, color: 'red'}} />
              </Button>}
          </Col>
          <Col style={{marginLeft: (DetectDeviceService.isTablet ? 15 : 5)}}>
          {(this.props.user.user_id != share.share_user_id) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end"  }} transparent onPress={() => { this.openBlockUser({blockuserid: share.share_user_id}) }}>
                <Icon name='block' type="Entypo" style={{marginLeft: 0, marginBottom: 5, fontSize: 20, color: 'red'}} />
              </Button>}
          </Col>
        </Row>
        {/* </Button> */}
      </Col>
    </Row>
    {share.sharable_id != null && <Row style={{ marginTop: 10, marginLeft: (DetectDeviceService.isTablet ? 80 : 0), width: DetectDeviceService.isTablet ? "66%" : "90%", marginRight: (DetectDeviceService.isTablet ? 165 : 0)}}>
      <Text>{share.my_vote_on_share == "Neutral" ? "Not sure" : share.my_vote_on_share},
        <Text style={[styles.unselectedText, {marginLeft: 5, fontSize: 16}]} onPress={() => this.gotoBillDetail(share)}>
          {share.title_or_name}
        </Text>
      </Text> 
    </Row>}
    {share.attachment_type != null && <Row style={{alignSelf: (DetectDeviceService.isTablet ? "flex-start" : "flex-start"), width: (DetectDeviceService.isTablet ? "80%" : "100%"), marginTop: 20, marginLeft: (DetectDeviceService.isTablet ? 98 : 0)}}>
      {share.attachment_type == "image" && <ScalableImage style={{marginLeft: (DetectDeviceService.isTablet ? 28 : 0)}} width={DetectDeviceService.isTab ? 295 : DetectDeviceService.isTablet ? 450 : 315} resizeMode="cover" source={{uri: this.shareImageUrl(share.share_attachment)}} />}
      {share.attachment_type == "video" && <VideoPlayer uri={(API_URL+ share.share_attachment).replace(/([^:]\/)\/+/g, "$1")}/>}
    </Row>}
    <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0), marginRight: (DetectDeviceService.isTablet ? 165 : 0) }}>
      {share.flag_comment != true && <Text style={styles.textColor}>{share.share_text}</Text>}
      {share.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
    </Row>
    {/* {(share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) }}>
      <Text style={{color: "#808080"}} onPress={() => this.shareLink(share.share_url)}>{share.share_url}</Text>
    </Row>} */}
    {(Platform.OS == "web" && share.parent_share_id == null && share.share_url != "" && share.share_url != null) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 125 : 0) } } >
      <WebLinkPreview fetcher={this.customFetcher} url={share.share_url} width="80%" /> 
    </Row>}
    {(Platform.OS != "web" && (share.share_url != null && share.share_url != "")) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "75%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } >
      <LinkPreview containerStyle={{width: "100%", borderColor: '#d3d3d3', borderWidth: 0.4, borderRadius: 20, overflow: 'hidden',}} text={share.share_url} width="100%" /> 
    </Row>}
    {/* {(Platform.OS != "web" && share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } ><LinkPreview link={share.share_url} width="100%" /> </Row>} */}

    {share.parent_share_id != null && this.nestedShareCard(share.parent_share, share)}

    {/* 
    {share.parent_share_id != null && !share.parent_share_deleted && this.nestedShareCard(share.parent_share, share)}
    {share.parent_share_id != null && share.parent_share_deleted && this.nestedDeletedShareCard(share)} */}
    { share.parent_share_id == null && <View
      style={{
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: StyleSheet.hairlineWidth,
        marginLeft: (DetectDeviceService.isTablet ? 80 : 0),
        marginTop: 10,
        width: DetectDeviceService.isTablet ? "77%" : "90%"
      }}
    />
    }
    {share.parent_share_id == null && <Row style={{marginTop: 5, width: DetectDeviceService.isTablet ? "77%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) }}>
      <Col size={3} style={{alignItems: "flex-start"}}>
        <View>
        {/* <Button transparent> */}
        <TouchableOpacity onPress={() => this.openReshare(share)} style={share.reshared ? styles.selectedButton : styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>SHARE </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.reshare_count})</Text>}
            {(share.sharable_id != null) && <Text style={styles.unselectedText}>SHARE ({share.reshare_count})</Text>}
        </TouchableOpacity>
        {/* </Button> */}
        </View>
      </Col>
      <Col size={3} style={{alignItems: "center"}}>
        <View>
        {/* <Button transparent> */}
        <TouchableOpacity onPress={() => this.openCommentBox(share.id)} style={styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>COMMENT </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.comments_count})</Text>}
        </TouchableOpacity>
        {/* </Button> */}
        </View>
      </Col>
      {(share.sharable_id != null) && <Col size={3} style={{alignItems: "flex-end", marginRight: (DetectDeviceService.isTablet ? 0 : 0)}}>
        <View>
          <TouchableOpacity onPress={() => this.gotoVYPdetails(share)} style={styles.selectedButton }>
          {/* <Button transparent onPress={this.voteCommentPopup}> */}
            <Text style={styles.unselectedText}>Voice Your Opinion</Text>
          {/* </Button> */}
          </TouchableOpacity>
          </View>
      </Col>}
      {(share.sharable_id == null) && <Col size={3} style={{alignItems: "flex-end"}}>
        <View>
          <TouchableOpacity onPress={() => this.shareLike(share.id)} style={[(share.liked ? styles.selectedButton : styles.unselectedButton), {width: 50}]}>
          {/* <Button transparent onPress={this.voteCommentPopup}> */}
            <Text style={styles.unselectedText}>LIKE</Text>
            <Text style={styles.unselectedText}>({share.like_count})</Text>
          {/* </Button> */}
          </TouchableOpacity>
          </View>
      </Col>}

    </Row>}
    { share.parent_share_id == null && <View
      style={{
        borderBottomColor: '#d3d3d3',
        borderBottomWidth: StyleSheet.hairlineWidth,
        marginLeft: (DetectDeviceService.isTablet ? 80 : 0),
        marginTop: 5,
        width: DetectDeviceService.isTablet ? "77%" : "90%"
      }}
    />
    }
    {(Object.keys(this.state.replyVisible).length != 0 && this.state.replyVisible[share.id] != undefined && this.state.replyVisible[share.id]["parent_reply"]) && this.newComment(share)}

    <FlatList style={{marginTop: 10}}
          listKey={(item, index) => 'C' + index.toString()}
          keyboardShouldPersistTaps='always'
          data={share.share_comments}
          initialNumToRender ={2}
          maxToRenderPerBatch = {2}
          renderItem={this.renderComment}
          keyExtractor={({item, index}) => (item && item.id)}
          >
    </FlatList>
    

  </Grid>)
  }

  nestedDeletedShareCard = (share) => {
    return (<Grid key={share.id} style={[{ width: (DetectDeviceService.isTablet ? "80%" : "100%"), marginTop: 10, paddingLeft: 10, paddingRight: 5, paddingTop: 10, paddingBottom: 10, borderColor: "#d3d3d3", borderWidth: 1, borderRadius: 15 }, styles.container]}>
        <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>This posting no longer exists because the account has been deactivated.</Text>
    </Grid>)
  }

  nestedShareCard = (share, sharedShare) => {
    return (share && <Grid key={share.id} style={[{ width: (DetectDeviceService.isTablet ? "80%" : "100%"), marginLeft: DetectDeviceService.isTablet ? 80 : 0, marginTop: 10, paddingLeft: 10, paddingRight: 5, paddingTop: 10, paddingBottom: 10, borderColor: "#d3d3d3", borderWidth: 1, borderRadius: 15 }, styles.container]}>
      <Row>
        <Col style={{width: 80}}>
          <Thumbnail source={{ uri: this.avatarUrl(share.user_avatar) }} />
        </Col>
        <Col size={7} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
          <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {this.props.user.user_id == share.share_user_id ? "I shared" : share.share_user_name}
          </Text>
          <Text style={styles.vote}>
          <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(share.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
          </Text>
        </Col>
      </Row>
      {share.sharable_id != null && <Row style={{ marginTop: 10 }}>
        <Text>{share.my_vote_on_share == "Neutral" ? "Not sure" : share.my_vote_on_share}, 
        <Text style={[styles.unselectedText, {fontSize: 16, marginLeft: 5}]} onPress={() => this.gotoBillDetail(share)}>
          {share.title_or_name}
        </Text>
        </Text> 
      </Row>}
      <Row style={{ marginTop: 10 }}>
        {share.flag_comment != true && <Text style={styles.textColor}>{share.share_text}</Text>}
        {share.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
      </Row>
      {/* {(share.share_url != null && share.share_url != "") && <Row style={{ marginTop: 10 }}>
        <Text style={{color: "#808080"}} onPress={() => this.shareLink(share.share_url)}>{share.share_url}</Text>
      </Row>} */}
      {(Platform.OS == "web" && share.parent_share_id == null && share.share_url != "") && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "66%" : "90%", marginLeft: (DetectDeviceService.isTablet ? 80 : 0) } } >
        <WebLinkPreview fetcher={this.customFetcher} url={share.share_url} width="100%" /> 
      </Row>}
      {(Platform.OS != "web" && (share.share_url != null && share.share_url != "")) && <Row style={{ marginTop: 10, width: DetectDeviceService.isTablet ? "95%" : "95%", marginLeft: (DetectDeviceService.isTablet ? 7 : 0) } } >
        <LinkPreview containerStyle={{width: "100%", borderColor: '#d3d3d3', borderWidth: 0.4, borderRadius: 20, overflow: 'hidden',}} text={share.share_url} width="90%" /> 
      </Row>}
      <Row style={{marginTop: 10 }}>
        <Col size={3} style={{alignItems: "flex-start"}}>
          <View style={{marginTop: 10}}>
          {/* <Button transparent> */}
          <TouchableOpacity onPress={() => this.openReshare(share)} style={share.reshared ? styles.selectedButton : styles.unselectedButton}>
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>SHARE </Text>}
            {(share.sharable_id == null) && <Text style={styles.unselectedText}>({share.reshare_count})</Text>}
            {(share.sharable_id != null) && <Text style={styles.unselectedText}>SHARE ({share.reshare_count})</Text>}
          </TouchableOpacity>
          {/* </Button> */}
          </View>
        </Col>
        {(share.sharable_id == null) && <Col size={2} style={{alignItems: "flex-start"}}>
          <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.shareLike(share.id, sharedShare.id)} style={[(share.liked ? styles.selectedButton : styles.unselectedButton), {width: 50}]}>
            {/* <Button transparent onPress={this.voteCommentPopup}> */}
              <Text style={styles.unselectedText}>LIKE</Text>
              <Text style={styles.unselectedText}>({share.like_count})</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
        {(share.sharable_id == null) && <Col size={3} style={{alignItems: "flex-start"}}>
        <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.shareTruth(share.id, 1, sharedShare.id)} style={share.truth_val == "1" ? styles.selectedButton : styles.unselectedButton}>
            {/* <Button transparent onPress={this.voteCommentPopup}> */}
              <Text style={styles.unselectedText}>UNTRUE</Text>
              <Text style={styles.unselectedText}>({share.untruth_count})</Text>          
            {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
        {(share.sharable_id == null) && <Col size={4} style={{alignItems: "flex-start"}}>
          <View style={{marginTop: 10}} >
              <TouchableOpacity onPress={() => this.shareTruth(share.id, 2, sharedShare.id)} style={share.truth_val == "2" ? styles.selectedButton : styles.unselectedButton}>
          {/* <Button transparent onPress={this.voteLike}> */}
            <Text style={styles.unselectedText}>PARTLY TRUE</Text>
            <Text style={styles.unselectedText}>({share.partially_truth_count})</Text>
          {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
        {(share.sharable_id != null) && <Col size={3} style={{alignItems: "flex-end"}}>
          <View style={{marginTop: 10}}>
            <TouchableOpacity onPress={() => this.gotoVYPdetails(share)} style={styles.selectedButton }>
            {/* <Button transparent onPress={this.voteCommentPopup}> */}
              <Text style={styles.unselectedText}>Voice Your Opinion</Text>
            {/* </Button> */}
            </TouchableOpacity>
            </View>
        </Col>}
      </Row>
    </Grid>)
  }

  shareComment = (comment) => {
    return <Grid key={comment.id} style={[{ paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }]}>
    {/* <Col style={{borderLeftWidth: 0.5, width: 2}}></Col> */}
    <Col>
    <Row style={{width: (DetectDeviceService.isTablet ? "84%" : "100%")}}>      
      <Col style={{width: 60, marginTop: 10}}>
        <Thumbnail small source={{ uri: this.avatarUrl(comment.user.avatar_url) }} />
      </Col>
      <Col size={(DetectDeviceService.isTablet ? 2 : 3)} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
        <Text style={{ marginBottom: 5, color: "#4589c6" }}>
          {comment.user.id == this.props.user.user_id ? "I commented" : (comment.user.first_name + " " + comment.user.last_name)}
        </Text>
        <Text style={styles.vote}>
          <Text note style={{ color: "#797979", fontSize: 10}}>{dayjs(comment.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
        </Text>
      </Col>
      <Col style={{marginLeft: (DetectDeviceService.isTablet ? 0 : -20), alignItems: (DetectDeviceService.isTablet ? "flex-end" : "flex-start")}}>
        {/* <Button iconLeft dark> */}
        <Row>
          {/* <Col> */}
            <TouchableOpacity onPress={() => this.openCommentBox(comment.share_id, comment.id)}>
              <Icon name='comments-o' type="FontAwesome" style={{color: "#333", fontSize: 22, marginTop: 10, marginRight: 15}} />
              <Text style={styles.unselectedText}>(1)</Text>
            </TouchableOpacity> 
            
          {/* </Col> */}
          {/* <Col> */}
            {(this.props.user.user_id == comment.user.id) && 
              <Button transparent onPress={() => { this.openDeleteShareComment({commentId: comment.id, shareId: comment.share_id}) }}>
                <Icon name='trash' style={{marginBottom: 3, fontSize: 22, color: '#d9534f'}} />
              </Button>}
              {(this.props.user.user_id != comment.user.id && !comment.flag_comment) && 
              <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShareComment({shareId: comment.id}) }}>
                <Icon name='flag-o' type="FontAwesome" style={{fontSize: 25, color: 'red'}} />
              </Button>}
          {/* </Col> */}
        </Row>
        {/* </Button> */}
      </Col>
    </Row>
    <Row style={{ marginTop: 10, marginLeft: 57, width: DetectDeviceService.isTablet ? "80%" : "75%" }}>
      {comment.flag_comment != true && <Text style={styles.textColor}>{comment.comment_text}</Text>}
      {comment.flag_comment == true && <Text style={[styles.textColor, {color: "#808080", fontStyle: 'italic'}]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
    </Row>

    {(Object.keys(this.state.replyVisible).length != 0 && this.state.replyVisible[comment.id] != undefined && this.state.replyVisible[comment.id]["sub_reply"]) && this.newNestedComment(comment)}

    <FlatList style={{marginTop: 10}}
          listKey={(item, index) => 'NC' + item.id.toString()}
          data={comment.comments}
          keyboardShouldPersistTaps='always'
          initialNumToRender ={2}
          maxToRenderPerBatch = {2}
          renderItem={this.renderNestedComment}
          keyExtractor={({item, index}) => (item && item.id)}
          >
    </FlatList>
    
    </Col>
  </Grid>
  }

  nestedShareComment = (comment, index) => {
    return <Grid style={[{ paddingLeft: 30, paddingRight: 20, paddingTop: 0, paddingBottom: 0, width: (Platform.OS == "web" ? "86%" : "100%")}, styles.white]}>
      <Col style={{ borderLeftWidth: 0.5, width: 2, position: "relative", top: -34, left: -13 }}></Col>
      <Col>
        <Row>
          <Col style={{width: 10, borderBottomWidth: 0.5, position: "relative", top: -27, left: -13}}></Col>
          <Col style={{ width: 60, marginTop: 10 }}>
            <Thumbnail small source={{ uri: this.avatarUrl(comment.user.avatar_url) }} />
          </Col>
          <Col size={7} style={{ justifyContent: "flex-start", alignItems: "flex-start", marginTop: 10 }}>
            <Text style={{ marginBottom: 5, color: "#4589c6" }}>
              {comment.user.id == this.props.user.user_id ? "I commented" : (comment.user.first_name + " " + comment.user.last_name)}
            </Text>
            <Text style={styles.vote}>
              <Text note style={{ color: "#797979", fontSize: 10 }}>{dayjs(comment.created_at).format('MM/DD/YYYY hh:mm A')}</Text>
            </Text>
          </Col>
          <Col size={3} style={{ alignItems: (DetectDeviceService.isTablet ? "flex-end" : "flex-end"), marginLeft: (DetectDeviceService.isTablet ? 0 : 0), }}>
            {/* <Button iconLeft dark> */}
            <Row>
              {(this.props.user.user_id == comment.user.id) &&
                <Button transparent onPress={() => { this.openDeleteShareComment({ commentId: comment.id, shareId: comment.share_id }) }}>
                  <Icon name='trash' style={{ marginBottom: 3, fontSize: 22, color: '#d9534f' }} />
                </Button>}
              {(this.props.user.user_id != comment.user.id && !comment.flag_comment) &&
                <Button style={{ justifyContent: "flex-end", alignItems: "flex-end" }} transparent onPress={() => { this.openFlagShareComment({ shareId: comment.id }) }}>
                  <Icon name='flag-o' type="FontAwesome" style={{ fontSize: 25, color: 'red' }} />
                </Button>}
            </Row>
            {/* </Button> */}
          </Col>
        </Row>
        <Row style={{ marginTop: 0, marginLeft: 70, width: DetectDeviceService.isTablet ? "67%" : "75%" }}>
          {comment.flag_comment != true && <Text style={styles.textColor}>{comment.comment_text}</Text>}
          {comment.flag_comment == true && <Text style={[styles.textColor, { color: "#808080", fontStyle: 'italic' }]}>Comment was flagged by other members as objectionable and was removed by the administrator.</Text>}
        </Row>
      </Col>
  </Grid>
  }

  newComment = (share) => {
    return <Grid style={[{ paddingLeft: 25 }]}>
      <Row>
        <Col style={{width: 40, height: 40}}><Thumbnail small style={{width: 35, height: 35, marginTop: 10}} source={{ uri: this.avatarUrl(this.props.user.avatar) }} /></Col>
        <Col style={{width: "68%"}}>
          <Item rounded style={{height: 30, marginTop: 12}}>
            <Input placeholder='Write a comment...' style={{outlineStyle: "none"}} value={this.state.commentVals[share.id + ":" + "0"]} onChangeText={(text) => { this.setCommentVal(text, share.id, 0) }}/>
          </Item>
        </Col>
        <Col style={{marginLeft: 10, width: 40, height: 40, paddingTop: 10}}>
          <Button transparent
            style={styles.commentpost}
            onPress={() => this.shareCommentStoreNew(share.id, 0)}
          >
            <Icon name="arrow-up" style={{fontSize: 28}} type="EvilIcons" />
          </Button>
          {/* <Icon name="comments-o" type="FontAwesome" onPress={() => this.shareCommentStoreNew(share.id, 0)} /> */}
        </Col>
      </Row>
    </Grid>
  }
 
  newNestedComment = (comment) => {
    return <Grid>
      <Row>
        <Col style={{marginLeft: 30, width: 40, height: 40}}><Thumbnail small style={{width: 35, height: 35, marginTop: 10}} source={{ uri: this.avatarUrl(this.props.user.avatar) }} /></Col>
        <Col style={{width: "66%"}}>
          <Item rounded style={{height: 30, marginTop: 12}}>
            <Input placeholder='Write a comment...' style={{outlineStyle: "none"}} value={this.state.commentVals[comment.share_id + ":" + comment.id]} onChangeText={(text) => { this.setCommentVal(text, comment.share_id, comment.id) }}/>
          </Item>
        </Col>
        <Col style={{marginLeft: 10, width: 40, height: 40, paddingTop: 10}}>
          <Button transparent
            style={styles.commentpost}
            onPress={() => this.shareCommentStoreNew(comment.share_id, comment.id)}
          >
            <Icon name="arrow-up" style={{fontSize: 28}} type="EvilIcons" />
          </Button>
          {/* <Icon name="comments-o" type="FontAwesome" onPress={() => this.shareCommentStoreNew(comment.share_id, comment.id)} /> */}
        </Col>
      </Row>
    </Grid>
  }

  renderItem = ({item, index}) => (this.shareCard(this.props.list[item.toString()]))
  renderComment = (({item, index}) => (this.shareComment(item)))
  renderNestedComment = (({item, index}) => (this.nestedShareComment(item)))

  render() {
    return (
      <Container>
        { !DetectDeviceService.isTablet && <Header style={styles.header}>
          <Left style={{flex: 1}}>
            <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon}/>
            </Button>
          </Left>
          <Body style={{flex: 1}}>
            <Title style={{color: "#fff", width: 240, textAlign: "center"}}>Share</Title>
          </Body>
          <Right style={{flex: 1}}>
            
          </Right>
        </Header> }

        { DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation} />}

        <Col style={[{backgroundColor: "#ececec", maxHeight: 45}]} >
          <BreadCrumb navigation={this.props.navigation} links={["Home", "Share", "Comments"]}></BreadCrumb>
        </Col> 

        { !this.state.loading && <Content style={{backgroundColor: "#ececec"}} scrollEnabled={!this.state.replymodal} enableResetScrollToCoords={false}>
       <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.sharecommentmodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Comment</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Type your comment here"
                            onChangeText={(text) => { this.setState({share_comment_text: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ sharecommentmodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.shareCommentStore}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalopen}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
           <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center" }}>
                <Text style={{ fontSize: 18, color: "black" }}>{this.state.modalheading}</Text>
              </View>
              <View>
                <Text>{this.state.modaltext}</Text>
              </View>
              <View style={styles.forgotactions}>
              {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
              {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ modalopen: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.callFunction}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.flagsharemodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Flag</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Flag objectionable content that violate the Terms of Use.  For example: violent threats, and obscene comments."
                            placeholderTextColor="#808080"
                            onChangeText={(text) => { this.setState({flag_share_comment: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ flagsharemodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={this.flagShare}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <SectionedMultiSelect
              ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
              IconRenderer={MaterialIcons} 
              items={this.state.network}
              noItemsComponent={() => <Text>There are no members in your network.  Go to CONNECT page to find members to connect with.</Text>}
              hideSearch={true}
              hideSelect={true}
              showChips={false}
              uniqueKey="id"
              displayKey="name"
              // subKey="children"
              headerComponent={this.SelectOrRemoveAll}
              selectText="Select users"
              alwaysShowSelectText={true}
              showDropDowns={false}
              readOnlyHeadings={true}
              showCancelButton={true}
              modalWithSafeAreaView={true}
              onSelectedItemsChange={this.onSelectedItemsChange}
              onConfirm={() => this.reshare()}
              selectedItems={this.state.selectedItems}
              renderSelectText={this.renderSelectText}
              iconKey="icon"
              styles={{
               chipText: {
                 maxWidth: Dimensions.get('screen').width - 90,
               },
              // itemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              // selectedItemText: {
              //   color: 'blue',
              // },
              // subItemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              item: {
                paddingHorizontal: 10,
              },
              subItem: {
                paddingHorizontal: 10,
              },
              selectedItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              selectedSubItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              // selectedSubItemText: {
              //   color: 'blue',
              // },
              scrollView: { paddingHorizontal: 0 },
              container: {width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center"}
            }}

            />
        <View style={styles.contentMargin}>
          {
            this.state.shareDetails.id != undefined && this.shareCard(this.state.shareDetails)
          }
         </View>
       </Content> }
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderBottomColor: '#808080',
    // borderBottomWidth: 1,
    // backgroundColor: COLOR.PRIMARY,
	  // flex: 1
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
  modal: {
    color: "#333",
  },
  contentMargin: {
    // marginLeft: Platform.isPad ? 170 : 10,
    marginBottom: 10,
    // marginRight: Platform.isPad ? 170 : 10,
    marginTop: 10,
    backgroundColor: "#fff",
    paddingLeft: (DetectDeviceService.isTab ? 0 : DetectDeviceService.isTablet ? 60 : 0),
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  forgotcancel: {
		height: 40,
		width: 85,
    marginRight: 20
  },
  forgotsubmit: {
		height: 40,
		width: 85
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 20,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  selectedButton: {
    // backgroundColor: "blue",
    padding: 5, 
    borderRadius: 10,
    borderColor: "#4589c6",
    borderWidth: 1,
    // marginLeft: 10,
    marginRight: 10,
    color: "#fff",
  },
  unselectedButton: {
    padding: 10, 
    borderRadius: 10,
    // marginLeft: 10,
    marginRight: 10,
  },
  selectedText: {
    color: "#fff",
    fontSize: 13
  },
  unselectedText: {
    color: "#4589c6",
    fontSize: 13
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	// textColor: {
  //   color: "rgba(255,255,255,1)",
  //   width: 240
	// },
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 20
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareDetailsScreen);
