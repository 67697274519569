import React from "react";
import { StyleSheet, View, Dimensions, Platform } from "react-native";
import {
  Button,
  Text,
  Container,
  Header,
  Left,
  Right,
  Icon,
  Content,
  Form,
  Item,
  Label,
  Body,
  Title,
  Input,
  Picker,
  Textarea,
  Toast
} from "native-base";

import HeaderMenu from '../components/HeaderMenu';

import BreadCrumb from "../components/BreadCrumb";

import DetectDeviceService from '../lib/devicedetection';

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { Col, Row, Grid } from "../lib/easy-grid";

import { Routes } from '../navigation/routes';
import { connect } from 'react-redux';
import agent from '../agent'
import {
	
} from '../ducks/auth';

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
	setAuthUser: (user) => dispatch(setAuthUser(user)),
});

class CreateInitiativeScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newinittitle: "",
      newinitdescription: "",
      my_representatives: {},
      my_state_representatives: {},
      selected_my_reps: {},
      selected_my_state_reps: {}
    }
  }

  componentDidMount() {
    agent.Home.myrepresentatives()
      .then(res => {
        this.setState({my_representatives: res})
      })
    agent.Home.staterepresentatives()
      .then(res => {
        this.setState({my_state_representatives: res})
      })
  }

  onSelectedItemsChange = (selectedItems, key) => {
    let selectedIds = selectedItems.map((obj) => { return obj.id })
    let obj = {}
    // if(this.state.selected_my_reps[key] == undefined) {
    obj[key] = selectedItems
    // } else {
      // obj[key] = this.state.selected_my_reps[key].concat(selectedItems)
    // }
    console.log(obj)
    console.log(this.state.selected_my_reps)
    this.setState({ selected_my_reps: {
      ...this.state.selected_my_reps,
      ...obj
    }});
  };

  createInitiative = () => {
    let ini = {}
    ini.title = this.state.newinittitle
    ini.description = this.state.newinitdescription
    ini.visibility = "National=>USA"
    // ini.representative_ids = Object.values(this.state.selected_my_reps).flat()
    // console.log(ini)
    agent.Bill.create_initiative(ini, Object.values(this.state.selected_my_reps).flat())
    .then(res => {
      this.setState({ 
        newinittitle: "",
        ewinitdescription: "",
        selected_my_reps: {}
      })
      Toast.show({
        text: 'Initiative Saved.  To view details, change filter setting in the listing page to My Initiatives.',
        duration: 5000,
        type: "success",
        textStyle: {textAlign: "center"}
      })
      // this.props.navigation.goBack()
    })
  }

  titleize = (sentence) => {
    if(!sentence.split) return sentence;
    var _titleizeWord = function(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        result = [];
    sentence.split("_").forEach(function(w) {
        result.push(_titleizeWord(w));
    });
    return result.join(" ");
}

  render() {
    return (
      <Container style={{backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)"}}>
        { !DetectDeviceService.isTablet && <Header style={styles.container}>
          <Left style={{flex: 1}}>
            <Button transparent light onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon}/>
            </Button>
          </Left>
          <Body style={{flex: 1}}>
            <Title style={[styles.text, styles.header, {textAlign: "center"}]}>Create Initiative</Title>
          </Body>
          <Right style={{flex: 1}}>
            <Button transparent light onPress={() => this.createInitiative()}>
              <Text style={{fontSize: 15, color: "#fff"}}>Save</Text>
            </Button>
          </Right>
        </Header> }
        { DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation} />}
        <Col style={[{backgroundColor: "#ececec", maxHeight: 60}]} >
        <BreadCrumb navigation={this.props.navigation} links={["Home", "Initiatives", "Create Initiative"]}></BreadCrumb>
        </Col>
        { DetectDeviceService.isTablet && <Row style={[styles.contentMargin]}>
          <Button  onPress={this.createInitiative} style={{height: 25, backgroundColor: "#2196F3"}}>
            <Text>Save</Text>
          </Button>
        </Row> }
        <Content padder style={[styles.container, styles.contentMargin]}>
          <View>
            <Form>
              <Grid>
                <Row>
                  <Col>
                  <Item stackedLabel>
                    <Label style={styles.text}>Title</Label>
                    <Input style={styles.text} autoCapitalize='sentences' onChangeText={(text) => { this.setState({ newinittitle: text }); }} />
                  </Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <View style={{ marginTop: 30, marginLeft: 10 }}>
                      <Label style={styles.text}>Description</Label>
                      <Textarea style={styles.text} rowSpan={5} bordered
                                onChangeText={(text) => { this.setState({ newinitdescription: text })}} 
                                placeholder="Enter detailed description about the initiative here" 
                                placeholderTextColor={DetectDeviceService.isTablet ? "#333": "#fff"} />
                    </View>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <View style={{marginTop: 30, marginLeft: 10}}>
                      <Label style={styles.text}>Petitioning (Optional)</Label>
                      <Text style={styles.text}>Federal Representatives</Text>
                      {
                        Object.keys(this.state.my_representatives).map((key) => {
                          return <SectionedMultiSelect
                          key={key}
                          ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
                          IconRenderer={MaterialIcons} 
                          items={this.state.my_representatives[key]}
                          hideSearch={true}
                          uniqueKey="id"
                          displayKey="name"
                          // subKey="children"
                          headerComponent={this.SelectOrRemoveAll}
                          selectText={this.titleize(key)}
                          alwaysShowSelectText={true}
                          showDropDowns={false}
                          // readOnlyHeadings={true}
                          showCancelButton={true}
                          modalWithSafeAreaView={true}
                          onSelectedItemsChange={(items) => this.onSelectedItemsChange(items, key)}
                          selectedItems={this.state.selected_my_reps[key]}
                          // renderSelectText={this.renderSelectText}
                          colors={{
                            selectToggleTextColor: DetectDeviceService.isTablet ? "#333": "#fff",
                            chipColor: DetectDeviceService.isTablet ? "#333": "#fff"
                          }}
                          styles={{
                           chipText: {
                             maxWidth: Dimensions.get('screen').width - 90,
                           },
                           container: {
                            height: 30,
                           },
                           selectToggle: { padding: 0 },
                          // itemText: {
                          //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                          // },
                          // selectedItemText: {
                          //   color: 'blue',
                          // },
                          // subItemText: {
                          //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
                          // },
                          item: {
                            paddingHorizontal: 10,
                          },
                          subItem: {
                            paddingHorizontal: 10,
                          },
                          selectedItem: {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                          },
                          selectedSubItem: {
                            backgroundColor: 'rgba(0,0,0,0.1)',
                          },
                          // selectedSubItemText: {
                          //   color: 'blue',
                          // },
                          scrollView: { paddingHorizontal: 0 },
                          container: {width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center"}
                        }}
            
                        />
                        })
                      }
                    </View>
                  </Col>
                </Row>
              </Grid>
            </Form>
          </View>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
  contentMargin: {
    width: DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center"
  },
  header: {
    fontSize: 15,
    width: 200,
    alignSelf: "center"
  },
  text: {
    color: DetectDeviceService.isTablet ? "#000" : "#FFF",
  },
  optional: {
    marginTop: 20
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInitiativeScreen);
