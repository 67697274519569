import React, {
    PixelRatio,
    Platform,
    Dimensions
  } from 'react-native';
  
const windowSize = Dimensions.get('window');
  
class DetectDeviceService {
    constructor() {
      this.pixelDensity = PixelRatio.get();
      this.width = windowSize.width;
      this.height = windowSize.height;
      this.adjustedWidth = this.width * this.pixelDensity;
      this.adjustedHeight = this.height * this.pixelDensity;
  console.log(this.pixelDensity)
  console.log(this.width)
  console.log(this.height)
  console.log(this.adjustedHeight)
  console.log(this.adjustedWidth)
  // alert("height - " + this.adjustedHeight + " width - " + this.adjustedWidth + " pd - " + this.pixelDensity) 
      this.isPhoneOrTablet();
      this.isIosOrAndroid();
      this.detectIphoneX();
    }
  
    // isPhoneOrTablet() {
    //   if (this.pixelDensity < 2 && (this.adjustedWidth >= 1000 || this.adjustedHeight >= 1000)) {
    //     this.isTablet = true;
    //     this.isPhone = false;
    //   } else if (this.pixelDensity === 2 && (this.adjustedWidth >= 1824 || this.adjustedHeight >= 1824)) {
    //     this.isTablet = true;
    //     this.isPhone = false;
    //   } else {
    //     this.isTablet = false;
    //     this.isPhone = true;
    //   }
    // }

    isPhoneOrTablet() {
      let widthLDesk = 1281;
      let widthDesk = 1025;
      let widthTab = 825;
      if (this.adjustedWidth >= widthLDesk*this.pixelDensity) {
        console.log(1)
        this.isTablet = true
        this.isTab = false
        this.isPhone = false
      } else if (this.adjustedWidth >= widthTab*this.pixelDensity && this.adjustedWidth < widthLDesk*this.pixelDensity) {
        console.log(2)
        this.isTablet = true
        this.isTab = true
        this.isPhone = false
      }  else if (this.adjustedWidth >= widthTab*this.pixelDensity && this.adjustedWidth < widthDesk*this.pixelDensity) {
        console.log(3)
        this.isTablet = false
        this.isTab = true
        this.isPhone = false
      } else if (this.adjustedWidth < widthTab*this.pixelDensity) {
        console.log(4)
        this.isTablet = false
        this.isTab = false
        this.isPhone = true
      } else {
        console.log(5)
        this.isTablet = false
        this.isTab = false
        this.isPhone = true
      }
    }

    static isTablet() {
      let windowSize = Dimensions.get('window');
      let pixelDensity = PixelRatio.get();
      let width = windowSize.width;
      let height = windowSize.height;
      let adjustedWidth = this.width * this.pixelDensity;
      let adjustedHeight = this.height * this.pixelDensity;
      let widthLDesk = 1281;
      let widthDesk = 1025;
      let widthTab = 825;
      if (adjustedWidth >= widthLDesk*pixelDensity || (this.adjustedWidth >= widthTab*this.pixelDensity && this.adjustedWidth < widthLDesk*this.pixelDensity)) {
        return true
      } else {
        return false
      }
    }

    static isTab() {
      let windowSize = Dimensions.get('window');
      let pixelDensity = PixelRatio.get();
      let width = windowSize.width;
      let height = windowSize.height;
      let adjustedWidth = this.width * this.pixelDensity;
      let adjustedHeight = this.height * this.pixelDensity;
      let widthLDesk = 1281;
      let widthDesk = 1025;
      let widthTab = 825;
      if((this.adjustedWidth >= widthTab*this.pixelDensity && this.adjustedWidth < widthLDesk*this.pixelDensity) || (this.adjustedWidth >= widthTab*this.pixelDensity && this.adjustedWidth < widthDesk*this.pixelDensity)) {
        return true
      } else {
        return false
      }
    }

    static isPhone() {
      let windowSize = Dimensions.get('window');
      let pixelDensity = PixelRatio.get();
      let width = windowSize.width;
      let height = windowSize.height;
      let adjustedWidth = this.width * this.pixelDensity;
      let adjustedHeight = this.height * this.pixelDensity;
      let widthLDesk = 1281;
      let widthDesk = 1025;
      let widthTab = 825;
      if(this.adjustedWidth < widthTab*this.pixelDensity) {
        return true
      } else {
        return false
      }
    }
  
    isIosOrAndroid() {
      if (Platform.OS === 'ios') {
        this.isIos = true;
        this.isAndroid = false;
      } else {
        this.isIos = false;
        this.isAndroid = true;
      }
    }
  
    detectIphoneX() {
      if (Platform.OS === 'ios' &&
        !Platform.isPad &&
        !Platform.isTVOS &&
        (
          (windowSize.height === 812 || windowSize.width === 812) || // iPhone X and iPhone XS
          (windowSize.height === 896 || windowSize.width === 896) // iPhone XS Max and XR
        )) {
        this.isIphoneX = true;
      } else {
        this.isIphoneX = false;
      }
  
    }
  }
  
  export default new DetectDeviceService();
  // module.exports = DetectDeviceService;