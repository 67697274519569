import React, { Component } from "react";
import { StyleSheet, View, TextInput, Dimensions } from "react-native";
import {
  Content,
	Button,
  Text
} from "native-base";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { connect } from 'react-redux';
import {
	setListFilter,
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

const mapStateToProps = state => ({ ...state.bill });

const mapDispatchToProps = dispatch => ({
	setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class BillsFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    }
  }

  setFilter = (key, value) => {
    let obj = {}
    obj[key] = value
    //obj = {...this.state.selectedFilter, ...obj}
    obj = {...this.props.selectedFilter, ...obj}
    //this.setState({ selectedFilter: obj });
    this.props.selectedFilter[key] = value
    this.props.setBillsFilter(obj)
  }

  render() {
    return (
      <View style={styles.outer}>
        <View style={styles.container}>
          <Text style={styles.label}>Congressional Year</Text>
          <Grid style={styles.filterBox}>
            <Row>
                <Col>
                  <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[0]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[0])}>
                    <Text style={styles.filterText}>{this.props.filterYears[0]}</Text>
                  </Button>
                </Col>
                <Col>
                  <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[1]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[1])}>
                    <Text style={styles.filterText}>{this.props.filterYears[1]}</Text>
                  </Button>
                </Col>
              </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'watchlist') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'watchlist')}><Text style={styles.filterText}>My Watchlist</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'local_proposed') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'proposed')}><Text style={styles.filterText}>Bill Proposals</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'local') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'fed')}><Text style={styles.filterText}>Signed Into Law</Text></Button>
              </Col>
              <Col size={50}></Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Sort</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'newest') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'newest')}><Text style={styles.filterText}>Newest</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'most_viewed') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'most_viewed')}><Text style={styles.filterText}>Most Viewed</Text></Button>
              </Col>
            </Row>
          </Grid>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
	outer: {
		backgroundColor: "#ECECEC"
	},
	container: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 5,
    marginBottom: 0,
  },

  label: {
    fontSize: 16,
    paddingBottom: 5
  },
  filterBox: {
    backgroundColor: '#ffffff',
    padding: 20
  },
  innerBox: {
    flex: 1,
    flexWrap: "wrap",
    padding: 20,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start'
  },
  yearOption: {
    margin: 5,
    justifyContent: 'center'
  },
  filterText: {
    fontSize: 13
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsFilter);
