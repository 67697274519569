import * as Device from 'expo-device';

const SET_DEVICE = 'SET_DEVICE'

export function setDevice(data) {
  return {
    type: SET_DEVICE,
    data
  }
}

const initialState = {
  dtype: '',
  isTab: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DEVICE:
      return Object.assign(
        {},
        state,
        {
          dtype: action.data,
          isTab: (action.data == Device.DeviceType.TABLET)
        }
      );
    default:
      return state;
  }
}
