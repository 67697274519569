import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import DetectDeviceService from '../lib/devicedetection';

//import { HomeNavigator } from './home.navigator';
import SplashScreen from '../screens/SplashScreen';
import AccountSetupScreen from '../screens/AccountSetupScreen';
import SignupScreen from '../screens/SignupScreen';
import LoginScreen from '../screens/LoginScreen';
import HomeScreen from '../screens/HomeScreen';
import BillDetailsScreen from '../screens/BillDetailsScreen';
import RepresentativeDetailsScreen from '../screens/RepresentativeDetailsScreen';
import VoiceYourOpinionScreen from '../screens/VoiceYourOpinionScreen';
import FilterScreen from '../screens/FilterScreen';
import AboutScreen from '../screens/AboutScreen';
import { Routes } from './routes';
import NotificationSettingsScreen from '../screens/NotificationSettingsScreen';
import GeneralSettingsScreen from '../screens/GeneralSettingsScreen';
import PrivacySettingsScreen from '../screens/PrivacySettingsScreen';
import ManageNetworkScreen from '../screens/ManageNetworkScreen';
import VoteDetailsScreen from "../screens/VoteDetailsScreen";
import ShareDetailsScreen from "../screens/ShareDetailsScreen";
import CreateInitiativeScreen from '../screens/CreateInitiativeScreen';
import FirstAboutUsScreen from '../screens/FirstAboutUsScreen';
import CommonFriendsScreen from '../screens/CommonFriendsScreen';

import LeftMenu from '../components/LeftMenu';


const Stack = createDrawerNavigator();

export const Navigator = (props): React.ReactElement => {

  return <Stack.Navigator {...props} drawerType={DetectDeviceService.isTablet ? "permanent" : "front"} 
  drawerContent={(props) => <LeftMenu {...props} />} 
                  headerMode='none'>
    <Stack.Screen name={Routes.SPLASH} component={SplashScreen}/>
    <Stack.Screen name={Routes.SIGN_IN} component={LoginScreen}/>
    <Stack.Screen name={Routes.SIGN_UP} component={SignupScreen}/>
    <Stack.Screen name={Routes.ACCOUNT_SETUP} component={AccountSetupScreen}/>
    <Stack.Screen name={Routes.AUTH} component={LoginScreen}/>
    <Stack.Screen name={Routes.HOME} component={HomeScreen}/>
    <Stack.Screen name={Routes.BILL_DETAILS} component={BillDetailsScreen}/>
    <Stack.Screen name={Routes.REPRESENTATIVE_DETAILS} component={RepresentativeDetailsScreen}/>
    <Stack.Screen name={Routes.VOICE_YOUR_OPINION} component={VoiceYourOpinionScreen}/>
    <Stack.Screen name={Routes.FILTERS} component={FilterScreen}/>
    <Stack.Screen name={Routes.FIRSTABOUTUS} component={FirstAboutUsScreen}/>
    <Stack.Screen name={Routes.ABOUT} component={AboutScreen}/>
    <Stack.Screen name={Routes.PRIVACY_SETTINGS} component={PrivacySettingsScreen}/>
    <Stack.Screen name={Routes.NOTIFICATION_SETTINGS} component={NotificationSettingsScreen}/>
    <Stack.Screen name={Routes.GENERAL_SETTINGS} component={GeneralSettingsScreen}/>
    <Stack.Screen name={Routes.MANAGE_NETWORK} component={ManageNetworkScreen}/>
    <Stack.Screen name={Routes.VOTE_DETAILS} component={VoteDetailsScreen}/>
    <Stack.Screen name={Routes.SHARE_DETAILS} component={ShareDetailsScreen}/>
    <Stack.Screen name={Routes.CREATE_INITIATIVE} component={CreateInitiativeScreen}/>
    <Stack.Screen name={Routes.COMMON_FRIENDS} component={CommonFriendsScreen}/>
  </Stack.Navigator>
};

