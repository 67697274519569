import React, { Component, useCallback } from "react";
import { StyleSheet, View, TextInput, Modal, TouchableHighlight, Platform } from "react-native";
import * as AuthSession from "expo-auth-session";

import RoundRectButton from "../components/RoundRectButton";
import FacebookButton from "../components/FacebookButton";
import {
  Button,
  Text,
  Container,
  Header,
  Content,
  Form,
  Item,
  Label,
  Body,
  Title,
  Input,
  CheckBox,
  Icon,
  Toast
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import * as AppleAuthentication from 'expo-apple-authentication';

import * as GoogleSignIn from 'expo-google-sign-in';

import FacebookWebLogin from '../components/FacebookWebLogin';
import GoogleWebLogin from '../components/GoogleWebLogin';
import AppleLogin from 'react-apple-login'

import { jwtDecode } from "../lib/jwt-decode";

import { CommonActions } from '@react-navigation/native';

import { Col, Row, Grid } from "../lib/easy-grid";

import { API_URL } from "../constants/server";

import * as Facebook from 'expo-facebook';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { Routes } from '../navigation/routes';
import { connect } from 'react-redux';
import agent from '../agent'
import {
  setAuthToken,
  setAuthUser,
} from '../ducks/auth';

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
  setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
  setAuthUser: (user) => dispatch(setAuthUser(user)),
});

const requestTokenURL = "https://www.townhallusa.com/api/v1/request/token";
const accessTokenURL = "https://www.townhallusa.com/api/v1/access_token";

// expo-auth-session/src/SessionUrlProvider.ts
// has been edited. 
// https://github.com/expo/expo/blob/a1142e8b0d2e8c50528a031ef87449a2f5dba717/packages/expo-auth-session/src/SessionUrlProvider.ts#L14
// https://forums.expo.dev/t/authsession-no-default-redirect-url-could-be-found/47130/3
const redirect = AuthSession.makeRedirectUri({
  useProxy: false,
  path: "callback.html"
});

console.log(`Callback URL: ${redirect}`);

class LoginScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: true,
      forgotemail: "",
      forgotemailmodal: false,
      emptyemailmodal: false,
      emptyemail: "",
      passwordicon: "eye-off",
      password1: true,
      users_count: "",
      twfunc: "",
      twtoken: "",
      twtwitterid: "",
      emptyemailsocial: "",
      appleAvailable: false,
      requestTokens: "",
      requestParams: "",
      remember_email: ""
    }
  }

  async componentDidMount() {
    const requestParams = this.toQueryString({ oauth_callback: redirect });
    console.log(12112)
    console.log(requestTokenURL + requestParams)

    const requestTokens = await fetch(
      requestTokenURL + requestParams
    ).then((res) => res.json());
    // this.setState({emptyemailmodal: true})
    let appleAvailable = await AppleAuthentication.isAvailableAsync();
    console.log(12111)
    console.log(appleAvailable)
    this.setState({ requestTokens: requestTokens })
    this.setState({ requestParams: requestParams })
    this.setState({ appleAvailable: appleAvailable })
    agent.Home.userscount()
      .then(res => {
        this.setState({ users_count: res.user_count })
      })
    AsyncStorage.getItem('@email_address')
      .then(res => {
        this.setState({ remember_email: res })
        this.setState({ username: res });
      });
  }

  login = () => {
    if (this.state.remember) {
      AsyncStorage.setItem('@email_address', this.state.username)
    } else {
      AsyncStorage.setItem('@email_address', "")
    }
    agent.Auth.login(this.state.username, this.state.password)
      .then(res => {
        if (res.errors) {
          Toast.show({
            text: res.errors,
            duration: 5000,
            type: "danger",
            textStyle: { textAlign: "center" }
          })
        } else {
          this.props.setAuthToken(res.auth_token);
          this.props.setAuthUser(res);
          agent.setToken(res.auth_token)
          AsyncStorage.setItem('@auth_token', res.auth_token)
          // this.props.navigation.navigate(Routes.HOME);
          this.props.navigation.dispatch(state => {
            return CommonActions.reset({
              routes: [
                { name: Routes.HOME, params: { first_time: "true" } }
              ],
              index: 0,
            });
          });
        }
      })
  };

  forgotpassword = () => {
    agent.Auth.forgotpassword(this.state.forgotemail)
      .then(res => {
        if (res.failure) {
          this.setState({ forgotemailmodal: false });
          Toast.show({
            text: res.failure,
            duration: 3000,
            type: "danger",
            textStyle: { textAlign: "center" }
          })
        } else {
          this.setState({ forgotemailmodal: false });
          Toast.show({
            text: 'Please check your email for instructions!',
            duration: 3000,
            type: "success",
            textStyle: { textAlign: "center" }
          })
        }
      })
  };

  toQueryString = (params) => {
    return (
      "?" +
      Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
  }

  createFbAccount = (token) => {
    fetch(`https://graph.facebook.com/me?access_token=${token}&fields=id,name,email`)
      .then(response => response.json())
      .then(data => {
        agent.Auth.fbloginnew(token, data.id, data.email, data)
          .then(res => {
            if (res.errors) {
              // if (res.code == 'open_email_popup') {
              //   this.setState({twfunc: "login"})
              //   this.setState({emptyemailmodal: true})
              //   this.setState({twtoken: token})
              //   this.setState({twtwitterid: data.id})
              //   this.setState({emptyemailsocial: "facebook"})
              // } else {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
              // }
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.setAuthUser(res);
              agent.setToken(res.auth_token)
              AsyncStorage.setItem('@auth_token', res.auth_token)

              // this.props.navigation.navigate(Routes.HOME);
              this.props.navigation.dispatch(state => {
                return CommonActions.reset({
                  routes: [
                    { name: Routes.HOME, params: { first_time: "true" } }
                  ],
                  index: 0,
                });
              });
              agent.setToken(res.auth_token)
            }
          })
      })
      .catch(e => console.log(e))
  }

  facebookLogin = async () => {
    try {
      await Facebook.initializeAsync({
        appId: '1637825516471711',
      });
      const {
        type,
        token,
        expires,
        permissions,
        declinedPermissions,
      } = await Facebook.logInWithReadPermissionsAsync({
        permissions: ['public_profile', 'email'],
      });
      if (type === 'success') {
        // Get the user's name using Facebook's Graph API
        fetch(`https://graph.facebook.com/me?access_token=${token}&fields=id,name,email`)
          .then(response => response.json())
          .then(data => {
            agent.Auth.fbloginnew(token, data.id, data.email, data)
              .then(res => {
                if (res.errors) {
                  // if (res.code == 'open_email_popup') {
                  //   this.setState({twfunc: "login"})
                  //   this.setState({emptyemailmodal: true})
                  //   this.setState({twtoken: token})
                  //   this.setState({twtwitterid: data.id})
                  //   this.setState({emptyemailsocial: "facebook"})
                  // } else {
                  Toast.show({
                    text: res.errors,
                    duration: 3000,
                    type: "danger",
                    textStyle: { textAlign: "center" }
                  })
                  // }
                } else {
                  this.props.setAuthToken(res.auth_token);
                  this.props.setAuthUser(res);
                  agent.setToken(res.auth_token)
                  AsyncStorage.setItem('@auth_token', res.auth_token)

                  // this.props.navigation.navigate(Routes.HOME);
                  this.props.navigation.dispatch(state => {
                    return CommonActions.reset({
                      routes: [
                        { name: Routes.HOME, params: { first_time: "true" } }
                      ],
                      index: 0,
                    });
                  });
                  agent.setToken(res.auth_token)
                }
              })
          })
          .catch(e => console.log(e))
      } else {
        // type === 'cancel'
      }
    } catch ({ message }) {
      Toast.show({
        text: 'There was an error logging in to Facebook.' + message,
        duration: 3000,
        type: "danger",
        textStyle: { textAlign: "center" }
      })
    }
  }

  twitterLogin = async () => {

    try {
      // Step #1 - first we need to fetch a request token to start the browser-based authentication flow
      // const requestParams = this.toQueryString({ callback_url: redirect });


      console.log("Request tokens fetched!", this.state.requestTokens);

      // Step #2 - after we received the request tokens, we can start the auth session flow using these tokens
      const authResponse = await AuthSession.startAsync({
        authUrl: "https://api.twitter.com/oauth/authenticate" + this.toQueryString(this.state.requestTokens),
        showInRecents: true,
        returnUrl: redirect
      });

      console.log("Auth response received!", authResponse);

      // Validate if the auth session response is successful
      // Note, we still receive a `authResponse.type = 'success'`, thats why we need to check on the params itself
      if (authResponse.params && authResponse.params.denied) {
        Toast.show({
          text: 'Please authorize the app',
          duration: 3000,
          type: "danger",
          textStyle: { textAlign: "center" }
        })
      }

      // Step #3 - when the user (successfully) authorized the app, we will receive a verification code.
      // With this code we can request an access token and finish the auth flow.
      const accessParams = this.toQueryString({
        oauth_token: this.state.requestTokens.oauth_token,
        oauth_token_secret: this.state.requestTokens.oauth_token_secret,
        oauth_verifier: authResponse.params.oauth_verifier,
      });
      const accessTokens = await fetch(
        accessTokenURL + accessParams
      ).then((res) => res.json());

      console.log("Access tokens fetched!", accessTokens);

      // Now let's store the username in our state to render it.
      // You might want to store the `oauth_token` and `oauth_token_secret` for future use.
      // setUsername(accessTokens.screen_name);
      // if (accessTokens.email != "" && accessTokens.email != "null" && accessTokens.email != null) {
      agent.Auth.twloginnew(authResponse.params.oauth_token, accessTokens.profile.twitter_id, accessTokens.email, accessTokens)
        .then(res => {
          if (res.errors) {
            // if (res.code == 'open_email_popup') {
            //   this.setState({twfunc: "login"})
            //   this.setState({emptyemailmodal: true})
            //   this.setState({twtoken: authResponse.params.oauth_token})
            //   this.setState({twtwitterid: accessTokens.twitter_id})
            //   this.setState({emptyemailsocial: "twitter"})
            // } else {
            Toast.show({
              text: res.errors,
              duration: 3000,
              type: "danger",
              textStyle: { textAlign: "center" }
            })
            // }
          } else {
            this.props.setAuthToken(res.auth_token);
            this.props.setAuthUser(res);
            agent.setToken(res.auth_token)
            AsyncStorage.setItem('@auth_token', res.auth_token)

            // this.props.navigation.navigate(Routes.HOME);
            this.props.navigation.dispatch(state => {
              return CommonActions.reset({
                routes: [
                  { name: Routes.HOME, params: { first_time: "true" } }
                ],
                index: 0,
              });
            });
            agent.setToken(res.auth_token)
          }
        })
      // } else {
      //   this.setState({twfunc: "login"})
      //   this.setState({emptyemailmodal: true})
      //   this.setState({twtoken: authResponse.params.oauth_token})
      //   this.setState({twtwitterid: accessTokens.twitter_id})
      //   this.setState({emptyemailsocial: "twitter"})
      // }
    } catch (error) {
      console.log("Something went wrong...", error);
      Toast.show({
        text: error.message,
        duration: 3000,
        type: "danger",
        textStyle: { textAlign: "center" }
      })
    } finally {

    }
  };

  skip = () => {
    // this.props.navigation.navigate(Routes.HOME);
    this.props.navigation.dispatch(state => {
      return CommonActions.reset({
        routes: [
          { name: Routes.HOME, params: { first_time: "true" } }
        ],
        index: 0,
      });
    });
  }

  signup = () => {
    this.props.navigation.navigate(Routes.SIGN_UP);
  }

  about = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/about_us",
      page_title: "About Townhall USA"
    });
  }

  terms = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/terms_of_service",
      page_title: "Terms of Use"
    });
  }

  privacy = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/privacy_policy",
      page_title: "Privacy Policy"
    });
  }

  changeIcon = () => {
    this.setState(prevState => ({
      passwordicon: prevState.passwordicon === "eye" ? "eye-off" : "eye",
      password1: !prevState.password1
    }));
  }

  callTwitterFunction = () => {
    console.log(this.state.twfunc)
    if (this.state.twfunc == "login") {
      this.setState({ emptyemailmodal: false });
      if (this.state.emptyemailsocial == "twitter") {
        agent.Auth.twlogin(this.state.emptyemail, this.state.twtoken, 'twitter', this.state.twtwitterid)
          .then(res => {
            if (res.errors) {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.setAuthUser(res);
              agent.setToken(res.auth_token)
              // this.props.navigation.navigate(Routes.HOME);
              this.props.navigation.dispatch(state => {
                return CommonActions.reset({
                  routes: [
                    { name: Routes.HOME, params: { first_time: "true" } }
                  ],
                  index: 0,
                });
              });
              agent.setToken(res.auth_token)
            }
          })
      } else if (this.state.emptyemailsocial == "apple") {
        agent.Auth.applelogin(this.state.emptyemail, this.state.twtoken, 'apple', this.state.twtwitterid)
          .then(res => {
            if (res.errors) {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.setAuthUser(res);
              agent.setToken(res.auth_token)
              // this.props.navigation.navigate(Routes.HOME);
              this.props.navigation.dispatch(state => {
                return CommonActions.reset({
                  routes: [
                    { name: Routes.HOME, params: { first_time: "true" } }
                  ],
                  index: 0,
                });
              });
              agent.setToken(res.auth_token)
            }
          })
      } else if (this.state.emptyemailsocial == "facebook") {
        agent.Auth.fblogin(this.state.emptyemail, this.state.twtoken, 'facebook', this.state.twtwitterid)
          .then(res => {
            if (res.errors) {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.setAuthUser(res);
              agent.setToken(res.auth_token)
              // this.props.navigation.navigate(Routes.HOME);
              this.props.navigation.dispatch(state => {
                return CommonActions.reset({
                  routes: [
                    { name: Routes.HOME, params: { first_time: "true" } }
                  ],
                  index: 0,
                });
              });
              agent.setToken(res.auth_token)
            }
          })
      }
    } else if (this.state.twfunc == "signup") {

    }
  }

  AppleAuthenticationButton = () => {
    return (
      this.state.appleAvailable && <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
        cornerRadius={25}
        style={[styles.sqrbuttonSmall, { marginTop: 20 }]}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });
            console.log(111111111)
            console.log(credential)
            // if(credential.email != null) {
            agent.Auth.appleloginnew(credential.authorizationCode, credential.user, credential.email, credential)
              .then(res => {
                if (res.errors) {
                  // if (res.code == 'open_email_popup') {
                  //   this.setState({twfunc: "login"})
                  //   this.setState({emptyemailmodal: true})
                  //   this.setState({twtoken: credential.authorizationCode})
                  //   this.setState({twtwitterid: credential.user})
                  //   this.setState({emptyemailsocial: "apple"})
                  // } else {
                  Toast.show({
                    text: res.errors,
                    duration: 3000,
                    type: "danger",
                    textStyle: { textAlign: "center" }
                  })
                  // }
                } else {
                  this.props.setAuthToken(res.auth_token);
                  this.props.setAuthUser(res);
                  agent.setToken(res.auth_token)
                  AsyncStorage.setItem('@auth_token', res.auth_token)

                  // this.props.navigation.navigate(Routes.HOME);
                  this.props.navigation.dispatch(state => {
                    return CommonActions.reset({
                      routes: [
                        { name: Routes.HOME, params: { first_time: "true" } }
                      ],
                      index: 0,
                    });
                  });
                  // agent.setToken(res.auth_token)
                }
              })
            // } else {
            //   this.setState({twfunc: "login"})
            //   this.setState({emptyemailmodal: true})
            //   this.setState({twtoken: credential.authorizationCode})
            //   this.setState({twtwitterid: credential.identityToken})
            //   this.setState({emptyemailsocial: "apple"})
            // }
            // signed in
          } catch (e) {
            if (e.code === 'ERR_CANCELED') {
              Toast.show({
                text: "There was an error signing in." + e,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              Toast.show({
                text: "There was an error signing in." + e,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            }
          }
        }}
      />
    );
  }

  initGoogleAsync = async () => {
    await GoogleSignIn.initAsync({
      // You may ommit the clientId when the firebase `googleServicesFile` is configured
    });
    this._syncUserWithStateAsync();
  };

  _syncUserWithStateAsync = async () => {
    const user = await GoogleSignIn.signInSilentlyAsync();
    console.log(user)
    agent.Auth.googleloginnew(user.auth.access_token, user.uid, user.email, user)
      .then(res => {
        if (res.errors) {
          // if (res.code == 'open_email_popup') {
          //   this.setState({twfunc: "login"})
          //   this.setState({emptyemailmodal: true})
          //   this.setState({twtoken: credential.authorizationCode})
          //   this.setState({twtwitterid: credential.user})
          //   this.setState({emptyemailsocial: "apple"})
          // } else {
          Toast.show({
            text: res.errors,
            duration: 3000,
            type: "danger",
            textStyle: { textAlign: "center" }
          })
          // }
        } else {
          this.props.setAuthToken(res.auth_token);
          this.props.setAuthUser(res);
          agent.setToken(res.auth_token)
          AsyncStorage.setItem('@auth_token', res.auth_token)

          // this.props.navigation.navigate(Routes.HOME);
          this.props.navigation.dispatch(state => {
            return CommonActions.reset({
              routes: [
                { name: Routes.HOME, params: { first_time: "true" } }
              ],
              index: 0,
            });
          });
          // agent.setToken(res.auth_token)
        }
      })
    // this.setState({ user });
  };

  signInGoogle = async () => {
    try {
      await GoogleSignIn.askForPlayServicesAsync();
      const { type, user } = await GoogleSignIn.signInAsync();
      if (type === 'success') {
        this._syncUserWithStateAsync();
      }
    } catch ({ message }) {
      alert('login: Error:' + message);
    }
  };

  /*{
    "authorization": {
      "state": "[STATE]",
      "code": "[CODE]",
      "id_token": "[ID_TOKEN]"
    },
    "user": {
      "email": "[EMAIL]",
      "name": {
        "firstName": "[FIRST_NAME]",
        "lastName": "[LAST_NAME]"
      }
    }
}*/
  appleResponse = async (response) => {
    console.log(response)
    // Handle case when user does not share email as well
    if (!response.error) {
      let email = null
      if (response.authorization.user) {
        email = response.authorization.user.email
      }
      let decoded = jwtDecode(response.authorization.id_token)
      agent.Auth.appleloginnew(response.authorization.code, decoded.sub, email, response)
        .then(res => {
          if (res.errors) {
            // if (res.code == 'open_email_popup') {
            //   this.setState({twfunc: "login"})
            //   this.setState({emptyemailmodal: true})
            //   this.setState({twtoken: credential.authorizationCode})
            //   this.setState({twtwitterid: credential.user})
            //   this.setState({emptyemailsocial: "apple"})
            // } else {
            Toast.show({
              text: res.errors,
              duration: 3000,
              type: "danger",
              textStyle: { textAlign: "center" }
            })
            // }
          } else {
            this.props.setAuthToken(res.auth_token);
            this.props.setAuthUser(res);
            agent.setToken(res.auth_token)
            AsyncStorage.setItem('@auth_token', res.auth_token)

            // this.props.navigation.navigate(Routes.HOME);
            this.props.navigation.dispatch(state => {
              return CommonActions.reset({
                routes: [
                  { name: Routes.HOME, params: { first_time: "true" } }
                ],
                index: 0,
              });
            });
            // agent.setToken(res.auth_token)
          }
        })
    } else {
      Toast.show({
        text: "The action was cancelled",
        duration: 3000,
        type: "danger",
        textStyle: { textAlign: "center" }
      })
    }
  }

  render() {
    return (
      <Container>
        <Header style={styles.container}>
          <Body>
            <Title style={[styles.text, styles.header]}>Townhall USA</Title>
          </Body>
        </Header>
        <Content padder style={styles.container}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.forgotemailmodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Form>
                  <Item floatingLabel>
                    <Label style={styles.modal}>Email Address</Label>
                    <Input style={styles.modal} autoCapitalize='none' onChangeText={(text) => { this.setState({ forgotemail: text }); }} />
                  </Item>
                </Form>
                <View style={styles.forgotactions}>
                  <Button
                    style={[styles.forgotcancel, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({ forgotemailmodal: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>
                  <Button
                    style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({ forgotemailmodal: false });
                    }}
                  >
                    <Text style={styles.textStyle} onPress={this.forgotpassword}>Submit</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.emptyemailmodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={{ fontSize: 12, marginTop: 20 }}>Enter email address that was used to setup Townhall account.</Text>
                <Form>
                  <Item floatingLabel>
                    <Label style={styles.modal}>Email Address</Label>
                    <Input style={styles.modal} autoCapitalize='none' onChangeText={(text) => { this.setState({ emptyemail: text }); }} />
                  </Item>
                </Form>
                <View style={styles.forgotactions}>
                  <Button
                    style={[styles.forgotcancel, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({ emptyemailmodal: false });
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>
                  <Button
                    style={[styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={this.callTwitterFunction}
                  >
                    <Text style={styles.textStyle}>Submit</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Text style={[styles.text, styles.login]}>Sign in</Text>
          <Text style={[styles.text, { fontSize: 15, alignSelf: "center", marginBottom: 15 }]}>{this.state.users_count} Registered Members</Text>
          <Form>
            <Item style={{ width: (DetectDeviceService.isTablet ? 525 : 350), alignSelf: "center" }}>
              <Label style={styles.text}>Email Address: </Label>
              <Input style={[styles.text]} defaultValue={this.state.remember_email} autoCapitalize='none' onChangeText={(text) => { this.setState({ username: text }); }} />
            </Item>
            <Item style={{ width: (DetectDeviceService.isTablet ? 525 : 350), alignSelf: "center" }}>
              <Label style={styles.text}>Password: </Label>
              <Input style={[styles.text]} secureTextEntry={this.state.password1} autoCapitalize='none' onChangeText={(text) => { this.setState({ password: text }); }} />
              <Icon style={styles.text} name={this.state.passwordicon} onPress={() => this.changeIcon()} />
            </Item>
          </Form>
          <View style={styles.actions}>
            <Text style={[styles.text, styles.fpassword]} onPress={() => { this.setState({ forgotemailmodal: true }) }} >Forgot Password?</Text>
          </View>
          <View style={[styles.actions, { marginTop: 15, alignSelf: "center", justifyContent: "center" }]}>
            <Text style={[styles.text]}>
              <CheckBox checked={this.state.remember} style={{ left: 4, marginTop: 0, marginRight: 0 }}
                onPress={() => this.setState({ remember: !this.state.remember })} />  Remember email address</Text>
          </View>
          <Grid>
            <Row style={styles.sqrbutton3Row}>
              <Col>
                <Button style={[styles.sqrbutton3, { alignSelf: "flex-end", marginRight: 30 }]} onPress={this.login}><Text style={styles.buttonText}>Sign in</Text></Button>
              </Col>
              <Col>
                <Button style={[styles.sqrbutton3, { alignSelf: "flex-start", marginLeft: 30 }]} onPress={this.signup}><Text style={styles.buttonText}>Sign up</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text style={[{ justifyContent: "center", alignSelf: "center", marginTop: 20 }, styles.text]}>Or</Text>
              </Col>
            </Row>
            {this.AppleAuthenticationButton()}
            {/* {Platform.OS === 'android' && <Row style={styles.sqrbutton3Row}>
              <Col>
                <Button style={styles.sqrbuttonSmall} onPress={this.signInGoogle}><Text style={styles.buttonText}>Sign in with Google</Text></Button>
              </Col>
            </Row>} */}
            {Platform.OS === 'web' && <Row style={styles.sqrbutton3Row}>
              <Col>
                <GoogleWebLogin requestType="login" props={this.props} />
              </Col>
            </Row>}
            {Platform.OS === 'web' && <Row style={styles.sqrbutton3Row}>
              <Col>
                <AppleLogin
                  clientId="com.yoopineapp.web"
                  redirectURI="https://www.townhallusa.com/callback.html"
                  usePopup={true}
                  callback={this.appleResponse} // Catch the response
                  scope="email name"
                  responseMode="query"
                  render={renderProps => (  //Custom Apple Sign in Button
                    <Button
                      onPress={renderProps.onClick}
                      style={styles.sqrbuttonSmall}
                    >
                      <Text style={styles.text}>Sign in with Apple</Text>
                    </Button>
                  )}
                />
              </Col>
            </Row>}
            {/* <Row style={styles.sqrbutton3Row}>
             <Col>
               {Platform.OS != "web" && <Button style={styles.sqrbuttonSmall} onPress={this.facebookLogin}><Text style={styles.buttonText}>Sign in with Facebook</Text></Button>}
               {Platform.OS == "web" && <FacebookWebLogin createFbAccount={this.createFbAccount}/>}
             </Col>
           </Row> */}
            <Row style={styles.sqrbutton3Row}>
              <Col>
                <Button style={styles.sqrbuttonSmall} onPress={this.twitterLogin}><Text style={styles.buttonText}>Sign in with Twitter</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col><Button style={[styles.extraLinks, { alignSelf: "flex-end", marginRight: (DetectDeviceService.isTablet ? 80 : 10) }]} transparent onPress={this.terms}><Text style={styles.text}>Terms of Use</Text></Button></Col>
              <Col><Button style={[styles.extraLinks, { alignSelf: "flex-start", marginLeft: (DetectDeviceService.isTablet ? 100 : 30) }]} transparent onPress={this.privacy}><Text style={styles.text}>Privacy Policy</Text></Button></Col>
            </Row>
          </Grid>
          <Button style={[styles.sqrbuttonSmall, { marginBottom: 20 }]} onPress={this.about}><Text style={styles.buttonText}>About Townhall USA</Text></Button>
          <Button style={styles.sqrbuttonSmall} onPress={this.skip}><Text style={styles.buttonText}>Skip</Text></Button>
          <Text style={styles.bottomText}>Note: If you are experiencing any issues related to Login with Twitter feature, please reset your password using Forgot Password link in this page.</Text>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)"
  },
  header: {
    fontSize: 25,
    alignSelf: "center"
  },
  text: {
    color: DetectDeviceService.isTablet ? "rgb(87, 87, 87)" : "#FFF",
  },
  modal: {
    color: "#333",
  },
  forgotcancel: {
    height: 40,
    width: 100,
    marginRight: 20
  },
  forgotsubmit: {
    height: 40,
    width: 100
  },
  forgotactions: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  login: {
    alignSelf: "center",
    fontSize: 20
  },
  actions: {
    marginTop: 30,
    alignSelf: "center",
    flexDirection: "row"
  },
  fpassword: {
    textAlign: "center"
  },
  remember: {
    textAlign: "center",
    marginTop: 10,
  },
  emailInput: {
    width: 247,
    height: 46,
    color: "#fff",
    marginTop: 49,
  },
  passwordInput: {
    width: 247,
    height: 50,
    color: "#fff",
    marginTop: 29,
  },
  extraLinks: {
    justifyContent: "center",
  },
  sqrbutton: {
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "rgba(255,255,255,1)",
    width: (DetectDeviceService.isTablet ? 440 : 293),
    height: 33,
  },
  sqrbuttonSmall: {
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    width: (DetectDeviceService.isTablet ? 375 : 225),
    height: 33,
  },
  sqrbutton2: {
    width: 293,
    height: 33,
    marginTop: 20,
    marginLeft: 42
  },
  buttonText: {
    // color: "rgba(33,72,155,1)",
    color: "#000",
    fontSize: 13,
    fontWeight: "500"
  },
  buttonTextBlue: {
    color: "rgba(33,72,155,1)",
    fontSize: 17
  },
  buttonTextWhite: {
    color: "#fff",
    fontSize: 17,
  },
  sqrbutton3: {
    width: (DetectDeviceService.isTablet ? 177 : 118),
    height: 33,
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    fontSize: 17,
    // alignSelf: "center"
  },
  sqrbutton3Row: {
    // marginLeft: 42,
    marginTop: 20,
  },
  sqrbutton5: {
    width: 150,
    height: 38,
    marginTop: 15,
    alignSelf: "center",
    backgroundColor: "rgba(43,94,170,1)",
    justifyContent: "center",
    borderColor: "#fff",
    color: "#fff",
    borderWidth: 2
  },
  sqrbutton6: {
    width: 100,
    height: 38,
    marginTop: 20,
    alignSelf: "center",
    backgroundColor: "rgba(43,94,170,1)",
    justifyContent: "center",
    borderColor: "#fff",
    color: "#fff",
    borderWidth: 2
  },
  bottomText: {
    color: "#808080",
    margin: 15,
    marginTop: 25,
    alignSelf: "center"
  },
  centeredView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
