const COLOR = {
    PRIMARY: '#2B5EAA',
    PRIMARY_DARK: '#392b5b',
    ACCENT: '#8b61ff',
    BUTTON: '#662eff',
    WHITE: '#fff',
    BLACK: '#000',
    RED: '#f54b5e',
};

export default COLOR;
