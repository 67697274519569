import React, { Component } from "react";
import { StyleSheet, View, TextInput, Dimensions } from "react-native";
import {
  Content,
	Button,
  Text
} from "native-base";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { connect } from 'react-redux';
import {
	setListFilter,
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

const mapStateToProps = state => ({ ...state.bill });

const mapDispatchToProps = dispatch => ({
	setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class BillsFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    }
  }

  setFilter = (key, value) => {
    let obj = {}
    obj[key] = value
    //obj = {...this.state.selectedFilter, ...obj}
    obj = {...this.props.selectedFilter, ...obj}
    //this.setState({ selectedFilter: obj });
    this.props.selectedFilter[key] = value

    this.props.setBillsFilter(obj)
  }

  removeFilter = () => {
    let obj = this.props.selectedFilter
    delete obj["filter"]
    delete this.props.selectedFilter["filter"]
    this.props.setBillsFilter(obj)
    console.log(this.props.selectedFilter["filter"])
  }

  setAll = () => {
    this.setFilter('filter', '')
    this.setFilter('category', 'eo')
  }

  render() {
    return (
      <View style={styles.outer}>
        <View style={styles.container}>
          <Text style={styles.label}>Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'eo_recommended') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setFilter('filter', 'eo_recommended')}><Text style={{fontSize: 15, textAlign: "center"}}>Townhall {'\n'} Short List</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == "") } style={[styles.yearOption, {height: 55}]} onPress={() => this.setAll()}><Text style={{fontSize: 15, textAlign: "center"}}>All Presidential Actions</Text></Button>
              </Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Sort</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'action_type') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'action_type')}><Text style={styles.filterText}>Action Type</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'eo_newest') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'eo_newest')}><Text style={styles.filterText}>Newest</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'eo_most_viewed') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'eo_most_viewed')}><Text style={styles.filterText}>Most Viewed</Text></Button>
              </Col>
              <Col size={50}></Col>
            </Row>
          </Grid>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
	outer: {
		backgroundColor: "#ECECEC"
	},
	container: {
    padding: 15,
    marginBottom: 10,
  },

  label: {
    fontSize: 16,
    paddingBottom: 5
  },
  filterBox: {
    backgroundColor: '#ffffff',
    padding: 20
  },
  innerBox: {
    flex: 1,
    flexWrap: "wrap",
    padding: 20,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start'
  },
  yearOption: {
    margin: 5,
    width: "90%",
    justifyContent: 'center'
  },
  filterText: {
    fontSize: 15
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsFilter);
