import React from "react";
import { StyleSheet, View, Modal, KeyboardAvoidingView, Platform } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  List,
  Separator,
  Spinner,
  Toast,
  Form, Item, Label, Input, Textarea
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import { Col, Row, Grid } from "../lib/easy-grid";

import { WebView } from 'react-native-webview';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../styles/Color';
import agent from '../agent';
import { connect } from 'react-redux';

import {
  setOpinions,
  updateOpinion
} from '../ducks/bill';

import BillOpinion from '../components/BillOpinion';
import BillComment from '../components/BillComment';

const mapStateToProps = state => ({ ...state.bill, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setOpinions: (opinions) => dispatch(setOpinions(opinions)),
  updateOpinion: (voteId, vote) => dispatch(updateOpinion(voteId, vote)),
});

class VoteDetailsScreen extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        replymodal: false,
        flagmodal: false,
        comment_text: "",
        newComment: {
          parent_comment: "",
          index: "",
          heading: ""
        },
        newFlag: {
          comment: "",
        },
        flag_comment_text: "",
        details: {},
        loading: true
      }
  }

	componentDidMount() {
    agent.Bill.vote_details(this.props.route.params.vote[0].vote_id)
    .then(res => {
      this.setState({ details: res.vote_details})
      this.setState({loading: false})
    })
  }
  
  openReplyModal = (comment, heading, index, comment_to=null) => {
    this.setState({ replymodal: true})
    let nc = {
      parent_comment: comment,
      heading: heading,
      index: index
    }
    if(comment_to != null)
      nc.comment_to = comment_to
    this.setState({ newComment: nc})
  }

  closeReplyModal = () => {
    this.setState({ replymodal: false})
  }

  openFlagModal = (comment) => {
    this.setState({ flagmodal: true})
    let nf = {
      comment: comment,
    }

    this.setState({ newFlag: nf})
  }

  closeFlagModal = () => {
    this.setState({ flagmodal: false})
  }


  voteComment = () => {
    // let newComment = this.props.comment;
    let newCommentReply = {
      parent_comment_id: this.state.newComment.parent_comment.comment_id,
      vote_id: this.state.newComment.parent_comment.vote_id,
      comment_text: this.state.comment_text,
      user_id: this.props.user.user_id
    }
    if(this.state.newComment.comment_to != "" && this.state.newComment.comment_to != undefined && this.state.newComment.comment_to != "")
      newCommentReply.comment_to_id = this.state.newComment.comment_to.comment_id
    this.setState({ replymodal: false });
    agent.Bill.comment_opinion(newCommentReply)
    .then(res => {
      agent.Bill.vote_details(this.state.newComment.parent_comment.vote_id)
      .then(res => {
        let vote = {
          ...res.vote_details,
        }
        this.setState({ details: res.vote_details})
        this.props.updateOpinion(this.state.newComment.parent_comment.vote_id, vote)
      })
    })
  }

  flagComment = () => {
    // let newComment = this.props.comment;
    this.setState({action_loading: true})
    let newFlagComment = {
      comment_id: this.state.newFlag.comment,
      comment_text: this.state.flag_comment_text,
    }
    
    agent.Bill.flag_comment(newFlagComment.comment_id, newFlagComment.comment_text)
    .then(res => {

      this.setState({ flagmodal: false });
      this.setState({action_loading: false})

      Toast.show({
        text: "This posting has been flagged. Yoopine Admin will review the text and take appropriate action.",
        duration: 5000,
        type: "success",
        textStyle: {textAlign: "center"}
      })

    })
  }


  render() {
    return (
      <Container>
        <Header style={styles.header}>
          <Left style={{flex: 1}}>
            <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon}/>
            </Button>
          </Left>
          <Body style={{flex: 1}}>
            <Title style={styles.textColor}>Voice Your Opinion</Title>
          </Body>
          <Right style={{flex: 1}}>
            <Button transparent onPress={() => props.navigation.goBack()}>
              <EntypoIcon name="share" style={styles.icon}/>
            </Button>
          </Right>
        </Header>

       <Content style={{backgroundColor: "#ececec"}} scrollEnabled={!this.state.replymodal} enableResetScrollToCoords={false}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.replymodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Reply</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Type your comment here"
                            onChangeText={(text) => { this.setState({comment_text: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ replymodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.voteComment()
                  }}
                >
                  <Text style={styles.textStyle} onPress={this.forgotpassword}>Submit</Text>
                </Button>
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.flagmodal}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <KeyboardAvoidingView
          behavior={Platform.OS == "ios" ? "padding" : "height"}
          enabled
          style={{flex: 1}}
           >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{height: 45, alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize: 18, color: "black"}}>Flag</Text>
              </View>
              <Form>
                {/* <Item> */}
                  {/* <Label style={styles.modal}>Email Address</Label> */}
                  <Textarea bordered rowSpan={5} style={styles.modal} placeholder="Flag objectionable content that violate the Terms of Use.  For example: violent threats, and obscene comments."
                            placeholderTextColor="#808080"
                            onChangeText={(text) => { this.setState({flag_comment_text: text }) }} />
                {/* </Item> */}
              </Form>
              <View style={styles.forgotactions}>
                {this.state.action_loading && <Spinner color='blue' style={{height: 40}}></Spinner>}
                {!this.state.action_loading && <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ flagmodal: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>}
                {!this.state.action_loading && <Button
                  style={{ ...styles.forgotsubmit, backgroundColor: "#2196F3" }}
                  onPress={() => {
                    this.flagComment()
                  }}
                >
                  <Text style={styles.textStyle}>Submit</Text>
                </Button>}
              </View>
            </View>
          </View>
          </KeyboardAvoidingView>
        </Modal>
        <List style={[styles.contentMargin, {backgroundColor: "#fff"}]}>
           {
             !this.state.loading && [this.state.details].map(comment => {
               return (
                 <BillOpinion key={comment.id} 
                              pageObject={this.props.details} 
                              allComments={true}
                              commentIndex={this.props.route.params.index}
                              comment={this.props.route.params.comment}
                              heading={this.props.route.params.heading}
                              comment={comment} 
                              openFlagModal={this.openFlagModal} 
                              openReplyModal={this.openReplyModal}/>
               );
             })
           }
         </List>
         {this.state.loading && <Spinner color='blue'></Spinner>}
       </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
	  flex: 1
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center"
  },
  contentMargin: {
    width: DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center",
    paddingTop: DetectDeviceService.isTablet ? 40 : 0
  },
  modal: {
    color: "#333",
  },
  forgotcancel: {
		height: 40,
		width: 85,
    marginRight: 20
  },
  forgotsubmit: {
		height: 40,
		width: 85
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 20,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	textColor: {
    color: "rgba(255,255,255,1)",
    width: 180
	},
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 20
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VoteDetailsScreen);
