import React, { Component } from "react";
import { StyleSheet, View, TextInput, Dimensions } from "react-native";
import {
  Content,
	Button,
  Text
} from "native-base";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { connect } from 'react-redux';
import {
	setListFilter,
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

const mapStateToProps = state => ({ ...state.bill });

const mapDispatchToProps = dispatch => ({
	setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class BillsFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    }
  }

  setFilter = (key, value) => {
    let obj = {}
    obj["category"] = "state"
    console.log(this.props.selectedFilter)
    if(key != 'filter2') {
      obj[key] = value
      //obj = {...this.state.selectedFilter, ...obj}
      obj = {...this.props.selectedFilter, ...obj}
      //this.setState({ selectedFilter: obj });
      this.props.selectedFilter[key] = value
      this.props.setBillsFilter(obj)
    } else {
      if(this.props.selectedFilter[key] == value) {
        obj[key] = ""
        obj = {...this.props.selectedFilter, ...obj}
        this.props.setBillsFilter(obj)
      } else {
        obj[key] = value
        //obj = {...this.state.selectedFilter, ...obj}
        obj = {...this.props.selectedFilter, ...obj}
        //this.setState({ selectedFilter: obj });
        this.props.selectedFilter[key] = value
        this.props.setBillsFilter(obj)
      }
    }
  }

  render() {
    return (
      <View style={styles.outer}>
        <View style={styles.container}>
          <Text style={styles.label}>Congressional Year</Text>
          <Grid style={styles.filterBox}>
          <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[0]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[0])}>
                  <Text style={styles.filterText}>{this.props.filterYears[0]}</Text>
                </Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[1]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[1])}>
                  <Text style={styles.filterText}>{this.props.filterYears[1]}</Text>
                </Button>
              </Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>General Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'state_recommended') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setFilter('filter', 'state_recommended')}><Text style={styles.filterText}>Townhall {'\n'} Short List</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'watchlist') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'watchlist')}><Text style={styles.filterText}>My Watchlist</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'state_proposed') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'state_proposed')}><Text style={styles.filterText}>All State Bills</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'state') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'state')}><Text style={styles.filterText}>Signed Into Law</Text></Button>
              </Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Status Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
             <Col>
                <Button bordered={!(this.props.selectedFilter['filter2'] == null) } style={styles.yearOption} onPress={() => this.setFilter('filter2', null)}><Text style={styles.filterText}>All</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'passed_senate') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'passed_senate')}><Text style={styles.filterText}>Passed Senate</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'passed_house') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'passed_house')}><Text style={styles.filterText}>Passed House</Text></Button>
              </Col>
              <Col>
              <Button bordered={!(this.props.selectedFilter['filter2'] == 'bills_voted') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'bills_voted')}><Text style={styles.filterText}>Bills I voted</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'bills_not_voted') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setFilter('filter2', 'bills_not_voted')}><Text style={[{textAlign: "center"}, styles.filterText]}>Bills I didn't vote</Text></Button>
              </Col>
              <Col></Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Sort</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'issues') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'issues')}><Text style={styles.filterText}>Issues</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'newest') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'newest')}><Text style={styles.filterText}>Newest</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'most_viewed') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'most_viewed')}><Text style={styles.filterText}>Most Viewed</Text></Button>
              </Col>
              <Col size={50}></Col>
            </Row>
          </Grid>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
	outer: {
		backgroundColor: "#ECECEC"
	},
	container: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 5,
    marginBottom: 0,
  },

  label: {
    fontSize: 16,
    paddingBottom: 5
  },
  filterBox: {
    backgroundColor: '#ffffff',
    padding: 20
  },
  innerBox: {
    flex: 1,
    flexWrap: "wrap",
    padding: 20,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start'
  },
  yearOption: {
    margin: 5,
    width: "90%",
    justifyContent: 'center'
  },
  filterText: {
    fontSize: 15
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsFilter);
