const SET_CONNECT_USERS = 'SET_CONNECT_USERS'
const SET_INVITATIONS = 'SET_INVITATIONS'
const SET_NETWORK = 'SET_NETWORK'
const SET_SUGGESTIONS = 'SET_SUGGESTIONS'
const EDIT_NETWORK = 'EDIT_NETWORK'
const DELETE_NETWORK = 'DELETE_NETWORK'
const GET_CONNECT_USERS = 'GET_CONNECT_USERS'

export function setConnectUsers(users) {
  return {
    type: SET_CONNECT_USERS,
    users
  }
}

export function setInvitations(invitations) {
  return {
    type: SET_INVITATIONS,
    invitations
  }
}

export function setNetwork(network) {
  return {
    type: SET_NETWORK,
    network
  }
}

export function setSuggestions(suggestions) {
  return {
    type: SET_SUGGESTIONS,
    suggestions
  }
}

export function editNetwork(user, index) {
  return {
    type: EDIT_NETWORK,
    user,
    index
  }
}

export function deleteNetwork(index) {
  return {
    type: DELETE_NETWORK,
    index
  }
}

const initialState = {
  users: {
    users: [],
    network: [],
    invitations: []
  },
  network: [],
  invitations: {
    invitations: []
  },
  suggestions: {
    suggestions: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONNECT_USERS:
      return Object.assign(
        {},
        state,
        {
          users: action.users
        }
      );
    case SET_INVITATIONS:
      return Object.assign(
        {},
        state,
        {
          invitations: action.invitations
        }
      );
    case SET_NETWORK:
      return Object.assign(
        {},
        state,
        {
          network: action.network
        }
      );
    case SET_SUGGESTIONS:
      return Object.assign(
        {},
        state,
        {
          suggestions: action.suggestions
        }
      );
    case EDIT_NETWORK:
      return Object.assign(
        {},
        state,
        {
          network: [
            ...state.network.slice(0, action.index),
            ...[action.user],
            ...state.network.slice(action.index+1),
          ]
        }
      );
    case DELETE_NETWORK:
      return Object.assign(
        {},
        state,
        {
          network: [
            ...state.network.slice(0, action.index),
            ...state.network.slice(action.index+1),
          ]
        }
      );
    case GET_CONNECT_USERS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
