import bill from './bill';
import auth from './auth';
import connect from './connect';
import home from './home';
import share from './share';
import device from './device';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  bill,
  auth,
  connect,
  home,
  share,
  device
});

export default reducer;