'use strict';

import React from 'react';
import * as Font from 'expo-font';
import { Root } from "native-base";
import { Provider } from 'react-redux';

import 'react-native-gesture-handler';

import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Platform } from 'react-native';

import * as ScreenOrientation from 'expo-screen-orientation';

import { NavigationContainer } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Navigator } from './src/navigation/navigator';
import WebNavigator from './src/navigation/web.navigator';

import { Routes } from './src/navigation/routes';
import { navigationRef } from './src/navigation/rootNavigation';
import * as rootNavigation from './src/navigation/rootNavigation';

import { store, history} from './src/store';
import { ConnectedRouter } from 'connected-react-router'
import { Ionicons } from '@expo/vector-icons';

import { Text } from 'native-base';

import dayjs from 'dayjs';

import * as Device from 'expo-device';

import GLOBAL from './src/globals'

import Constants from 'expo-constants';
import { linking } from './src/navigation/routes';

import ShareMenu from "react-native-share-menu";

if(Text.defaultProps == null) {
  Text.defaultProps = {}
  Text.defaultProps.allowFontScaling = false;
  Text.defaultProps.uppercase = false;
} else {
  Text.defaultProps.allowFontScaling = false;
  Text.defaultProps.uppercase = false;
}

import AppLoading from 'expo-app-loading';
import SplashScreenWeb from './src/screens/web/SplashScreenWeb';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default class App extends React.Component {

  constructor(props){
	  super(props);
		this.state = {
      isReady: false,
      isWebReady: false,
      start_params: {
        test: 1
      },
      isTab: false
    };
    GLOBAL.data = this;
	}

  async loadFonts() {
    await Font.loadAsync({
      Roboto: require("native-base/Fonts/Roboto.ttf"),
      Roboto_medium: require("native-base/Fonts/Roboto_medium.ttf"),
			Ionicons: require("native-base/Fonts/Ionicons.ttf"),
			Entypo: require("native-base/Fonts/Entypo.ttf")
    });
    // Text.defaultProps.style =  { fontFamily: 'Roboto' }
	}

  handleShare = (item) => {
    if(item.data != null)
      GLOBAL.share_data = item
  }

  async componentDidMount() {
    var now = dayjs().unix().toString()
    await AsyncStorage.setItem('@ads_open_time', now)
    await AsyncStorage.setItem('@ads_seen_time', "0")

    if (Platform.OS != "web") {
      await ScreenOrientation.unlockAsync()
    }
    // console.log(222222)
    // console.log(device)
    // Notifications.addNotificationReceivedListener(this._handleNotification);
    // Notifications.addNotificationResponseReceivedListener(this.handleNotificationResponse);
    let devicet = await Device.getDeviceTypeAsync();
    if (devicet == Device.DeviceType.TABLET) {
      window.isTab = true
    } else {
      window.isTab = false
    }

    // ShareMenu.getInitialShare(this.handleShare);

    // device.setDevice(devicet)
    // console.log(device)
    
    if (Platform.OS != "web") {
      Device.getDeviceTypeAsync()
      .then((deviceType) =>
        ScreenOrientation.lockAsync(
          deviceType === Device.DeviceType.PHONE
            ? ScreenOrientation.OrientationLock.PORTRAIT_UP
            : ScreenOrientation.OrientationLock.LANDSCAPE
        )
      )
    }
    
    // this.changeScreenOrientation()

    if(Platform.OS == "web") {
      // let devicet = await Device.getDeviceTypeAsync();
      // this.props.setDevice(devicet)
      AsyncStorage.getItem('@auth_token')
      .then(res => {
        let authToken = res
        let redirect = "";
      });
    }
  }

  changeScreenOrientation = async() => {
    // let deviceId = DeviceInfo.getDeviceId();
    let devicet = await Device.getDeviceTypeAsync();

    if(devicet == Device.DeviceType.TABLET) {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
    }
    //  else {
    //   if(Constants.deviceName.includes("iPad Pro")) {
    //     await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT);
    //   } else {
    //     await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE_LEFT);
    //   }
    // }
  }

  _handleNotification = notification => {
    console.log("*&^%")
    console.log(notification)
  };

  handleNotificationResponse = async response => {
    console.log("1111111")
    console.log(response);
    // response = {
    //   notification: {
    //     request: {
    //       content: {
    //         data: {
    //           body: {
    //             title: "title", message: "message", page: "share_list", id: "59044"
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log(response.notification.request.content.data)
    // this.setState({start_params: response.notification.request.content.data.body})
    await AsyncStorage.setItem('@push_params', JSON.stringify(response.notification.request.content.data))

    // rootNavigation.navigate(Routes.SPLASH);
  };

  fetchResourcesHandleNotif() {
    const fonts = Font.loadAsync({
      Roboto: require("native-base/Fonts/Roboto.ttf"),
      Roboto_medium: require("native-base/Fonts/Roboto_medium.ttf"),
			Ionicons: require("native-base/Fonts/Ionicons.ttf"),
			Entypo: require("native-base/Fonts/Entypo.ttf")
    });
    // const notif = Notifications.addNotificationResponseReceivedListener(this._handleNotificationResponse)

    return Promise.all([fonts]);
  }

  setWebReady = () => {
    this.setState({isWebReady: true})
  }

  render() {
    if (!this.state.isReady) {
      return <AppLoading
        startAsync={this.fetchResourcesHandleNotif}
        onFinish={() => 
          setTimeout(async () => {
            this.setState({ isReady: true })
          }, 2000)
        }
        onError={console.warn}
      />;
    }
    if(Platform.OS == "web" && !this.state.isWebReady) {
      return <Provider store={store}><SplashScreenWeb setWebReady={this.setWebReady} /></Provider>
    }
    if(Platform.OS == "web") {
      return (
        <Root>
          <React.Fragment>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <SafeAreaProvider>
                  <NavigationContainer ref={navigationRef} linking={linking} fallback={<Text>Loading...</Text>}>
                    <WebNavigator initialRouteName={Routes.SPLASH} />
                  </NavigationContainer>
                </SafeAreaProvider>
              </ConnectedRouter>
            </Provider>
          </React.Fragment>
        </Root>
      );
    } else {
      return (
        <Root>
          <React.Fragment>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <SafeAreaProvider>
                  <NavigationContainer ref={navigationRef}>
                    <WebNavigator initialRouteName={Routes.SPLASH} />
                  </NavigationContainer>
                </SafeAreaProvider>
              </ConnectedRouter>
            </Provider>
          </React.Fragment>
        </Root>
      );
    }
  }
};

