import React from "react";
import {
  StyleSheet, Dimensions, View,
  TouchableOpacity, Platform, Modal
} from "react-native";
import {
  Container,
  Header,
  Title,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Footer,
  FooterTab,
  Spinner,
  Content,
  Toast,
  Card,
  CardItem,
  Tabs, Tab, ScrollableTab,
  Thumbnail
} from "native-base";

import { Col, Row, Grid } from "../lib/easy-grid";

import DetectDeviceService from '../lib/devicedetection';

import Menu, { MenuItem } from 'react-native-material-menu';

import agent from '../agent'

import { API_URL } from "../constants/server";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import { MaterialIcons } from '@expo/vector-icons';

import * as MailComposer from 'expo-mail-composer';

import * as Linking from 'expo-linking';

import { WebView } from 'react-native-webview';
import EntypoIcon from "react-native-vector-icons/Entypo";
import { Routes } from '../navigation/routes';
import COLOR from '../styles/Color'
import { connect } from 'react-redux';

import {
  setBillId,
  setListType,
  setListFilter
} from '../ducks/bill';

const mapStateToProps = state => ({ ...state.bill, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setListType: (list) => dispatch(setListType(list)),
  setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class RepresentativeDetailsScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      representative: {},
      address: [],
      opinionvote: "",
      my_representatives: [],
      selectedItems: [],
      bill: {},
      representative_select: "",
      loginWarnPopup: false,
      webviewcounter: 1,
      currentCongress: ""
    }
  }

  _menu = null;
  _iniwebview = null;

  setIniBillRef = ref => {
    this._iniwebview = ref;
  };

  setMenuRef = ref => {
    this._menu = ref;
  };

  hideMenu = () => {
    this._menu.hide();
  };

  showMenu = () => {
    this._menu.show();
  };

  getEmailFromIds = () => {
    let s = []
    let self = this;
    this.state.my_representatives.forEach(function (obj) {
      obj["children"].forEach(function (obj) {
        if (self.state.selectedItems.includes(obj["id"]))
          s.push(obj["email"])
      })
    })
    return s;
  }

  componentDidMount() {
    let currentYear = new Date().getFullYear()
    let currentCongress = ''
    let prevCongress = ''
    if (currentYear % 2 == 0) {
      currentCongress = (currentYear - 1) + "," + currentYear
      prevCongress = (currentYear - 3) + "," + (currentYear - 2)
    } else {
      currentCongress = currentYear + "," + (currentYear + 1)
      prevCongress = (currentYear - 2) + "," + (currentYear - 1)
    }
    this.setState({ currentCongress: currentCongress })
    let self = this;
    agent.Representative.details(this.props.details.id)
      .then(res => {
        this.setState({ representative: res.representative })
        this.setState({ address: res.address })
      })
    this.focusListener = this.props.navigation.addListener('focus', () => {
      agent.Bill.voice_your_opinion(this.props.details.id, this.props.list_url)
        .then(res => {
          // this.props.setOpinions(res);
          // this.setState({loading: false})
          this.setState({ bill: res.object })
          // let userVote = ""
          // try {
          //   userVote = res["My Opinion"][0].user_vote
          // } catch (e) {

          // }
          // this.setState({opinionvote: userVote})
        })

      agent.Bill.user_vote(this.props.details.id, this.props.list_url)
        .then(res => {
          let vote = res.vote != undefined ? res.vote.voting_parameters : "Not Voted"
          this.setState({ opinionvote: vote })
        })
    });

    let rep = []
    if (this.props.list_type == "state") {
      agent.Home.staterepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators" && key != "other_state_assembly_representatives" && key != "other_state_senator") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep }, () => console.log(this.state.my_representatives))
        })
    } else {
      agent.Home.myrepresentatives()
        .then(res => {
          let id = 0;
          Object.keys(res).forEach(function (key) {
            if (key != "other_house_representatives" && key != "other_senators" && key != "other_state_assembly_representatives" && key != "other_state_senator") {
              let obj = {
                id: id,
                name: self.titleCase(key),
                children: res[key]
              }
              rep.push(obj)
              id = id + 1;
            }
          })
          this.setState({ my_representatives: rep }, () => console.log(this.state.my_representatives))
        })
    }
  }

  componentWillUnmount() {
    this.focusListener();
  }

  titleCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
  }

  billTypeUri = (req) => {
    if (this.props.list_type == "initiative") {
      return (API_URL + "api/v1/initiatives/" + this.props.details.id + "?auth_token=" + this.props.auth_token)
    } else if (this.props.list_type == "representative") {
      return (API_URL + "api/v1/representatives/" + this.props.details.id + "?auth_token=" + this.props.auth_token)
    } else {
      return (API_URL + "api/v1/bills/" + this.props.details.id + "?auth_token=" + this.props.auth_token)
    }
  };

  billTypeTitle = (req) => {
    if (this.props.list_type == "initiative") {
      return "Initiative details"
    } else if (this.props.list_type == "eo") {
      return "Executive Order details"
    } else if (this.props.list_type == "representative") {
      return "Representative details"
    } else {
      return "Bill details"
    }
  };

  billTypeText = () => {
    if (this.props.list_type == "initiative") {
      return "Initiative"
    } else if (this.props.list_type == "eo") {
      return "Executive Order"
    } else if (this.props.list_type == "representative") {
      return "Representative"
    } else {
      return "Bill"
    }
  }

  redirectToVyp = () => {
    if (this.props.auth_token != "") {
      this.props.navigation.navigate(Routes.VOICE_YOUR_OPINION);
    } else {
      this.setState({ loginWarnPopup: true })
    }
  }

  gotoDetail = (req) => {
    if (req.url.startsWith("schemabillswatchlist")) {
      let id = req.url.split("schemabillswatchlist://104.154.88.101/api/v1/bills/")[1].split("/")[0]
      agent.Bill.add_watchlist(this.props.details.id)
        .then(res => {
          Toast.show({
            text: 'Added to Watchlist',
            duration: 5000,
            type: "success",
            textStyle: { textAlign: "center" }
          })
        })
      return false
    } else if (req.url.startsWith("schemainitiativesubmit")) {
      let id = req.url.split("schemainitiativesubmit://104.154.88.101/api/v1/initiatives/")[1].split("/")[0]
      this.submitInitiative(id)
      return false;
    } else if (req.url.startsWith("schemainitiativepetition")) {
      let id = this.props.details.id
      this.setState({ representative_select: "initiative" })
      this.SectionedMultiSelect._toggleSelector()
      return false;
    } else if (req.url.startsWith("schemabills")) {
      console.log("12121212121212")
      console.log(this.state.currentCongress)
      this.setState({ webviewcounter: (this.state.webviewcounter + 1) })
      let id = req.url.split("schemabills://104.154.88.101/api/v1/bills/")[1].split("/")[0]
      this.props.setBillId(id);
      this.props.setListType({ type: "fed", url: "/api/v1/bills" });
      this.props.setListFilter({
        filter: 'recommended',
        sort: 'issues',
        years: this.state.currentCongress,
        issue_list: ''
      });
      this.props.navigation.push(Routes.BILL_DETAILS, {
        refreshCurrentTab: (() => {
          this.props.setBillId(this.state.bill.id);
          this.setState({ billListType: "representative" })
          this.props.setListType({ type: "representative", url: "/api/v1/representatives" })
          this.props.setListFilter({});
        }),
        bill_id: id
      });
      return false
    } else {
      return true
    }
  }

  SelectOrRemoveAll = () =>
    this.SectionedMultiSelect && (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          height: 44,
          borderWidth: 0,
          paddingHorizontal: 10,
          backgroundColor: 'darkgrey',
          alignItems: 'center',
        }}
        onPress={
          this.state.selectedItems.length == this.state.my_representatives.length
            ? this.SectionedMultiSelect._removeAllItems
            : this.SectionedMultiSelect._selectAllItems
        }
      >
        <Text style={{ color: 'white', fontWeight: 'bold' }}>
          {this.state.selectedItems.length == this.state.my_representatives.length ? 'Remove' : 'Select'} all
        </Text>
      </TouchableOpacity>
    );

  callRepFunction = () => {
    // console.log(this.state.representative_select)
    if (this.state.representative_select == "email_elected") {
      let emails = this.getEmailFromIds()
      let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
        this.billTypeText() + " Title: " + "\n" +
        this.state.bill.title + '\n\n' +
        this.billTypeText() + " Summary: " + "\n" +
        this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
        "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
        "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
        "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
        "Sincerely,\n" + this.props.user.first_name
      MailComposer.composeAsync({
        recipients: emails,
        body: body
      })
    } else if (this.state.representative_select == "initiative") {
      // let emails = this.getEmailFromIds()
      // MailComposer.composeAsync({
      //   recipients: emails
      // })
      let ini = {}
      ini.representative_ids = this.state.selectedItems
      agent.Bill.update_initiative(ini, this.props.details.id)
        .then(res => {
          this.reloadPage()
        })
    }
  }

  submitInitiative = (id) => {
    agent.Bill.submit_initiative(id)
    agent.Bill.email_initiatives(id)
      .then(res => {
        let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
          this.billTypeText() + " Title: " + "\n" +
          this.state.bill.title + '\n\n' +
          this.billTypeText() + " Summary: " + "\n" +
          this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
          "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
          "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
          "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
          "Sincerely,\n" + this.props.user.first_name
        MailComposer.composeAsync({
          recipients: res.map(r => r.email),
          body: body,
          subject: ''
        })
      })
  }

  renderLoading = () => {
    return <Spinner color="blue" style={{ position: "absolute", height: "100%", width: "100%" }} />
  }

  emailElectedOfficials = () => {
    this.hideMenu()
    this.setState({ representative_select: "email_elected" })
    let self = this;
    setTimeout(function () { self.SectionedMultiSelect._toggleSelector() }, 500);
  }

  emailFriend = () => {
    let body = this.state.bill.supports_count + " people nationwide are supporting this issue." + '\n\n' +
      this.billTypeText() + " Title: " + "\n" +
      this.state.bill.title + '\n\n' +
      this.billTypeText() + " Summary: " + "\n" +
      this.state.bill.summary.replace(/(<([^>]+)>)/ig, '') + '\n\n' +
      "To view details, please download the app from the App Store or the Google Play Store or go to the website.\n\nhttps://www.townhallusa.com" + '\n\n' +
      "App Store - https://itunes.apple.com/in/app/yoopine/id1015813014?mt=8\n\n" +
      "Google Play store - https://play.google.com/store/aps/details?id=com.townhallusa.www\n\n" +
      "Sincerely,\n" + this.props.user.first_name
    console.log(body)
    MailComposer.composeAsync({
      subject: this.state.bill.title,
      body: body
    })
  }

  backButton = () => {
    if (this.props.route.params != undefined && this.props.route.params.notification == "true") {
      this.props.navigation.navigate(Routes.HOME);
    } else {
      if (this.props.route.params != undefined && this.props.route.params.refreshCurrentTab != undefined)
        this.props.route.params.refreshCurrentTab()
      this.props.navigation.goBack()
    }
  }

  reloadPage = () => {
    this._iniwebview.reload()
  }

  postOnFacebook = () => {
    let facebookParameters = [];
    let shareurl;
    if (this.props.list_type == "initiative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "eo") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "representative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    }

    facebookParameters.push('u=' + encodeURI(shareurl));
    facebookParameters.push('quote=' + encodeURI(''));
    const url =
      'https://www.facebook.com/sharer/sharer.php?'
      + facebookParameters.join('&');

    Linking.openURL(url)
      .then((data) => {
        // alert('Facebook Opened');
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  postOnTwitter = () => {
    let facebookParameters = [];
    let shareurl;
    if (this.props.list_type == "initiative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "eo") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else if (this.props.list_type == "representative") {
      shareurl = API_URL + "bills/" + this.state.bill.id;
    } else {
      console.log(1111)
      console.log(this.state.bill)
      shareurl = API_URL + "bills/" + this.state.bill.id.toString();
    }

    facebookParameters.push('url=' + encodeURI(shareurl));
    // facebookParameters.push('text=' + encodeURI(''));
    const url =
      'https://twitter.com/intent/tweet?'
      + facebookParameters.join('&');

    Linking.openURL(url)
      .then((data) => {
        // alert('Facebook Opened');
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  washingtonAddress = () => {
    let a = this.state.address.map((a) => {
      if (a.city == "Washington")
        return a
    })[0]
    if (a == undefined)
      return <Row><Text style={styles.darkFont}>{this.state.representative.address}</Text></Row>
    else
      return <Col>
        <Row><Text style={styles.darkFont}>{a.street}</Text> <Text style={styles.darkFont}>{a.building}</Text></Row>
        <Row><Text style={styles.darkFont}>{a.city}</Text> <Text style={styles.darkFont}>{a.state}</Text></Row>
      </Col>
  }

  otherAddress = () => {
    let a = this.state.address.map((a) => {
      if (a.city != "Washington")
        return a
    })

    if (a.length == 0)
      return null
    else
      return <Col>
        {a.map((aa) => {
          return aa != undefined ? <Col>
            <Row><Text style={styles.darkFont}>{a.street}</Text> <Text style={styles.darkFont}>{aa.building}</Text></Row>
            <Row><Text style={styles.darkFont}>{a.city}</Text> <Text style={styles.darkFont}>{aa.state}</Text></Row>
          </Col> : ""
        })}
      </Col>
  }

  render() {
    return (
      <Container>
        <Header style={styles.header}>
          <Left style={{ flex: 1 }}>
            <Button transparent onPress={() => this.backButton()}>
              <Icon name="arrow-back" style={styles.icon} />
            </Button>
          </Left>
          <Body style={{ flex: 1 }}>
            <Title style={[styles.textColor, { width: 200, alignSelf: "center" }]}>{this.billTypeTitle()}</Title>
          </Body>
          <Right style={{ flex: 1 }}>
            {this.props.auth_token != "" && <Menu ref={this.setMenuRef}
              style={{ marginTop: 40, width: 220 }}
              button={<Icon
                name="share"
                style={styles.icon2}
                onPress={this.showMenu}
                type="Entypo"></Icon>}>
              <MenuItem style={styles.menuOption} onPress={() => this.postOnFacebook()}>
                <Text style={styles.headerMenuText}>Post on Facebook</Text>
              </MenuItem>
              <MenuItem style={styles.menuOption} onPress={() => this.postOnTwitter()}>
                <Text style={styles.headerMenuText}>Post on X</Text>
              </MenuItem>
              <MenuItem style={styles.menuOption} onPress={() => this.emailElectedOfficials()}>
                <Text style={styles.headerMenuText}>Email Elected Officials</Text>
              </MenuItem>
              <MenuItem style={styles.menuOption} onPress={() => this.emailFriend()}>
                <Text style={styles.headerMenuText}>Email a friend</Text>
              </MenuItem>
            </Menu>}
          </Right>
        </Header>
        <Content style={{ paddingLeft: 200, paddingRight: 200 }}>
          <Grid>
            <Row>
              <Card key={this.state.representative.id} style={{ width: "100%" }}>
                <CardItem>
                  <Grid>
                    <Col style={{ width: 75 }}>
                      {this.state.representative.image != undefined && <Thumbnail square source={{ uri: (API_URL + this.state.representative.image.url) }} />}
                    </Col>
                    <Col>
                      <Row>
                        <Text style={{ fontWeight: "bold", fontSize: 12 }}>
                          {this.state.representative.name}
                        </Text>
                      </Row>
                      <Row>
                        <Text style={{ fontSize: 12 }}>
                          {this.state.representative.position}
                        </Text>
                      </Row>
                      <Row>
                        <Text style={{ fontSize: 12 }}>
                          {this.state.representative.party_description}
                        </Text>
                      </Row>
                    </Col>
                  </Grid>
                </CardItem>
              </Card>
            </Row>
            <Row>
              <Tabs renderTabBar={() => <ScrollableTab style={{ width: 250, alignSelf: "center" }} tabsContainerStyle={{ width: 250 }} />}>
                <Tab heading="General">
                  <Grid>
                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Election History</Text></Row>
                    <Col style={styles.backgroundWhite}>
                      <Row><Text style={styles.darkFont}>First Elected: {this.state.representative.first_elected}</Text></Row>
                      <Row><Text style={styles.darkFont}>Next Election: {this.state.representative.next_election}</Text></Row>
                    </Col>

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Washington D.C. Address</Text></Row>
                    {this.washingtonAddress() != undefined && this.washingtonAddress()}

                    {this.otherAddress() != null && <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Other Address</Text></Row>}
                    {this.otherAddress() != null && this.otherAddress()}

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Biography</Text></Row>
                    <Row><Text style={styles.darkFont}>Date of Birth: {this.state.representative.dob}</Text></Row>
                    <Row><Text style={styles.darkFont}>Place of Birth: {this.state.representative.pob}</Text></Row>
                    <Row><Text style={styles.darkFont}>Home: {this.state.representative.home}</Text></Row>

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Education</Text></Row>
                    <Row><Text style={styles.darkFont}>{this.state.representative.education2}</Text></Row>

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Religion</Text></Row>
                    <Row><Text style={styles.darkFont}>{this.state.representative.religion}</Text></Row>

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Experience</Text></Row>
                    <Row><Text style={styles.darkFont}>{this.state.representative.experience}</Text></Row>

                    <Row style={styles.backgroundGrey}><Text style={styles.whiteFont}>Committee Chair</Text></Row>
                    <Row><Text style={styles.darkFont}></Text></Row>
                  </Grid>
                </Tab>
                <Tab heading="Bills">
                  <Text>This is looong text.his is looong text.his is looong text.his is looong text.his is looong text.his is looong text.</Text>
                </Tab>
              </Tabs>
            </Row>
          </Grid>
        </Content>
        <Footer style={styles.footer}>
          {/* <FooterTab> */}
          <Button transparent style={styles.voiceopinionbutton} onPress={this.redirectToVyp}>
            {this.state.opinionvote == "" && <Text style={({ textAlign: "center" }, [styles.textColor])}>Voice Your Opinion</Text>}
            {this.state.opinionvote != "" && <Text style={({ textAlign: "center" }, [styles.textColor])}>My Opinion: {this.state.opinionvote == "Neutral" ? "Not Sure" : this.state.opinionvote}</Text>}
          </Button>
          {/* </FooterTab> */}
        </Footer>
      </Container>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
    flex: 1
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
  icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
  },
  textColor: {
    color: "rgba(255,255,255,1)"
  },
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: DetectDeviceService.isTablet ? "33%" : "100%",
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12
  },
  footer: {
    padding: 5,
    backgroundColor: "white"
  },
  icon2: {
    color: "rgba(255,255,255,1)",
  },
  backgroundGrey: {
    backgroundColor: "#999999",
    color: "#fff",
    padding: 10
  },
  backgroundWhite: {
    backgroundColor: "#ffffff",
    padding: 15
  },
  whiteFont: {
    color: "#fff"
  },
  darkFont: {
    fontSize: 13
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeDetailsScreen);
