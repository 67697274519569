import React, { Component } from "react";
import { StyleSheet, View, TextInput, Dimensions, TouchableOpacity, Platform } from "react-native";
import {
  Content,
  Button,
  Text
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { connect } from 'react-redux';
import {
	setListFilter,
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
	setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class BillsFilter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: []
    }
  }

  componentDidMount() {
    let vals;
    if (this.props.list_filters.issue_list != undefined && this.props.list_filters.issue_list != "") {
      // vals = this.props.list_filters.issue_list.split(":").map((name) => {
      //   return this.props.issues.filter((obj) => { return obj.name == name })[0].id
      // })
      vals = this.props.list_filters.issue_list
    }
    if (vals == undefined || vals.length == 0) {
      vals = this.props.issues.map((obj) => { return obj.id})
    }
    this.setState({ selectedItems: vals })
  }

  onSelectedItemsChange = (selectedItems) => {
    console.log(this.props.issues)
    let selectedIssuesObj = selectedItems.map((id) => { 
      return this.props.issues.filter(obj => obj.id == id )[0] 
    })
    //this.props.issues.map((obj) => selectedItems.includes(obj["id"]))

    let selectedIds = selectedIssuesObj.map((obj) => { return obj.id })
    this.setState({ selectedItems:  selectedIds});
    this.props.setBillsIssues({ selectedItems: selectedIssuesObj });
  };

  renderSelectText = () => {
    const { selectedItems} = this.state

    const selectText = selectedItems.length
      ? `${selectedItems.length} categories selected` : 'Select an issue category'
    return <Text style={{color: "#007aff", fontSize: (Platform.OS == "android" ? 17 : 15)}}>{selectText} (Tap to change)</Text>
  }

  setFilter = (key, value) => {
    let obj = {}
    if(key != 'filter2') {
      obj[key] = value
      //obj = {...this.state.selectedFilter, ...obj}
      obj = {...this.props.selectedFilter, ...obj}
      //this.setState({ selectedFilter: obj });
      this.props.selectedFilter[key] = value
      this.props.setBillsFilter(obj)
    } else {
      if(this.props.selectedFilter[key] == value) {
        obj[key] = ""
        obj = {...this.props.selectedFilter, ...obj}
        this.props.setBillsFilter(obj)
      } else {
        obj[key] = value
        //obj = {...this.state.selectedFilter, ...obj}
        obj = {...this.props.selectedFilter, ...obj}
        //this.setState({ selectedFilter: obj });
        this.props.selectedFilter[key] = value
        this.props.setBillsFilter(obj)
      }
    }
  }

  SelectOrRemoveAll = () =>
  this.SectionedMultiSelect && (
    <TouchableOpacity
      style={{
        justifyContent: 'center',
        height: 44,
        borderWidth: 0,
        paddingHorizontal: 10,
        backgroundColor: 'darkgrey',
        alignItems: 'center',
      }}
      onPress={
        this.state.selectedItems.length == this.props.issues.length
          ? this.SectionedMultiSelect._removeAllItems
          : this.SectionedMultiSelect._selectAllItems
      }
    >
      <Text style={{ color: 'white', fontWeight: 'bold' }}>
        {this.state.selectedItems.length == this.props.issues.length ? 'Remove' : 'Select'} all
      </Text>
    </TouchableOpacity>
  );


  render() {
    return (
      <View style={styles.outer}>
        <View style={styles.container}>
          <Text style={styles.label}>Congressional Year</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[0]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[0])}>
                  <Text style={styles.filterText}>{this.props.filterYears[0]}</Text>
                </Button>
              </Col>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['years'] == this.props.filterYears[1]) } style={styles.yearOption} onPress={() => this.setFilter('years', this.props.filterYears[1])}>
                  <Text style={styles.filterText}>{this.props.filterYears[1]}</Text>
                </Button>
              </Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>General Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'recommended') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setFilter('filter', 'recommended')}><Text style={styles.filterText}>Townhall {'\n'} Short List</Text></Button>
              </Col>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'watchlist') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'watchlist')}><Text style={styles.filterText}>My Watchlist</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col style={styles.filterOption}>
              <Button bordered={!(this.props.selectedFilter['filter'] == '') } style={styles.yearOption} onPress={() => this.setFilter('filter', '')}><Text style={styles.filterText}>All Fed Bills</Text></Button>
              </Col>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'fed') } style={styles.yearOption} onPress={() => this.setFilter('filter', 'fed')}><Text style={styles.filterText}>Signed Into Law</Text></Button>
              </Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Status Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
             <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter2'] == null) } style={styles.yearOption} onPress={() => this.setFilter('filter2', null)}><Text style={styles.filterText}>All</Text></Button>
              </Col>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'passed_senate') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'passed_senate')}><Text style={styles.filterText}>Passed Senate</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'passed_house') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'passed_house')}><Text style={styles.filterText}>Passed House</Text></Button>
              </Col>
              <Col style={styles.filterOption}>
              <Button bordered={!(this.props.selectedFilter['filter2'] == 'bills_voted') } style={styles.yearOption} onPress={() => this.setFilter('filter2', 'bills_voted')}><Text style={styles.filterText}>Bills I voted</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['filter2'] == 'bills_not_voted') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setFilter('filter2', 'bills_not_voted')}><Text style={[{textAlign: "center"}, styles.filterText]}>Bills I didn't vote</Text></Button>
              </Col>
              <Col style={styles.filterOption}></Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Sort</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'issues') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'issues')}><Text style={styles.filterText}>Issues</Text></Button>
              </Col>
              <Col style={styles.filterOption}>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'newest') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'newest')}><Text style={styles.filterText}>Newest</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col style={styles.filterOption} size={50}>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'most_viewed') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'most_viewed')}><Text style={styles.filterText}>Most Viewed</Text></Button>
              </Col>
              <Col style={styles.filterOption} size={50}></Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Issue Categories</Text>
          <View style={{backgroundColor: "#fff", paddingBottom: 20, paddingLeft: 10, paddingRight: 10}}>
            <SectionedMultiSelect
              ref={SectionedMultiSelect => (this.SectionedMultiSelect = SectionedMultiSelect)}
              IconRenderer={MaterialIcons}
              items={this.props.issues}
              hideSearch={true}
              uniqueKey="id"
              displayKey="name"
              // subKey="children"
              headerComponent={this.SelectOrRemoveAll}
              selectText="Select categories"
              alwaysShowSelectText={true}
              showDropDowns={false}
              readOnlyHeadings={false}
              showCancelButton={true}
              modalWithSafeAreaView={true}
              onSelectedItemsChange={this.onSelectedItemsChange}
              selectedItems={this.state.selectedItems}
              renderSelectText={this.renderSelectText}
              styleListContainer={{height: 256}}
              styles={{
               chipText: {
                 maxWidth: Dimensions.get('screen').width - 90,
               },
              // itemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              // selectedItemText: {
              //   color: 'blue',
              // },
              // subItemText: {
              //   color: this.state.selectedItems.length ? 'black' : 'lightgrey'
              // },
              item: {
                paddingHorizontal: 10,
              },
              subItem: {
                paddingHorizontal: 10,
              },
              selectedItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              selectedSubItem: {
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
              // selectedSubItemText: {
              //   color: 'blue',
              // },
              scrollView: { paddingHorizontal: 0 },
              container: { backgroundColor: '#ffffff', width: (DetectDeviceService.isTablet ? "40%" : "90%"), alignSelf: "center"},
            }}

            />
            </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
	outer: {
		backgroundColor: "#ECECEC"
	},
	container: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 5,
    marginBottom: 0,
  },

  label: {
    fontSize: 16,
    paddingBottom: 5
  },
  filterBox: {
    backgroundColor: '#ffffff',
    padding: 20
  },
  innerBox: {
    flex: 1,
    // flexWrap: "wrap",
    padding: 20,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start'
  },
  yearOption: {
    width: "90%",
    margin: 5,
    justifyContent: 'center',
  },
  filterText: {
    fontSize: 15,
    textAlign: "center"
  },
  filterOption: {
    width: "50%"
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsFilter);
