import React, { Component } from "react";
import { StyleSheet, View, TextInput, Dimensions } from "react-native";
import {
  Content,
	Button,
  Text
} from "native-base";

import SectionedMultiSelect from 'react-native-sectioned-multi-select';
import {MaterialIcons} from '@expo/vector-icons';

import { connect } from 'react-redux';
import {
	setListFilter,
} from '../ducks/bill';

import { Col, Row, Grid } from "../lib/easy-grid";

const mapStateToProps = state => ({ ...state.bill });

const mapDispatchToProps = dispatch => ({
	setListFilter: (filters) => dispatch(setListFilter(filters)),
});

class InitiativesFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    }
  }

  setFilter = (key, value) => {
    let obj = {}
    obj[key] = value
    //obj = {...this.state.selectedFilter, ...obj}
    obj = {...this.props.selectedFilter, ...obj}
    //this.setState({ selectedFilter: obj });
    this.props.selectedFilter[key] = value
    this.props.setBillsFilter(obj)
  }

  setMyInitiatives = (visibility, filter) => {
    let obj = {}
    obj.sort = "newest"
    if(visibility != undefined && visibility != "") {
      obj.visibility = visibility
    }
    if(filter != undefined) {
      obj.filter = filter
      this.props.selectedFilter["filter"] = filter
    }
    this.props.selectedFilter["visibility"] = visibility
    this.props.setBillsFilter(obj)
    console.log(this.props.selectedFilter)

  }

  render() {
    return (
      <View style={styles.outer}>
        <View style={styles.container}>
          <Text style={styles.label}>Filters</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == 'recommended') } style={[styles.yearOption, {paddingTop: 0, paddingBottom: 0, justifyContent: "center"}]} onPress={() => this.setMyInitiatives(undefined, 'recommended')}><Text style={styles.filterText}>Townhall {'\n'} Short List</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['filter'] == undefined)} style={styles.yearOption} onPress={() => this.setMyInitiatives('', undefined)}><Text style={styles.filterText}>My Initiatives</Text></Button>
              </Col>
            </Row>
            <Row>
              <Col size={50}>
                <Button bordered={!(this.props.selectedFilter['visibility'] == 'national') } style={styles.yearOption} onPress={() => this.setMyInitiatives('national')}><Text style={styles.filterText}>All Initiatives</Text></Button>
              </Col>
              <Col size={50}></Col>
            </Row>
          </Grid>
        </View>
        <View style={styles.container}>
          <Text style={styles.label}>Sort</Text>
          <Grid style={styles.filterBox}>
            <Row>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'newest') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'newest')}><Text style={styles.filterText}>Newest</Text></Button>
              </Col>
              <Col>
                <Button bordered={!(this.props.selectedFilter['sort'] == 'most_viewed') } style={styles.yearOption} onPress={() => this.setFilter('sort', 'most_viewed')}><Text style={styles.filterText}>Most Viewed</Text></Button>
              </Col>
            </Row>
          </Grid>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
	outer: {
		backgroundColor: "#ECECEC"
	},
	container: {
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 5,
    marginBottom: 0,
  },

  label: {
    fontSize: 16,
    paddingBottom: 5
  },
  filterBox: {
    backgroundColor: '#ffffff',
    padding: 20
  },
  innerBox: {
    flex: 1,
    flexWrap: "wrap",
    padding: 20,
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start'
  },
  yearOption: {
    margin: 5,
    width: "90%",
    justifyContent: 'center'
  },
  filterText: {
    fontSize: 15
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InitiativesFilter);
