import { registerRootComponent } from 'expo';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://e06430f3867d4e1fb1d4904b07adb1bb@o1283082.ingest.sentry.io/6492475',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

// import { connectToDevTools } from "react-devtools-core";
// // if (__DEV__) {
//   connectToDevTools({
//     host: "localhost",
//     port: 8097,
//   });
// // }

import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
