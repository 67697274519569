import React, { Component } from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Container,
  Header,
  Title,
  Content,
  Button,
  Icon,
  Left,
  Right,
  Body,
  Text,
  Footer,
  Spinner,
  CheckBox,
  FooterTab
} from "native-base";

import HeaderMenu from '../components/HeaderMenu';

import { Col, Row, Grid } from "../lib/easy-grid";

import DetectDeviceService from '../lib/devicedetection';

import { WebView } from 'react-native-webview';
import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import { Routes } from '../navigation/routes';
import COLOR from '../styles/Color'
import FONT from '../styles/Font'
import { connect } from 'react-redux';

import BreadCrumb from "../components/BreadCrumb";

import { CommonActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const mapStateToProps = state => ({ ...state.device });

const mapDispatchToProps = dispatch => ({
});

class FirstAboutScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      dontshow: false
    }
  }

  componentDidMount() {
    this.setState({spinner: true})
  }

  hideSpinner = () => {
    this.setState({spinner: false})
  }

  redirect = () => {
    this.props.navigation.dispatch(state => {
      return CommonActions.reset({
        routes: [
          { name: Routes.HOME }
        ],
        index: 0,
      });
    });
    // if(this.props.route.params.redirect == "home") {
    //   this.props.navigation.dispatch(state => {
    //     return CommonActions.reset({
    //       routes: [
    //         { name: Routes.HOME }
    //       ],
    //       index: 0,
    //     });
    //   });
    // } else if(this.props.route.params.redirect == "sign_in") {
    //   this.props.navigation.navigate(Routes.SIGN_IN);
    // } else {
    //   this.props.navigation.navigate(Routes.SIGN_IN);
    // }
  }

  skip = () => {
    AsyncStorage.setItem('@skip_about_us', "true")
    this.redirect()
  }

  render() {
    return (
      <Container>
        { !DetectDeviceService.isTablet && <Header style={styles.header}>
          <Left style={{flex: 1}}>
            {/* <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon} />
            </Button> */}
          </Left>
          <Body style={{flex: 1}}>
            <Title style={styles.textColor}>{this.props.route.params.page_title}</Title>
          </Body>
          <Right style={{flex: 1}}>
             <Button transparent light onPress={() => this.skip()}>
              <Text>Next</Text>
            </Button>
          </Right>
        </Header>}

        { DetectDeviceService.isTablet && <HeaderMenu navigation={this.props.navigation}/> }

        { this.state.spinner && <Spinner color="blue" /> }
        <Col style={[{backgroundColor: "#ececec", maxHeight: 60}]} >
          { DetectDeviceService.isTablet && <BreadCrumb key={this.props.route.params.page_title} navigation={this.props.navigation} links={["Home", this.props.route.params.page_title]}></BreadCrumb> }
        </Col>
        <View style={styles.webviewContainer} >
        <WebView onLoad={this.hideSpinner} source={{ uri: this.props.route.params.page_uri }} />
        </View>

        {/* <Footer style={styles.footer}>
          <FooterTab>
            <View style={{alignSelf: "center", flexDirection: "row"}}>
              <CheckBox checked={this.state.dontshow} style={{marginRight: 20}}
                        onPress ={() => this.setState({ dontshow: !this.state.dontshow })} />
              <Text>Don't show this again</Text>
            </View>
          </FooterTab>
        </Footer> */}
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.PRIMARY,
	  flex: 1
  },
  webviewContainer: {
    flex: 1,
    width: DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center",
    paddingRight: (DetectDeviceService.isTablet ? 60 : 0) 
  },
  header: {
    backgroundColor: "rgba(43,94,170,1)",
    alignSelf: "center",
    width: "100%"
  },
	icon: {
    color: "rgba(255,255,255,1)",
    fontSize: 30
	},
	textColor: {
    color: "rgba(255,255,255,1)",
    width: 200
	},
  voiceopinionbutton: {
    backgroundColor: COLOR.PRIMARY,
    width: 80,
    height: 40
  },
  footer: {
    padding: 5
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstAboutScreen);
