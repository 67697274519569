export enum Routes {
  SPLASH = 'Splash',
  AUTH = 'Auth',
  ACCOUNT_SETUP = 'Account Setup',
  SIGN_IN = 'Sign In',
  SIGN_UP = 'Sign Up',
  RESET_PASSWORD = 'Reset Passrord',
  HOME = 'Home',
  BILL_DETAILS = 'Bill Details',
  INITIATIVE_DETAILS = 'Initiative Details',
  REPRESENTATIVE_DETAILS = 'Representative Details',
  VOICE_YOUR_OPINION = 'Voice Your Opinion',
  FILTERS = 'Filters',
  GENERAL_SETTINGS = 'General Settings',
  PRIVACY_SETTINGS = 'Privacy Settings',
  NOTIFICATION_SETTINGS = 'Notification Settings',
  MANAGE_NETWORK = 'Manage Network',
  MANAGE_NETWORK_BLOCKED = 'Manage Network Blocked',
  MANAGE_NETWORK_FOLLOWING = 'Manage Network Following',
  ABOUT = 'About',
  TERMS = 'Terms of use',
  PRIVACY = 'Privacy Policy',
  DISCLOSURES = 'Disclosures',
  VOTE_DETAILS = 'VOTE Details',
  SHARE_DETAILS = 'Share Details',
  CREATE_INITIATIVE = 'Create Initiative',
  FIRSTABOUTUS = 'About Yoopine',
  COMMON_FRIENDS = 'Mutual Friends',
  SHARES = 'Share',
  FEDERAL_BILLS = 'Federal Bills',
  STATE_BILLS = 'State Bills',
  EXECUTIVE_ORDERS = 'Executive Orders',
  INITIATIVES = 'Initiatives',
  MEMBERS = 'Members',
  INVITATIONS = 'Invitations',
  SUGGESTIONS = 'Suggestions',
  MATCH_MY_VOTES = 'Match my votes',
  REPRESENTATIVES = 'Representatives',
  ADS = 'Ads',
  CREATE_ADS = 'Create Ad'
}

export const linking = {
  // prefixes: ['http://localhost:19006/v2/'],
  config: {
    screens: {
      "Splash": '/',
      "Home": '/home',
      "Share": '/shares',
      "Sign In": '/sign_in',
      "Sign Up": '/sign_up',
      "Bill Details": '/bills/:bill_id',
      "Initiative Details": '/initiatives/:bill_id',
      "Representative Details": '/representatives/:bill_id',
      "Voice Your Opinion": '/voice',
      "General Settings": '/general_settings',
      "Privacy Settings": '/privacy_settings',
      "Notification Settings": '/notification_settings',
      "Manage Network": '/manage_network',
      "Manage Network Blocked": '/manage_network_blocked',
      "Manage Network Following": '/manage_network_following',
      "Members": '/members',
      "Invitations": '/invitations',
      "Suggestions": '/suggestions',
      "VOTE Details": '/vote/:vote_id',
      "Share Details": '/share/:share_id',
      "Federal Bills": '/fedbills',
      "State Bills": '/statebills',
      "Executive Orders": '/execorders',
      "Initiatives": '/initiatives',
      "Representatives": '/representatives',
      "Match my votes": '/match_my_votes',
      "Settings": '/settings',
      "Terms of use": '/terms_of_use',
      "Privacy Policy": '/privacy_policy',
      "Disclosures": '/disclosures',
      "About": '/about',
      "Ads": '/ads',
      "Create Ad": '/ads/create'
    }
  },
};