import React, { Component } from "react";
import { StyleSheet, View, Platform, Modal } from "react-native";
import {
  Container,
  Tab,
  Tabs,
  TabHeading,
  ScrollableTab,
  Fab,
  Icon,
  IconNB,
  Content,
  Badge,
  Button,
  Spinner,
  Text
} from 'native-base';

import DetectDeviceService from '../lib/devicedetection';

import {
  TabViewVertical,
  TabBarVertical,
  TabView,
  TabBar,
  SceneMap,
} from 'react-native-vertical-tab-view';

// import {
//   TabView,
//   TabBar,
//   SceneMap,
// } from 'react-native-tab-view';

import ConnectTab from '../screens/ConnectTab';
import SharesScreen from '../screens/SharesScreen';
import BillListing from '../screens/BillListing';
import * as MailComposer from 'expo-mail-composer';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';

import dayjs from 'dayjs';

import LandingScreen from '../screens/LandingScreen';
import { WebView } from 'react-native-webview';

import SafeAreaView from 'react-native-safe-area-view';
import EntypoIcon from "react-native-vector-icons/Entypo";
import COLOR from '../styles/Color'
import FONT from '../styles/Font'
import { Routes } from '../navigation/routes';

import {CommonActions } from '@react-navigation/native';

import agent from '../agent'
import { connect } from 'react-redux';
import {
	setBillId,
	setListFilter,
	setListType,
} from '../ducks/bill';
import {
	setHomeTabCount,
  setAdEnabled
} from '../ducks/home';
import {
	setAuthUserDetails,
} from '../ducks/auth';

import { API_URL } from '../constants/server';

import HeaderMenu from '../components/HeaderMenu';
import { Dimensions } from "react-native";
import RepresentativeListingScreen from "./RepresentativeListingScreen";

const mapStateToProps = state => ({ ...state.bill, ...state.home, ...state.auth, ...state.device });

const mapDispatchToProps = dispatch => ({
	setBillId: (id) => dispatch(setBillId(id)),
	setHomeTabCount: (data) => dispatch(setHomeTabCount(data)),
	setListFilter: (filters) => dispatch(setListFilter(filters)),
  setListType: (list) => dispatch(setListType(list)),
  setAuthUserDetails: (user) => dispatch(setAuthUserDetails(user)),
  setAdEnabled: (user) => dispatch(setAdEnabled(user)),
});

class HomeScreen extends React.Component {

  constructor(props) {
      super(props);
      let currentYear = new Date().getFullYear()
      let currentCongress = ''
      let prevCongress = ''
      if(currentYear % 2 == 0) {
        currentCongress = (currentYear - 1) + "," + currentYear
        prevCongress = (currentYear - 3) + "," + (currentYear - 2)
      } else {
        currentCongress = currentYear + "," + (currentYear + 1)
        prevCongress = (currentYear - 2) + "," + (currentYear - 1)
      }
      this.state = {
        active: false,
        startTab: 0,
        activeTab: 0,
        activesubtab: 0,
        billListType: "",
        shareRefreshVal: 0,
        fedBillRefreshVal: 0,
        stateBillRefreshVal: 0,
        initiativeBillRefreshVal: 0,
        execOrderRefreshVal: 0,
        matchMyVotesRefreshVal: 0,
        repRefreshVal: 0,
        prevCongress: prevCongress,
        currentCongress: currentCongress,
        tab_no: 0,
        loginWarnPopup: false,
        accountnotsetuppopup: false,
        tabroutes: [],
        tabRefreshVal: 0,
        webviewurl: ""
      };
  }

  _fedwebview = null;
  _statewebview = null;
  _iniwebview = null;
  _eowebview = null;

  setFedBillRef = ref => {
    this._fedwebview = ref;
  };
  setStateBillRef = ref => {
    this._statewebview = ref;
  };
  setIniBillRef = ref => {
    this._iniwebview = ref;
  };
  setEoBillRef = ref => {
    this._eowebview = ref;
  };

  _handleNotification = response => {
    let notification = response.notification.request.content.data
    console.log(123123123)
    console.log(notification)
    if(notification.page == "bill_detail") {
      this.setState({billListType: "fed"})
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
      this.props.setBillId(notification.id);
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        refreshCurrentTab: (() => ({})),
        notification: "true",
        bill_id: notification.id
      });
    } else if(notification.page == "initiative_detail") {
      this.setState({billListType: "initiative"})
      this.props.setBillId(notification.body.id);
      this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        refreshCurrentTab: (() => ({})),
        notification: "true",
        bill_id: notification.body.id
      });
    } else if(notification.page == "share_list") {
      let v = parseInt(this.state.shareRefreshVal)
      this.setState({shareRefreshVal: (v+1)})


      this.setState({activeTab: 1})
      // this.setState({activesubtab: 1})
      // this.props.navigation.navigate(Routes.HOME, {
      //   start_tab: 1
      // });
    } else if(notification.page == "invitation_list") {
      let v = parseInt(this.state.tabRefreshVal)
      this.setState({tabRefreshVal: (v+1)})


      this.setState({activeTab: 2})
      this.setState({activesubtab: 1})
      // this.props.navigation.navigate(Routes.HOME, {
      //   start_tab: 2,
      //   sub_start_tab: 1
      // });
    } else if(notification.page == "manage_network") {
      this.props.navigation.navigate(Routes.MANAGE_NETWORK);
    } else if(notification.page == "representatives_list") {
      this.setState({billListType: "representative"})
      this.props.setListType({type: "representative", url: "/api/v1/representatives"})
      this.props.setListFilter({});
      let v = parseInt(this.state.repRefreshVal)
      this.setState({repRefreshVal: (v+1)})
      let startTab = 6
      if(this.isStateCalifornia())
        startTab = 7
      
        this.setState({activeTab: startTab})
      // this.props.navigation.navigate(Routes.HOME, {
      //   start_tab: startTab
      // });
    }
  };


	async componentDidMount() {   
    if(this.props.auth_token != "") {
      Notifications.addNotificationResponseReceivedListener(this._handleNotification);

      let shares_from_time = await AsyncStorage.getItem('@shares_from_time')
      if(shares_from_time == null)
        shares_from_time = ''
		  agent.Home.tabcount(shares_from_time)
		  .then(res => {  
		  	this.props.setHomeTabCount(res);
      })
      agent.Auth.get_personal_details()
      .then(res => {
        this.props.setAuthUserDetails(res)
      })
      agent.Home.ad_enabled()
      .then(res => {
        this.props.setAdEnabled(res.ad_enabled)
        // this.setState({ ad_enabled: res.ad_enabled })
      })
      console.log(this.props)
      if(this.props.route.params != undefined && this.props.route.params.first_time == "true" && Constants.isDevice) {
        const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          // alert('Failed to get push token for push notification!' + ' - ' + finalStatus);
          // return;
        } else {
          const token = (await Notifications.getExpoPushTokenAsync()).data;
          let device = {
            user_id: this.props.user.user_id,
            device_id: Constants.installationId,
            device_token: token,
            device_type: Platform.OS,
            push_type: "expo"
          }
          agent.Auth.update_device(device)
        }
      }

      if(this.props.route.params != undefined && this.props.route.params.start_tab != undefined) {
        // setTimeout(() => this.setState({startTab: this.props.route.params.start_tab}), 1000);
        // setTimeout(() => this.scrollableTabView.goToPage(this.props.route.params.start_tab), 300);

        setTimeout(() => {
          this.setState({activeTab: this.props.route.params.start_tab})
          this.setState({activesubtab: this.props.route.params.sub_start_tab})
        }, 300);

      }
    }
    
    let defroutes = [
      { key: 'home', title: 'Home' },
      { key: 'share', title: 'Share' },
      { key: 'connect', title: 'Connect' },
    ]

    if(DetectDeviceService.isTablet) {
      defroutes.push({ key: 'connectmem', title: 'Members', type: "sub" })
      defroutes.push({ key: 'connectinv', title: 'Invitations', type: "sub" })
      defroutes.push({ key: 'connectsugg', title: 'Suggestions', type: "sub" })
    }

    defroutes.push({ key: 'fedbills', title: 'Federal Bills' })
    
    if(this.isStateCalifornia())
      defroutes.push({ key: 'statebills', title: 'State Bills' })

    defroutes.push({ key: 'execorders', title: 'Exec Orders' })
    defroutes.push({ key: 'initiatives', title: 'Initiatives' })
    defroutes.push({ key: 'representatives', title: 'Representatives' })
    defroutes.push({ key: 'match', title: 'Match my Votes' })

    this.setState({tabroutes: defroutes})

    // this.focusListener = this.props.navigation.addListener('focus', () => {
    //   this.refreshCurrentTab()
    // });
  }

  // componentWillUnmount() {
  //   this.focusListener();
  // }
  
  renderLoading = () => {
    return <Spinner color="blue" style={{position: "absolute", height: "100%", width: "100%"}}/>
  }

  gotoDetail = (req) => {
    console.log(12121212)
    if(req.url.startsWith("schemabills")){
		  let id = req.url.split("schemabills://104.154.88.101/api/v1/bills/")[1].split("/")[0]
			this.props.setBillId(id);
  		this.props.navigation.navigate(Routes.BILL_DETAILS, {
        refreshCurrentTab: this.refreshCurrentTab,
        bill_id: id
      });
      return false
    } else if(req.url.startsWith("schemainitiatives")){
		  let id = req.url.split("schemainitiatives://104.154.88.101/api/v1/initiatives/")[1].split("/")[0]
      this.props.setBillId(id);
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        // refreshCurrentTab: this.refreshCurrentTab
        bill_id: id
      });
      return false
    } else if(req.url.startsWith("schemarepresentative")){
		  let id = req.url.split("schemarepresentative://104.154.88.101/api/v1/representatives/")[1].split("/")[0]
      this.props.setBillId(id);
      this.props.navigation.navigate(Routes.BILL_DETAILS, {
        // refreshCurrentTab: this.refreshCurrentTab
        bill_id: id
      });
      return false
    } else if(req.url.startsWith("schemasettings")){
      this.props.navigation.navigate(Routes.GENERAL_SETTINGS);
      return false;
    } else if(req.url.startsWith("schemainitiativesubmit")) { 
      let id = req.url.split("schemainitiativesubmit://104.154.88.101/api/v1/initiatives/")[1].split("/")[0]
      this.submitInitiative(id)
      return false;
    } else if(req.url == "#") {
			return false
		}
    return true
  };

  setListingPageUrl = (req) => {
    // if(req.nativeEvent.url.startsWith("https")) {
      // this.setState({webviewurl: req.nativeEvent.url})
    // }
    console.log(121212)
    console.log(req)
    // let url = document.getElementById("iframe_id").contentWindow.location.href
  }

  refreshCurrentTab = () => {
    if(this.props.list_type == "initiative") {
      // this._iniwebview.reload()
      this.setState({initiativeBillRefreshVal: (this.state.initiativeBillRefreshVal+1)})
      this._iniwebview.injectJavaScript('document.getElementById('+this.props.details.id+').scrollIntoView()')
    } else if(this.props.list_type == "eo") {
      // this._eowebview.reload()
      this.setState({execOrderRefreshVal: (this.state.execOrderRefreshVal+1)})
      this._eowebview.injectJavaScript('document.getElementById('+this.props.details.id+').scrollIntoView()')
    } else if(this.props.list_type == "fed") {
      // this._fedwebview.reload()
      this.setState({fedBillRefreshVal: (this.state.fedBillRefreshVal+1)})
      setTimeout(() => this._fedwebview.injectJavaScript('document.getElementById('+this.props.details.id+').scrollIntoView()'), 2000);
    } else if(this.props.list_type == "state") {
      // this._statewebview.reload()
      this.setState({stateBillRefreshVal: (this.state.stateBillRefreshVal+1)})
      this._statewebview.injectJavaScript('document.getElementById('+this.props.details.id+').scrollIntoView()')
    }
  }

  setActiveTab = (tab_i) => {
    this.setState({activeTab: tab_i})
    this.setState({webviewurl: ""})
    if(tab_i == 1) {
      let shares_from_time = dayjs().toISOString();
      AsyncStorage.setItem('@shares_from_time', shares_from_time)
      let v = parseInt(this.state.shareRefreshVal) + 1
      this.setState({shareRefreshVal: v})
      agent.Home.tabcount(shares_from_time)
		  .then(res => {  
		  	this.props.setHomeTabCount(res);
      })
    }
    if(!DetectDeviceService.isTablet && tab_i == 3 || DetectDeviceService.isTablet && tab_i == 6) {
      this.setState({billListType: "fed"})
      this.props.setListType({type: "fed", url: "/api/v1/bills"})
      this.props.setListFilter({
        filter: 'recommended',
        sort: 'issues',
        years: this.state.currentCongress,
        issue_list: ''
      });
      let v = parseInt(this.state.fedBillRefreshVal) + 1
      this.setState({fedBillRefreshVal: v})
    }
    if(this.isStateCalifornia()) {
      if(!DetectDeviceService.isTablet && tab_i == 4 || DetectDeviceService.isTablet && tab_i == 7) {
        this.setState({billListType: "state"})
        this.props.setListType({type: "state", url: "/api/v1/bills"})
        this.props.setListFilter({
          filter: 'state_recommended',
          sort: 'issues',
          category: 'state',
          years: this.state.currentCongress,
        });
        let v = parseInt(this.state.stateBillRefreshVal) + 1
        this.setState({stateBillRefreshVal: v})
      }
      // if(this.isCityMv()) {
      //   if(tab_i == 5) {
      //     this.setState({billListType: "local"})
      //     this.props.setListType({type: "local", url: "/api/v1/bills"})
      //     this.props.setListFilter({
      //       filter: 'local_proposed',
      //       sort: 'newest',
      //       category: 'local',
      //       years: this.state.currentCongress,
      //     });
      //   }
      //   if(tab_i == 6) {
      //     this.setState({billListType: "eo"})
      //     this.props.setListType({type: "eo", url: "/api/v1/bills"})
      //     this.props.setListFilter({
      //       filter: 'eo_recommended',
      //       sort: 'action_type',
      //       category: 'eo'
      //     });
      //   }
      //   if(tab_i == 7) {
      //     this.setState({billListType: "initiative"})
      //     this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
      //     this.props.setListFilter({
      //       filter: 'recommended',
      //       sort: 'newest',
      //       years: this.state.currentCongress,
      //     });
      //   }
      // } else {
        if(!DetectDeviceService.isTablet && tab_i == 5 || DetectDeviceService.isTablet && tab_i == 8) {
          this.setState({billListType: "eo"})
          this.props.setListType({type: "eo", url: "/api/v1/bills"})
          this.props.setListFilter({
            filter: 'eo_recommended',
            sort: 'action_type',
            category: 'eo'
          });
          let v = parseInt(this.state.execOrderRefreshVal) + 1
          this.setState({execOrderRefreshVal: v})
        }
        if(!DetectDeviceService.isTablet && tab_i == 6 || DetectDeviceService.isTablet && tab_i == 9) {
          this.setState({billListType: "initiative"})
          this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
          this.props.setListFilter({
            filter: 'recommended',
            sort: 'newest',
            years: this.state.currentCongress,
          });
          let v = parseInt(this.state.initiativeBillRefreshVal) + 1
          this.setState({initiativeBillRefreshVal: v})
        }
        if(!DetectDeviceService.isTablet && tab_i == 7 || DetectDeviceService.isTablet && tab_i == 10) {
          this.setState({billListType: "representative"})
          this.props.setListType({type: "representative", url: "/api/v1/representatives"})
          this.props.setListFilter({});
        }
        if(!DetectDeviceService.isTablet && tab_i == 8 || DetectDeviceService.isTablet && tab_i == 11) {
          let v = parseInt(this.state.matchMyVotesRefreshVal) + 1
          this.setState({matchMyVotesRefreshVal: v})
        }
      // }
    } else {
      if(!DetectDeviceService.isTablet && tab_i == 4 || DetectDeviceService.isTablet && tab_i == 7) {
        this.setState({billListType: "eo"})
        this.props.setListType({type: "eo", url: "/api/v1/bills"})
        this.props.setListFilter({
          filter: 'eo_recommended',
          sort: 'action_type',
          category: 'eo'
        });
      } else if(!DetectDeviceService.isTablet && tab_i == 5 || DetectDeviceService.isTablet && tab_i == 8) {
        this.setState({billListType: "initiative"})
        this.props.setListType({type: "initiative", url: "/api/v1/initiatives"})
        this.props.setListFilter({
          filter: 'recommended',
          sort: 'newest',
          years: this.state.currentCongress,
        });
      } else if(!DetectDeviceService.isTablet && tab_i == 6 || DetectDeviceService.isTablet && tab_i == 9) {
        this.setState({billListType: "representative"})
        this.props.setListType({type: "representative", url: "/api/v1/representatives"})
        this.props.setListFilter({});
      } else if(!DetectDeviceService.isTablet && tab_i == 7 || DetectDeviceService.isTablet && tab_i == 10) {
        let v = parseInt(this.state.matchMyVotesRefreshVal) + 1
        this.setState({matchMyVotesRefreshVal: v})
      }
    }
  }

  resetwebviewurl = () => {
    this.setState({webviewurl: ""})
    this.setState({fedBillRefreshVal: (this.state.fedBillRefreshVal + 1)})
  }

  setBillUrl = () => {
    let url;

    // if(this.state.webviewurl != "") {
    //   url = this.state.webviewurl
    // } else {
    //if(billType == "fed") {
    url = API_URL + this.props.list_url + "?" + this.toQueryString(this.props.list_filters)+"&sync=true"
    if(this.props.auth_token != "")
      url = url + "&auth_token="+this.props.auth_token
    //}
    // }
    return url;
  }

  toQueryString = (params) =>{
    return (
      Object.entries(params)
      .map(
        ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")
    );
  }

  tabHeadingView = (title, tabcount) => {
    return <TabHeading style={styles.tabs}>
             <Text style={styles.tabHeading}>{title}</Text>
             {this.props.auth_token != "" && <Badge><Text>{tabcount}</Text></Badge>}
           </TabHeading>
  }

  isStateCalifornia = () => {
    if(this.props.user.state)
      return ["California", "california", "ca", "CA", "Ca"].includes(this.props.user.state)
    else
      return false
  }

  isCityMv = () => {
    if(this.props.user.city)
      return ["mountain view"].includes(this.props.user.city.toLowerCase())
    else
      return false
  }

  submitInitiative = (id) => {
    agent.Bill.submit_initiative(id)
    MailComposer.composeAsync({
      recipients: ["admin@yoopine.com"]
    })
  }

  createInitiative = () => {
    if(this.props.auth_token != "") {
      this.props.navigation.navigate(Routes.CREATE_INITIATIVE)
    } else {
      this.setState({loginWarnPopup: true})
    }
  }

  HomeRoute = () => (
    <View style={{ height: 120, flex: 1, flexDirection: "row", paddingRight: (DetectDeviceService.isTablet ? 0 : 0), backgroundColor: "#ececec", }}>
      <LandingScreen navigation={this.props.navigation} key={this.props.auth_token} />
    </View>
  )

  ShareRoute = () => (
    <SharesScreen navigation={this.props.navigation} key={this.state.shareRefreshVal}/>
  )

  FedBillsRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <BillListing key={this.setBillUrl()} navigation={this.props.navigation} uri={this.setBillUrl()} currentCongress={this.state.currentCongress} prevCongress={this.state.prevCongress} setBillUrl={this.setBillUrl}/>
    </Container>
    // <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
    //   <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
    //     ref={this.setFedBillRef}
    //     source={{ uri: this.setBillUrl() }} key={this.state.fedBillRefreshVal}
    //     incognito={true}
    //     startInLoadingState={true} renderLoading={this.renderLoading} />
    //   <View style={styles.filterButton}>
    //     <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
    //       prevCongress: this.state.prevCongress,
    //       currentCongress: this.state.currentCongress,
    //       refresh: this.resetwebviewurl
    //     })}>
    //       <Icon name="ios-funnel" type="Ionicons" />
    //     </Button>
    //   </View>
    //   <View style={styles.filterButtonText}>
    //     <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
    //       <Text style={{ color: "#fff" }}>Filters</Text>
    //     </Badge>
    //   </View>
    // </Container>
  )

  ConnectRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={this.state.activesubtab}/>
  )
  ConnectInvRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={1}/>
  )
  ConnectSuggRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={2}/>
  )
  ConnectMemRoute = () => (
    <ConnectTab key={this.state.tabRefreshVal} navigation={this.props.navigation} activesubtab={0}/>
  )

  ExecOrerRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setEoBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.execOrderRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  InitiativesRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemainitiativesubmit://", "schemainitiatives://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} onLoad={this.setListingPageUrl}
        ref={this.setIniBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.initiativeBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
      <View style={styles.addInitiativeButton}>
        <Button info rounded onPress={() => this.createInitiative()}>
          <Icon name="plus" type="FontAwesome" />
        </Button>
      </View>
      <View style={styles.addInitiativeButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Create</Text>
        </Badge>
      </View>
    </Container>
  )

  RepreRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <RepresentativeListingScreen navigation={this.props.navigation} uri={(API_URL+"/api/v1/representatives.json?auth_token="+this.props.auth_token)} setBillUrl={this.setBillUrl}/>
    </Container>
  )

  MatchRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
    <WebView originWhitelist={["schemasettings:", "schemarepresentative://", "schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         source={{uri: API_URL + "api/v1/users/match_my_votes_page?auth_token="+this.props.auth_token}} 
                         incognito={true} key={this.state.matchMyVotesRefreshVal}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
    </Container>
  )

  StateRoute = () => (
    <Container style={{paddingRight: (DetectDeviceService.isTablet ? 200 : 0)}}>
      <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail}
        ref={this.setStateBillRef}
        source={{ uri: this.setBillUrl() }} key={this.state.stateBillRefreshVal}
        incognito={true}
        startInLoadingState={true} renderLoading={this.renderLoading} />
      <View style={styles.filterButton}>
        <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
          prevCongress: this.state.prevCongress,
          currentCongress: this.state.currentCongress,
          refresh: this.resetwebviewurl
        })}>
          <Icon name="ios-funnel" type="Ionicons" />
        </Button>
      </View>
      <View style={styles.filterButtonText}>
        <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
          <Text style={{ color: "#fff" }}>Filters</Text>
        </Badge>
      </View>
    </Container>
  )

  renderScene = SceneMap({
    home: this.HomeRoute,
    share: this.ShareRoute,
    connect: this.ConnectRoute,
    connectmem: this.ConnectMemRoute,
    connectinv: this.ConnectInvRoute,
    connectsugg: this.ConnectSuggRoute,
    fedbills: this.FedBillsRoute,
    execorders: this.ExecOrerRoute,
    initiatives: this.InitiativesRoute,
    representatives: this.RepreRoute,
    match: this.MatchRoute,
    statebills: this.StateRoute,
  });

  renderBadge = (props) => {
    if(props.route.key == "share")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.shares}</Text></Badge>
    else if(props.route.key == "connect")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.connects}</Text></Badge>
    else if(props.route.key == "fedbills")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.fed_bills}</Text></Badge>
    else if(props.route.key == "execorders")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.executive_orders}</Text></Badge>
    // else if(props.route.key == "initiatives")
    //   return <Badge><Text>{props.route.key}</Text></Badge>
    else if(props.route.key == "statebills")
      return <Badge style={{marginTop: -10, marginLeft: 5}}><Text style={{fontSize: 13}}>{this.props.tabcount.state_bills}</Text></Badge>
  }

  renderTabBar = (props) => {
    if (!DetectDeviceService.isTablet) {
      return <TabBar
        {...props}
        scrollEnabled
        // indicatorStyle={{ backgroundColor: 'white' }}
        // style={styles.tabs}
        style={[styles.tabs, {elevation: 0, borderColor: 'rgba(43,94,170,1)', borderBottomWidth: 1, height:50}]}
        indicatorStyle={{backgroundColor: 'yellow', height: 2.5}}
        {...(DetectDeviceService.isTablet? {tabStyle: {width: 240}} : {})}
        renderLabel={({ route, focused, color }) => {
          return <View style={{ flexDirection: "row", width: '100%', alignItems: "center", justifyContent: "center"}}>
             <Text style={{ color: "#fff", textTransform: "uppercase", fontSize: 15}}>
              {route.title}
            </Text>
            {this.props.auth_token != "" && this.renderBadge({ route })}
          </View>
        }}
      // renderBadge={this.renderBadge}
      // labelStyle={{fontSize: 15, paddingRight: 25}}
      />
    } else {
      return <TabBarVertical
      {...props}
      scrollEnabled
      // indicatorStyle={{ backgroundColor: 'white' }}
      // style={styles.tabs}
      style={[styles.leftmenu, { elevation: 0, borderColor: 'rgba(43,94,170,1)', borderBottomWidth: 1 }]}
      indicatorStyle={{ backgroundColor: '#6da8c0', width: 200, marginLeft: 200, height: "100%" }}
      {...(DetectDeviceService.isTablet ? { tabStyle: { height: 45 } } : { height: 80 })}
      renderLabel={({ route, focused, color }) => {
        return <View style={{ flexDirection: "row", width: (DetectDeviceService.isTablet ? '100%' : '120%') }}>
          <Text style={{ color: (focused ? "#fff" : "#000"), textTransform: "uppercase", fontSize: (route.type == "sub" ? 13 : 15), paddingLeft: (route.type == "sub" ? 25 : 0) }}>
            {route.title}
          </Text>
          {this.props.auth_token != "" && this.renderBadge({ route })}
        </View>
      }}
    // renderBadge={this.renderBadge}
    // labelStyle={{fontSize: 15, paddingRight: 25}}
    />
    }
  }

  render() {
    return (
      <SafeAreaView forceInset={{ bottom: 'never' }} style={styles.container}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.loginWarnPopup}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
              </View>
              <View style={{marginBottom: 10}}>
                <Text>Signup and Login to use this feature.</Text>
              </View>
              <View style={styles.forgotactions}>
              <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ loginWarnPopup: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.setState({ loginWarnPopup: false });this.props.navigation.navigate(Routes.SIGN_IN);
                  }}
                >
                  <Text style={styles.textStyle}>Login</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.accountnotsetuppopup}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <View style={{ height: 35, alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                <Text style={{ fontSize: 18, color: "black" }}>Error!</Text>
              </View>
              <View style={{marginBottom: 10}}>
                <Text>Account setup must be completed to use this feature.</Text>
              </View>
              <View style={styles.forgotactions}>
              <Button
                  style={[ styles.forgotcancel, { backgroundColor: "#333" }]}
                  onPress={() => {
                    this.setState({ accountnotsetuppopup: false });
                  }}
                >
                  <Text style={styles.textStyle}>Cancel</Text>
                </Button>
                <Button
                  style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                  onPress={() => {
                    this.setState({ accountnotsetuppopup: false });this.props.navigation.navigate(Routes.GENERAL_SETTINGS);
                  }}
                >
                  <Text style={styles.textStyle}>Login</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        <Container style={styles.tabs} key={Dimensions.width}>
          <HeaderMenu navigation={this.props.navigation}/>
            
          {this.state.tabroutes.length > 0 && DetectDeviceService.isTablet && <TabViewVertical
            // lazy
            navigationState={{index: this.state.activeTab, routes: this.state.tabroutes}}
            renderScene={this.renderScene}
            renderTabBar={this.renderTabBar}
            onIndexChange={this.setActiveTab}
          />}

          {this.state.tabroutes.length > 0 && !DetectDeviceService.isTablet && <TabView
            // lazy
            navigationState={{ index: this.state.activeTab, routes: this.state.tabroutes }}
            renderScene={this.renderScene}
            renderTabBar={this.renderTabBar}
            onIndexChange={this.setActiveTab}
          />}
            
            
            {/* <Tabs key={Dimensions.width} ref={(ref) => { this.scrollableTabView = ref; }} initialPage={this.state.startTab} locked={true} onChangeTab={this.setActiveTab} renderTabBar={()=> <ScrollableTab key={Dimensions.width} style={styles.tabs}/>}>
              <Tab key={Dimensions.width} heading={<TabHeading style={styles.tabs}><Text style={styles.tabHeading}>Home</Text></TabHeading>}>
					  	  <View style={{height: 120, flex: 1, flexDirection: "row"}}>
                  <LandingScreen navigation={this.props.navigation} key={this.props.auth_token}/>
					  		</View>
              </Tab>
              <Tab key={Dimensions.width} heading={this.tabHeadingView("Share", this.props.tabcount.shares)}>
                <SharesScreen navigation={this.props.navigation} key={this.state.shareRefreshVal}/>
              </Tab>
              <Tab key={Dimensions.width} heading={this.tabHeadingView("Connect", this.props.tabcount.connects)}>
                <ConnectTab navigation={this.props.navigation} />
              </Tab>
              <Tab heading={this.tabHeadingView("Federal Bills", this.props.tabcount.fed_bills)}>
                <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         ref={this.setFedBillRef}
                         source={{uri: this.setBillUrl()}} key={this.state.fedBillRefreshVal}
                         incognito={true}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
                <View style={styles.filterButton}>
                  <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
                    prevCongress: this.state.prevCongress,
                    currentCongress: this.state.currentCongress
                  })}>
								    <Icon name="ios-funnel" type="Ionicons" />
                  </Button>
                </View>
                <View style={styles.filterButtonText}>
                  <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#fff" }}>Filters</Text>
                  </Badge>
                </View>
              </Tab>
              {this.isStateCalifornia() && <Tab heading={this.tabHeadingView("State Bills", this.props.tabcount.state_bills)}>
                <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         ref={this.setStateBillRef}
                         source={{uri: this.setBillUrl()}} key={this.state.stateBillRefreshVal} 
                         incognito={true}
                         startInLoadingState={true} renderLoading={this.renderLoading}/>
                <View style={styles.filterButton}>
                  <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
                    prevCongress: this.state.prevCongress,
                    currentCongress: this.state.currentCongress
                  })}>
								    <Icon name="ios-funnel" type="Ionicons" />
                  </Button>
                </View>
                <View style={styles.filterButtonText}>
                  <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#fff" }}>Filters</Text>
                  </Badge>
                </View>
              </Tab>}
              <Tab heading={this.tabHeadingView("Executive Orders", this.props.tabcount.executive_orders)}>
                <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         ref={this.setEoBillRef}
                         source={{uri: this.setBillUrl()}} key={this.state.execOrderRefreshVal}
                         incognito={true}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
                <View style={styles.filterButton}>
                  <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
                    prevCongress: this.state.prevCongress,
                    currentCongress: this.state.currentCongress
                  })}>
								    <Icon name="ios-funnel" type="Ionicons" />
                  </Button>
                </View>
                <View style={styles.filterButtonText}>
                  <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#fff" }}>Filters</Text>
                  </Badge>
                </View>
              </Tab>
              <Tab heading={this.tabHeadingView("Initiatives", 0)}>
                <WebView originWhitelist={["schemainitiativesubmit://", "schemainitiatives://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         ref={this.setIniBillRef}
                         source={{uri: this.setBillUrl()}} key={this.state.initiativeBillRefreshVal}
                         incognito={true}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
                <View style={styles.filterButton}>
                  <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
                    prevCongress: this.state.prevCongress,
                    currentCongress: this.state.currentCongress
                  })}>
								    <Icon name="ios-funnel" type="Ionicons" />
                  </Button>
                </View>
                <View style={styles.filterButtonText}>
                  <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#fff" }}>Filters</Text>
                  </Badge>
                </View>
                <View style={styles.addInitiativeButton}>
                  <Button info rounded onPress={() => this.createInitiative()}>
								    <Icon name="plus" type="FontAwesome" />
                  </Button>
                </View>
                <View style={styles.addInitiativeButtonText}>
                  <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ color: "#fff" }}>Create</Text>
                  </Badge>
                </View>
              </Tab>
              <Tab heading={this.tabHeadingView("Representatives", 0)}>
                <WebView originWhitelist={["schemasettings:", "schemarepresentative://", "schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         source={{uri: this.setBillUrl()}} 
                         incognito={true}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
              </Tab>
              <Tab heading={<TabHeading style={styles.tabs}><Text style={styles.tabHeading}>Match My Votes</Text></TabHeading>}>
                <WebView originWhitelist={["schemasettings:", "schemarepresentative://", "schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} 
                         source={{uri: API_URL + "api/v1/users/match_my_votes_page?auth_token="+this.props.auth_token}} 
                         incognito={true} key={this.state.matchMyVotesRefreshVal}
                         startInLoadingState={true} renderLoading={this.renderLoading} />
              </Tab>
              {/* <Tab heading={this.tabHeadingView("Match my Votes", 0)}>
                <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} source={{uri: "http://staging.yoopine.com/api/v1/users/match_my_votes?auth_token="}} />
              </Tab>
              <Tab heading={this.tabHeadingView("Leaderboard", 0)}>
                <WebView originWhitelist={["schemabills://", "https://", "http://"]} onShouldStartLoadWithRequest={this.gotoDetail} source={{uri: "http://staging.yoopine.com/api/v1/scores/leaderboard?auth_token="}} />
              </Tab> *
            </Tabs> */}
	  		</Container>
      </SafeAreaView>
    );
  }

}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000"
  },
  tabHeading: {
    color: "rgba(255,255,255,1)",
    textTransform: "uppercase"
  },
  tabs: {
    backgroundColor: "rgba(43,94,170,1)",
  },
  filterButton: {
		position: "absolute",
    bottom: 40,
    right: 40
  },
  filterButtonText: {
    position: "absolute",
    bottom: 10,
    right: 30
  },
  addInitiativeButton: {
		position: "absolute",
    bottom: 40,
    left: "40%"
  },
  addInitiativeButtonText: {
    position: "absolute",
    bottom: 10,
    left: DetectDeviceService.isTablet ? "39%" : "38%",
  },
  forgotcancel: {
		height: 36,
		width: 90,
    marginRight: 20
  },
  forgotsubmit: {
		height: 36,
		width: 90
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    width: DetectDeviceService.isTablet ? "40%" : "90%",
		margin: 20,
		backgroundColor: "white",
    borderRadius: 5,
    // paddingTop: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  leftmenu: {
    width: 400,
    backgroundColor: "#fff",
    paddingLeft: 200
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
