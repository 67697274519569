import * as React from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { ResponseType } from 'expo-auth-session';
import { StyleSheet } from "react-native";
import {
    Button,
    Text,
  } from "native-base";
import DetectDeviceService from '../lib/devicedetection';
import agent from '../agent'

WebBrowser.maybeCompleteAuthSession();

const FacebookWebLogin = (props) => {
  const createFbAccount = props.createFbAccount;

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: '1637825516471711',
    scopes: ['public_profile', 'email'],
    responseType: ResponseType.Token,
  });

  React.useEffect(() => {
    if (response?.type === 'success') {
      console.log(response)
      const { access_token } = response.params;

      createFbAccount(access_token)
    }
  }, [response]);

  return (
    <Button disabled={!request} style={styles.sqrbuttonSmall} onPress={() => { promptAsync() }}><Text style={styles.buttonText}>Sign in with Facebook</Text></Button>
  );
}

const styles = StyleSheet.create({
    sqrbuttonSmall: {
        alignSelf: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255,255,255,1)",
        width: (DetectDeviceService.isTablet ? 375 : 225),
        height: 33,
      },
    buttonText: {
    // color: "rgba(33,72,155,1)",
    color: "#000",
    fontSize: 13,
    fontWeight: "500"
    },
})

export default FacebookWebLogin;
