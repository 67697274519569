import React, { Component } from "react";
import { StyleSheet, View, Text, TextInput } from "react-native";
import {
  Container, Textarea
} from 'native-base';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';

import agent from '../agent'
import {
	setAuthToken,
	setAuthUser,
} from '../ducks/auth';
import {
	setBillId,
	setListType,
} from '../ducks/bill';

import {
  setDevice
} from '../ducks/device';

import { API_URL } from "../constants/server";

import * as Device from 'expo-device';

import { connect } from 'react-redux';
import { Routes } from '../navigation/routes';
import { CommonActions } from '@react-navigation/native';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  setBillId: (id) => dispatch(setBillId(id)),
  setListType: (list) => dispatch(setListType(list)),
  setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
  setAuthUser: (user) => dispatch(setAuthUser(user)),
  setDevice: (device) => dispatch(setDevice(device)),
});

if(Text.defaultProps == null) {
  Text.defaultProps = {}
  Text.defaultProps.allowFontScaling = false;
}

if(TextInput.defaultProps == null) {
  TextInput.defaultProps = {}
  TextInput.defaultProps.allowFontScaling = false;
}

class SplashScreen extends React.Component {

  async componentDidMount(){
      // Notifications.addNotificationReceivedListener(this._handleNotification);
      // Notifications.addNotificationResponseReceivedListener(this._handleNotification);
      
         // Start counting when the page is loaded
      let devicet = await Device.getDeviceTypeAsync();
      this.props.setDevice(devicet)
      AsyncStorage.getItem('@auth_token')
      .then(res => {
        let authToken = res
        let redirect = "";
        if(authToken != null && authToken != "") {
          redirect = "home"
        } else {
          redirect = "login"
        }
        // this.timeoutHandle = setTimeout(()=>{
          if(redirect == "home") {
            this.props.setAuthToken(authToken);
            agent.setToken(authToken)
            agent.Auth.current()
            .then(res => {
              if(res.err == "invalid_session") {
                agent.setToken("")
                this.props.setAuthToken("");
                AsyncStorage.setItem('@auth_token', "")
                this.props.setAuthUser({});

                this.props.navigation.navigate(Routes.SIGN_IN);

                // AsyncStorage.getItem('@skip_about_us')
                // .then(res => {

                //   if(res != null) {
                //     this.props.navigation.navigate(Routes.SIGN_IN);
                //   } else {
                //     this.props.navigation.navigate(Routes.FIRSTABOUTUS, {
                //       page_uri: API_URL + "api/v1/about_us",
                //       page_title: "About Yoopine",
                //       redirect: "sign_in"
                //     });
                //   }
                // })
              } else {
                this.props.setAuthUser(res)
                           
                // this.props.navigation.navigate(Routes.HOME);
                // this._handleNotification({
                //   request: {
                //     content: {
                //       data: {
                //         page: "share_list"
                //       }
                //     }
                //   }
                // })
                let route = this.props.user.account_setup == true ? Routes.HOME : Routes.GENERAL_SETTINGS
                this.props.navigation.dispatch(state => {
                  return CommonActions.reset({
                    routes: [
                      { name: route, params: { first_time: "true" } }
                    ],
                    index: 0,
                  });
                });
                // AsyncStorage.getItem('@skip_about_us')
                // .then(res => {
                //   if(res != null) {
                //     // AsyncStorage.getItem('@push_params')
                //       // .then(res => {
                //         // if(res != null) {
                //         //   let pparams = JSON.parse(res);
                //         //   AsyncStorage.removeItem('@push_params')
                //         //   this._handleNotification(pparams);
                //         // } else {
                //         //   this.props.navigation.dispatch(state => {
                //         //     return CommonActions.reset({
                //         //       routes: [
                //         //         { name: Routes.HOME, params: { first_time: "true" } }
                //         //       ],
                //         //       index: 0,
                //         //     });
                //         //   });
                //         // }
                //         let route = this.props.user.account_setup == true ? Routes.HOME : Routes.GENERAL_SETTINGS
                //         this.props.navigation.dispatch(state => {
                //           return CommonActions.reset({
                //             routes: [
                //               { name: route, params: { first_time: "true" } }
                //             ],
                //             index: 0,
                //           });
                //         });
                //       // })
                //   } else {
                //     this.props.navigation.navigate(Routes.FIRSTABOUTUS, {
                //       page_uri: API_URL +"api/v1/about_us",
                //       page_title: "About Yoopine",
                //       redirect: "home"
                //     });
                //   }
                // })
              }
            })
          } else {
            this.props.navigation.navigate(Routes.SIGN_IN);

            // AsyncStorage.getItem('@skip_about_us')
            // .then(res => {
            //   if(res != null) {
            //     this.props.navigation.navigate(Routes.SIGN_IN);
            //   } else {
            //     this.props.navigation.navigate(Routes.FIRSTABOUTUS, {
            //       page_uri: API_URL+"api/v1/about_us",
            //       page_title: "About Yoopine",
            //       redirect: "sign_in"
            //     });
            //   }
            // })
          }
        // }, 5000);
      })
    }

    _handleNotification = response => {
      let notification = response.notification.request.content.data
      if(notification.page == "bill_detail") {
        this.setState({billListType: "fed"})
        this.props.setBillId(notification.id);
        this.props.navigation.navigate(Routes.BILL_DETAILS, {
          refreshCurrentTab: (() => ({})),
          notification: "true",
          bill_id: notification.id
        });
      } else if(notification.page == "initiative_detail") {
        this.setState({billListType: "initiative"})
        this.props.setBillId(notification.body.id);
        this.props.navigation.navigate(Routes.BILL_DETAILS, {
          refreshCurrentTab: (() => ({})),
          notification: "true",
          bill_id: notification.body.id
        });
      } else if(notification.page == "share_list") {
        this.props.navigation.navigate(Routes.HOME, {
          start_tab: 1
        });
      } else if(notification.page == "invitation_list") {
        this.props.navigation.navigate(Routes.HOME, {
          start_tab: 2
        });
      } else {
        this.props.navigation.dispatch(state => {
          return CommonActions.reset({
            routes: [
              { name: Routes.HOME, params: { first_time: "true" } }
            ],
            index: 0,
          });
        });
      }
    };

    componentWillUnmount(){
         clearTimeout(this.timeoutHandle); // This is just necessary in the case that the screen is closed before the timeout fires, otherwise it would cause a memory leak that would trigger the transition regardless, breaking the user experience.
    }

  render() {
    return (
      <Container style={styles.container}>
        {/* <View>
          <Text style={styles.mainText}>Yoopine</Text>
          <Text style={styles.subText}>Your Opinion Matters</Text>
        </View> */}
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgba(43,94,170,1)",
  	flex: 1,
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainText: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#ffffff",
    textAlign: "center"
  },
  subText: {
    fontSize: 18,
    color: "#ffffff"
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
